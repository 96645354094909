import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Circle, GoogleMap, InfoWindow, Marker, MarkerF } from "@react-google-maps/api";
import mapStyles from '../../Tracking/mapStyles';
import { useState, useRef, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { ArrowBackIos } from '@mui/icons-material';
import { palette } from '../../../utils/Palette';
import FullScreenModal from '../../FullScreenModal';
import AddContact from '../AddContact';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';

function MapContactView() {



    const [selectedProgress, setSelectedProgress] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const [opendAddContact, setOpenAddContact] = useState(false);
    const userDetails = useSelector(state => state?.auth?.userData)
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedMarker, setSelectedMarker] = useState({});
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },

                (error) => {
                    setCurrentLocation({
                        lat: 38.7946,
                        lng: -106.5348,
                    });
                }
            );
        }
        else {

            setCurrentLocation({
                lat: 38.7946,
                lng: -106.5348,
            });
        }
        setLoader(false)
    }, []);

    const handleOnLoad = (map) => {
        mapRef.current = map;
        fitBoundsToMarkers(map);
    };

    const fitBoundsToMarkers = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng));
        map.fitBounds(bounds);
        new window.google.maps.event.addListenerOnce(map, 'idle', function () {
            if (map.getZoom() > 16) map.setZoom(16);
        });
    };

    const reverseGeocode = async (lat, lng, markerId) => {
        const geocoder = new window.google.maps.Geocoder();
        const location = { lat, lng };
        geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[0]) {
                console.log(results[0], "cdcdsddc")

                const components = results[0].address_components;
                const addressDetails = {
                    street: getFullStreetAddress(components),
                    city: getAddressComponent(components, 'locality') || '',
                    state: getAddressComponent(components, 'administrative_area_level_1') || '',
                    postalCode: getAddressComponent(components, 'postal_code') || '',
                    country: getAddressComponent(components, 'country') || '',
                    address: results[0].formatted_address
                };

                updateMarkerAddress(markerId, addressDetails);
            }
        });
    };
    const getAddressComponent = (components, type) => {
        const component = components.find(comp => comp.types.includes(type));
        return component ? component.long_name : null;
    };

    const getFullStreetAddress = (components) => {
        const premise = getAddressComponent(components, 'premise');
        const sublocalityLevel3 = getAddressComponent(components, 'sublocality_level_3');
        const sublocalityLevel2 = getAddressComponent(components, 'sublocality_level_2');
        const route = getAddressComponent(components, 'route');
        let fullStreet = '';

        if (premise) {
            fullStreet += premise;
        }

        if (sublocalityLevel3) {
            fullStreet += fullStreet ? `, ${sublocalityLevel3}` : sublocalityLevel3;
        }

        if (sublocalityLevel2) {
            fullStreet += fullStreet ? `, ${sublocalityLevel2}` : sublocalityLevel2;
        }

        if (route) {
            fullStreet += fullStreet ? `, ${route}` : route;
        }

        return fullStreet;
    };

    const updateMarkerAddress = (markerId, address) => {
        setMarkers((prevMarkers) => prevMarkers.map(marker =>
            marker.id === markerId ? { ...marker, ...address } : marker
        ));
    };

    const handleMapClick = async (e) => {
        if (selectedProgress) {
            const newMarker = {
                id: markers.length + 1,
                position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                icon: selectedProgress.icon,
                name: selectedProgress.value,
                color: selectedProgress.color,
                address: ''
            };
            setMarkers([...markers, newMarker]);
            await reverseGeocode(newMarker.position.lat, newMarker.position.lng, newMarker.id);
            setSelectedProgress(null);
            setAnchorEl(null);
        }
    };

    const handleIconSelect = (item) => {
        setSelectedProgress(item);
        setAnchorEl(null);
    };

    const handleMarkerClick = (markerId) => {
        if (selectedMarkerId === markerId) {
            setSelectedMarkerId(null);
        } else {
            setSelectedMarkerId(markerId);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const removeMarker = (markerId) => {
        setMarkers((prevMarkers) => prevMarkers.filter(marker => marker.id !== markerId));
    };

    return (
        <>
        <Body>
            {loader ? <StyledPlayer> <Player
                src={require('../../../assets/loaders.json')}
                className="player"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
            />
            </StyledPlayer> : <Container>
                <ProgressContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloseButton onClick={() => navigate(-1)} >
                            <ArrowBackIos style={{ fontSize: '16px' }} />
                        </CloseButton>

                    </div>
                    <StyledButton variant="contained" onClick={handleClick} fullWidth>
                        {selectedProgress?.value ? (
                            <LabelWrapper>
                                <Avatar
                                    src={selectedProgress.icon}
                                    sx={{ marginRight: 1, width: '24px', height: '24px' }}
                                />
                                <Name style={{ color: '#0B3C91', textTransform: 'capitalize' }}>{selectedProgress.value}</Name>
                            </LabelWrapper>
                        ) : (
                            <Name style={{ color: '#0B3C91', textTransform: 'capitalize' }}> {"Add Marker"}</Name>
                        )}
                    </StyledButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}

                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: 'auto',
                                maxWidth: '450px'
                            },
                        }}
                    >
                        <Grid container spacing={2} padding={2}>
                            {userDetails?.statusOptions?.map((item) => (
                                <Grid item xs={4} key={item.value}>

                                    <div onClick={() => handleIconSelect(item)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer', alignSelf: 'center', alignItems: 'center' }}>
                                        <Avatar src={item.icon} sx={{ marginRight: 1, marginBottom: '5px' }} />
                                        <Name>{item.value}</Name>
                                    </div>

                                </Grid>
                            ))}
                        </Grid>
                    </Menu>
                </ProgressContainer>

                {currentLocation?.lat && <MapContainer>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={handleMapClick}
                        clickableIcons
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        options={{ styles: mapStyles }}
                        zoom={5}
                        center={currentLocation}
                    >
                        {currentLocation && (
                            <Circle
                                center={currentLocation}
                                radius={500}

                                options={{
                                    strokeColor: "#0F52BA",
                                    strokeOpacity: 0.5,
                                    strokeWeight: 1,
                                    fillColor: "#0F52BA",
                                    fillOpacity: 0.15,
                                    clickable: false
                                }}
                            />
                        )}
                        <MarkerF
                            position={currentLocation}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 8,
                                fillColor: palette.blue,
                                fillOpacity: 1,
                                strokeWeight: 2,
                                strokeColor: "#ffffff",
                            }}
                        />
                        {markers.map(marker => (
                            <MarkerF
                                key={marker.id}
                                position={marker.position}
                                icon={{
                                    url: marker.icon,
                                    scaledSize: new window.google.maps.Size(50, 50),
                                    fillColor: '#000000',
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: '#000000',
                                    scale: 10 // Adjust scale as needed
                                }}

                                onClick={() => handleMarkerClick(marker.id)}
                            >
                                {selectedMarkerId === marker.id && (
                                    <InfoWindow position={marker.position} onCloseClick={() => setSelectedMarkerId(null)}>
                                        <div>
                                            <Header>
                                                <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Marker Detail</h4>
                                                <RemoveButton onClick={() => removeMarker(marker.id)}>Remove Marker</RemoveButton>
                                            </Header>
                                            <Content>
                                                <strong>{marker.address}</strong>
                                                <div style={{display:'flex',alignItems:'center',marginTop:'15px',fontWeight:'500'}}>
                                                    <img
                                                        src={marker?.icon}
                                                        alt={marker?.icon}
                                                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                                    />
                                                    {marker.name}
                                                   
                                                </div>
                                            </Content>
                                            <ButtonContainer>
                                                <RippleButton onClick={() => {
                                                    setSelectedMarker(marker)
                                                    setOpenAddContact(true)
                                                }} variant="contained">Add Contact</RippleButton>
                                            </ButtonContainer>
                                        </div>
                                    </InfoWindow>
                                )}
                            </MarkerF>
                        ))}
                    </GoogleMap>
                </MapContainer>}
            </Container>}
         
        </Body>
        {opendAddContact && <FullScreenModal
                isOpen={opendAddContact}
                onClose={() => {
                    setOpenAddContact(false)
                }}
            >
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                    <AddContact
                        value={selectedMarker}
                        onCancel={(num) => {

                            setOpenAddContact(false)



                        }}
                        onSubmit={(num) => {

                            setOpenAddContact(false)
                            navigate(`/dashboard/contacts/${num?._id}`)

                        }}
                    />
                </div>
            </FullScreenModal>}
        </>
    );
}

export default MapContactView;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ProgressContainer = styled.div`
    display: flex;
width:100%;
    gap: 20px;
    padding: 10px;
  justify-content:space-between;
  

    overflow-y: auto;
`;

const MapContainer = styled.div`
    flex-grow: 1; /* Allow the map to grow and fill the remaining space */
`;

const Name = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin: 0;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #F44336;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
`;
const Content = styled.div`
    margin: 10px 0;
    font-size: 14px;
    color: #333;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width:210px;
    margin-right:30px;
    background-color: white;
    color: #0F52BA;
    padding: 7px 20px;
    border: 1.5px solid #0F52BA;
    border-radius: 8px;
    transition: all 0.3s ease;
   
    
    &:hover {
      background-color: #f0f7ff;
      color: #0D47A1;
      border-color: #0D47A1;
    }

    &:active {
      background-color: #e6f1ff;
      color: #0B3C91;
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const StyledPlayer = styled.div`
display:flex;
height:80vh; 
align-items:center; 
justify-content:center; 
`
const Label = styled.label`

    font-weight: 600;
    font-size: 13px;
    margin-left:4px;
    color: ${palette.charcoleBlack};
    display: flex;
    margin-bottom: 8px;
    margin-top:20px;
    align-self: flex-start;
    font-family:Inter;

  
`
