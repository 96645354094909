import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StartOutlined } from '@mui/icons-material';
import { palette } from '../../../utils/Palette';
import { useSelector } from 'react-redux';

const TravelTime = React.memo(({ item, index }) => {
    const detail = useSelector(state => state?.auth?.userDetails)
    const getName = (channel) => {
        if (!channel) {
          return
        }
        const val = channel?.split(' ')
    
        if (val.length == 2) {
          return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
          return `${val[0]?.charAt(0).toUpperCase()}`
        }
    
      }
    return (
        <Container marginTop={index === 0 ? 10 : -5}>
            <Box>
           
                    <StatusContainer>
                        <StatusIndicator 
                            backgroundColor={ palette.newBlue}
                        >
                            
                           {getName(detail?.firstName+detail?.lastName)}
                        </StatusIndicator>
                        <StatusText>
                            {`${detail?.firstName} ${detail?.lastName} `}
                        </StatusText>
                    </StatusContainer>
                    <div style={{width:'33%'}}>
                    <StatusText style={{ textAlign:'start',}}>
                            {item?.name === 'Start' ? 'Time started' : item?.type === 'Completed my work' ? "Job finished" : 'Time paused'}
                        </StatusText>
                    <StatusText  style={{ textAlign:'start',marginTop:'3px',fontSize:'13px',fontWeight:'400',color:'gray' }}>
                        {item?.type}
                    </StatusText>
                    </div>
               
                <InvoiceDetails>
                    <DateText>{moment(item?.time).format('DD MMM YYYY hh:mm A')}</DateText>
                    {/* {item?.description && (
                        <Description>
                            <StatusText>{item?.description}</StatusText>
                        </Description>
                    )} */}
                </InvoiceDetails>
            </Box>
         
        </Container>
    );
});


const Container = styled.div`
    margin-horizontal: 10px;
    margin-top: 20px;
`;

const Box = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
`;

const Header = styled.div`
    flex-direction: row;
    padding-vertical: 5px;
    align-items: center;
    justify-content: space-between;
`;

const StatusContainer = styled.div`
    display:flex;
    align-items: center;
`;

const StatusIndicator = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 15px;
    justify-content: center;
    display:flex;
    font-size:13px;
    color:#fff;
    align-items: center;
    margin-right: 10px;
    background-color: ${props => props.backgroundColor};
`;

const StatusText = styled.div`
    font-size: 14px;
    font-weight: bold;
`;

const InvoiceDetails = styled.div`
 
`;

const DateText = styled.div`
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    color: ${palette.newGrey};
    font-weight: 400;
    margin-left: 5px;
`;

const Description = styled.div`
    background-color: ${palette.smokeWhite};
    margin-top: 15px;
    border-radius: 4px;
    padding: 10px;
`;

const ConnectorContainer = styled.div`
    padding-left: 25px;
`;

const ConnectorLine = styled.div`
    border-left-width: 1px;
    height: 30px;
    border-color: ${palette.lightGrey};
`;

export default TravelTime;
