import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { Device } from '@twilio/voice-sdk';
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";

export const submitAddTemplate = (value) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.createTemplate}`, value)
    await dispatch(getUserDetails())
    return res
  }

  export const submitDeleteTemplate = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteTemplate}?referenceNo=${id}`)
    await dispatch(getUserDetails())
    return res
  }

  export const submiteditTemplate = (value,id) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.editTemplate}?referenceNo=${id}`, value)
    await dispatch(getUserDetails())
    return res
  }