import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import cross from '../../assets/cross.png'
import Autocomplete from 'react-google-autocomplete';
import { colorThemes } from '../../utils/Commonfunction';
import moment from 'moment'
import { addHasgtag, CreateBookings, editCreateBookings, fetchAllContacts, getBookings, getUserDetails, submitBookingStatus, submitchecklist, submitEditContacts, UploadNotesImage } from '../../store/Actions/dashboard.action';
import plus from '../../assets/plus.png'
import unlock from '../../assets/unlock.png'
import pause from '../../assets/pause.png'
import fastforward from '../../assets/fast-forward.png'
import check from '../../assets/check.png'
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { Checkbox, MenuItem, Radio, Select, Switch, TextField } from '@mui/material'
import LineItemComponent from './LineItemComponent'
import { palette } from '../../utils/Palette'
import { Add, AddBoxOutlined, BookmarkAdd, CalendarMonth, Cancel, DeleteOutlined, Description, ListAltOutlined, LocalOffer, LocationOn, ModeEdit, More, MoreHoriz, People, Photo, Schedule } from '@mui/icons-material'
import InputBox from '../../ReuseableComponets/InputBox'
import TopModal from '../TopModal'
import RippleButton from '../../ReuseableComponets/RippleButton'
import InputPicker from '../../ReuseableComponets/InputPicker'
import FormDetails from './FormDetails';
import { submitAddTemplate, submiteditTemplate } from '../../store/Actions/BookingAction';
const filter = createFilterOptions();
function CreateTemplate(props) {
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
    const [addOwnerData, setAddOwnerData] = useState(props?.value?.items || [])
    const detailContact = useSelector(state => state?.auth?.allContacts)
    const [hastTag, setHasTag] = useState(hashTags)
    const customFieldData = useSelector(state => state?.auth?.userData?.customeFields)
    const checklist = useSelector(state => state?.auth?.userData?.checkLists)
    const [customField, setDataCustomField] = useState(customFieldData)
    const [selectCustomField, setCustomField] = useState(props?.value?.customField || [])
    const [notes, setNotes] = useState(props?.value?.notes || '')
    const [selectHash, setSelectedHash] = useState(props?.value?.tags || [])
    const [loader, setLoader] = useState(false)
    const [selectedChecklist, setSelectedChecklist] = useState(props?.value?.checklistNo || [])
    const [edit, setEdit] = useState(false)
    const [value, setValue] = React.useState(null);
    const [selectedImage, setSelectedImage] = useState(props?.value?.images || []);
    const [postData, setpostData] = useState([]);
    const [opendAddContact, setOpenAddContact] = useState(false)
    const [isbookingStatus, setBookingStatus] = React.useState(props?.value?.bookingStatus)
    const [team, setTeam] = React.useState(props?.team || [])
    const [selectedTeam, setSelectedTeam] = React.useState(props?.value?.team || [])
    const dispatch = useDispatch()
    const userdata = useSelector(state => state?.auth?.userdata)
    const [value1, setValue1] = React.useState(null);
    const [value3, setValue3] = useState(null)
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || props?.value?.customerName || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || props?.value?.customerAddress || "",
        "to": props?.value?.number || props?.value?.to || props?.value?.customerNumber || "",
        "businessName": props?.value?.businessName || ''
    })
    const [addchecklist, setAddChecklist] = useState({
        "title": "",
        "data": []
    })
    const [checklistItemData, setChecklistItemData] = useState([{
        type: '',
        value: ''
    }])

    const [contactData, setContactData] = useState(detailContact?.length > 0 ? detailContact : [])

    const [boolValue, setBoolValue] = useState({
        description: false,
        edit: false,
        tag: false,
        customField: false,
        isEditItem: false
    })
    const [values, setValues] = useState({
        date: props?.value?.date || '',
        time: props?.value?.time ? moment(props?.value?.time)?.format('hh:mm A') : '',
        endDate: props?.value?.endDate || '',
        endTime: props?.value?.endTime ? moment(props?.value?.endTime)?.format('hh:mm A') : '',
        "name": userdata?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || props?.value?.customerAddress || "",
        "email": userdata?.email || "",
        "address": userdata?.address || "",
        "notes":props?.value?.notes || "",
        "service": "",
        "phone": userdata?.to || "",
        "workersAssigned": [],
        "typeOfJob": props?.value?.typeOfJob || "",
        "priorityJob": props?.value?.priorityJob || "",
        "mainAddress": [],
        "templateTitle":props?.value?.templateTitle || "",
        "description":props?.value?.description || "",
        
    })

    const statusData = [{ name: "Open", img: unlock, value: 'Waiting for confirmation' },
    { name: "On hold", img: pause, value: 'On Hold' },
    { name: "Active", img: fastforward, value: 'In Progress' },
    { name: "Done", img: check, value: 'Completed' }]


    const onSubmit = async () => {
        const selectedData = []
        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (props?.serviceRequest) {
            const res = await dispatch(submiteditTemplate({
                "templateName": values?.templateTitle,
                "templateData": { ...values, "items": addOwnerData, "tags": selectHash, "customField": selectCustomField, "notes": notes,description:values?.description, "images": selectedImage, ...editValue, phone: '', "workersAssigned": selectedTeam, time: '', endTime: '', 'checklistNo': selectedChecklist,team:selectedTeam }
            },props?.value?.referenceNo))
            props?.settemplateSubmit({ ...editValue, number: res?.number })
        }
        else {
            const res = await dispatch(submitAddTemplate({
                "templateName": values?.templateTitle,
                "templateData": { ...values, "items": addOwnerData, "tags": selectHash, "customField": selectCustomField, "notes": notes,description:values?.description, "images": selectedImage, ...editValue, phone: '', "workersAssigned": selectedTeam, time: '', endTime: '', 'checklistNo': selectedChecklist,team:selectedTeam }
            }))
            props?.settemplateSubmit({ ...editValue, number: res?.number })
        }
    }
    const onSubmitLineItem = async (val) => {
        var newDate = values?.time ? new Date() : '';
        var newDate1 = values?.endTime ? new Date() : '';
        const selectedData = []

        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (values?.time) {
            const val = values.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (values?.endTime) {
            const val3 = values.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }

        if (props?.serviceRequest) {
            const res = await dispatch(editCreateBookings({ ...values, "items": val, "tags": selectHash, "customField": selectCustomField, "notes": notes, "images": selectedImage, ...editValue, phone: editValue?.to, "referenceNo": props?.value?.referenceNo, "workersAssigned": selectedData, time: newDate, endTime: newDate1 }))

        }

    }
    const imageHandleChange = async (e) => {
        const FileList = e.target.files
        const imageList = []

        Object.keys(FileList).map((item, index) => {
            const fileURL = URL.createObjectURL(FileList[item])
            imageList.push(fileURL)

        })

        const res = await dispatch(UploadNotesImage([...FileList]));
        setpostData([...postData, ...res?.data])
        setSelectedImage([...selectedImage, ...res?.data])
    }

    const addNewHashTag = async (val) => {
        setSelectedHash([...selectHash, val])
        const res = await dispatch(addHasgtag({
            "hashTags": val
        }))
        setHasTag([...hastTag, val])
    }
    const bookingStatus = async (value) => {
        const data = {
            "referenceNo": props?.value?.referenceNo,
            "bookingStatus": value
        }

        const res = await dispatch(submitBookingStatus(data))
        if (res.status === true) {
            setBookingStatus(value)
        }
    }



    const init = async () => {
        setLoader(true)
        const res = await dispatch(fetchAllContacts())
        const re3 = await dispatch(getUserDetails())
        setLoader(false)
        let res1 = await dispatch(getBookings())
        if (res1.status == true) {
            setTeam(res1?.data?.team)

        }
        setContactData(res?.data)
    }
    useEffect(() => {
        setNotes(props?.value?.notes)
        init()
    }, [])


    return (
        <Main>
            <HeaderContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CloseButton onClick={() => props?.settemplateCancel(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                    <h3>
                        {props?.serviceRequest ? "Edit template" : "Create template"}
                    </h3>
                </div>
                <RippleButton style={{ marginRight: '40px' }} onClick={() => onSubmit({ draft: false })}>{props?.serviceRequest ? "Save" : "Create"}</RippleButton>

            </HeaderContainer>
            <div style={{ display: 'flex', width: '100%' }}>
                <Flexbox style={{ width: '100%' }}>
                    <div className='leftdiv'>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5>Template title</h5>
                                <EditContainer style={{ padding: '8px' }} onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <ListAltOutlined style={{ fontSize: '16px' }} />
                                </EditContainer>

                            </div>
                            <InputBox
                                placeholder="Template title"
                                type="text"
                                value={values?.templateTitle}
                                style={{ height: '20px', width: '82%', margin: '12px 0px', border: '1px solid #ccc' }}
                                onChange={(e) => {
                                    setValues({ ...values, "templateTitle": e.target.value })
                                }} />


                        </StyleBox>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Crew
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <People style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>

                            <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', }}>
                                <Autocomplete1
                                    value={value3}
                                    onChange={(event, newValue) => {

                                        const searchString = 'Add new contact';

                                        if (newValue && newValue?.firstName?.includes(searchString)) {

                                            setOpenAddContact(true)
                                        }

                                        else if (typeof newValue?.firstName === 'string') {
                                            //setValue2(result);
                                            setSelectedTeam([...selectedTeam, newValue])

                                            // setSelectedHash([...selectHash, result])
                                        }
                                        else {
                                            //setValue2(result)
                                            setSelectedTeam([...selectedTeam, newValue])
                                            //  setHasTag([...selectHash,result])
                                        }

                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option?.firstName);

                                        // filtered.push({ name: `Add new contact` });


                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={team}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option?.firstName === 'string') {
                                            return option.firstName;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.firstName}</li>}
                                    sx={{
                                        width: 315, '& input': {
                                            height: '5px', // Define the height of the input box here
                                        },
                                    }}
                                    freeSolo
                                    style={{ height: 30, marginLeft: '10px', marginBottom: '20px', fontSize: '13px' }}
                                    renderInput={(params) => (
                                        <div>
                                            <TextField style={{ fontSize: '13px' }} {...params}


                                                placeholder="Add crew member" />

                                        </div>
                                    )}
                                />
                                {selectedTeam?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                                    {selectedTeam?.map((item, index) =>
                                        <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, }}>{item?.firstName} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                            const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                            setSelectedTeam(arr)
                                        }} src={cross} /></WrapperContainer>
                                    )}
                                </WrapperField> : null}

                            </div>


                        </StyleBox>

                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Tags
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <LocalOffer style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>

                            <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', }}>
                                <Autocomplete1
                                    value={value}
                                    onChange={(event, newValue) => {

                                        const searchString = 'Create ';
                                        const replacement = '';
                                        const result = newValue?.replace(searchString, replacement)?.replace(/"/g, '');

                                        if (newValue && newValue?.includes(searchString)) {

                                            addNewHashTag(result)
                                            setValue('');
                                        }

                                        else if (typeof newValue === 'string') {
                                            setValue('');
                                            setSelectedHash([...selectHash, result])
                                            // setSelectedHash([...selectHash, result])
                                        }
                                        else {
                                            setValue('');
                                            //  setHasTag([...selectHash,result])
                                        }

                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(`Create "${inputValue}"`);
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={hastTag}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option}</li>}
                                    sx={{
                                        width: 315, marginLeft: '12px', '& input': {
                                            height: '5px', // Define the height of the input box here
                                        },
                                    }}
                                    freeSolo
                                    style={{ height: 30, marginLeft: '10px', marginBottom: '20px', fontSize: '13px' }}
                                    renderInput={(params) => (
                                        <TextField style={{ fontSize: '13px' }} {...params} placeholder="Search or create a tag" />
                                    )}
                                />
                                {selectHash?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                                    {selectHash?.map((item, index) =>
                                        <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>{item} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                            const arr = selectHash?.filter(obj => obj !== item)
                                            setSelectedHash(arr)
                                        }} /></WrapperContainer>
                                    )}
                                </WrapperField> : null}

                            </div>


                        </StyleBox>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Checklist
                                </h5>
                                <EditContainer onClick={() => {
                                    checklist?.length > 0 ? setBoolValue({ ...boolValue, isChecklist: true }) : setBoolValue({ ...boolValue, addchecklist: true })
                                }}>
                                    <Add style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>
                            {selectedChecklist?.length > 0 && <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '5px' }}>
                                {selectedChecklist?.map((item) =>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', margin: "5px" }}>
                                        <div>
                                            <Label>{item?.title}</Label>
                                            <Label style={{ color: palette.grey }}>{item?.data?.length} checklist items</Label>
                                        </div>
                                        <DeleteOutlined style={{ cursor: 'pointer' }} onClick={async () => {
                                            const data = selectedChecklist.filter(items => items?.checklistNo !== item?.checklistNo);
                                            setSelectedChecklist(data)
                                        }} />
                                    </div>
                                )}
                            </div>}
                        </StyleBox>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Custom field
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <BookmarkAdd style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>

                            <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                <Autocomplete1
                                    value={value1}
                                    onChange={(event, newValue) => {

                                        const searchString = 'Create ';
                                        const replacement = '';
                                        const result = newValue?.fieldName?.replace(searchString, replacement)?.replace(/"/g, '');

                                        if (typeof newValue === 'string') {
                                            setValue1(result);
                                            setCustomField([...selectCustomField, { fieldName: 'cdsf' }])
                                        } else if (newValue && newValue.fieldName) {
                                            setCustomField([...selectCustomField, { fieldName: newValue?.fieldName, fieldValue: newValue?.fieldValue }])
                                            setValue1(result);
                                        } else {
                                            setValue1(result);

                                        }

                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={customField}
                                    getOptionLabel={(option) => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }

                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }

                                        return option;
                                    }}
                                    renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.fieldName}</li>}
                                    sx={{
                                        width: 315, marginLeft: '12px', '& input': {
                                            height: '5px', // Define the height of the input box here
                                        },
                                    }}
                                    freeSolo
                                    style={{ height: 30, marginLeft: '10px', marginBottom: '10px', fontSize: '13px' }}
                                    renderInput={(params) => (
                                        <TextField style={{ fontSize: '13px' }} {...params} placeholder="Search custom field" />
                                    )}
                                />

                                {selectCustomField?.length > 0 ? <CustomBox style={{ width: '100%' }}>
                                    {selectCustomField?.map((item, index) =>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '310px', }}>
                                            <div style={{ width: '100%', marginBottom: '10px' }}>
                                                <Label>{item?.fieldName}</Label>
                                                <InputBox

                                                    placeholder="Value"
                                                    type="text"
                                                    value={item?.fieldValue}
                                                    style={{ height: '15px', width: '100%' }}
                                                    onChange={(e) => {
                                                        const data = [...selectCustomField]
                                                        data[index].fieldValue = e.target.value
                                                        setCustomField(data)
                                                    }}


                                                />
                                            </div>

                                            <DeleteOutlined style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '20px', marginTop: '10px' }} onClick={() => {
                                                const data = [...selectCustomField]
                                                data.splice(index, 1)
                                                setCustomField(data)
                                            }} /></div>

                                    )}
                                </CustomBox> : null}

                            </div>

                        </StyleBox>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Notes
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <Description style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>
                            {/* {!boolValue?.description ? notes ? <p className='valueContainer' style={{ width: '55%', textAlign: 'start', flexWrap: 'wrap', wordBreak: 'break-all', whiteSpace: 'nowrap', overflow: 'hidden', }}>{notes}</p> : <div style={{ alignItems: 'start' }} className='valueContainer'>Add note
                        </div> : */}
                            <>
                                <div className='valueContainer' style={{ flexDirection: 'column', justifyContent: 'center' }}>
                                    {/* <Label htmlFor={'Email'}>Description</Label> */}
                                    <InputText
                                        placeholder="Note"
                                        type="text"
                                        value={notes}
                                        // value={editValue?.email}

                                        onChange={(e) => {
                                            setNotes(
                                                e.target.value
                                            )
                                        }}

                                    />
                                </div>

                            </>

                        </StyleBox>
                        <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Photos / Attachments
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <Photo style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>
                            <>
                                <div className='valueContainer' style={{ flexDirection: 'column', }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', }}>
                                        {selectedImage?.map((item) =>
                                            <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                                <PhotosStyle src={item} />
                                                <div
                                                    onClick={() => {
                                                        const arr = selectedImage?.filter(obj => obj !== item)
                                                        setSelectedImage(arr)
                                                    }}
                                                    style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                    <MoreIcon style={{ width: '15px' }} src={cross} />
                                                </div>
                                            </div>
                                        )}

                                        <FileInputButton htmlFor="fileInput">
                                            <MoreIcon src={plus} />
                                        </FileInputButton>
                                        <FileInput
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={imageHandleChange}
                                        />
                                    </div>

                                </div>
                            </>
                        </StyleBox>
                    </div>
                    <StyleRight>
                
                        <RightLineItemMain style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <InputPicker
                                    value={values?.typeOfJob}
                                    data={[{
                                        label: "Maintenance", value: 'Maintenance',
                                    }, {
                                        label: "Install", value: 'Install',
                                    }, { label: "Tune up", value: 'Tune up' }
                                        , { label: 'Estimate', value: 'Estimate' },
                                    { label: 'Repair', value: 'Repair' },
                                    { label: 'Event', value: 'Event' },
                                    ]}
                                    label='Job type'
                                    onChange={(val) => setValues({ ...values, typeOfJob: val })}

                                />
                                <InputPicker
                                    value={values?.priorityJob}
                                    data={[{
                                        label: "Very critical", value: 'Very critical',
                                    }, {
                                        label: "Critical", value: 'Critical',
                                    }, { label: "Moderate", value: 'Moderate' },
                                    { label: "Low", value: 'Low' }

                                    ]}
                                    label='Priority'
                                    onChange={(val) => setValues({ ...values, priorityJob: val })}
                                />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <h4 style={{ marginBottom: '0px' }}>{"Add line items"}</h4>

                            </div>


                            {!loader && <LineItemComponent
                                edit={boolValue}
                                value={(val) => {

                                    if (props?.serviceRequest) {
                                        setAddOwnerData(val)
                                        onSubmitLineItem(val)
                                    }
                                    else {
                                        setAddOwnerData(val)
                                    }

                                }}
                                items={[...addOwnerData]}
                                serviceRequest={props?.serviceRequest}
                            />}
                        </RightLineItemMain>
                        <RightLineItemMain style={{ paddingLeft: '40px',marginTop:'20px', paddingRight: '40px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <h4 style={{ marginBottom: '0px',marginTop:'0px' }}>{"Add field note"}</h4>

                            </div>
                            <InputText
                                placeholder="Field note"
                                type="text"
                                value={values?.description}
                                // value={editValue?.email}
                                    style={{width:'97%',marginLeft:'0px'}}
                                onChange={(e) =>  setValues({...values,description:e.target.value})}

                            />
                        </RightLineItemMain>


                    </StyleRight>
                </Flexbox>
                {props?.form && <div style={{
                    width: '40%',
                    paddingTop: '80px',
                    position: 'sticky',
                    top: '0',
                    overflowY: 'auto',
                    height: '100vh'
                }}>
                    <div style={{ textAlign: 'start', fontSize: '16px', marginLeft: '20px', fontWeight: '600' }}>Form details</div>
                    <FormDetails
                        formDetail={props?.formDetail}
                        type={'Job'}
                    />
                </div>}
            </div>



            {boolValue?.addchecklist && <TopModal
                onClose={() => setBoolValue({ ...boolValue, addchecklist: false })}
                overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>
                        {"Add checklist"}
                    </h4>
                    <Cancel onClick={() => {
                        setBoolValue({ ...boolValue, addchecklist: false })
                    }} style={{ cursor: 'pointer' }} />
                </div>
                <InputBox
                    header={'Checklist name'}
                    style={{ width: '95%' }}
                    placeholder='Checklist name'
                    value={addchecklist?.title}
                    onChange={(e) => setAddChecklist({ ...addchecklist, title: e.target.value })}
                />
                <h5 style={{ textAlign: 'start', margin: '15px 5px 0px 5px', fontWeight: '500' }}>Checklist item</h5>
                {checklistItemData?.map((item, index) => <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <InputPicker
                        value={item?.type}
                        label={'Type'}
                        style={{ height: '40px', background: 'white' }}
                        data={[
                            { label: 'Checkbox', value: 'Checkbox' },
                            { label: 'Text', value: 'Text' },
                            { label: 'Link', value: 'Link' },
                        ]}
                        onChange={(val) => {
                            const data = [...checklistItemData]
                            data[index].type = val
                            setChecklistItemData([...data])
                        }}
                    />
                    <InputBox
                        style={{ width: '85%' }}
                        placeholder='Item'
                        value={item?.value}
                        onChange={(e) => {
                            const data = [...checklistItemData]
                            data[index].value = e.target.value
                            setChecklistItemData([...data])
                        }}
                    />
                    <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                        const data = [...checklistItemData]
                        data.splice(index, 1)
                        setChecklistItemData(data)
                    }} />
                </div>)}
                <h4 onClick={() => {
                    setChecklistItemData([...checklistItemData,
                    {
                        type: '',
                        value: '',
                    }
                    ])
                }} style={{ textAlign: 'start', color: palette.newBlue, cursor: 'pointer' }}>+ Checklist item</h4>
                <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                        setBoolValue({ ...boolValue, addchecklist: false })
                    }}>Cancel</RippleButton>
                    <RippleButton onClick={async () => {
                        const res1 = await dispatch(submitchecklist({ ...addchecklist, 'data': checklistItemData }))
                        setSelectedChecklist([...selectedChecklist, { ...addchecklist, 'data': checklistItemData, "checklistNo": res1?.data?.checklistNo }])
                        setBoolValue({ ...boolValue, addchecklist: false })
                    }}>Save</RippleButton>
                </div>
            </TopModal>}

            {boolValue?.isChecklist &&
                <TopModal
                    onClose={() => setBoolValue({ ...boolValue, isChecklist: false })}
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>
                            {"Checklist"}
                        </h4>
                        <Cancel onClick={() => {
                            setEdit(false)
                        }} style={{ cursor: 'pointer' }} />
                    </div>
                    {checklist?.map((item, index) => <div onClick={() => {
                        const isItemSelected = selectedChecklist?.some(items => items?.checklistNo === item?.checklistNo);
                        if (isItemSelected) {
                            const data = selectedChecklist.filter(items => items?.checklistNo !== item?.checklistNo);
                            setSelectedChecklist(data);
                        } else {
                            const data = [...selectedChecklist, item];
                            setSelectedChecklist(data);
                        }
                    }} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '15px', cursor: 'pointer' }}>
                        <Checkbox style={{ width: '25px', marginTop: '-2px', marginRight: '5px' }} checked={selectedChecklist?.some(items => items?.checklistNo === item?.checklistNo)} />
                        <div>
                            <Label>{item?.title}</Label>
                            <Label style={{ color: palette.grey }}>{item?.data?.length} checklist items</Label>
                        </div>
                    </div>
                    )}
                    <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between', }}>
                        <h4 onClick={() => {
                            setBoolValue({ ...boolValue, isChecklist: false, addchecklist: true })
                            setChecklistItemData([{ type: '', value: '' }])
                        }} style={{ textAlign: 'start', marginTop: '0px', marginBottom: '0px', color: palette.newBlue, cursor: 'pointer' }}>+ New Checklist</h4>
                        <div style={{ display: 'flex' }}>
                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                setBoolValue({ ...boolValue, isChecklist: false })
                            }}>Cancel</RippleButton>
                            <RippleButton onClick={() => {
                                setBoolValue({ ...boolValue, isChecklist: false })
                            }}>Save</RippleButton>
                        </div>
                    </div>
                </TopModal>
            }
        </Main>
    )
}

export default CreateTemplate

const Main = styled.div`

width:100%;
height:99vh;
overflow-y:scroll;



`
const ProcessWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
margin-bottom:-40px;
border:1px solid #ddd;
align-items:center;
width:100px;
padding-top:15px;
height:70px;
margin-left:-15px;
border-radius:15px;
cursor:pointer;
`
const IconProcess = styled.img`
width: 25px;
height: 25px;
margin-bottom:-10px;
`

const Wrapper = styled.div`
display:block;
width:90%;
align-self:center;
margin-top:30px;
margin-bottom:50px;
margin-left:30px;
margin-right:30px;
box-size:border-box;
flex-wrap:wrap;
border-bottom:1px solid #ddd;
.inputwrapper{
    width:41%;
    @media (min-width: 260px) and (max-width: 721px){
        width:38.5%;
       
        }
}
.button{
    margin-left:230px;
    width:300px;
}
@media (min-width: 260px) and (max-width: 721px){
    width:90%;
    
    }

`
const AddressButton = styled.div`
  background: #0F52BA;
  cursor: pointer;
  color: white;
  padding: 10px 13px;
  border-radius: 8px;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: center;

  h4 {
    text-align: start;
    font-size: 13px;
    margin: 0;
    color: white;
  }
`;

const EditContainer = styled.div`

border-left:1px solid  ${palette.smokeWhite};
display:flex;
align-items:center;
padding:10px;
cursor:pointer;

&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`

const DateButton = styled.div`
display:flex;
align-items:center;
margin-left:15px;
cursor:pointer;
height:35px;
width:125px;
border-radius:8px;
padding-left:10px;
padding-right:10px;
background:#F3F3F3;
font-sizze:14px;
justify-content:space-between;


`

const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-bottom:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:60px;
  height:60px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 60px;
height: 60px;

`

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const MoreIcon = styled.img`
width:15px;
cursor:pointer;
color:black
`
const CalendarStyle = styled.div`
display:flex;
box-sizing: border-box;
background: #FFFFFF;

height:auto;
border-radius: 5px;
padding:15px;
margin-left:15px;
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 14px;
font-weight:500;
padding-left:15px;
padding-right:15px;
height:40px;
border-radius:8px;
border:1px solid white;
color:white;

cursor:pointer;

`
const CustomBox = styled.div`
display:flex;
flex-wrap:wrap;
margin-top:15px;
margin-left:12px;
width:285px;

`
const SendButtonContainer = styled.div`
   
    margin-right: 40px; /* Adjust as needed */
    display: flex;
    justify-content: flex-end;
`;
const FieldContainer = styled.div`
display:flex; 
align-items:center;
font-size:14px;
width:280px;
 margin-left:25px;
margin-right:5px;
margin-top:0px;

.fieldName{
    width:48%;
    text-align:start;
}
.fieldValue{
    width:55%;
    text-align:start;
    flex-wrap:wrap;
  
    word-break:break-all;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
`
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 
  padding: 0px 20px; 

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
`;
const Place = styled(Autocomplete)`
height:30px;
width:296px;
border-radius:4px;
border:1px solid lightgray;
font-weight:400;
padding-left:12px;
@media (min-width: 260px) and (max-width: 721px){
    width:85%;
    }
&::placeholder {
  font-size:14px;
  font-weight:400;
}
&:focus {
outline:none;
border:1px solid lightgray;
}
`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`

const Icons = styled.img`
width: 15px;
height: 15px;
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid ${palette.smokeWhite};
background:white;
width:350px;
margin-top:20px;
border-radius:4px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
h5{
    margin-top:0px;
    margin-bottom:0px;
}
p{
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left:10px;
    margin-right:10px;
    line-height:16px;
}
.keyContainer{
    padding: 0px 0px 0px 10px;
    font-size:16px;
    font-weight:500;
    position:relative;
    color:${palette.black};
    background:#f9f9f9;
display:flex;
justify-content:space-between;
border-bottom:1px solid ${palette.smokeWhite};
align-items:center;
}
.valueContainer{
    padding:7px;
    
    background:#fff;
    font-size:14px;
  
display:flex;
}
.authoCompletewrapper{
    margin-top:20px; 
    margin-bottom:30px; 
    display:flex; 
    justify-content:center;
}
.valuewrapper{
    margin-top:20px;
    margin-bottom:30px; 
    display:flex; 
    justify-content:center; 
}
.datewrapper{
    margin-top:10px;
    display:flex; 
    justify-content:center;  
}
`
const StyleRight = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
overflow-y:scroll;

width:90%;
margin-right:30px;
margin-left:30px;
margin-top:20px;
height:auto;
border-radius:8px;



`
const WrapperField = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
`;
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;
const RightLineItemMain = styled.div`
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
border:1px solid #f3f3f3;
border-radius:8px;
padding-top:20px;
padding-bottom:20px;
background:white;
`
const RightContainerBox = styled.div`

border:1px solid #f3f3f3;
border-radius:8px;
background:white;
padding-left:60px;
padding-right:60px;
padding-top:20px;
padding-bottom:20px;
border:1px solid #f3f3f3;
background:white;
margin-top:30px;
margin-bottom:20px;
display:flex;
justify-content:flex-start;
flex-direction:column;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
`

const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`
const CustomButton2 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 16px;
margin-left:10px;
width:96.5%;
height:54px;
border-radius:8px;
border:1px solid white;
align-self:flex-end;
color:white;
font-size:16px;
margin-top:35px;
margin-bottom:35px;
cursor:pointer;
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

width:70%;
margin-bottom:8px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const ValueInvoice = styled.p`
color:#000;
font-size: 14px;
text-align:start;
padding:6px;
font-weight:500;
margin-top:-10px;
margin-bottom:8px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const LeftHeader = styled.p`
color:#000;
font-size: 16px;
text-align:start;
margin-top:0px;
margin-bottom:0px;
width:70%;
font-weight:600;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const Texts2 = styled.div`
height:70vh;
overflow-y: scroll;
display: flex;
flex-direction: column-reverse;

&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const OptionBox = styled.div`
width:100px;
height:35px;
border-radius:8px;
border:1px solid #bbb;

display:flex;

font-size:14px;

align-items:center;
justify-content:center;
margin-left:30px;

font-weight:500;
cursor:pointer;

`


const SendIcon = styled.img`
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
margin-right:10px;
margin-top:20px;

`;
const ItemIcon = styled.img`

  width: 15px;
  height: 15px;

`;
const Flexbox = styled.div`
display:flex;
height:auto;
margin-top:60px;
background:#fff;

.leftdiv{
    width:450px; 
    display:flex; 
    flex-direction:column; 
    padding-left:20px;
}
`
const Profile = styled.div`
width:20px;
padding:3px;
background:#4169e1;
text-align:center;
border-radius:5px;
color:white;
margin-left:20px;
height:20px;
margin-top:4px;
font-weight:600;
font-size:14px;
padding-top:4px;
`
// const Line1=styled.p`
// color:#d4d4d4;
// margin-left:20px;
// margin-right:10px;
// margin-top:8px;
// `
const Line2 = styled.p`
color:#d4d4d4;
margin-left:10px;
margin-right:13px;
margin-top:8px;
`
// const ReviewStar=styled.img`
// margin-top:7px;
// `
const Hr = styled.hr`
border:1px solid #E2E8F0;
margin-top:-3px;
`
const LocateGps = styled.div`
display:flex;
padding-top:10px;
padding-left:8px;
padding-right:8px;
border-radius:8px;
margin-bottom:6px;
font-size:14px;
font-weight:600;
cursor:pointer;
margin-top:-3px;
&:hover {
    background-color:#F3F3F3;
  }
`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  margin-top:10px;
  margin-bottom:10px;
  margin-left:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.renderdiv{
    display:flex;
    justify-content:space-between; 
    align-items:center; 
    font-size:13px; 
}
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const WrapperHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    margin:5px;
    .text{
        font-size:13px;
        text-align:start;
        margin-top:0px;
        margin-bottom:5px;
        line-height: 16px;

    }
    .nametext{
        font-size:16px; 
        font-weight:500; 
        margin-top:8px;
    }
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left:12px;

  margin-right:13px;
  
p{
    text-align:start;
    display:flex;
    justify-content:start;
    align-self:start;
    margin-top:0px;
    margin-bottom:5px;
}
  .input{
    padding: 10.5px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    
    width: 20%;
    align-items: center;
    
    color: #344054;
 
  
    &:focus {
      outline: none;
      border-color: #000;
      box-shadow: none;
    }
  }
`;

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;

