import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

// Styled Components
const Wrapper = styled.div`
  position: relative;
  width: 300px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #000; 
    color: #000;
  }
`;

const DropdownArrow = styled.div`
  position: absolute;
  right: 10px;
  pointer-events: none;
  font-size: 14px;
  padding-top:5px;
  user-select: none;
`;

const Dropdown = styled.ul`
  position: absolute;
  width: 99%;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  background-color: white;
  z-index: 100;
`;

const OptionItem = styled.li`
  padding: 8px 10px;

  cursor: pointer;
  display:flex;
  background-color: white;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Footer = styled.div`
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
`;



const MultiSelectWithSearch = ({ options, onSelectionChange, dropDownStyle,containerStyle }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionToggle = (option) => {
    let newSelectedOptions;

    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };

  // Show comma-separated selected values in the search box
  const selectedValuesString = selectedOptions.join(', ');

  return (
    <Wrapper ref={wrapperRef}>
      <SearchContainer style={containerStyle} onClick={() => setDropdownOpen((prev) => !prev)}>
        <SearchInput
          type="text"
          placeholder="Search for a choice"
          value={dropdownOpen ? searchTerm : selectedValuesString}
          onChange={(e) => setSearchTerm(e.target.value)}
          readOnly={!dropdownOpen} // Make input read-only when dropdown is closed
        />
        <DropdownArrow>
        <KeyboardArrowDownOutlined style={{fontSize:'14px'}} />
            </DropdownArrow>
      </SearchContainer>

      {dropdownOpen && (
        <Dropdown style={dropDownStyle} >
            
          {filteredOptions.map((option) => (
            <OptionItem
              key={option}
              selected={selectedOptions.includes(option)}
              onClick={() => handleOptionToggle(option)}
            >
              <Checkbox
                type="checkbox"
                checked={selectedOptions.includes(option)}
                readOnly
              />
              {option}
            </OptionItem>
          ))}

          {filteredOptions.length === 0 && <OptionItem>No results found</OptionItem>}
        </Dropdown>
      )}

    </Wrapper>
  );
};

// PropTypes for validation
MultiSelectWithSearch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  manageChoicesLabel: PropTypes.string,
};

// Default props
MultiSelectWithSearch.defaultProps = {
  manageChoicesLabel: 'Manage Choices',
};

export default MultiSelectWithSearch;
