import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import { addnewBundleItems, editnewBundleItems, UploadNotesImage } from "../../../store/Actions/dashboard.action";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import { AddBoxOutlined, AddPhotoAlternateOutlined, Cancel, DeleteOutlined, DragIndicatorOutlined, Edit, Search, Upload } from "@mui/icons-material";
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { palette } from "../../../utils/Palette";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEffect } from "react";
const filter = createFilterOptions();
const EditBundle = (props) => {
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const autocompleteRef = useRef(null);
    const [isAddLine, setIsAddline] = useState(false)
    const [addOwnerData, setAddOwnerData] = useState(props?.value?.lineItems || [])
    const [editInvoiceData, setEditInvoiceData] = useState(props?.value?.edit)
    const [isFocused,setIsFocused]=useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [boolValue, setBoolValue] = useState({})
    const mainRef = useRef(null);
    const addLineFieldRef = useRef(null);
    const [lineItem, setLineItem] = useState(props?.value || {
        "name": "",
        "price": 0,
        "description": "",
        "quantity": "1",
        "tax": "0",
        "taxName": "no Tax",
        "optional": false,
        "unitPrice": ''
    })
    const [invoicedata, setInvoiceData] = useState({
        "name": "",
        "price": "0",
        "description": "",
        "quantity": "1",
        "tax": "0",
        "taxNumber": '',
        "taxName": "no Tax",
        "optional": false,
        categoryVisiblity:props?.value?.categoryVisiblity
    })
    const [addImage, setImage] = useState(props?.value?.picUrl || '')
    const [addImageData, setImagedata] = useState(props?.value?.picUrl || '')
    const [addEditImageData, setEditImagedata] = useState('')
    const [value2, setValue2] = useState(null)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files;
        const res = await dispatch(UploadNotesImage([...selectedFile]));
        setImage(res?.data[0])
        setImagedata(res?.data[0])
        setEditImagedata(selectedFile)
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const draggedItem = addOwnerData[result.source.index];
        const remainingItems = [...addOwnerData];
        remainingItems.splice(result.source.index, 1);
        remainingItems.splice(result.destination.index, 0, draggedItem);

        setAddOwnerData(remainingItems);

        if (editInvoiceData) {
            setEditInvoiceData(false)
        }

    };

    const addCustomFiled = () => {
        setAddOwnerData([...addOwnerData, invoicedata])
        setInvoiceData({
            "name": "",
            "price": "",
            "description": "",
            "quantity": "1",
            "tax": "0",
            "taxName": "no Tax",
            "taxNumber": ''
        })
        setIsAddline(false)
        setIsEdit(false)
    }
    const onSubmit = async () => {
        props?.setValue({...lineItem,
            "name": lineItem?.name,
            "description": lineItem?.description,
            "price": ([...addOwnerData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0))?.toFixed(2),
            "lineItems": addOwnerData,
            "picUrl": addImage,
            "categoryVisiblity":invoicedata?.categoryVisiblity,
            "type":'Bundle'

        })

    }
    function calculateMarkupPercentage(costPrice, unitPrice) {

        const markupPercentage = ((unitPrice - costPrice) / costPrice) * 100;
        return parseFloat(markupPercentage)?.toFixed(2);
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.toString()?.replace(/,/g, '');
        const taxes = parseFloat(price ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }
    function validateInput1(event) {
        if (event.key === '-' || event.key === 'e' || event.key === '.') {
            event.preventDefault(); // Prevent typing negative sign, exponent, or decimal point
        }
    }
    const renderOption = useMemo(() => (props, item, index) => (
        <div {...props}>
            {item?.name === 'Add new line item'
                ? <div />
                : (
                    <LineItem style={{ width: '100%' }}>
                        <div style={{ width: '90%' }}>
                            <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                            {item?.description && <p className="description">{item?.description}</p>}
                        </div>
                        <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                    </LineItem>
                )}
        </div>
    ), [putCommaInvalue, taxprices]);

    function validateInput(event) {
        if (event.key === '-' || event.key === 'e') {
            event.preventDefault(); // Prevent typing negative sign, exponent, or decimal point
        }
    }

    useEffect(() => {
        if (isFocused && addLineFieldRef.current && mainRef.current) {
          addLineFieldRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
        else{
            if (mainRef.current) {
                mainRef.current.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }
        }
      }, [isFocused]);
    return (
        <>
           
            <Flexbox ref={mainRef}  >
            <HeaderContainer >
                <HeaderDiv>
                    <CloseButton onClick={() => props?.setModalOpenInvoice(false)}>
                        <Cancel style={{ fontSize: '24px' }} />
                    </CloseButton>
                    <h4 className="title">{"Insert bundle"}</h4>
                </HeaderDiv>
                <RippleButton style={{ marginRight: '50px' }} onClick={async () => {
                    await onSubmit()
                }}>Save</RippleButton>
            </HeaderContainer>

                <MainContainer>
                    <InputContainer>
                        <FlexContainer>
                            <FullWidth>

                                <Label>Name</Label>
                                <Inputbox
                                    type="text"
                                    placeholder='item name'
                                    style={{ width: '100%' }}
                                    value={lineItem?.name}
                                    onChange={(e) => setLineItem({ ...lineItem, name: e.target.value })}
                                />

                                <div className="name" style={{ marginBottom: '10px', width: '100%' }} >
                                    <Label>Description</Label>
                                    <InputText
                                        placeholder="Description"

                                        type="text"
                                        value={lineItem?.description}
                                        onChange={(e) => setLineItem({ ...lineItem, description: e.target.value })}

                                    />
                                </div>
                            </FullWidth>
                            {addImage ? <ImageUrl>
                                <Image src={addImage} />
                            </ImageUrl> : <><FileInputButton htmlFor="fileInput">
                                <AddPhotoAlternateOutlined style={{ fontSize: "60px", marginBottom: '10px' }} />
                                Upload Image
                            </FileInputButton>
                                <FileInput
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </>
                            }
                        </FlexContainer>
                        <DropdownContainer>
                            <Label>Category Visibility</Label>
                            <SelectDrpoDown id="category-visibility" value={invoicedata?.categoryVisiblity} onChange={(event) => {
                                setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })

                            }}>
                                <Option value="Items and item prices are visible to your customer">
                                    Items and item prices are visible to your customer
                                </Option>
                                <Option value="Items are NOT visible to your customer">
                                    Items are NOT visible to your customer
                                </Option>
                                <Option value="Items are visible, but item prices are NOT visible to your customer">
                                    Items are visible, but item prices are NOT visible to your customer
                                </Option>
                            </SelectDrpoDown>
                        </DropdownContainer>
                    </InputContainer>
                    {<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <BundleTitle>Bundle items</BundleTitle>
                        <BundleTitle>${putCommaInvalue(([...addOwnerData].reduce(function (accumulator, currentValue) {
                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                        }, 0))?.toFixed(2))}</BundleTitle>
                    </div>}


                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="selectedItems">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ width: '100%' }}
                                >
                                    {addOwnerData?.map((item, index) => (
                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                            {(provided, snapshot) => (
                                                <DraggableItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        top: 'auto',
                                                        left: 'auto',
                                                        userSelect: 'none',
                                                        padding: snapshot.isDragging ? '10px' : '7px 0px 7px 0px',
                                                        background: snapshot.isDragging ? '#f3f3f3' : 'white',
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    <LineItem style={{ width: '100%' }}>
                                                        <LineItemContainer>
                                                            <DragIndicatorOutlined style={{ fontSize: '18px', marginLeft: '-5px', marginRight: '5px' }} />
                                                            <div style={{ width: '100%' }}>
                                                                <p className="name">{item?.name} × {item?.quantity || '1'} {item?.optional ? '(Optional)' : null}</p>
                                                                <p className="description">{item?.description}</p>
                                                            </div>
                                                        </LineItemContainer>
                                                        <div style={{ display: 'flex' }}>
                                                            <p className="price">${putCommaInvalue(totalprice(item?.price, item?.quantity, item?.tax)?.toFixed(2))} </p>
                                                            {
                                                                <>
                                                                    <Edit style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                                        const data = [...addOwnerData];
                                                                        data.splice(index, 1);
                                                                        setAddOwnerData(data);
                                                                        setInvoiceData(item);
                                                                        setIsAddline(true);
                                                                        setIsEdit(true);
                                                                    }} />
                                                                    <DeleteOutlined style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                                        const data = [...addOwnerData];
                                                                        data.splice(index, 1);
                                                                        setAddOwnerData(data);
                                                                    }} />
                                                                </>
                                                            }
                                                        </div>
                                                    </LineItem>
                                                </DraggableItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {isAddLine ?
                        <AddLineFiled ref={addLineFieldRef} className="add-line-field" isFocused={isFocused}>
                            {<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ItemDetailsTitle >Item details</ItemDetailsTitle>
                                <ItemDetailsTitle>${putCommaInvalue(totalprice(invoicedata?.price, invoicedata?.quantity, invoicedata?.tax)?.toFixed(2))}</ItemDetailsTitle>
                            </div>}
                            <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                <div className="name" style={{ marginRight: '10px' }}>
                                    <Label>Name</Label>
                                    <Inputbox
                                        type="text"
                                        placeholder='item name'
                                        style={{ width: '100%' }}
                                        value={invoicedata?.name}

                                        onChange={(e) => setInvoiceData({ ...invoicedata, name: e.target.value })}
                                    />
                                </div>

                                <div style={{ marginLeft: '0px', width: '20%' }} className="subname">
                                    <Label>Qty</Label>
                                    <CustomUrl placeholder='Quantity' type='number' value={invoicedata?.quantity} onKeyDown={validateInput1} onChange={(e) => setInvoiceData({ ...invoicedata, quantity: e.target.value })} />
                                </div>
                                <div style={{ width: '20%', marginRight: '10px' }} className="subname">
                                    <Label>Price</Label>
                                    <InputWrapper>
                                        <PercentageSymbol>$</PercentageSymbol>
                                        <CustomUrl style={{ paddingLeft: '20px' }} type='number' placeholder='Price' min='0' onKeyDown={validateInput} value={invoicedata?.price} onChange={(e) => {
                                            const markupPercentage = ((e.target.value - invoicedata?.unitPrice) / invoicedata?.unitPrice) * 100;
                                            setInvoiceData({ ...invoicedata, price: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                        }} />

                                    </InputWrapper>
                                </div>
                                <div style={{ width: '20%', marginRight: '10px' }} className="subname">
                                    <Label>Cost</Label>
                                    <InputWrapper>
                                        <PercentageSymbol>$</PercentageSymbol>
                                        <CustomUrl style={{ paddingLeft: '20px' }} type='number' placeholder='Price' min='0' onKeyDown={validateInput} value={invoicedata?.unitPrice} onChange={(e) => {
                                           const markupPercentage = ((invoicedata?.price - e.target.value) / e.target.value) * 100;
                                           setInvoiceData({ ...invoicedata, unitPrice: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                        }} />

                                    </InputWrapper>
                                </div>

                                <div className="subname">
                                    <Label>Tax</Label>
                                    <Selectbox style={{ width: '100%', height: '34px', fontSize: 13, }} onChange={(e) => {
                                       
                                        setInvoiceData({ ...invoicedata, tax: e.target?.value?.value, taxName: e.target?.value?.name, taxNumber: e.target?.value?.description })
                                    }}
                                        sx={{
                                            "&:hover": {
                                                "&& fieldset": {
                                                    border: "0.5px solid #414552"
                                                }
                                            },

                                        }}


                                        renderValue={() => invoicedata?.tax ? `${invoicedata?.tax} %` : "Select tax"}
                                        defaultValue={"0"} >
                                        {[{ name: 'No tax', value: '0' }, ...tax]?.map((item, index) =>
                                            <StyledMenuItem key={item.value} value={item}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #f3f3f3', width: '100%', marginTop: '-10px', marginBottom: '-10px' }}>
                                                    <div>
                                                        <p style={{ fontSize: '14px', textAlign: 'start' }}>{item?.name}</p>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                    </div>
                                                </div>
                                            </StyledMenuItem>
                                        )}
                                    </Selectbox>
                                </div>
                            </div>

                            <DescriptionContainer className="name" style={{ width: '100%', marginBottom: '25px' }}>
                                <Label>Description</Label>
                                <InputText
                                    type="text"
                                    placeholder='Details'
                                    style={{ marginTop: '0px', minHeight: '25px' }}
                                    value={invoicedata?.description}
                                    onChange={(e) => setInvoiceData({ ...invoicedata, description: e.target.value })}
                                />
                            </DescriptionContainer>

                        </AddLineFiled> : (!boolValue?.newItem ?
                            <NewLineItemButton onClick={() => {
                                setBoolValue({ ...boolValue, newItem: true })
                                if (autocompleteRef.current) {
                                    autocompleteRef.current.focus();
                                }
                            }} >
                                <AddBoxOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                                <NewLineItemText>Add item</NewLineItemText>
                            </NewLineItemButton> :
                            <Autocomplete1
                                value={value2}
                                onChange={(event, newValue) => {
                                   
                                    const searchString = 'Add new line item';
                                    const replacement = '';
                                    const result = newValue?.name
                                  
                                    if (newValue && newValue?.name?.includes(searchString)) {
                                        setInvoiceData({
                                            "name": "",
                                            "price": "0",
                                            "description": "",
                                            "quantity": "1",
                                            "tax": "0",
                                            "taxName": "no Tax",
                                            "taxNumber": '',
                                            "optional": false
                                        })
                                        setIsAddline(true)
                                        setIsFocused(true)
                                        // Create a new value from the user input
                                        // addNewHashTag(result)
                                        //setValue(result);
                                        // setValue2(result)
                                        //   setOpenAddContact(true)
                                    }

                                    else if (typeof newValue?.name === 'string') {
                                        setIsAddline(true)
                                        setIsFocused(true)
                                        const taxItem = tax.find(t => t.name === newValue.taxName);
                                        setInvoiceData({ ...newValue, taxNumber: taxItem?.description, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) })


                                    }
                                    else {
                                        setValue2(result)


                                        //  setHasTag([...selectHash,result])
                                    }

                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option?.name);
                                    filtered.push({ name: `Add new line item` });


                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={props?.lineItem}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option?.name === 'string') {
                                        return option.name;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option;
                                }}
                                renderOption={renderOption}
                                sx={{
                                    width: "100%", '& input': {
                                        height: '5px', fontSize: '13px'
                                    },
                                }}
                                freeSolo
                                style={{ height: 30, fontSize: '13px', marginTop: '10px' }}
                                renderInput={(params) => (
                                    <AutocompleteWrapper>
                                        <TextField
                                            {...params}
                                            style={{ fontSize: '13px' }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search style={{ fontSize: '20px' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Search line item"
                                            inputRef={autocompleteRef}
                                        />

                                    </AutocompleteWrapper>
                                )}
                            />)
                    }
                    {isAddLine ? <Footer>
                        <RippleButton style={{ marginRight: '20px', background: '#fff', color: '#000', border: '1px solid #ddd' }} onClick={() => {
                            if (isEdit) {
                                addCustomFiled()
                            }
                            setIsAddline(false)
                            setIsFocused(false)
                            setBoolValue({ ...boolValue, newItem: false })
                        }}>Cancel</RippleButton>
                        <RippleButton onClick={() => {
                            setBoolValue({ ...boolValue, newItem: false })
                            setIsFocused(false)
                            addCustomFiled()
                        }}>Add</RippleButton>
                    </Footer> : null}

                </MainContainer>
            </Flexbox>
        </>
    )
}

export default EditBundle

const Flexbox = styled.div`
padding-left:30px;
padding-right:30px;
flex-direction:column;
overflow-y:scroll;
background:#fff;
align-items:center;
height:100vh;
display:flex;
 position: relative;
${({ isFocused }) =>
    isFocused &&
    `  &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6); /* White faded overlay */
        z-index: 1;
      }
      overflow-y: scroll;
    & > .add-line-field {
      filter: blur(0px); /* Remove blur for focused child */
      background-color: white;
      z-index: 1000000000; /* Bring child above the Main container */
      pointer-events: auto;
    }
  `}
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const HeaderContainer = styled.div`
  position: fixed;
  top: 2.5%;

  width: 47%;
  display: flex;
align-self:center;
  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 


  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton{
      
  }
`;
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#fff;
margin-right:15px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`

const Inputbox = styled.input`
height: 34px;
font-size: 13px;
box-sizing: border-box;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
::placeholder {
    font-size: 13px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1.5px solid ${palette.charcoleBlack};
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-top:15px;
    margin-bottom: 8px;
    margin-left:5px;
    align-self: flex-start;
    font-family:Inter;

  
`
const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top:5px;
  margin-bottom:5px;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-top:0px;
      margin-bottom:5px;
  
    }
    &.description {
      color: gray;
      margin-top:0px;
      text-align:start;
      margin-bottom:0px;
      line-height:16px;
      flex-wrap:wrap;
      word-break:break-all;
      white-space:nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    &.price {
      font-weight: 550;
      margin-top:0px;
    }
  }
`;

const InputText = styled.textarea`
  font-family: Inter;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  border-radius: 5px;
  font-size: 13px;
  height: 45px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  resize: vertical; /* Allow vertical resizing */
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 99%;
  }
`;

const AddLineFiled = styled.div`
display:flex; 
flex-direction:column; 
width:100%;
.name{
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    width:60%;
}
.subname{
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    width:20%;
    margin-left:20px;
}
`
const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:4px;
height: 30px;
font-size:13px;
width:100%;
-moz-appearance: textfield; /* Firefox */
  
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
&:focus {
    outline: none;
    border: 1.5px solid #000; /* Remove border on focus */
  }
`
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const PercentageSymbol = styled.span`
  position: absolute;
  left:8px;
  top: 49%;
  transform: translateY(-50%);

  font-size: 13px;
  color: black;
  pointer-events: none; 
`;
const Selectbox = styled(Select)`
width:300px;
border-radius:4px;
text-align:start;
display:flex;
border:none;
height:38px;
font-size:14px;
.MuiSelect-select {
    padding-right: 0px;
   
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }



  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
align-self:start;
@media (min-width: 260px) and (max-width: 700px){
    zoom:0.6;
}
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const HeaderDiv = styled.div`
    display: flex;
    align-items: center;
    margin-left:14px;
    .title{
        color: ${palette.charcoleBlack};  
    }
`;

const MainContainer = styled.div`
    margin-top: 80px;
    width: 100%;
    padding-bottom:80px;
  
`;

const InputContainer = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content:space-between;
    width: 100%;
`;

const FullWidth = styled.div`
    width: 100%;
`;

const BundleTitle = styled.h4`
    margin-top: 20px;
    text-align: start;
`;

const DraggableItem = styled.div`
    user-select: none;
    padding: ${(props) => (props.isDragging ? '10px' : '7px 0')};
    background: ${(props) => (props.isDragging ? '#f3f3f3' : 'white')};
`;

const LineItemContainer = styled.div`
    display: flex;
    width: 75%;
`;

const ItemDetailsTitle = styled.h4`
    margin-top: 25px;
    margin-bottom: 5px;
`;

const DescriptionContainer = styled.div`
    width: 98.5%;
    margin-bottom: 25px;
`;

const NewLineItemButton = styled.div`
background:#0F52BA; 
cursor:pointer; 
color:white; 
padding:10px 13px 10px 13px;
display:flex; 
align-items:center; 
justify-content:center; 
    width: 100px;
    margin-top: 20px;
    border-radius: 8px;
   
`;

const NewLineItemText = styled.div`
    text-align: start;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    color:${palette.white};
`;

const AutocompleteWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: -5px;
    background: #fff;
`;

const Footer = styled.div`
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 15px;
`;
const ImageUrl = styled.div`
height:170px;
margin-left:20px;
border:2px solid #ddd;
border-radius:8px;
display:flex;
justify-content:center;
align-items:center;
`
const Image = styled.img`
width:190px;
padding:5px;
`
const FileInputButton = styled.label`
  display: flex;
  flex-direction:column;
  padding: 10px 15px;
  background-color: white;
  justify-content:center;
  align-items:center;
  color: ${palette.newBlue}; 
  font-size:14px;
  margin-top:10px;
  border-radius: 8px;
  border:2px solid #ddd;
  font-weight:600;
  width:200px;
  margin-left:20px;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelDropdown = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const SelectDrpoDown = styled.select`
  padding: 8.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;