import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CarouselOverlay = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

const CarouselContainer = styled.div`
  width: 80%;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  background: white;
`;

const ImageContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${(props) => `translateX(-${props.translate}%)`};
`;

const Image = styled.img`
  width: 100%;
  
  flex-shrink: 0;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  width:100%;
  position:absolute;
  align-self:center;
  bottom:0;
  margin-bottom:15px;
`;

const Dot = styled.button`
  background: ${(props) => (props.active ? 'black' : 'gray')};
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
`;

const ImageCarousel = ({ images, visible, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <CarouselOverlay visible={visible} onClick={onClose}>
      <CarouselContainer onClick={(e) => e.stopPropagation()}>
        <ImageContainer translate={currentIndex * 100}>
          {images?.map((image, index) => (
            <Image key={index} src={image} alt={`Slide ${index}`} />
          ))}
        </ImageContainer>
        <NavButton left onClick={prevSlide}>
          ❮
        </NavButton>
        <NavButton onClick={nextSlide}>
          ❯
        </NavButton>
        <DotsContainer>
          {images?.map((_, index) => (
            <Dot key={index} active={index === currentIndex} onClick={() => goToSlide(index)} />
          ))}
        </DotsContainer>
      </CarouselContainer>
    </CarouselOverlay>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageCarousel;
