import React, { useEffect, useState } from "react";
import styled from "styled-components";
//import assigned from '../../assets/assigned.png';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Leads from '../../assets/Leads.png'
import cross from '../../assets/cross.png'
import Modal from "../Modal";
import AddTeamMember from "../Settings/AddTeamMember";
import { useDispatch, useSelector } from "react-redux";
import { formatMobileNumber } from "../../utils/Commonfunction";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken, getunreadCount, getUserDetails, getuserInfo } from "../../store/Actions/dashboard.action";
import {  calander, calanderColor, contactIcon, contactIconColor, FromColor, FromIcon, homeColor, homes, inbox, inboxBlue, Invoice, InvoiceColor, job, jobColor, MissionControl, MissionControlColor, plusIcon, quoteIcon, quoteIconColor, support, supportColor, team, teamColor, telephone, telephoneColor } from "../../assets";
import { palette } from "../../utils/Palette";
import {
 HomeOutlined, MessageOutlined, CallOutlined, KeyboardCommandKeyOutlined,
  AutoAwesome,
  PersonOutlineOutlined,
  CalendarMonthOutlined,
  RequestQuoteOutlined,
  PaidOutlined,
  HandymanOutlined,
  FeedOutlined,
  SettingsOutlined,
  AddCircle,
  CancelRounded,
  LocalAtmOutlined,
  AccountBalanceWalletOutlined

} from '@mui/icons-material';
import { Badge } from "@mui/material";
import io from 'socket.io-client';
const socket = io('https://apis.getnotifi.com',{autoConnect:false})

function LeftMenu(props) {
  const [active, setActive] = useState('Inbox')
  const [isModalopen, setModalOpen] = useState(false);
  const userdata = useSelector(state => state?.auth?.userData)
  const unreadCount = useSelector(state => state?.auth?.unreadCount)
  const dispatch = useDispatch()
  const [con, setCon] = useState()
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const socketRefresh=async()=>{
    await dispatch(getunreadCount())
  }

  useEffect(() => {
    socket.connect()
    socket.on('unreadCountUpdate', (msg) => {
      socketRefresh()
      socket.close()
      socket.connect()
    })
    
    return () => {
      socket.off('unreadCountUpdate');
    };
  }, []);


  // const token = new Device("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzgyNWY3Zjk2OGU0OGUzMDVkMzE3MzNjNTgzNTQ2ZjUzLTE3MTEyMzA5NzAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiIrMTgwNzgwODU0OTciLCJ2b2ljZSI6eyJpbmNvbWluZyI6eyJhbGxvdyI6dHJ1ZX0sIm91dGdvaW5nIjp7ImFwcGxpY2F0aW9uX3NpZCI6IkFQOGEyMjEzZjFlMzNjNzNhMWRlMzkzMDViMThhOWIwNTMifSwicHVzaF9jcmVkZW50aWFsX3NpZCI6IkNSNmM5Y2Y2MzQ4MzExMjk0MDkwMWUxYTdjMzEwMWU3ZjkifX0sImlhdCI6MTcxMTIzMDk3MCwiZXhwIjoxNzExMjM0NTcwLCJpc3MiOiJTSzgyNWY3Zjk2OGU0OGUzMDVkMzE3MzNjNTgzNTQ2ZjUzIiwic3ViIjoiQUMwNDMwZDllZDI1NWJkYjE5MDg3MTk1OGQ0Yzc3ZTI5YSJ9.afXA-tTQA3MYPT0LWPfY3g_gb2PGj-6UMRAEbmjv3rM")

  // token.on('error', (deviceError) => {
  //   // the following table describes how deviceError will change with this feature flag
  // });
  // const handleSuccessfulRegistration = () => {
  //   console.log('The device is ready to receive incoming calls.')
  // }

  // token.register();
  // token.audio.ringtoneDevices.get(); 
  // token.audio.ringtoneDevices.test();

  // console.log(token.audio.ringtoneDevices.get())
  // token.on('registered', handleSuccessfulRegistration);
  // token.on("incoming", call => {
  //   navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  //   setCon(call)
  //   //call.accept();
  //   console.log(call.parameters);
  // })
  // token.on('disconnect',async(call) => {
  //   const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: false });
  //   mediaStream.getTracks().forEach((track) => {
  //     track.stop(); // Stop each track in the media stream
  //   });
  //   mediaStream = null; 
  // })
  const elements = [
    {
      'name': 'Home',
      'icon': homes,
      'colorIncon': homeColor,
      'outlined': HomeOutlined,
      'screen': '/dashboard/home'
    },

    {
      'name': 'Inbox',
      'icon': inbox,
      'colorIncon': inboxBlue,
      'outlined': MessageOutlined,
      'screen': '/dashboard/inbox'
    },
    {
      'name': 'Call logs',
      'icon': telephone,
      'colorIncon': telephoneColor,
      'outlined': CallOutlined,
      "screen": '/dashboard/calllogs'
    },
    {
      'name': 'Leads',
      'icon': Leads,
      'colorIncon': Leads,
      'outlined': AutoAwesome,
      'screen': '/dashboard/leads'
    },
    {
      'name': 'Notifications',
      'icon': MissionControl,
      'colorIncon': MissionControlColor,
      'outlined': KeyboardCommandKeyOutlined,
      'screen': '/dashboard/missioncontrol'
    },





  ]
  const element2 = [
    {
      'name': 'Contacts',
      'icon': contactIcon,
      'colorIncon': contactIconColor,
      'outlined': PersonOutlineOutlined,
      "screen": '/dashboard/contacts'
    },

    {
      'name': 'Calendar',
      'icon': calander,
      'colorIncon': calanderColor,
      'outlined': CalendarMonthOutlined,
      "screen": '/dashboard/calander'
    },
    {
      'name': 'Quotes',
      'icon': quoteIcon,
      'colorIncon': quoteIconColor,
      'outlined': RequestQuoteOutlined,
      "screen": '/dashboard/quotes'
    },
    {
      'name': 'Invoices',
      'icon': Invoice,
      'colorIncon': InvoiceColor,
      'outlined': PaidOutlined,
      "screen": '/dashboard/invoices'
    },
    {
      'name': 'Jobs',
      'icon': job,
      'colorIncon': jobColor,
      'outlined': HandymanOutlined,
      "screen": '/dashboard/servicerequests'
    },
    {
      'name': 'Customer forms',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': FeedOutlined,
      "screen": '/dashboard/forms'
    },
    {
      'name': 'Pricebook',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': LocalAtmOutlined,
      "screen": '/dashboard/pricebook'
    },
    {
      'name': 'Payments',
      'icon': FromIcon,
      'colorIncon': FromColor,
      'outlined': AccountBalanceWalletOutlined,
      "screen": '/dashboard/payments'
    },




  ]


  const init = async () => {
    await dispatch(getUserDetails())
    await dispatch(getuserInfo())
    await dispatch(getunreadCount())
    await dispatch(getAccessToken())

  }

  useEffect(() => {
    init()
  }, [])
  const getName = (channel) => {
    if (!channel) {
      return
    }
    const val = channel?.split(' ')

    if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
    }
    else {
      return `${val[0]?.charAt(0).toUpperCase()}`
    }
  }

  return (
    <Main>
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', marginBottom: '10px', marginLeft: '5px' }}>
        {userdata?.logoImage ? <ProfilePic src={userdata?.logoImage} /> : <OutProfile>
          {getName(userdata?.businessName)}
        </OutProfile>}
        <IconWrapper>
          <Heading>{userdata?.businessName?.slice(0, 17)}{userdata?.businessName?.length > 17 ? '...' : ''}</Heading>
          <Header>{formatMobileNumber(localStorage.getItem('number'))}</Header>
        </IconWrapper>

      </div>

      <div>
        <Element style={{ background: anchorEl && palette.smokeWhite }} onClick={handleClick}>
          <div style={{display:'flex',alignItems:'center'}}>
          {anchorEl ? <CancelRounded
            style={{ fontSize: '20px', color: anchorEl ? palette.newBlue : '#000', marginRight: '15px', marginLeft: '10px' }}
          /> : <AddCircle
            style={{ fontSize: '20px', color: anchorEl ? palette.newBlue : '#000', marginRight: '15px', marginLeft: '10px' }}
          />
          }
          <span style={{ color: anchorEl ? palette.newBlue : '#000' }}>{"Create"}</span>
          </div>
        </Element>
        {/* <Element style={{ justifyContent: 'center', marginBottom: '15px',  boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)',  borderRadius: '25px', marginLeft: 0, background: palette.newBlue, color: palette.white }} aria-describedby={id} variant="contained" onClick={handleClick}>
          <AddCircleOutlineOutlined
            style={{ fontSize: '18px', color: palette.white, marginRight: '10px' }}
          />

          Create
        </Element> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                overflow: 'visible',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 8,
                  width: 10,
                  height: 10,
                  backgroundColor: 'inherit',
                  transform: 'translateY(-50%) rotate(45deg)',
                  boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                },
              },
            },
          }}
          style={{ marginLeft: '20px' }}
        >

          <Typography style={{ display: 'flex' }} sx={{ p: 2 }}>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/contacts?contact=true')
              }}
            >

              <PersonOutlineOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.green }}

              />
              Contact
            </CreateContainer>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/invoices?invoice=true')
              }}
            >

              <PaidOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.indinRed }} />
              Invoice
            </CreateContainer>
            <CreateContainer
              onClick={() => {
                handleClose()
                navigate('/dashboard/quotes?quote=true')
              }}
            >

              <RequestQuoteOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.orange }}

              />
              Quote
            </CreateContainer>
            <CreateContainer>

              <HandymanOutlined
                style={{ fontSize: '24px', marginBottom: '5px', color: palette.pup }}

                onClick={() => {
                  handleClose()
                  navigate('/dashboard/servicerequests?request=true')
                }}
              />
              Job
            </CreateContainer>
            {/* The content of the Popover. */}
          </Typography>
        </Popover>
      </div>



      <div style={{ paddingBottom: '10px' }}>
        {elements?.map((x, index) => {
          const OutlinedComponent = x?.outlined
          return (
            <Element className={location.pathname.startsWith(x.screen) ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              setActive(x.name)
              props?.setIndex(x.name)

            }}>
              <div style={{display:'flex',alignItems:'center'}}>
                
              <OutlinedComponent
                style={{ fontSize: location.pathname.startsWith(x.screen) ? '18px' : '16px', color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
              />
              <span style={{ color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44' }}>{x.name}</span>
              </div>
              {x.name === 'Inbox' && unreadCount?.unreadCountMessage > 0 && (
                <Badge style={{background:'#fa3e3e',color:'white',width: '19px', height: '19px',justifyContent:'center',alignItems:'center', marginRight:'15px', fontSize:'9px',borderRadius:'10px'}}>
                  {unreadCount?.unreadCountMessage > 90 ? '90+' : unreadCount?.unreadCountMessage}
                </Badge>
              )}
               {x.name === 'Notifications' && unreadCount?.missionControlCount > 0 && (
                <Badge style={{background:'#fa3e3e',color:'white',width: '18px', height: '18px',justifyContent:'center',alignItems:'center',marginRight:'15px', fontSize:'9px',borderRadius:'10px'}}>
                  {unreadCount?.missionControlCount > 90 ? '90+' : unreadCount?.missionControlCount}
                </Badge>
              )}
            </Element>
          )
        })}
        {/* {localStorage.getItem('fromnumber') !== '+12363122675' && elements4?.map((x, index) => {
          return (
            <Element className={location.pathname === x.screen ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              setActive(x.name)
              props?.setIndex(x.name)
            }}>
              <Icon src={location.pathname === x.screen ? x.colorIncon : x.icon} alt={x.name} /><span style={{ color: location.pathname === x.screen ? '#0F52BA' : '#353a44' }}>{x.name}</span>
            </Element>
          )
        })} */}
      </div>
      <div style={{ paddingTop: '10px', paddingBottom: '115px', scrollbarWidth: 'none', overflowY: 'scroll', borderTop: '1px solid #ddd' }}>
        {/* <div style={{ alignSelf: 'start', display: 'flex', justifyContent: 'start', fontSize: '14px',marginTop:'0px', marginLeft: '10px', marginBottom: '10px', fontWeight: 'bold' }}>Smart views</div> */}
        {element2?.map((x, index) => {
          const OutlinedComponent = x?.outlined
          return (
            <Element className={location.pathname.startsWith(x.screen) ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              setActive(x.name)
              props?.setIndex(x.name)
            }}>
                <div style={{display:'flex',alignItems:'center'}}>
              <OutlinedComponent
                style={{ fontSize: x.screen === '/dashboard/home' ? '18px' : '16px', color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
              />
              <span style={{ color: location.pathname.startsWith(x.screen) ? '#fff' : '#353a44' }}>{x.name}</span>
              </div>
            </Element>
          )
        })}
        {/* {localStorage.getItem('fromnumber') !== '+12363122675' && element2?.map((x, index) => {
          return (
            <Element className={location.pathname === x.screen ? "blueclass" : ''} key={index} onClick={() => {
              navigate(x?.screen)
              setActive(x.name)
              props?.setIndex(x.name)
            }}>
              <Icon src={location.pathname === x.screen ? x.colorIncon : x.icon} alt={x.name} /><span style={{ color: location.pathname === x.screen ? '#0F52BA' : '#353a44' }}>{x.name}</span>
            </Element>
          )
        })} */}
      </div>
      <BottomTexts>

        {/* <Element2 onClick={() => {
          navigate('/dashboard/crewmember')
          setActive('crew')
          props?.setIndex("crew")
        }} className={location.pathname === '/dashboard/crewmember' ? "blueclass" : ''}><GroupOutlined 
         alt='addteam' 
        style={{ fontSize: '16px', color: location.pathname === '/dashboard/crewmember' ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
        /><span style={{ color: location.pathname === '/dashboard/crewmember' ? '#fff' : '#353a44' }}>Crew members</span></Element2> */}
        <Element2 className={location.pathname === '/dashboard/settings' ? "blueclass" : ''} onClick={() => {
          navigate('/dashboard/settings/account-details')
          setActive('settings')
          props?.setIndex("settings")
        }}><SettingsOutlined

            style={{ fontSize: '16px', color: location.pathname === '/dashboard/settings' ? '#fff' : '#353a44', marginRight: '15px', marginLeft: '10px' }}
            alt='settings' /><span style={{ color: location.pathname === '/dashboard/settings' ? '#fff' : '#353a44' }}>Settings</span></Element2>
        {/* <Element2 className={active === 'Help' ? "blueclass" : ''} ><Icon src={question} alt='settings' /><span>Help</span></Element2> */}

      </BottomTexts>
      {isModalopen && <Modal onClose={() => setModalOpen(false)} >
        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
          <h2>Add team member</h2>
          <CloseButton onClick={() => setModalOpen(false)}>
            <Icons src={cross} />
          </CloseButton>
        </div>
        <AddTeamMember
          onCancel={() => setModalOpen(false)}
        />
      </Modal>}
      {/* <div onClick={()=>{
        const call=token.connect({
          params:{
            to: '+13087374557',
            from:'+15066990701'
          }
        })
      }}>call</div>
       <div onClick={()=>{
       con.accept()
      }}>accept</div> */}

    </Main>
  )
}
export default LeftMenu

const Main = styled.div`
width:210px;
flex-shrink: 0;
overflow: auto; 
position:fixed;
background: #FCFBFD;
border-right:1px solid #F3F3F3;
color:black;

margin-top:-10px;

display:flex;
flex-direction:column;
padding-left:15px;
padding-right:15px;
padding-top:25px;
padding-bottom:10px;
height: 100vh;
overflow-y:hidden;

.blueclass{
background-color:${palette.newBlue};
font-weight:500;

}
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Heading = styled.p`
font-size: 14px;
font-weight:900;
margin-bottom:-10px;
text-align:start;

`

const Element = styled.div`
font-size:13px;
line-spacing:1.5;
cursor:pointer;
display:flex;
justify-content:space-between;
font-weight:500;
align-items:center;
border-radius:8px;
padding-top:9px;
padding-bottom:9px;
padding-left:5px;

margin-bottom:2px;
&:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); 
  }
`
const Element2 = styled.div`
font-size:13px;
line-spacing:1.5;
cursor:pointer;
display:flex;
justify-content:start;
font-weight:500;
align-items:center;
border-radius:8px;
padding-top:8px;
padding-bottom:8px;
padding-left:5px;

margin-bottom:5px;
&:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); 
  }
`
const BottomTexts = styled.div`
display:flex;
flex-direction:column;
width:210px;
position:absolute;
bottom:-10px;
background:#FCFBFD;

justify-content:flex-end;
padding-bottom:50px;

`
const Icon = styled.img`
height:15px;
width:15px;
margin-right:15px;
padding-left:8px;
color:black
`
const IconWrapper = styled.div`



  display:flex;
  flex-direction:column;
justify-content:start;
`;

const Sublogo = styled.img`
padding:3px;
margin-top:12px;
position: absolute;
top: 0;
left: 0;
width: 130%;
height: 130%;
transition: transform 0.3s ease, opacity 0.3s ease;
${IconWrapper}:hover & {
  transform: rotate(180deg);
  opacity: 0;
}
`
const HoverIcon = styled.img`
position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin-top:18px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  ${IconWrapper}:hover & {
    transform: rotate(0);
    opacity: 1;
  }
`
const Sublogo1 = styled.img`


width: 37px;

margin-top:17px;
height: 37px;


`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:45px;
width:45px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:25px;
font-weight:bold;
margin-left:-5px;
`
const Header = styled.p`
font-size:14px;
font-weight:500;
color:gray;

`
const ProfilePic = styled.img`
background:#fff;
color:white;
height:auto;
width:45px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:25px;
font-weight:bold;
margin-left:-5px;

`
const CreateIcons = styled.img`
width: 35px;
height: 35px;
`
const CreateContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
font-size:14px;
font-weight:500;
margin-right:20px;
margin-left:20px;
cursor: pointer;
`