import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { resetPassword, updateDetail } from '../../store/Actions/dashboard.action'
import { formatMobileNumber } from '../../utils/Commonfunction'
import InputBox from '../../ReuseableComponets/InputBox'
import { palette } from '../../utils/Palette'

function LoginSecurity(props) {
    const detail = useSelector(state => state?.auth?.userDetails)
    const [loader, setLoader] = useState('')
    const [values, setValues] = useState({
        "email": detail?.email,
        "password": "",
        "newPassword": ""

    })
    const [onFocus, setOnfocus] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch()

    const onPress = async () => {
        const res = await dispatch(resetPassword(values))
        if (!res.status) {
            setError(res?.message)
        }
        else {
            setLoader('Saved')
        }
        setOnfocus(false)
    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    return (
        <Main>

            <Header>Password</Header>
            <Name>Login with your password instead of using temprorary login codes</Name>
            {/* <Profile>{getName(`${detail?.firstName} ${detail?.lastName}`)}</Profile> */}

            <Wrapper>


                <InputBox header={"Email"} value={detail?.email} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%' }} id="outlined-basic" size="small" placeholder="First name" variant="outlined" onChange={(e) => setValues({ ...values, firstName: e.target.value })} />



                <InputBox header={"Current password"} variant="outlined" type={'password'} value={values?.lastName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%' }} id="outlined-basic" size="small" placeholder="Current password" variant="outlined" onChange={(e) => setValues({ ...values, password: e.target.value })} />


                <InputBox header={"New password"} variant="outlined" type={'password'} value={values?.lastName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%' }} id="outlined-basic" size="small" placeholder="New password" variant="outlined" onChange={(e) => setValues({ ...values, newPassword: e.target.value })} />

            </Wrapper>
            {error ? <div style={{ color: 'red', textAlign: 'start' }}>{error}</div> : null}
            {loader ? <div style={{ color: 'green', textAlign: 'start' }}>{"Your password has been changed successfully."}</div> : null}
            {<div style={{ alignSelf: 'end', marginRight: '30px' }}>

                {loader ? <SendButton style={{ background: 'green' }}>Saved !</SendButton> : <SendButton onClick={() => onPress()}>Save</SendButton>}
            </div>}

        </Main>
    )


}

export default LoginSecurity;


const Main = styled.div`
width:660px;
margin-top:-10px;
padding-top:25px;
scrollbar-width: thin;
padding-left:30px;
flex-shrink: 0;
overflow: auto; 
padding-bottom:50px;
height: 92vh;
  display: flex;
  flex-direction: column;
  @media (min-width: 260px) and (max-width: 999px){
    width:650px;
    flex-shrink: 0;
overflow: auto; 
}
@media (min-width: 1000px) and (max-width:1311px){
  width:730px;
}
@media (min-width: 1500px) and (max-width: 5000px){
  width:70%;
}
`
const Name = styled.p`
text-align:start;
font-size: 14px;
line-height: 21px;
color: rgb(45, 47, 49);
font-weight: 400;
margin-top:-15px;
margin-bottom:0px;
`

const Header = styled.p
    `
font-weight: 600;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-top:40px;
margin-right:10px;
`
const Profile = styled.div`
width:80px;
height:80px;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
border-radius:10px;
background:#4169e1;

color:white;
font-weight:600;
font-size:24px;

`
const Wrapper = styled.div`
display:block;
width:600px;
margin-top:30px;
.inputwrapper{
    width:auto;
    margin-bottom:30px;
 
}
.button{
    margin-left:230px;
    width:300px;
}
@media (min-width: 260px) and (max-width: 721px){
    width:90%;

    }

`
const Inputbox = styled.input`
width:auto;
border-radius:5px;
font-size: 16px;
height:25px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding: 10px 14px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:4px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

cursor:pointer;
width:60%;
:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`
const SendButton = styled.button`
border:1px solid ${palette.newBlue};
 background:${palette.newBlue}; 
 borderRadius:4px;
color:#fff; 
font-size: 14px;
margin-top:20px;
width:200px;
height:44px;
font-weight:600;
border-radius:8px;
border:1px solid white;
color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`