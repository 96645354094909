import React from 'react';

function RightModal({ children, onClose, isOpen,customStyle }) {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {

            onClose();
        }
    };

    return (
        <div style={{ ...styles.overlay, display: isOpen ? "flex" : "none" }} onClick={handleOverlayClick}>
            <div style={{ ...styles.modal,...customStyle, ...(isOpen && styles.openModal) }}>
                {children}
                {/* <button style={styles.closeButton} onClick={onClose}>X</button> */}
            </div>
        </div>
    );
}

const styles = {

    overlay: {
        zIndex: 1500,
        position: "fixed",
        justifyContent: "flex-end",

        width: "100%",
        height: "100%",

        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignContent: "stretch",
        alignItems: "center",
    },
    modal: {
        backgroundColor: '#FAFAFA',
        transform: "translate3d(100%, 0, 0)",
        transition: "transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)", 
        transition: "transform 0.5s ease", 
        marginLeft: "auto",
        willChange: "transform", 
        boxShadow: "0 12px 48px rgba(32,40,77,.40),0 32px 80px rgba(32,40,77,.1)",
        opacity: 1.5,
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '8px',
        position: 'relative',
        flexShrink: 0,
        overflow: 'auto',
        width: '35%',
        height: '100vh',
        maxHeight: '100vh',

        
    },
    openModal: {
        transform: "translate3d(0, 0, 0)",
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer'
    }
};

export default RightModal;