import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { deleteForm, getFormDetails } from "../../../store/Actions/dashboard.action";
import setting from '../../../assets/setting.png'
import moment from "moment";
import { Box, ClickAwayListener, MenuItem, Select, Tab, TableHead, Tabs, useTheme } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import SwipeableViews from 'react-swipeable-views';
import cross from '../../../assets/cross.png'
import RequestForm from "../../../Components/Dashboard/RequestForm";
import CustomerOnlineRequestForm from "../../../Components/Dashboard/CustomerOnlineForm";
import RightModal from "../../../Components/RightSideModal";
import TabPanel from "../../../Components/TabPanel";
import Modal from "../../../Components/Modal";
import FullScreenModal from "../../../Components/FullScreenModal";
import { ContentCopyOutlined, DeleteOutline, Edit, Settings } from "@mui/icons-material";
import { palette } from "../../../utils/Palette";
const FormList = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState(props?.data || [])
    const [loader, setLoader] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [formDetailModal, setFormDetailModal] = useState(false)
    const [copy, setCopy] = useState('Copy link')
    const [shareModal, setShareModal] = useState(false)
    const [formNumber, setFormNumber] = useState('')
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [indexs, setIndex] = useState(0)
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getFormDetails())
        setData(res?.data)
        setLoader(false)
    }
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (index) => {
        setIndex(index)
        setOpen(true);
    };


    useEffect(() => {
        setData(props?.data)
    }, [props?.data])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const handleCopyClick = (item) => {
        navigator.clipboard.writeText(`https://app.getnotifi.com/form/${item}`);

    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }




    const dataList = ["Edit form", 'Delete form']
    return (
        <>
            <FlexboxContainer>

                {loader ? <LoaderContainer>
                    <Player
                        src={require('../../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                </LoaderContainer>

                    :
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <CustomTable>

                            {data?.map((item, index) => (

                                <StyleBox>
                                    <HeaderContainer>
                                        <NameLabel>{item?.header || "New request"}</NameLabel>
                                        <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '6px', border: 'none', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() =>
                                                <Settings style={{ fontSize: '16px', marginTop: '5px' }} src={setting} />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px',
                                                    },
                                                },
                                            }}
                                        >

                                            <StyledMenuItem value={"Edit"}>
                                                <FilterBox
                                                    onClick={() => {
                                                        props?.setFormNumber(item?.formNo)
                                                        props?.setFormModal(true)
                                                    }}
                                                >

                                                    <Edit style={{ marginRight: '10px', fontSize: '16px' }} />
                                                    {"Edit"}</FilterBox>
                                            </StyledMenuItem>
                                            <StyledMenuItem value={"item"}>
                                                <FilterBox
                                                    style={{ color: '#C30000' }}

                                                    onClick={async () => {
                                                        await dispatch(deleteForm(item?.formNo))
                                                        props?.init()
                                                    }}
                                                >

                                                    <DeleteOutline style={{ marginRight: '10px', fontSize: '16px' }} />
                                                    {"Delete"}</FilterBox>
                                            </StyledMenuItem>
                                        </Selectbox>
                                    </HeaderContainer>
                                    {/* <TaskLabel>{item?.header}</TaskLabel> */}
                                    <DateContainer>
                                        <TaskLabel style={{ color: 'gray' }}>Created at</TaskLabel>
                                        <TaskLabel>{moment(item?.createdAt).format('DD MMM YYYY')}</TaskLabel>
                                    </DateContainer>
                                    <DateContainer>
                                        <TaskLabel style={{ color: 'gray' }}>Number of submissions</TaskLabel>
                                        <TaskLabel>{item?.customers}</TaskLabel>
                                    </DateContainer>
                                    <BottomContainer>

                                        <ViewButton onClick={() => {
                                            handleCopyClick(item?.formNo)
                                            handleTooltipOpen(index)
                                        }}>
                                            <ContentCopyOutlined style={{ marginRight: '10px', fontSize: '16px' }} />
                                            {index === indexs && open ? 'Copied!' : copy}</ViewButton>

                                        <SendButton onClick={() => {
                                            props?.setFormNumber(item?.formNo)
                                            props?.setShareModal(true)

                                        }}>
                                            {"Share"}</SendButton>
                                    </BottomContainer>

                                </StyleBox>
                            ))}
                          {data?.length > 0 ? null : <NoFormsMessage>No forms</NoFormsMessage>}
                        </CustomTable>
                    </ClickAwayListener>
                }
                {formModal && <FullScreenModal
                    isOpen={formModal}
                    onClose={() => {
                        init()
                        setFormModal(false)
                    }}
                >

                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
                        <h2>{formNumber ? "Edit form" : "Create form"}</h2>

                        <CloseButton onClick={() => setFormModal(false)}>
                            <Icons src={cross} />
                        </CloseButton>
                    </div>
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <RequestForm
                            onCancel={() => {
                                init()
                                setFormModal(false)
                            }}
                            formNumber={formNumber}
                        />

                    </div>
                </FullScreenModal>}
                {formDetailModal && <RightModal
                    isOpen={formDetailModal}
                    onClose={() => setFormDetailModal(false)}
                >

                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Form detail</h2>
                        <CloseButton onClick={() => setFormDetailModal(false)}>
                            <Icons src={cross} />
                        </CloseButton>
                    </div>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <CustomerOnlineRequestForm
                            formNumber={formNumber}
                        />
                    </div>
                </RightModal>}


            </FlexboxContainer>
            {shareModal && <Modal
                customStyle={{ background: '#fff' }}
                onClose={() => setShareModal(false)}
            >
                <div >
                    <Box sx={{ bgcolor: 'background.paper', width: 500 }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#0F52BA', // Change this to your desired color
                                },
                            }}
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab sx={{ textTransform: "none" }} label="Share a link" {...a11yProps(0)} />
                            <Tab sx={{ textTransform: "none" }} label="Add to website" {...a11yProps(1)} />

                        </Tabs>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <InputBoxContainer style={{ marginBottom: '20px', alignItems: 'center' }}>
                                    <div style={{ fontSize: '14px', marginBottom: '20px' }}>copy and paste your scheduled link Into a message</div>
                                    <Inputbox

                                    >
                                        {`https://app.getnotifi.com/form/${formNumber}`}
                                        <CopyButton onClick={() => {
                                            handleCopyClick(formNumber)

                                        }}>{copy}</CopyButton>
                                    </Inputbox>
                                </InputBoxContainer>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <h4 style={{ marginTop: 0, textAlign: 'start' }}>Embed code</h4>
                                <InputBoxContainer style={{ marginBottom: '20px', }}>

                                    <div style={{ fontSize: '14px', marginBottom: '20px' }}>Place your code in you HTML where you want your Notifi widget appear</div>
                                    <EmbbededInput
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {`
<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
<iframe src="https://app.getnotifi.com/onlineForm/${formNumber}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                                    `}
                                    </EmbbededInput>
                                </InputBoxContainer>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <CopyButton style={{ alignSelf: 'flex-end', marginRight: -20 }} onClick={handleCopyClick}>{"Copy code"}</CopyButton>
                                </div>
                            </TabPanel>

                        </SwipeableViews>
                    </Box>

                </div>
            </Modal>}
        </>
    )
}

export default FormList;
const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height:100%;
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid #f3f3f3; 
    padding-Bottom:10px; 
`;

const InputBoxContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
position:relative;
margin-bottom:20px;
@media (min-width: 260px) and (max-width: 721px){
    margin-left:0px;
}
`
const DateContainer = styled.div`
    display: flex;
 justify-content:space-between;
    padding-bottom:10px;
`;

const Inputbox = styled.div`
align-self:center;
width:100%;
border-radius:10px;
display:flex;
justify-content:space-between;
height:35px;
align-items:center;
font-size: 14px;

font-weight: 400;
color: #344054;
background:#f3f3f3;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding-left:10px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const EmbbededInput = styled.div`
width:100%;

border-radius:10px;
display:flex;
justify-content:start;
height:auto;
display:flex;
font-size: 12px;
border-style: dotted;

boder:1px solid #4169e1;
font-weight: 400;
color: gray;
background:#f3f3f3;
padding:20px;

`
const CopyButton = styled.button`
    border: 1px solid #4169e1;
    background: #4169e1;
    color: #fff;
    width: 150px;
    height: 38px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
`;
const StyleBox = styled.div`
cursor:pointer;
display:flex;
justify-content:flex-start;
flex-direction:column;
background:white;
width:300px;
margin-left:15px;
margin-top:15px;
margin-bottom:15px;
padding:25px;
border-radius:8px;
box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`
const ViewButton = styled.button`
border:1px solid #fff;
display:flex;
justify-content:center;
color:${palette.newBlue};
align-items:center;
border-radius:8px;
background:#fff;
font-size:13px;
height:30px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`

const FlexboxContainer = styled.div`
min-width:86vw;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow-y:scroll;
    align-items: flex-start;
`;

const CustomTable = styled.div`
display:flex; 
flex-wrap:wrap;
height:100%;
width:100%;
`

const NameLabel = styled.p`
    font-weight: 600;
    text-align:start;
    color: #344054;
    margin-top:0px;
    margin-bottom:0px;
    font-size:16px;

`;

const TaskLabel = styled.label`
padding-top:20px;
    color: #000;
    text-align:start;
    font-size:13px;
    
`;

const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 15px;
height: 15px;
`
const SendButton = styled.button`
border:1.3px solid ${palette.newBlue};
 background:#fff; 
 borderRadius:4px;
 color:${palette.newBlue};
font-size: 13px;
height:30px;
padding-left:15px;
padding-right:15px;
border-radius:8px;
margin-bottom:10px;
cursor:pointer;

`
const Selectbox = styled(Select)`

border-radius:4px;
text-align:start;
display:flex;
align-self:flex-start;
justify-content:flex-start;
align-items:center;
border:1px solid #fff;
background:white;
font-size:14px;
height:30px;
 margin-top:10px;

 .MuiSelect-select:focus {
    border: none !important; 
    border-color: #fff; 
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:hover {
    border: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}
@media (min-width: 260px) and (max-width: 721px) {
    width: 98%;
  }

`
const FilterBox = styled.div`
width:90px;
height:28px;
border-radius:8px;
display:flex;
font-size:13px;
align-items:center;
font-weight:500;
cursor:pointer;

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const BottomContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid #f3f3f3;
  justify-content: space-between;
  width: 100%;
`;
const NoFormsMessage = styled.h3`
  color: gray;
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  margin-bottom: 30vh;
`;
