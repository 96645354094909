export const palette={
    babyPink:'#FBE8EA',
    black:"#222222" ,
    white:'#FFFFFF',
    orange:'#de8c4e',
    smokeWhite:'#ebeef1',
    grey:'#787373',
    pink:'#D81159',
    lightGrey:'#B8BEC3',
    blue:'#398AFF',
    purple:'#4169e1',
    pup:'#4169e1',
    lightPurple:'#EBE6F9',
    green:'#34b233',
    lightPink:'#F089A8',
    indinRed:'#CD5C5C',
    lightGreen:'#9FE2BF',
    charcoleBlack:'#414552',
    charcolegrey:'#2b373f',
    newBlue:'#0F52BA',

}