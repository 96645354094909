import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RecurringJobModal.css';
import styled from 'styled-components';
import RippleButton from './RippleButton';
import { Cancel, CancelOutlined } from '@mui/icons-material';
const weekDays = [
  { day: 'Sunday', label: 'S', key: 0 },
  { day: 'Monday', label: 'M', key: 1 },
  { day: 'Tuesday', label: 'T', key: 2 },
  { day: 'Wednesday', label: 'W', key: 3 },
  { day: 'Thursday', label: 'T', key: 4 },
  { day: 'Friday', label: 'F', key: 5 },
  { day: 'Saturday', label: 'S', key: 6 },
];
const monthDate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]


const RecurringJobModal = ({ isOpen, onClose, onSave }) => {
  const [repeatUnit, setRepeatUnit] = useState('Day');
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [dayOfYear, setDayOfYear] = useState(1);


  const handleWeekDayClick = (day) => {
    setSelectedWeekDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };
  const handleMonthDayClick = (day) => {
    setSelectedMonthDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleSubmit = () => {
    const recurrence = {
      repeatUnit,
      dayOfYear,
      selectedWeekDays,
      repeatInterval: repeatUnit === 'Week' ? selectedWeekDays : selectedMonthDays,
      value: { type: repeatUnit, interval: dayOfYear || 1, customDaysString: repeatUnit === 'Week' ? selectedWeekDays : selectedMonthDays },
      label: summary()
    };
    onSave(recurrence);
    onClose()
  };

  if (!isOpen) return null;

  const getOrdinalSuffix = (num) => {
    if (num % 10 === 1 && num % 100 !== 11) return `${num}st`;
    if (num % 10 === 2 && num % 100 !== 12) return `${num}nd`;
    if (num % 10 === 3 && num % 100 !== 13) return `${num}rd`;
    return `${num}th`;
  };

  const summary = () => {
    if ((dayOfYear ? dayOfYear : 1) < 1) return '';

    const getDaysList = (daysArray, unitType) =>
      daysArray?.sort((a, b) => a - b)
        ?.map((item, index, arr) => {
          if (arr.length > 2 && index === arr.length - 1) {
            return `and ${getOrdinalSuffix(item)}`;
          } else if (arr.length === 2 && index === arr.length - 1) {
            return ` and ${getOrdinalSuffix(item)}`;
          }
          return `${getOrdinalSuffix(item)}`;
        })
        .join(', ');
    const getWeekList = (daysArray, unitType) =>
      daysArray?.sort((a, b) => a - b)
        ?.map((item, index, arr) => {
          const dayName = unitType[item]?.day; // Extract the 'day' property from weekDays
          if (arr.length > 2 && index === arr.length - 1) {
            return `and ${dayName}`;
          } else if (arr.length === 2 && index === arr.length - 1) {
            return ` and ${dayName}`;
          }
          return dayName;
        })
        .join(', ');

    switch (repeatUnit) {
      case 'Day':
        return dayOfYear > 1
          ? `Every ${dayOfYear} days`
          : 'Daily';

      case 'Week':
        return selectedWeekDays?.length > 0
          ? `${dayOfYear > 1 ? `Every ${dayOfYear} weeks` : 'Weekly'} on ${getWeekList(selectedWeekDays, weekDays)}`
          : '';

      case 'Month':
        return selectedMonthDays?.length > 0
          ? `${dayOfYear > 1 ? `Every ${dayOfYear} months` : 'Monthly'} on ${getDaysList(selectedMonthDays, monthDate)}`
          : '';

      case 'Year':
        return dayOfYear > 1
          ? `Every ${dayOfYear} years`
          : 'Yearly';

      default:
        return '';
    }
  };


  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'25px'}}>
         <h2 style={{ marginTop: '0px',marginBottom:'0px' }}>Repeat</h2>
        <CancelOutlined onClick={() => onClose()}/>
        </div>
       
        
        <Label>
          Repeat frequency
        </Label>
        <SelectDrpoDown
          value={repeatUnit}
          onChange={(e) => setRepeatUnit(e.target.value)}
        >
          <Option value="Day">Daily</Option>
          <Option value="Week">Week</Option>
          <Option value="Month">Month</Option>
          <Option value="Year">Year</Option>
        </SelectDrpoDown>

        {repeatUnit === 'Week' && (
          <>
            <Label style={{ marginTop: '20px' }}>
              {" Every  "}
              <input

                min="1"
                max="100"
                style={{ width: '20px', height: '20px', textAlign: 'center' }}
                value={dayOfYear}
                onChange={(e) => setDayOfYear(e.target.value)}
              />
              {"  week(s) on:"}
            </Label>
            <div className="week-days">

              {weekDays.map((day) => (
                <button
                  key={day.key}
                  className={selectedWeekDays.includes(day.key) ? 'selected' : ''}
                  onClick={() => handleWeekDayClick(day.key)}
                >
                  {day.label}
                </button>
              ))}
            </div>
          </>
        )}
        {repeatUnit === 'Month' && (
          <>
            <Label style={{ marginTop: '20px' }}>
              {" Every  "}
              <input

                min="1"
                max="100"
                style={{ width: '20px', height: '20px', textAlign: 'center' }}
                value={dayOfYear}
                onChange={(e) => setDayOfYear(e.target.value)}
              />
              {"  month(s):"}
            </Label>
            <GridContainer>
              {monthDate.map(day => (
                <GridItem
                  className={selectedMonthDays.includes(day) ? 'selected' : ''}
                  onClick={() => handleMonthDayClick(day)}
                  key={day}>{day}</GridItem>
              ))}
              <GridItem style={{ width: '70px' }}>Last Day</GridItem>
            </GridContainer>
          </>

        )}
        {repeatUnit === 'Year' && (
          <>
            <Label style={{ marginTop: '20px' }}>
              {" Every  "}
              <input

                min="1"
                max="100"
                style={{ width: '20px', height: '20px', textAlign: 'center' }}
                value={dayOfYear}
                onChange={(e) => setDayOfYear(e.target.value)}
              />
              {"  year(s)"}
            </Label>
          </>
        )}
        {
          repeatUnit === 'Day' && (
            <>
              <Label style={{ marginTop: '20px' }}>
                {" Every  "}
                <input

                  min="1"
                  max="100"
                  style={{ width: '20px', height: '20px', textAlign: 'center' }}
                  value={dayOfYear}
                  onChange={(e) => setDayOfYear(e.target.value)}
                />
                {"  day(s)"}
              </Label>
            </>
          )
        }

        <div style={{ fontSize: '13px',paddingTop:'10px',borderTop:'1px solid #ddd',marginTop:'10px', fontWeight: '550', textAlign: 'start', flexWrap: 'wrap', width: '100%' }}>
          {summary()}
        </div>



        <div className="modal-footer">
          <RippleButton style={{border:'1.5px solid #ddd',background:'#fff',color:'#000'}} onClick={() => onClose()}>
            Cancel
          </RippleButton>
          <RippleButton onClick={() => {
            handleSubmit()
          }}>
            Save
          </RippleButton>
        </div>
      </div>
    </div>
  );
};

RecurringJobModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default RecurringJobModal;

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  width:100%;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;

const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 34px);
  grid-gap: 2px;
  margin-top:20px;
  margin-bottom:20px;
  .selected {
    background: #007bff;
    color: #fff;

  }

`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  cursor:pointer;
  align-items: center;
  width: 35px;
  height: 30px;
  border: 1px solid #ccc;
  font-size: 11px;
  font-weight: bold;
  color: #333;
`;