import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { deleteCrew, getListForm, } from "../../store/Actions/dashboard.action";
import trash from '../../assets/trash.png'
import moment from "moment";
import { Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import plus from '../../assets/add.png'
import cross from '../../assets/cross.png'
import { formatMobileNumber } from "../../utils/Commonfunction";
import FormDetails from "../../Components/Dashboard/FormDetails";
import RightModal from "../../Components/RightSideModal";
import { pendingInvite } from "../../store/Actions/dashboard.action";
import AddTeamMember from "../../Components/Settings/AddTeamMember";
import Modal from "../../Components/Modal";
import { plusIcon } from "../../assets";


const CrewMember = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [isModalopen, setModalOpen] = useState(false)
    const [formDetailModal, setFormDetailModal] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [formNumber, setFormNumber] = useState('')
    const init = async () => {
        setLoader(true)
        const res = await dispatch(pendingInvite())
        setData(res?.data)
        setLoader(false)
    }

    useEffect(() => {
        init()

    }, [])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    return (
        <Body>
            <FlexboxContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginRight: '40px' }}>
                    <h1 style={{ textAlign: 'start' }}>Crew members</h1>
                    <OptionBox onClick={() => {
                        setModalOpen(true)
                    }}>
                        <Icons style={{ width: '12px', height: '12px', marginRight: '10px' }} src={plusIcon} />

                        Create Crew
                    </OptionBox>
                </div>
                {loader ? <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </div>

                    : <CustomTable>

                        <div style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <CustomHead>
                                    <TableRow >

                                        <TableCell size='small'>
                                            <div >
                                                Name
                                            </div>
                                        </TableCell>
                                        <TableCell size='small'>

                                        </TableCell>
                                        <TableCell size="small">
                                            Phone number
                                        </TableCell>
                                        <TableCell size='small'>

                                            Email

                                        </TableCell>


                                        <TableCell size='small'>

                                        </TableCell>



                                    </TableRow>
                                </CustomHead>
                                {data?.map((item) => (
                                    <TableBody
                                        onClick={() => {
                                            props?.setFormDetailModal(true)
                                            props?.setSelectedData(item)
                                        }}
                                        key={item._id} style={{ background: '#fff', cursor: 'pointer' }}>
                                        <TableRow>
                                            <TableCell size="small">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ProfileIcon>
                                                        {getName(item?.name)}
                                                    </ProfileIcon>
                                                    <NameLabel>{item?.name}</NameLabel>
                                                </div>
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>
                                                    <div style={{ padding: 5, width: '90px', fontWeight: '400', borderRadius: 5, display: 'flex', textAlign: 'center', justifyContent: 'center', color: item?.inviteStatus !== 'Pending' ? '#29740f' : '#fa811f', fontSize: '12px', background: item?.inviteStatus !== 'Pending' ? '#d1fab3' : '#fee6c8', border: item?.invoiceStatus === 'Rejected' ? '1px solid #fbd4dd' : item?.invoiceStatus === 'Paid' ? 'ipx solid #a8f16f' : item?.invoiceStatus === 'Draft' ? '1px solid #d8dee3' : '' }}>

                                                        <TaskLabel style={{ color: item?.inviteStatus === 'Pending' ? '#fa811f' : '#29740f' }}>{item?.inviteStatus}</TaskLabel>
                                                    </div>

                                                </TaskDetails>
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>

                                                    <TaskLabel>{formatMobileNumber(item?.phone)}</TaskLabel>
                                                </TaskDetails>
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>

                                                    <TaskLabel>{item?.email}</TaskLabel>
                                                </TaskDetails>
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails onClick={async () => {
                                                    await dispatch(deleteCrew(item?.email))
                                                    init()
                                                }}>
                                                    <Icons src={trash} />
                                                </TaskDetails>
                                            </TableCell>

                                            {/* <TableCell size="small">
                                            <TaskDetails>

                                                <TaskLabel>{moment(item?.createdAt)?.format('DD MMM hh:mm A')}</TaskLabel>
                                            </TaskDetails>
                                        </TableCell> */}
                                            {/* <TableCell size="small">
                                            <ViewButton
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                setFormNumber(item?.formNo)
                                                setFormModal(true)
                                            }}
                                            >Open form</ViewButton>
                                        </TableCell> */}

                                        </TableRow>
                                    </TableBody>
                                ))}

                            </Table>
                            {data?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No Crew member</h3>}
                        </div>

                    </CustomTable>}


                {isModalopen && <RightModal
                    isOpen={isModalopen}
                    onClose={() => setModalOpen(false)} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Add team member</h2>
                        <CloseButton onClick={() => setModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>
                    </div>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <AddTeamMember
                            onCancel={() => {
                                init()
                                setModalOpen(false)
                            }}
                        />
                    </div>
                </RightModal>}
            </FlexboxContainer>
        </Body>
    )
}

export default CrewMember;
const ViewButton = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#f3f3f3;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`

const FlexboxContainer = styled.div`
width:100%;
    background: #fff;
    display: flex;
    margin-left:30px;
    margin-right:30px;
    flex-direction: column;
    
    align-items: flex-start;
`;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const CustomTable = styled(TableContainer)`
&::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
border-color: red;
.MuiTableBody-root {
  
    border-bottom: none !important; /* Add !important */
    font-family:Inter;
    font-size:14px;
 }
 .MuiTableHead-root {
    font-weight: 600;
    font-family:Inter;
    font-size:14px;
  }
 .MuiTableRow-root {
  
    height: 30px;  // Set the height for each row
    border-bottom: none !important;
    font-family:Inter;
    font-size:14px;
    &:hover {
        background:#f3f3f3;
      }
  }
 .MuiTableCell-root{
    border-color: #f3f3f3;
    height: 32px;  
    font-family:Inter;
    font-size:14px;
  
   
   
}

  
.purple{
background:#974EDE;
}
.green{
background:#32cd32;
}
.dimgreen{
background:#008000;
}

`

const CustomHead = styled(TableHead)`
border-radius:8px;


`


const ProfileIcon = styled.div`
    background: #2b373f;

    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 12px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: bold;
`;

const NameLabel = styled.label`
    font-weight: bold;
    color: #344054;

`;

const TaskLabel = styled.label`
    color: #344054;
    text-align:start;
    
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
`;

const DateLabel = styled.label`
    margin-top: 5px;
    margin-right: 35px;
    color: #344054;
`;

const ActionIcon = styled.img`
    width: 22px;
    cursor: pointer;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content:start;
    align-self:start;
    max-width:350px;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-wrap;
`;
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
background:#0F52BA;
color:#fff;
font-size:14px;


display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`

