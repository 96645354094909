import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {  getListForm, } from "../../../store/Actions/dashboard.action";
import calenda from '../../../assets/calenda.png'
import moment from "moment";
import { Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

import cross from '../../../assets/cross.png'
import { formatMobileNumber } from "../../../utils/Commonfunction";
import FormDetails from "../../../Components/Dashboard/FormDetails";
import RightModal from "../../../Components/RightSideModal";
import { palette } from "../../../utils/Palette";


const FormDataList = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [formDetailModal, setFormDetailModal] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [formNumber, setFormNumber] = useState('')
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getListForm())
        setData(res?.data)
        setLoader(false)
    }

    useEffect(() => {
        // init()
        setData(props?.data)
    }, [props?.data])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    return (
        <FlexboxContainer>
            {loader ? <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                src={require('../../../assets/loaders.json')}
                className="player"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
            />
            </div>

                : <CustomTable>

                    <div style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <CustomHead>
                                <TableRow >

                                    <TableCell size='small'>
                                        <div >
                                            Customer name
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', color: palette.black, position: 'relative', fontWeight: '700' }} size="small">
                                        Phone number
                                    </TableCell>
                                    <TableCell style={{ color: palette.black, position: 'relative', fontWeight: '700' }} size='small'>

                                        Customer email

                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', color: palette.black, position: 'relative', fontWeight: '700' }} size='small'>

                                        Form name

                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', color: palette.black, position: 'relative', fontWeight: '700' }} size='small'>

                                        Submitted at

                                    </TableCell>
                                 



                                </TableRow>
                            </CustomHead>
                            {data?.map((item) => (
                                <TableBody
                                    onClick={() => {
                                        props?.setFormDetailModal(true)
                                         props?.setSelectedData(item)
                                    }}
                                    key={item._id} style={{ background: '#fff', cursor: 'pointer' }}>
                                    <TableRow>
                                        <TableCell size="small">
                                          {item?.customerName}
                                        </TableCell>
                                        <TableCell size="small" style={{width:'180px'}}>
                                           {formatMobileNumber(item?.customerNumber)}
                                        
                                        </TableCell>
                                        <TableCell size="small" style={{width:'180px'}}>
                                           {item?.customerEmail}
                                        </TableCell>
                                        <TableCell size="small">
                                            {item?.header||"New request"}
                                        </TableCell>
                                        <TableCell size="small">
                                            {moment(item?.createdAt)?.format('DD MMM hh:mm A')}
                                        </TableCell>
                                        {/* <TableCell size="small">
                                            <ViewButton
                                            onClick={(e)=>{
                                                e.stopPropagation()
                                                setFormNumber(item?.formNo)
                                                setFormModal(true)
                                            }}
                                            >Open form</ViewButton>
                                        </TableCell> */}

                                    </TableRow>
                                </TableBody>
                            ))}

                        </Table>
                        {data?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No forms</h3>}
                    </div>

                </CustomTable>}
         
            {formDetailModal && <RightModal
                isOpen={formDetailModal}
                onClose={() => setFormDetailModal(false)}
            >

                <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                    <h2>Form detail</h2>
                    <CloseButton onClick={() => setFormDetailModal(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                </div>
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                    <FormDetails
                        formDetail={selectedData}
                      
                        onCancel={() => {
                            init()
                            setFormDetailModal(false)
                        }}
                    />
                </div>
            </RightModal>}
        </FlexboxContainer>
    )
}

export default FormDataList;
const ViewButton = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#f3f3f3;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`

const FlexboxContainer = styled.div`
min-width:86vw;
    background: #fff;
    display: flex;
    margin-left:-20px;
    margin-right:30px;
    flex-direction: column;
    
    align-items: flex-start;
`;

const ContentContainer = styled.div`
    padding: 30px;

    width:95%;
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const ValueContainer = styled.div`
    padding: 15px;
    background: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ProfileIcon = styled.div`
    background: #2b373f;

    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 12px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: bold;
`;

const NameLabel = styled.label`
    font-weight: bold;
    color: #344054;

`;

const TaskLabel = styled.label`
    color: #344054;
    text-align:start;
    
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
`;

const DateLabel = styled.label`
    margin-top: 5px;
    margin-right: 35px;
    color: #344054;
`;

const ActionIcon = styled.img`
    width: 22px;
    cursor: pointer;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content:start;
    align-self:start;
    max-width:350px;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-wrap;
`;
const OptionBox = styled.div`
width:150px;
height:35px;
border-radius:8px;
font-weight:600;
font-size:14px;
border:1px solid #bbb;

display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`

