import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import cross from '../../assets/cross.png'
import { Form } from 'react-bootstrap'
import { useState } from "react";
import { formatMobileNumber, putCommaInvalue, taxprice, totalprice } from "../../utils/Commonfunction";
import { editInvoice, sendInvoice } from "../../store/Actions/dashboard.action";
import moment from 'moment'
import Autocomplete from 'react-google-autocomplete';
import PreviewInvoice from "../../Pages/PreviewInvoice";
import more from '../../assets/edit.png'
import pen from '../../assets/pen.png'
import plus from '../../assets/plus.png'
import { Checkbox, MenuItem, Select, InputAdornment, TextField, } from "@mui/material";
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RippleButton from "../../ReuseableComponets/RippleButton";
import { palette } from "../../utils/Palette";
import { Search } from "@mui/icons-material";
const filter = createFilterOptions();
function LineItemComponent(props) {
    const globename = useSelector(state => state?.auth?.globename)
    const globenumber = useSelector(state => state?.auth?.globenum)
    const userdata = useSelector(state => state?.auth?.userdata)
    const lineItem = useSelector(state => state?.auth?.userData?.lineItem)
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const invoicenum = useSelector(state => state?.auth?.userData?.numberOfInvoice)
    const dispatch = useDispatch()
    const [value2, setValue2] = useState(null)
    const [boolValue, setBoolValue] = useState({})
    const autocompleteRef = useRef(null);
    const [value, setValue] = useState({
        "name": props?.value?.name || userdata?.name || "",
        "email": props?.value?.email || userdata?.email || "",
        "address": props?.value?.address || userdata?.address || "",
        "phoneNumber": props?.value?.customerPhoneNumber || props?.value?.number || props?.value?.to || userdata?.to || userdata?.number || globenumber,
        "notes": 'Due date',
        "date": new Date(),
        'invoiceNumber': props?.value?.numberOfInvoice || invoicenum + 1 || '',
        'currency': userdata?.value?.currency || 'CAD'
    })
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || userdata?.name || "",
        "email": props?.value?.email || userdata?.email || "",
        "address": props?.value?.address || userdata?.address || "",

    })
    const [addOwnerData, setAddOwnerData] = useState(props?.items || [])
    const [checkedaddOwnerData, setcheckedAddOwnerData] = useState([])
    const [editDetail, setEditDetail] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [invoicedata, setInvoiceData] = useState({
        "name": "",
        "price": "",
        "description": "",
        "quantity": "",
        "tax": "0",
        "taxName": "no Tax",
        "optional": false,
        "unitPrice": ''
    })
    const [isAddLine, setIsAddline] = useState(false)
    const [defaultTax, setDefaultTax] = useState({
        name: '',
        value: '',
        default: true,
        index: 0

    })


    const addCustomFiled = () => {
        setAddOwnerData([...addOwnerData, invoicedata])
        setBoolValue({ ...boolValue, newItem: false })
        props?.value([...addOwnerData, invoicedata])
        setInvoiceData({
            "name": "",
            "price": "",
            "description": "",
            "quantity": "",
            "tax": "0",
            "taxName": "no Tax",
            "unitPrice": ''
        })



        setIsAddline(false)
        setIsEdit(false)
    }
    const deleteHandler = (index) => {

        const data = [...addOwnerData]
        data.splice(index, 1)
        setAddOwnerData(data)


    }
    const onchangeMaterials = (text, index, value) => {

        const data = [...addOwnerData]
        data[index][text] = value;
        setAddOwnerData(data);
        props?.value([...addOwnerData])
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }
    function calculateMarkupPercentage(costPrice, unitPrice) {


        const markupPercentage = ((unitPrice - costPrice) / costPrice) * 100;
        return parseFloat(markupPercentage)?.toFixed(2);
    }

    const onPress = async (draft) => {

        const val = {
            ...value, "items": addOwnerData, "total": `${addOwnerData.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
            }, 0)}`, "subtotal": `${addOwnerData.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)}`
        }
        if (draft?.draft) {
            if (props?.value?.edit) {
                const res1 = await dispatch(editInvoice({ ...val, referenceNo: props?.value?.invoiceNo, invoiceDraft: true }))
                if (res1.status === true) {
                    props?.setModalOpenInvoice(false)
                }
            }
            else {
                const res = await dispatch(sendInvoice({ ...val, invoiceDraft: true }))
                if (res.status === true) {
                    props?.setModalOpenInvoice(false)
                }
            }
        }
        else {
            if (props?.value?.edit) {
                const res1 = await dispatch(editInvoice({ ...val, referenceNo: props?.value?.invoiceNo }))
                if (res1.status === true) {
                    props?.onSubmitsw(`Please see the attached invoice for $${addOwnerData.reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                    }, 0)?.toFixed(2)}. https://app.getnotifi.com/invoice/${res1?.data?.referenceNo}`, val)
                }
            }
            else {
                const res = await dispatch(sendInvoice(val))
                if (res.status === true) {
                    props?.onSubmitsw(`Please see the attached invoice for $${addOwnerData.reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                    }, 0)?.toFixed(2)}. https://app.getnotifi.com/invoice/${res?.data?.referenceNo}`)
                }
            }
        }

    }
    const handleCheckboxChange = (item) => {
        // Add item to invoiceData or remove it based on checkbox state

        const newData = [...checkedaddOwnerData];

        const existingIndex = newData.findIndex((el) => `${el.ids}` === `${item.ids}`);
        console.log(newData, existingIndex)

        if (existingIndex !== -1) {
            newData.splice(existingIndex, 1);
        } else {
            newData.push(item);
        }
        props?.value(newData)
        setcheckedAddOwnerData(newData);
    };


    return (
        <>



            <MainContainer>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }} >

                    {
                        addOwnerData?.map((item, index) =>
                            <LineItem >
                                <div style={{ display: 'flex' }}>
                                    {props?.serviceRequest ? props?.edit?.isEditItem && <Checkbox
                                        style={{ width: '25px', marginRight: '10px' }}

                                        checked={checkedaddOwnerData.some((el) => el.ids === index)}
                                        onChange={() => handleCheckboxChange({ ...item, ids: index })}
                                    /> : null}

                                    <div>
                                        <p className="name">{item?.name} × {item?.quantity || '1'} {item?.optional ? '(Optional)' : null}</p>
                                        <p className="description">{item?.description}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))} </p>
                                    {<>
                                        <EditContainer

                                            style={{ marginTop: '10px', marginLeft: '10px', width: '45px' }} onClick={() => {
                                                const data = [...addOwnerData]
                                                data.splice(index, 1)
                                                setAddOwnerData(data)

                                                setInvoiceData(item)
                                                setIsAddline(true)
                                                setIsEdit(true)


                                            }}>

                                            <EditOutlinedIcon style={{ fontSize: '18px' }} />
                                        </EditContainer>
                                        <EditContainer

                                            style={{ marginTop: '10px', marginLeft: '10px', width: '45px' }} onClick={() => {
                                                const data = [...addOwnerData]
                                                data.splice(index, 1)
                                                setAddOwnerData(data)
                                                props?.value(data);
                                            }}>

                                            <DeleteOutlinedIcon style={{ fontSize: '18px' }} />
                                        </EditContainer>
                                    </>}
                                </div>
                            </LineItem>
                        )
                    }
                    {


                        ((!isAddLine) && (!boolValue?.newItem ?
                            <div onClick={() => {
                                setBoolValue({ ...boolValue, newItem: true })
                                if (autocompleteRef.current) {
                                    autocompleteRef.current.focus();
                                }
                            }} style={{ border: '1.5px solid #0F52BA', marginTop: '15px', padding: '7px 15px 7px 15px', borderRadius: '20px' }}>
                                <h4 style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px', color: palette.newBlue, cursor: 'pointer' }}>+ New Line item</h4></div> :
                            <Autocomplete1
                                value={value2}
                                onChange={(event, newValue) => {
                                    console.log(newValue, "dfkvndkkjdvkjdvfjkn")
                                    const searchString = 'Add new line item';
                                    const replacement = '';
                                    const result = newValue?.name
                                    //const regex = new RegExp(`\\b${searchString}\\b`, 'i'); 
                                    console.log(newValue, 'dsckjnl')
                                    if (newValue && newValue?.name?.includes(searchString)) {
                                        setInvoiceData({
                                            "name": "",
                                            "price": "0",
                                            "description": "",
                                            "quantity": "1",
                                            "tax": "0",
                                            "taxName": "no Tax",
                                            "unitPrice": '',
                                            "taxNumber": '',
                                            "optional": false
                                        })
                                        setIsAddline(true)
                                        // Create a new value from the user input
                                        // addNewHashTag(result)
                                        //setValue(result);
                                        // setValue2(result)
                                        //   setOpenAddContact(true)
                                    }

                                    else if (typeof newValue?.name === 'string') {
                                        setIsAddline(true)
                                        console.log(newValue, 'cdcdkndcjk')

                                        setInvoiceData({ ...newValue, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price), "unitPrice": newValue?.unitPrice })

                                        setValue2('')
                                        // setSelectedHash([...selectHash, result])
                                    }
                                    else {
                                        setValue2(result)
                                        setEditValue(newValue)

                                        //  setHasTag([...selectHash,result])
                                    }

                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option?.name);
                                    filtered.push({ name: `Add new line item` });


                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={lineItem}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option?.name === 'string') {
                                        return option.name;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option;
                                }}
                                renderOption={(props, item, index) => <div {...props}>
                                    {item?.name === 'Add new line item' ? <div style={{ position: 'fixed', bottom: 0, left: '0px', color: palette.newBlue, fontSize: '13px', fontWeight: '600', background: '#F0F8FF', width: '99%', right: 0, display: 'flex', justifyContent: 'center', padding: '4px' }}>+ Add new line item</div> : <LineItem style={{ width: '100%', marginTop: '-10px', '.&hover': { background: '#f3f3f3' } }}>
                                        <div style={{ width: '90%' }}>
                                            <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                            {item?.description && <p className="description">{item?.description}</p>}
                                        </div>
                                        <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                    </LineItem>}
                                </div>}
                                sx={{
                                    width: "100%", '& input': {
                                        height: '5px', fontSize: '13px'
                                    },
                                }}
                                freeSolo
                                style={{ height: 30, fontSize: '13px', marginTop: '10px' }}
                                renderInput={(params) => (
                                    <div style={{ position: 'relative' }}>
                                        <TextField
                                            {...params}
                                            style={{ fontSize: '13px' }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search style={{ fontSize: '20px' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Search line item"
                                            inputRef={autocompleteRef}
                                        />


                                    </div>
                                )}
                            />))
                    }
                    {isAddLine ?



                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingRight: '10px' }}>
                            {<div style={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                <h4>Item Details</h4>
                                <h4>${putCommaInvalue((invoicedata?.price * invoicedata?.quantity)?.toFixed(2))}</h4>
                            </div>}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: '8px', width: '38%', }}>
                                    <Label>Name</Label>
                                    <Inputbox
                                        type="text"
                                        placeholder='item name'
                                        style={{ width: '100%' }}
                                        value={invoicedata?.name}
                                        onChange={(e) => setInvoiceData({ ...invoicedata, name: e.target.value })}
                                    />
                                </div>
                                <div style={{ marginLeft: '20px', width: '15%' }}>
                                    <Label>Quantity</Label>
                                    <CustomUrl placeholder='Quantity' type='number' value={invoicedata?.quantity} onChange={(e) => setInvoiceData({ ...invoicedata, quantity: e.target.value })} />
                                </div>
                                <div style={{ marginLeft: '20px', width: '15%' }}>
                                    <Label>Price</Label>
                                    <CustomUrl type='number' placeholder='Price' value={invoicedata?.price} onChange={(e) => setInvoiceData({ ...invoicedata, price: e.target.value })} />
                                </div>
                                <div style={{ marginLeft: '20px', width: '15%' }}>
                                    <Label>Cost</Label>
                                    <CustomUrl type='number' placeholder='Cost' value={invoicedata?.unitPrice} onChange={(e) => setInvoiceData({ ...invoicedata, unitPrice: e.target.value })} />
                                </div>
                                <div style={{ marginLeft: '20px', marginRight: '5px', width: '15%' }}>
                                    <Label>Tax</Label>
                                    <Selectbox style={{ width: '100%', height: '39px', fontSize: 14, borderRadius: 8 }} onChange={(e) => {
                                        console.log(e.target)
                                        setInvoiceData({ ...invoicedata, tax: e.target?.value?.value, taxName: e.target?.value?.name })
                                    }}
                                        renderValue={() => invoicedata?.tax ? invoicedata?.tax : "Select tax"}
                                        defaultValue={"0"} >
                                        {tax?.length > 0 && [{ name: 'No tax', value: '0' }, ...tax]?.map((item, index) =>
                                            <StyledMenuItem key={item.value} value={item}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #f3f3f3', width: '100%', marginTop: '-10px', marginBottom: '-10px' }}>
                                                    <div>
                                                        <p style={{ fontSize: '14px', textAlign: 'start' }}>{item?.name}</p>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ fontSize: '14px' }}>{item?.value}%</p>

                                                    </div>
                                                </div>
                                            </StyledMenuItem>
                                        )}
                                    </Selectbox>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', }}>
                                    <Label>Description</Label>
                                    <InputText
                                        type="text"
                                        placeholder='Item details'
                                        style={{ width: '97%' }}
                                        value={invoicedata?.description}
                                        onChange={(e) => setInvoiceData({ ...invoicedata, description: e.target.value })}
                                    />
                                </div>

                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', alignSelf: 'start' }}>
                                <Checkbox
                                    style={{ width: '25px', marginRight: '10px' }}
                                    value={invoicedata.optional}
                                    onChange={() => invoicedata.optional ? setInvoiceData({ ...invoicedata, optional: false }) : setInvoiceData({ ...invoicedata, optional: true })}
                                // key={index}
                                // value={item?.email}
                                // checked={day.includes(item?.email)}
                                // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                />
                                <Label style={{ marginTop: '12px', marginBottom: 0 }}>Optional</Label>
                            </div>


                        </div> : null

                    }


                    {isAddLine ? <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end', paddingBottom: '15px' }}>
                        <RippleButton style={{ background: '#fff', color: '#000', border: '1px solid #ddd', marginRight: '20px' }} onClick={() => {
                            if (props?.isEdit) {
                                    props?.onCancel()
                            }
                            else {
                                if (isEdit) {
                                    addCustomFiled()
                                }
                            }
                            setIsAddline(false)
                            setBoolValue({ ...boolValue, newItem: false })
                        }}>Cancel</RippleButton>
                        <RippleButton onClick={() => addCustomFiled()}>Save</RippleButton>
                    </div> : null}

                    {/* <ContainerTotal>
                        <SubTable >
                            <p className="key" >Sub total</p>
                            <p className="text" style={{ textAlign: 'end', }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }, 0)?.toFixed(2))}`}</p>
                        </SubTable>
                        <SubTable style={{ marginTop: '-10px', paddingTop: 0 }}>
                            <p className="key">Tax</p>
                            <p className="text" style={{ textAlign: 'end', paddingBottom: '15px' }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                return accumulator + taxprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                            }, 0)?.toFixed(2))}`}</p>
                        </SubTable>
                        <SubTable style={{ marginTop: '-10px', paddingTop: 0, borderTop: '1px solid #DDD', }}>
                            <p className="key" style={{ paddingTop: '10px', }}>Total</p>
                            <p className="text" style={{ textAlign: 'end', paddingTop: '10px' }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                            }, 0)?.toFixed(2))}`}</p>
                        </SubTable>
                    </ContainerTotal> */}
                </div>
            </MainContainer>



        </>
    )
}

export default LineItemComponent;

const PreviewContainer = styled.div`
width:50%;
height:100%;
margin-top:40px;
paddingBottom:80px;
display: flex;
padding-right:50px;
justify-content:center; 
@media (min-width: 260px) and (max-width: 1300px){
    display:none;
}

`
const ContainerTotal = styled.div`
display:none;
@media (min-width: 260px) and (max-width: 1300px){
    display:flex;
    flex-direction:column;
}

`
const MainContainer = styled.div`
width:100%;
padding-top:5px;
display:block;
height:auto;


`
const Place = styled(Autocomplete)`
height:30px;
width:315px;
border-radius:4px;
border:1px solid lightgray;
font-weight:400;
padding-left:12px;
@media (min-width: 260px) and (max-width: 721px){
    width:85%;
    }
&::placeholder {
  font-size:14px;
  font-weight:400;
}
&:focus {
outline:none;
border:1px solid lightgray;
}
`

const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:8px;
height: 35px;
font-size:13px;
width:100%;

&:focus {
    outline: none;
    border: 1px solid #000; /* Remove border on focus */
  }
`
const DueDateForm = styled(Form.Select)`
border-radius:8px;
cursor:pointer;
width: 235px;
color:black;
border:1px solid #ddd;
font-size:14px;
padding-left:10px;
padding-right:30px;
margin-top:20px;
height:35px;
appearance: none; /* Remove default arrow */
 
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust arrow position */
  background-size: 12px; 
&:focus {
outline: none;
box-shadow: 0px 0px 0px white;
background: #fff;
}
@media (min-width: 360px) and (max-width: 820px){
width:11rem;
}

`
const SelectForm = styled.p`
display:flex;

font-size:14px;
font-weight:600;
cursor:pointer;
`
const Arrow = styled.img`

width:20px;
height:22px;

`
const CustomButton = styled.button`
width:130px;
height:40px;
border-radius:8px;
border:1px solid white;
align-self:flex-end;
color:white;
font-size:14px;
background:#000;
margin-top:15px;
cursor:pointer;
`
const Selectbox = styled(Select)`
width:300px;
border-radius:8px;
text-align:start;
display:flex;
border:none;
height:38px;
font-size:14px;
.MuiSelect-select {
    padding-right: 0px;
   
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }



  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
align-self:start;
@media (min-width: 260px) and (max-width: 700px){
    zoom:0.6;
}
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const HeaderContainer = styled.div`
display:flex;
background:#fff;
padding-left:25px;
padding-right:25px;
height:auto;
justify-content:space-between;
align-items:center; 
`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const Inputbox = styled.input`
height: 35px;
font-size: 14px;
padding-left:10px;

font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;

border-radius:8px;
align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 13px;
    color: #344054;
    display: flex;
    margin-top:15px;
    margin-left:3px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 100%;
  margin-top:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 14px;
font-weight:500;
padding-left:15px;
padding-right:15px;
height:40px;
border-radius:8px;
border:1px solid white;
color:white;

cursor:pointer;

`
const DraftButton = styled.button`
border:1px solid #4169e1;
 background:#f3f3f3; 
 margin-right:20px;
 borderRadius:4px;
color:#000; 
font-size: 14px;
font-weight:500;
padding-left:15px;
padding-right:15px;
height:40px;
border-radius:8px;
border:1px solid #DDD;


cursor:pointer;

`
const Title = styled.p`
color:gray;
font-size: 14px;
text-align:start;
font-weight:500;
padding:6px;

margin-bottom:0px;

`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;
padding:6px;
width:70%;
font-weight:500;
margin-top:-10px;
margin-bottom:8px;

`
const Flexbox = styled.div`
display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    height:100%;
    }



`
const SubTable = styled.div`

display:flex;
justify-content:space-between; 
width:550px;

padding-top:25px; 

.key{
     text-align:start; 
     font-weight:bold;
     font-size:14px;
}
.text{
    font-size:14px;
}
`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`
const MoreIcon = styled.img`


width:15px;
cursor:pointer;

color:black
`
const EditContainer = styled.div`
margin-top:-15px;
border-radius:5px;
display:flex;
justify-content:center;
align-items:center;
margin-left:70px;
width:65px;
height:25px;
cursor:pointer;

&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`
const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
  margin-top:10px;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-bottom:0px;
  
    }
    &.description {
      color: gray;
      margin-top:3px;
      text-align:start;
      margin-bottom:5px;
      line-height:16px;
      white-space:pre-line;
    }
    &.price {
      font-weight: 550;
       text-align: start;
      margin-bottom:0px;
    }
  }
`;
const Icons = styled.img`
width: 15px;
height: 15px;
`
