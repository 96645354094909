import React from 'react';
import styled from 'styled-components';
import { palette } from '../../../../utils/Palette';
import { FaExclamationCircle } from 'react-icons/fa'; 
import { formatMobileNumber } from '../../../../utils/Commonfunction';
import { Tooltip } from '@mui/material';
import EventDetails from './EventCard';
import { deleteAddEvent, deleteAddTask } from '../../../../store/Actions/dashboard.action';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;


const Card = styled.div`
  background-color: #E1ECFD;
  border: 1px solid #d4e3c6;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor:pointer;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
`;

const Title = styled.span`
  color: #2c3e50;
  font-size: 14px;
  text-align: start;
`;

const Number = styled.span`
  background-color: ${palette.newBlue};
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

const Address = styled.div`
  color: #2c3e50;
  font-size: 13px;
  text-align: start;
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f8c8d;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

const MapCard = ({ data,onclick,type,isRoute }) => {
  if (!data || data.length === 0) {
    return (
      <Container>
        <NoDataMessage>
          <IconWrapper>
            <FaExclamationCircle />
          </IconWrapper>
          No jobs
        </NoDataMessage>
      </Container>
    );
  }

  return (
  

    <Container>
      {data.map((item, index) => (
            <TooltipStyled
            disableTouchListener
            disableInteractive={false}
            placement={'right-start'}
            PopperProps={{
                disableInteractive: true,
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#fff',
                        color: '#000',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                        border: '1px solid #ddd',
                        fontSize: '14px',
                        maxHeight: '550px',
        
                        paddingBottom: (item?.data?.type == 'task' || item?.data?.type == 'event') ? "0px" : '40px',
                        width: '350px',
        
                        overflowY: 'scroll',
                    },
                },
                arrow: {
                    sx: {
                        color: '#fff',
                    },
                },
            }}
            title={
               type?null:<EventDetails 
                event={item}    
                />
        
            }
        >
        <Card key={item.id} onClick={()=>onclick(item)}>
          <CardHeader>
            <Title>{item?.data?.name || item?.data?.customerNumber||item?.item?.name}</Title>
            {item?.index&&<Number>{item?.index}</Number>}
          </CardHeader>
          <Address>
          <p style={{marginTop:'6px',marginBottom:'5px'}}>{formatMobileNumber(item?.data?.customerNumber||item?.item?.customerNumber)}</p>
            <p style={{marginTop:'6px',marginBottom:'5px'}}>{item?.data?.customerAddress||item?.item?.customerAddress}</p>
          </Address>
        </Card>
        </TooltipStyled>
      ))}
      
    </Container>

  );
};

export default MapCard;
