
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";



export const registerUser = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.register, data)
  if (res.status === true) {
    localStorage.setItem('token', res?.token)
    dispatch({
      type: 'USERID',
      payload: res?.data
    })
  }
  return res
}
export const displaynumber = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.displayNumber, data)

  if (res.status === true) {
    dispatch({
      type: 'DISPNUMBER',
      payload: res?.data
    })
  }
  return res
}
export const buynumber = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.buyNumber, data)
  localStorage.setItem('number', JSON.stringify(data))
  if (res.status === true) {
    dispatch({
      type: 'BUYNUMBER',
      payload: res?.data
    })
  }
  return res
}

export const loginUsers = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.loginUser, data)
  if (res.status === true) {
    if (res?.data?.isWorker === true||res?.data?.isTechnician === true) {

    }
    else {

      localStorage.setItem('token', res?.token)
      localStorage.setItem('number', res?.data?.twilioPhone)
      dispatch({
        type: 'LOGIN_TOKEN',
        payload: res?.token
      })
    }
  }
  return res
}

export const getpaymentIntent = (value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.paymentLink}`, value)
  if (res.status === true) {
    dispatch({
      type: 'PAYMENT',
      payload: res?.token
    })
  }
  return res
}

export const getLocationDetails = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.locationDetails}?referenceNo=${value}`)
  if (res.status === true) {
    dispatch({
      type: 'LOCATION',
      payload: res.data
    })
  }
  return res;
}

export const getTrackingDetails = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.livetracker}`)
  if (res.status === true) {
    dispatch({
      type: 'TRACKING',
      payload: res.data
    })
  }
  return res;
}
export const getUserData = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.userdata}`)
  if (res.status === true) {
    dispatch({
      type: 'USERDATA',
      payload: res.data
    })
  }
  return res;
}
export const getQuotedata = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.quotedata}?referenceNo=${value.referenceNo}`)
  if (res.status === true) {
    dispatch({
      type: 'QUOTE',
      payload: res.data?.data
    })
    dispatch({
      type: 'USERNAME',
      payload: res.data?.userData
    })
  }
  return res
}
export const getInvoiceData = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.invoiceData}?referenceNo=${value.referenceNo}`)
  if (res.status === true) {
    dispatch({
      type: 'INVOICE',
      payload: res.data?.data
    })
    dispatch({
      type: 'USERNAME',
      payload: res.data?.userData
    })
  }
  return res
}

export const getAllconversations = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.fetchAllConvo1}`)
  // const res1 = await dispatch(getContactDetail(res?.data?.[0]?.to))


  const Dates = res?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
  const token = await localStorage.getItem("fbToken");
  const vals = []
  const tokenVal = JSON.parse(token)
  if (tokenVal?.token) {
    const response = await fetch(`https://graph.facebook.com/v12.0/${tokenVal.id}?fields=access_token&access_token=${tokenVal.token}&api_version=v12.0&access_token_source=TEST_USER`);
    const json = await response.json();
    fetch(`https://graph.facebook.com/v12.0/${tokenVal.id}/conversations?fields=id,messages{message,to,from,created_time,attachments}&?&access_token=${json.access_token}`)
      .then(response => response.json())
      .then(leadsDat => {
        // Process the leads data retrieved from the API
        leadsDat?.data?.map(async (item) => {
          vals.push({
            fbs: "Facebook",
            name: item?.messages?.data[0]?.from?.id === tokenVal?.id ? item?.messages?.data[0]?.to?.data[0]?.name : item?.messages?.data[0]?.to?.data[0]?.name,
            body: item?.messages?.data[0]?.message,
            to: item?.messages?.data[0]?.from?.id === tokenVal?.id ? item?.messages?.data[0]?.to?.data[0]?.name : item?.messages?.data[0]?.to?.data[0]?.name,
            createdAt: item?.messages?.data[0]?.created_time, fb: true, user: item?.messages?.data[0], data: { ...item, name: item?.messages?.data[0]?.from?.id === tokenVal?.id ? item?.messages?.data[0]?.to?.data[0]?.name : item?.messages?.data[0]?.from?.name, to: '' }
          })
            // setAllData((prevMessages) => [...prevMessages, { name: item?.messages?.data[0]?.from?.id === '109427341524673' ? item?.messages?.data[0]?.to?.data[0]?.name : item?.messages?.data[0]?.from?.name, body: item?.messages?.data[0]?.message, to: '', createdAt: item?.messages?.data[0]?.created_time, fb: true, user: item?.messages?.data[0], data: { ...item, name: item?.messages?.data[0]?.from?.id === '109427341524673' ? item?.messages?.data[0]?.to?.data[0]?.name : item?.messages?.data[0]?.from?.name, to: '' } }])
            // fetch(`https://graph.facebook.com/v12.0/${item?.messages?.data[0]?.id}?fields=message,from,to,created_time&?&access_token=${dataDetil?.fbAcessToken}`)
            .then(response => response.json())
            .then(leadsDat => {
              // Process the leads data retrieved from the API
              console.log(leadsDat, "dataszzzzxxxxz");
              //  setAllData((prevMessages) => [...prevMessages,{name:leadsDat?.from?.id==='109427341524673'?leadsDat?.to?.data[0]?.name:leadsDat?.from?.name,body:leadsDat?.message,to:'',createdAt:leadsDat?.created_time,fb:true,user:leadsDat,data:{...item,name:leadsDat?.from?.id==='109427341524673'?leadsDat?.to?.data[0]?.name:leadsDat?.from?.name}}])
            })
            .catch(error => {
              console.error(error);
            });
        })
        console.log(vals)
        const allDat = [...Dates, ...vals]?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        dispatch({
          type: 'CONVO',
          payload: [...allDat]
        })
        // setAllData([...res3.data, ...vals])
        // setLoader(false)
      })
      .catch(error => {
        dispatch({
          type: 'CONVO',
          payload: Dates
        })
      });
  }
  else {
    dispatch({
      type: 'CONVO',
      payload: Dates
    })
  }
  // if (res.status === true) {
  //   dispatch({
  //     type: 'CONVO',
  //     payload: Dates
  //   })
  // }
  return res
}


export const chatgprtApi = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.ChatgptApi}`, data, {
    'Authorization': `Bearer ${process.env.REACT_APP_ENVIRONMENT}`,
    'Content-Type': 'application/json'

  })
  return res
}

export const getAlloutchats = (number, value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.chats}?to=${number}&from=${value}`)
  if (res.status === true) {
    dispatch({
      type: 'OUTCHATS',
      payload: res.data
    })
  }
  return res
}

export const getAllinchats = (value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.chats}`, {
    "to": value
  })
  if (res.status === true) {
    dispatch({
      type: 'INCHATS',
      payload: res.data
    })
  }
  return res
}
export const chatgprtImgApi1 = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.generateImg}`, data, {
    'Authorization': 'Bearer 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',
    'Content-Type': 'application/json'
  })
  return res
}

export const getnumber = (number) => async (dispatch) => {
  dispatch({
    type: 'GLOBENUM',
    payload: number
  })
  return number
}

export const getcolor = (color) => async (dispatch) => {
  dispatch({
    type: 'GLOBECOLOR',
    payload: color
  })
  return color
}
export const saveSign = (data, value, email) => async (dispatch) => {
  var formData = new FormData();
  console.log(data, "dataa")
  formData.append('files', data);
  formData.append("bookingId", value);
  formData.append("email", email);
  console.log(formData, "dataa")
  console.log([...formData.entries(), "dhyvdjhcdsjcdhvbhjvghj"])
  const res = await NetworkOps.post(ServiceEnum.saveSign, formData, {
    "content-Type": 'multipart/form-data'
  });

  return res
}

export const getname = (name) => async (dispatch) => {
  dispatch({
    type: 'GLOBENAME',
    payload: name
  })
  return name
}
export const activemenu = (name) => async (dispatch) => {
  dispatch({
    type: 'ACTIVEMENU',
    payload: name
  })
  return name
}
export const sendMessage = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.sendMessage, data)
  if (res.status === true) {
    dispatch({
      type: 'SENDMESSAGE',
      payload: res?.data
    })
  }
  dispatch(getAllconversations(data?.from))
  return res
}
export const sendPhoneCode = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.sendPhoneCode}`, {
    "number": `${data}`
  })

  return res
}
export const phoneVerification = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.phoneVerification}`, {
    "number": `${data?.number}`,
    "code": data?.code
  })
  return res
}