import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button, TextField, Box, Grid } from '@mui/material';
import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExploreOffTwoTone } from '@mui/icons-material';
import DatePickerComponent from './DatePickerComponent';
import RippleButton from './RippleButton';

const ExportDropdown = ({ anchorEl, open, handleClose, onExport,statusData }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('All data')

    const handleExport = () => {
        onExport(startDate, endDate,status);
        handleClose();
        setStartDate(null)
        setEndDate(null)
    };

    const handleCancel = () => {
        setStartDate(null);
        setEndDate(null);
        handleClose();
    };


    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '380px',
                    marginTop: '100px',
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center'
                },
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <h3 style={{ marginTop: '5px' }}>Export data</h3>

            <DatePickerComponent
                label="Start Date"
                selectedDate={startDate}


                onDateChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                customStyle={{ width: '330px', marginBottom: '10px', height: '40px' }}
            />
            <DatePickerComponent
                label="End Date"
                selectedDate={endDate}
                onDateChange={(newValue) => setEndDate(newValue)}
                customStyle={{ width: '330px', height: '40px' }}
            />
            <DropdownContainer>
                <Label style={{ marginTop: '10px', fontWeight: '600' }}>Status</Label>
                <SelectDrpoDown id="category-visibility" value={status} onChange={(event) => {
                    // // setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })
                    // setReoccuringValue({ ...recurringValue, repeatType: event.target.value })
                    setStatus(event.target.value)

                }}>
                    {
                        statusData?.map((item) =>
                            <Option value={item?.value}>
                               {item?.label}
                            </Option>
                        )
                    }
                
                </SelectDrpoDown>
            </DropdownContainer>


            <div style={{ display: 'flex', alignItems: 'flex-end', position: 'absolute', bottom: '20px', right: '0px' }}>
                <RippleButton
                    variant="contained"
                    color="primary"
                    startIcon={<ExploreOffTwoTone />}
                    onClick={handleExport}
                    style={{ marginRight: '15px',fontWeight:'500' }}
                    fullWidth
                >
                    Export
                </RippleButton>

                <RippleButton
                    variant="outlined"
                    color="secondary"
                    style={{ background: '#fff', color: '#000', border: '1px solid #ddd',fontWeight:'500' }}
                    startIcon={<CancelIcon />}
                    onClick={handleCancel}
                    fullWidth
                >
                    Cancel
                </RippleButton>

            </div>


        </Menu>
    );
};

ExportDropdown.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    statusData:PropTypes.array
};

export default ExportDropdown;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;

  

  .input{
    padding: 10.5px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    
    width: 20%;
    align-items: center;
    
    color: #344054;
 
  
    &:focus {
      outline: none;
      border-color: #000;
      box-shadow: none;
    }
  }
`;

const LabelDropdown = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
    margin-left:3px;
`
