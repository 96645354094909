export const imageLinks =[
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+1.png", color: "#A5B5FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+10.png", color: "#FFD4A5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+11.png", color: "#A5D4FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+12.png", color: "#FFAAC4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+13.png", color: "#AAC4FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+14.png", color: "#D1A5FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+15.png", color: "#FFB5D1" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+16.png", color: "#B5E6FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+17.png", color: "#FFD1B5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+18.png", color: "#A5FFA5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+19.png", color: "#FFB3BA" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+2.png", color: "#A3FFC4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+20.png", color: "#B3FFFF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+21.png", color: "#FFDFBA" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+22.png", color: "#BAE1FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+23.png", color: "#D4A5A5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+24.png", color: "#A5D4D4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+25.png", color: "#33FF57" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+26.png", color: "#3357FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+27.png", color: "#FF33A6" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+28.png", color: "#33FFF0" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+29.png", color: "#FFC733" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+3.png", color: "#C4A3FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+30.png", color: "#C733FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+4.png", color: "#A3C4FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+5.png", color: "#FFC4C4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+6.png", color: "#C4FFA5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+7.png", color: "#A5A3FF" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+8.png", color: "#A3FFA5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Component+9.png", color: "#C4D4A5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1549.png", color: "#E6FFB5" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1550.png", color: "#BFFCC6" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1551.png", color: "#FF6347" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1555.png", color: "#FFC4A3" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1556.png", color: "#FFA6C4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1557.png", color: "#A5C4AA" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1558.png", color: "#C4AFAA" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1559.png", color: "#AAF3C4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1560.png", color: "#C4AFFA" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1561.png", color: "#FAA4D4" },
  { imageLink: "https://myawsbucket-swiftbel.s3.ca-central-1.amazonaws.com/Icons/Group+1562.png", color: "#FAD4A4" }
];