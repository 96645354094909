import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { palette } from '../utils/Palette';

const CustomTabs = ({ tabsData,tabpanelWidth,boxStyle }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    return (
        <Box sx={{ bgcolor: 'background.paper',  }}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                style={{maxWidth:tabpanelWidth?tabpanelWidth: '800px'}}
                aria-label="full width tabs example"
                sx={{
                    
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#000',
                     
                       

                    },
                    '& .MuiTab-root': {
                        marginTop:'-10px',
                        marginBottom: '-20px'
                    },

                }}
            >
                {tabsData.map((tab, index) => (
                    <Tab
                        {...a11yProps(index)}
                        sx={{ textTransform: "none", fontSize: '13px', fontWeight: '550' }}
                        key={index}
                        icon={tab.icon}
                        iconPosition="start"
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="body2">{tab.label}</Typography>
                              {tab.unreadCount > 0 && (
                               <Typography
                               variant="body2"
                               sx={{
                                 marginLeft: '14px',
                                 backgroundColor: 'rgba(32, 32,32, 0.85)',
                                 color: 'white',
                                 borderRadius: '12px',
                                 padding: '1px 5px',
                                 fontSize: '9px',
                               }}
                             >
                               {tab.unreadCount}
                             </Typography>
                              )}
                            </Box>
                          }
              
                    />
                ))}
            </Tabs>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {tabsData.map((tab, index) => (
                    <div
                        key={index}
                        role="tabpanel"
                        hidden={value !== index}
                        id={`full-width-tabpanel-${index}`}
                        aria-labelledby={`full-width-tab-${index}`}
                    >
                        {value === index && (
                            <Box sx={{ p:3,...boxStyle }}  >
                                <Typography component="div">{tab.content}</Typography>
                            </Box>
                        )}
                    </div>
                ))}
            </SwipeableViews>
        </Box>
    );
};

CustomTabs.propTypes = {
    tabsData: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.element,
            content: PropTypes.node.isRequired,
        })
    ).isRequired,
    tabpanelWidth:PropTypes.string,
    boxStyle:PropTypes.object
};

export default CustomTabs;
