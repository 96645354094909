import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import 'video.js/dist/video-js.css';

const VideoJS = (props) => {
  const { options, onReady } = props;
  const videoNode = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Initialize Video.js only once
    if (!playerRef.current) {
      const videoElement = document.createElement('video');
      videoNode.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log('Player is ready');
        onReady && onReady(player);
      });

      // Add event listener for play event to enter fullscreen
      player.on('play', () => {
        if (videoNode.current.requestFullscreen) {
          videoNode.current.requestFullscreen();
        } else if (videoNode.current.mozRequestFullScreen) { // Firefox
          videoNode.current.mozRequestFullScreen();
        } else if (videoNode.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
          videoNode.current.webkitRequestFullscreen();
        } else if (videoNode.current.msRequestFullscreen) { // IE/Edge
          videoNode.current.msRequestFullscreen();
        }
      });

      // Handle ESC key to exit fullscreen and stop video
      const handleKeyDown = (event) => {
          console.log(event,"ffjb")
        if (event.key === 'Escape') {
          handleClose();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    } else {
      // Update player on options change
      const player = playerRef.current;

      if (player) {
        player.autoplay(options.autoplay);
        player.src(options.sources);
      }
    }
  }, [options, onReady]);

  const handleClose = () => {
    if (playerRef.current) {
      playerRef.current.pause();
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.mozFullScreenElement) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitFullscreenElement) { // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msFullscreenElement) { // IE/Edge
      document.msExitFullscreen();
    }
  };

  return (
    <div data-vjs-player>
      <div style={{width:'150px',borderRadius:'8px',height:'150px'}} ref={videoNode} className="video-js vjs-big-play-centered">
        {/* <button 
          onClick={handleClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: '1000',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 10px',
            cursor: 'pointer'
          }}
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

VideoJS.propTypes = {
  options: PropTypes.shape({
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    responsive: PropTypes.bool,
    fluid: PropTypes.bool,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onReady: PropTypes.func,
};

export default VideoJS;
