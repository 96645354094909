import React, { Fragment, useEffect, useState } from 'react';
import logo from '../assets/logo/Logo.png';
import { formatMobileNumber, putCommaInvalue, totalprice } from '../utils/Commonfunction';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

const NewPreviewInvoice = (props) => {
    const [taxval, setTaxVal] = useState([]);

    useEffect(() => {
        calc();
    }, []);

    const calc = () => {
        const totalTax = {};
        props?.data?.items?.forEach(item => {
            const taxName = item.taxName;
            const taxAmount = parseFloat(item.tax);
            const taxNumber = item.taxNumber;

            if (taxAmount === 0) {
                // Handle zero tax amount case
            } else if (totalTax.hasOwnProperty(taxName)) {
                totalTax[taxName].taxAmount += taxAmount;
                totalTax[taxName].taxNumber = taxNumber; // Update taxNumber if taxName exists
            } else {
                totalTax[taxName] = {
                    taxAmount,
                    taxNumber
                };
            }
        });

        const totalTaxArray = Object.entries(totalTax).map(([taxName, { taxAmount, taxNumber }]) => ({
            taxName,
            taxAmount,
            taxNumber
        }));

        setTaxVal(totalTaxArray);
    }

    const getName = (channel) => {
        if (!channel) {
            return;
        }
        const val = channel?.split(' ');

        if (val.length === 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
        } else {
            return `${val[0]?.charAt(0).toUpperCase()}`;
        }
    }
    const SubTableTotal = () => (
        <View>
            <Text>Subtotal</Text>
            <Text>
                {`$${putCommaInvalue(props?.data?.items?.reduce(function (accumulator, currentValue) {
                    if (!currentValue?.optional) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    }
                    return accumulator
                }, 0)?.toFixed(2))}`}
            </Text>
        </View>
    );
    
    const TaxTotal = () => (
        <View>
            {taxval?.map((item, index) => (
                <Fragment key={index}>
                    <Text>{item?.taxName}</Text>
                    <Text>{`$${putCommaInvalue(parseFloat(item?.taxAmount)?.toFixed(2))}`}</Text>
                </Fragment>
            ))}
        </View>
    );
    
    const TableTotal = () => (
        <View>
            <Text>Total</Text>
            <Text>
                {`$${putCommaInvalue(props?.data?.items?.reduce(function (accumulator, currentValue) {
                    if (!currentValue?.optional) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                    }
                    return accumulator;
                }, 0)?.toFixed(2))}`}
            </Text>
        </View>
    );
    const InvoiceTitle = () => (
        <View>
            {props?.userData?.logoImage ? <Image src={props?.userData?.logoImage} /> :
                <View style={{ backgroundColor: '#4169e1', height: '90px', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ color: 'white', marginLeft: '-5px', fontSize: '28px' }}>{getName(props?.userData?.businessName)}</Text>
                </View>
            }
            <Text style={{ fontSize: '16px', textAlign: 'center' }}>{props?.userData?.businessName}</Text>
        </View>
    );
    
    const Address = () => (
        <View>
            <View>
                <Text>Invoice</Text>
                <Text>Invoice number: #{props?.data?.numberOfInvoice}</Text>
            </View>
            <View>
                <Text style={{ fontWeight: 'bold' }}>Bill to</Text>
                <Text>{props?.data?.name}</Text>
                <Text>{formatMobileNumber(props?.data?.customerPhoneNumber)}</Text>
                <Text>{props?.data?.email}</Text>
                <Text>{props?.data?.customerAddress}</Text>
            </View>
        </View>
    );
    
    const UserAddress = () => (
        <View>
            <Text>{moment(props?.data?.date)?.format('DD MMM YYYY')}</Text>
        </View>
    );
    
    const TableHead = () => (
        <View>
            <Text>Items</Text>
            <Text>Price</Text>
            <Text>Qty</Text>
            <Text>Amount</Text>
        </View>
    );
    
    const TableBody = () => (
        props?.data?.items?.map((receipt) => (
            <div style={{display:'flex',justifyContent:'space-between'}}  key={receipt.id}>
                <View style={{display:'flex',flexDirection:'column'}}>
                    <Text>{receipt.name}</Text>
                    <Text>{receipt.description}</Text>
                </View>
                <View>
                    <Text>${receipt?.price ? parseFloat(receipt.price) : '0'}</Text>
                </View>
                <View>
                    <Text>{receipt?.quantity ? putCommaInvalue(receipt?.quantity) : '1'}</Text>
                </View>
                <View>
                    <Text>${receipt?.price && receipt?.quantity ? putCommaInvalue((receipt?.price * receipt?.quantity)?.toFixed(2)) : putCommaInvalue(parseFloat(receipt?.price)?.toFixed(2))}</Text>
                </View>
            </div>
        ))
    );

    return (
        <Document>
            <Page>
                <InvoiceTitle />
                <Address />
                <UserAddress />
                <TableHead />
                <TableBody />
                <SubTableTotal />
                {taxval?.length > 0 && <TaxTotal />}
                <TableTotal />
            </Page>
        </Document>
    );
}

export default NewPreviewInvoice;

const Document = styled.div`
  font-family: Arial, sans-serif;
  font-size: 11px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: #3E3E3E;
`;

const View = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 90px;
`;




