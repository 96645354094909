import React, { useEffect, useState } from "react";
import styled from "styled-components";
import user from '../../assets/profile-user.png'
import volume from '../../assets/volume.png'
import speaker from '../../assets/microphone.png'
import { useDispatch, useSelector } from "react-redux";
import { formatMobileNumber } from "../../utils/Commonfunction";
import { Device } from '@twilio/voice-sdk';
const IncomingUI=(props)=>{
    const token = useSelector(state => state?.auth?.token)
    const [callDuration, setCallDuration] = React.useState(0);
const dispatch=useDispatch()
    const detail = useSelector(state => state?.auth?.incominDetail)
    // const token = new Device(tokens)
    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    token.on('connect', async (call) => {
        let timer = setInterval(() => {
            setCallDuration((prevDuration) => prevDuration + 1);
        }, 1000);
    })
    token?.on('error', (deviceError) => {
        setCallDuration(0)
    });
        useEffect(() => {
            let timer = setInterval(() => {
                setCallDuration((prevDuration) => prevDuration + 1);
            }, 1000);
          return () => clearInterval(timer);
        }, []);
    token.on('disconnect', async (call) => {
        console.log('hello bro hey')
       setCallDuration(0)
       token.disconnectAll()
       dispatch({ type: "CALLSHOW", payload:false })
       dispatch({ type: "INCOMINGCALLSHOW", payload: false })
    })
    console.log(detail?.parameters,"dhdbjdh")
    
    return(
        <MainContainer>
            <div style={{display:'flex',marginTop:10,paddingBottom:5, alignSelf:'start',paddingLeft:20,paddingRight:20, borderBottom:'1px solid gray',justifyContent:'space-between', width:'86.5%'}}>
             <Text>{" "}</Text>
             <Text style={{fontSize:'12px',fontWeight:'lighter'}} >{"Incoming call"}</Text>
             </div>
           
            <ImgContainer>
            <Img src={user}/>
           
            </ImgContainer>
            <Text>{formatMobileNumber(detail?.parameters?.Params
    ?.replace(/first_name=/g, '')
    ?.replace(/%2B/g, '')  // This removes '%2B'
    ?.replace(/\+/g, ' '))}</Text>
            <Text>{formatMobileNumber(detail?.parameters?.From)}</Text>
            <div style={{display:'flex',alignSelf:'center',justifyContent:'center', marginTop:20,}}>
            
            <Button onClick={()=>{
                detail.reject()
                dispatch({ type: "INCOMINGCALLSHOW", payload:false })
            }}>
                Reject call
            </Button>
            <Button1 onClick={()=>{
               detail.accept()
               dispatch({ type: "INCOMINGCALLSHOW", payload: false })
                dispatch({ type: "CALLSHOW", payload:true })
            }}>
                Accept call
            </Button1>
            </div>
        </MainContainer>
    )
}

export default IncomingUI

const MainContainer = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  display:flex;
 
  flex-direction:column;
  align-items:center;
  transition: all 0.2s ease-in-out;

  border-radius: 10px;
  background: #1E1E1E;
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.3);
`;

const ImgContainer=styled.div`
background:#f3f3f3;
width:80px;
height:80px;
border-radius:50px;
margin-top:20px;
margin-bottom:10px;
`
const Img=styled.img`
width:80px;
height:80px;
color:black;
`
const Text=styled.p`
font-size:14px;
color:white;
text-align:center;
margin-top:5px;
font-weight:600;
margin-bottom:5px;
`
const IconContainer=styled.div`
background:black;
width:38px;
height:38px;
display:flex;
justify-content:center;
align-items:center;
border-radius:5px;
margin-left:10px;


`
const Icon=styled.img`
width18px;
height:18px;
color:white;
`
const Button=styled.button`
width:120px;
height:38px;
border-radius:5px;
background:red;
border:1px solid red;
font-weight:600;
color:white;
cursor:pointer;
margin-right:10px;


`
const Button1=styled.button`
width:120px;
height:38px;
border-radius:5px;
background:green;
border:1px solid green;
font-weight:600;
color:white;
cursor:pointer;

`