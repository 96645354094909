import React, { useState } from 'react';
import styled from 'styled-components';
import logoName from '../../assets/LogoName.png'
const ResponsePage = () => {

    return(<>
    <Container>
        <MainContainer>
            <Form>
                <h3>Thanks for submitting the form</h3>
                <p>Someone will get in touch within 24 hours.</p>

            </Form>
            <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center'}}>
                    Powered by
                    <img style={{ width: '100px', height: '30px', marginLeft: '15px' }} src={logoName} />
                    </div>
        </MainContainer>
    </Container>

    </>)
}

export default ResponsePage;

const Container = styled.div`
display: flex;
  justify-content: center;

  background:#FAFAFA;
  width:100%;
`

const MainContainer = styled.div`
height:100vh;

@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100%;
    max-width: 520px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const Form = styled.div`
border-top:5px solid #4169e1;
overflow-y:scroll;
 margin-bottom:40px;
 margin-top:60px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

`;