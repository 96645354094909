import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Legend, ArcElement, CategoryScale } from 'chart.js';
import { Box, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { putCommaInvalue } from '../utils/Commonfunction';
ChartJS.register(Title, Legend, ArcElement, CategoryScale);

const Container = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
margin-top:20px;
`;

const ChartContainer = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 20px;

`;

const InfoSection = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  background:#fff;
`;

const BreakdownItem = styled(Box)`
padding-bottom: 10px;
  padding-top:10px;
`;

const BreakdownLabel = styled(Typography)`
  display: flex;
  align-items: start;
  text-align:start;
  font-size:14px;
  padding-bottom:10px;
  padding-top:10px;
`;
const Header = styled.p`
font-size:13px;
`

const BreakdownValue = styled(Typography)`
  margin-top: 8px;
  font-weight: 500;
  color:gray;
`;

const BreakdownComponent = ({ totalRevenue, totalJobCosts, totalGrossProfit, profitMargin, chartArray }) => {

    const data = {
        labels:chartArray?.percentageData[0]===0&&chartArray?.percentageData[1]===0?['']:['Total Gross Profit', 'Total Job Costs'],
        datasets: [
            {
                data:chartArray?.percentageData[0]===0&&chartArray?.percentageData[1]===0?[100]: chartArray?.percentageData,
                backgroundColor:chartArray?.percentageData[0]===0&&chartArray?.percentageData[1]===0?['#ccc']: ['#0F52BA', '#ee4a49'], // Colors for each segment
                borderColor: chartArray?.percentageData[0]===0&&chartArray?.percentageData[1]===0
                ? ['#d3d3d3'] // Border color for the grey segment
                : ['#0F52BA', '#ee4a49'],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: false, // Hide the default legend
            },
            datalabels: {
                formatter: (value) => {
                    return value + '%';
                },
            },
        },
    }


    return (
        <Container>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                    <InfoSection container spacing={1}>
                        <Grid item>
                            <Header >Profit margin</Header>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Calculated as the total gross profit divided by revenue (excluding taxes and tips)">
                                <IconButton size="small">
                                    <InfoIcon style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </InfoSection>
                    <Typography style={{ textAlign: 'start' }} variant="h5">{parseFloat(chartArray?.percentageData[0])?.toFixed(2)}%</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <InfoSection container spacing={1}>
                        <Grid item>
                            <Header >Total revenue</Header>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Calculated as the subtotal of the job minus any discounts">
                                <IconButton size="small">
                                    <InfoIcon style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </InfoSection>

                    <Typography variant="body1" style={{ textAlign: 'start' }}>${putCommaInvalue(chartArray?.totalGrossProfit?.toFixed(2))}</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <InfoSection container spacing={1}>
                        <Grid item>
                            <Header >Total job costs</Header>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Calculated as the total service, labor, material, commissions, and/or miscellaneous costs">
                                <IconButton size="small">
                                    <InfoIcon style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </InfoSection>
                    <Typography variant="body1" style={{ textAlign: 'start' }}>${putCommaInvalue(chartArray?.totalCostPrice?.toFixed(2))}</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <InfoSection container spacing={1}>
                        <Grid item>
                            <Header >Total gross profit</Header>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Calculated as revenue minus total job costs">
                                <IconButton size="small">
                                    <InfoIcon style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </InfoSection>
                    <Typography variant="body1" style={{ textAlign: 'start' }}>${putCommaInvalue((chartArray?.totalGrossProfit-chartArray?.totalCostPrice)?.toFixed(2))}</Typography>
                </Grid>
            </Grid>

            <ChartContainer >
                {/* <Doughnut data={data} /> */}
                <div style={{borderRight:'1px solid #ddd',paddingRight:'20px'}}>
                    <h5 style={{ textAlign: 'start' }}>Total revenue breakdown</h5>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '250px' }}>
                            <Doughnut data={data} options={options} style={{ width: '100%', height: '100%' }} />
                        </div>

                        <Grid style={{ marginLeft: '15px' }}>
                            <Grid item >

                                <BreakdownLabel variant="body1">
                                    <Box component="span" sx={{ backgroundColor: '#0F52BA', width: 16, height: 16, borderRadius: '50%', marginRight: '8px', marginTop: '4px' }}></Box>
                                    <div>
                                        Total gross profit
                                        <BreakdownValue variant="body2">{parseFloat(chartArray?.percentageData[0])?.toFixed(2)}% of total revenue</BreakdownValue>
                                    </div>
                                </BreakdownLabel>


                            </Grid>
                            <Grid item>

                                <BreakdownLabel variant="body1">
                                    <Box component="span" sx={{ backgroundColor: '#ee4a49', width: 16, height: 16, borderRadius: '50%', marginRight: '8px', marginTop: '4px' }}></Box>
                                    <div>
                                        Total job costs
                                        <BreakdownValue variant="body2">{parseFloat(chartArray?.percentageData[1])?.toFixed(2)}% of total revenue</BreakdownValue>
                                    </div>


                                </BreakdownLabel>
                            </Grid>

                            {/* <Grid item xs={12} md={6}>
          <BreakdownItem>
            <BreakdownLabel variant="body1">
              <Box component="span" sx={{ backgroundColor: '#d3d3d3', width: 16, height: 16, borderRadius: '50%', marginRight: '8px' }}></Box>
              Total job costs
            </BreakdownLabel>
            <BreakdownValue variant="body2">0% of total revenue</BreakdownValue>
          </BreakdownItem>
        </Grid> */}
                        </Grid>
                    </div>
                </div>
                <div style={{paddingLeft:'20px'}}>
                    <h5 style={{ textAlign: 'start', }}>Total job cost breakdown</h5>
                    
                    <Grid style={{ marginLeft: '15px',height:'250px',justifyContent:'center',display:'flex',flexDirection:'column' }}>
                        
                        <Grid item >

                            <BreakdownLabel variant="body1">
                                <Box component="span" sx={{ backgroundColor: '#bbb', width: 16, height: 16, borderRadius: '50%', marginRight: '8px', marginTop: '4px' }}></Box>
                                <div>
                                Materials
                                <BreakdownValue variant="body2">${parseFloat(chartArray?.materialData?.cost)?.toFixed(2)}</BreakdownValue>
                                    <BreakdownValue variant="body2">{parseFloat(chartArray?.materialData?.percentage)?.toFixed(2)}% of total job cost</BreakdownValue>
                                </div>
                            </BreakdownLabel>


                        </Grid>
                        <Grid item>

                            <BreakdownLabel variant="body1">
                                <Box component="span" sx={{ backgroundColor: '#bbb', width: 16, height: 16, borderRadius: '50%', marginRight: '8px', marginTop: '4px' }}></Box>
                                <div>
                                Services
                                <BreakdownValue variant="body2">${parseFloat(chartArray?.serviceData?.cost)?.toFixed(2)}</BreakdownValue>
                                    <BreakdownValue variant="body2">{parseFloat(chartArray?.serviceData?.percentage)?.toFixed(2)}% of total job cost</BreakdownValue>
                                </div>


                            </BreakdownLabel>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
          <BreakdownItem>
            <BreakdownLabel variant="body1">
              <Box component="span" sx={{ backgroundColor: '#d3d3d3', width: 16, height: 16, borderRadius: '50%', marginRight: '8px' }}></Box>
              Total job costs
            </BreakdownLabel>
            <BreakdownValue variant="body2">0% of total revenue</BreakdownValue>
          </BreakdownItem>
        </Grid> */}
                    </Grid>
                </div>

            </ChartContainer>


        </Container>
    );
};

export default BreakdownComponent;
