import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { assignedJob, submitBookingStatus, updateDetail } from '../../../store/Actions/dashboard.action'
import { formatMobileNumber, TimeListAm } from '../../../utils/Commonfunction'
import { Checkbox, MenuItem, Select } from '@mui/material'
import add from '../../../assets/adds.png'
import payments from '../../../assets/paymentsIconss.png'
import payment from '../../../assets/payment.png'
import reviews from '../../../assets/reviews.png'
import unlock from '../../../assets/unlock.png'
import cross from '../../../assets/cross.png'
import pause from '../../../assets/pause.png'
import fastforward from '../../../assets/fast-forward.png'
import check from '../../../assets/check.png'
import moment from 'moment'
import CreateInvoice from '../CreateInvoice'
import CreateQuote from '../CreateQuote'
import Modals from '../../Modal'
import { sendMessage } from '../../../store/Actions/Auth.action'

function BookingDetails(props) {
    const detail = useSelector(state => state?.auth?.userDetails)
    const [isbookingStatus, setBookingStatus] = React.useState(props?.value?.bookingStatus)
    const [day, setDay] = React.useState(props?.value?.team || [])
    const [isModalopenInvoice, setModalOpenInvoice] = useState(false);
    const [isModalopenQuote, setModalOpenQuote] = useState(false);
    const userdata = useSelector(state => state?.auth?.userData)
    const [selectedBox, setSelectedBox] = useState(false)
    const [message, setMessage] = useState(null)
    const statusData = [{ name: "Open", img: unlock, value: 'Waiting for confirmation' },
    { name: "On hold", img: pause, value: 'On Hold' },
    { name: "Active", img: fastforward, value: 'In Progress' },
    { name: "Done", img: check, value: 'Completed' }]
    const dispatch = useDispatch()
    const handleCheckboxChange = (item) => {
        if (day.some(selectedItem => selectedItem.email === item)) {
            setDay(day.filter(selectedItem => selectedItem !== item));
        } else {
            setDay([...day, item]);
        }
    };

    const handleRemoveSelected = (val) => {
       

        const updatedCheckboxData = day.filter(data => val !== data);
      
        setDay([...updatedCheckboxData]);
    };
    const onSubmit = async () => {
        const selectedData = []
        day?.map((item) => {
            selectedData.push(item)
        })
        const details = {
            "email": selectedData,
            "bookingNo": props?.value?.referenceNo,
            "serviceName": "Moving"
        }
        await dispatch(assignedJob(details))
        setSelectedBox(false)
        props?.refresh()

    }
    const sendchat = async (val) => {

        await dispatch(sendMessage(
            {
                "to": props?.value?.customerNumber,
                "from": `+${localStorage.getItem('number')}`,
                "body": val
            }
        ))



    }
    const bookingStatus = async (value) => {
        const data = {
            "referenceNo": props?.value?.referenceNo,
            "bookingStatus": value
        }

        const res = await dispatch(submitBookingStatus(data))
        if (res.status === true) {
            setBookingStatus(value)
        }
    }

    return (
        <Main>

            <Wrapper style={{ display: 'flex' }}>
                <div className='inputwrapper' style={{ marginRight: '60px' }}>
                    <Label htmlFor={'First name'}>Name</Label>
                    <Value >{props?.value?.name}</Value>
                </div>
                <div className='inputwrapper'>
                    <Label htmlFor={'Last name'}>Phone number</Label>
                    <Value >{formatMobileNumber(props?.value?.customerNumber||props?.value?.phone)}</Value>
                </div>
            </Wrapper>
            <Wrapper style={{ display: 'flex' }}>
                <div className='inputwrapper' style={{ marginRight: '60px' }}>
                    <Label htmlFor={'First name'}>Date</Label>
                    <Value >{moment(props?.value?.date)?.format('DD MMM')}, {moment(props?.value?.time)?.format('hh:mm')}</Value>
                </div>
                <div className='inputwrapper'>
                    <div onClick={() => selectedBox ? setSelectedBox(false) : setSelectedBox(true)} style={{ background: '#fff', height: '45px', border: '1px solid #ddd', marginTop: '5px', cursor: 'pointer', justifyContent: 'center', display: 'flex', alignItems: 'center', borderRadius: "5px" }}>
                        <Icons src={add} />
                        <p>Assign crew</p>
                    </div>
                    {selectedBox && <DetailsOptions className='mt-1'>
                        {props?.team?.map((item, index) => {
                            return item?.email === 'AllData' ? null : <div onClick={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }} style={{ display: 'flex' }}>
                                <Checkbox
                                    key={index}
                                    value={item?.email}
                                    checked={day.includes(item?.email)}
                                    onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                />
                                <Text className='maintext' id={item} key={index}>{item?.firstName} {item?.lastName || ''} </Text>

                            </div>
                        })}

                        <SendButton onClick={() => onSubmit()} >Save</SendButton>
                    </DetailsOptions>
                    }
                </div>
            </Wrapper>
            <Wrapper style={{ display: 'flex' }}>
                <div  style={{ marginRight: '60px' }}>
                    <Label htmlFor={'First name'}>Address</Label>
                    <Value >{props?.value?.customerAddress}</Value>
                </div>


            </Wrapper>
            <Wrapper style={{ display: 'flex', justifyContent: 'space-around' }}>
                {
                    statusData.map((item) =>
                        <ProcessWrapper onClick={() => bookingStatus(item?.value)} style={{ background: item?.value === isbookingStatus ? '#d4e6ff' : '#fff' }}>
                            <IconProcess src={item?.img} />
                            <p style={{fontSize:'14px'}}>{item?.name}</p>
                        </ProcessWrapper>
                    )
                }
            </Wrapper>
            <Wrapper>
                <Label htmlFor={'Description'}>Description</Label>
                <Value >{props?.value?.notes}</Value>
            </Wrapper>
            <Elements>
                <Flexbox onClick={() => setModalOpenQuote(true)}>
                    <ItemIcon src={payment} />
                    <Element>&nbsp;Quote&nbsp;</Element>
                </Flexbox>
                <Flexbox onClick={() => setModalOpenInvoice(true)} >
                    <ItemIcon src={payments} />
                    <Element>&nbsp;Invoice&nbsp;</Element>
                </Flexbox>
                <Flexbox onClick={() => sendchat(`Please leave us a quick review by clicking the link below:https://app.getnotifi.com/review/${new Date().getDate()}/${userdata?.placeId}`)}>
                    <ItemIcon src={reviews} />
                    <Element>&nbsp;Ask for review&nbsp;</Element>
                </Flexbox>
            </Elements>

            {isModalopenInvoice && (
                <Modals onClose={() => setModalOpenInvoice(false)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Invoice</h2>
                        <CloseButton onClick={() => setModalOpenInvoice(false)}>
                            <ModalIcon src={cross} />
                        </CloseButton>
                    </div>
                    <CreateInvoice
                        {...props}
                        value={{name:props?.value?.name,to:props?.value?.customerNumber||props?.value?.phone,address:props?.value?.address}}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenInvoice(false)


                        }
                        }
                    />


                </Modals>
            )}
            {isModalopenQuote && (
                <Modals onClose={() => setModalOpenQuote(false)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Quote</h2>
                        <CloseButton onClick={() => setModalOpenQuote(false)}>
                            <ModalIcon src={cross} />
                        </CloseButton>
                    </div>
                    <CreateQuote
                        {...props}
                        value={{name:props?.value?.name,to:props?.value?.customerNumber||props?.value?.phone,address:props?.value?.address}}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenQuote(false)


                        }
                        }
                    />


                </Modals>
            )}
        </Main>
    )


}

export default BookingDetails;
const Text = styled.p`
font-size:14px;
font-family: Inter;
font-style: normal;
font-weight: 400;
cursor:pointer;
color: black;
`

const Main = styled.div`
width:460px;
margin-top:-10px;
padding-top:25px;
height:530px;
flex-shrink: 0;
overflow: auto; 
margin-left:15px;
padding-bottom:30px;
  display: flex;
  flex-direction: column;

`

const DetailsOptions = styled.div`
position:absolute;
z-index:999999999999999999999;
margin-right:15px;
border-radius:8px;
margin-bottom:15px;
border:1px solid lightgray;
color:#787373;
font-family:Inter;
font-size:12px;
padding-bottom:5px;
overflow:scroll;

position:absolute;
background:#fff;
width:200px;
max-height:320px;
margin-top:10px;


`

const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Wrapper = styled.div`
display:block;
width:460px;
margin-top:30px;
.inputwrapper{
    width:41%;
    @media (min-width: 260px) and (max-width: 721px){
        width:38.5%;
       
        }
}
.button{
    margin-left:230px;
    width:300px;
}
@media (min-width: 260px) and (max-width: 721px){
    width:90%;
    
    }

`

const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:4px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

cursor:pointer;

:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 16px;
margin-bottom:20px;
width:90%;
height:44px;
border-radius:8px;
border:1px solid white;

color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`
const Selectbox = styled(Select)`
width:100%;
border-radius:12px;
text-align:start;
height:50px;
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const Icons = styled.img`
width: 20px;
height: 20px;
margin-right:10px;
`
const ModalIcon = styled.img`
width: 20px;
height: 20px;

`
const ProcessWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
border:1px solid #ddd;
align-items:center;
width:100px;
padding-top:15px;
height:70px;
margin-left:-15px;
border-radius:15px;
cursor:pointer;
`
const IconProcess = styled.img`
width: 25px;
height: 25px;
margin-bottom:-10px;
`
const Flexbox = styled.div`
display:flex;
align-items:center;
`
const ItemIcon = styled.img`

  width: 20px;
  height: 20px;

`
const Element = styled.div`

font-size:14px;
font-weight:400;
margin-right:12px;
cursor:pointer;
`
const Elements = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:space-around;
margin-top:30px;

`