import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import trash from '../../assets/trash.png'
import check from '../../assets/check.png'
import { deleteAddTask, DoneAddTask, getMissionControl } from "../../store/Actions/dashboard.action";
import calenda from '../../assets/calenda.png'
import moment from "moment";
import { Box, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { palette } from "../../utils/Palette";
import { Check, DeleteOutlined, Handyman, HandymanOutlined, LowPriorityTwoTone, Notifications, Paid, Receipt, TaskSharp, TypeSpecimenOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTabs from "../../ReuseableComponets/CustomTab";
import { FiEdit, FiTrash2, FiMoreHorizontal, FiCheck, FiCheckCircle } from "react-icons/fi";

const MissionControl = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const init = async () => {
    setLoader(true)
    const res = await dispatch(getMissionControl())
    setData(res?.data?.all)
    setLoader(false)
  }

  useEffect(() => {
    init()
  }, [])

  const getName = (channel) => {
    if (!channel) {
      return
    }
    const val = channel?.split(' ')

    if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
    }
    else {
      return `${val[0]?.charAt(0).toUpperCase()}`
    }
  }
  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderData = (datas,type) => {
    return (


      <div style={{ overflowY: 'auto', scrollbarWidth: 'none', height: '99vh', paddingBottom: '120px' }}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <CustomHead>
            <TableRow >

              <TableCell size='small'>
                <div >
                  Name
                </div>
              </TableCell>
              <TableCell size="small" style={{ position: 'relative', color: palette.black, fontWeight: '700' }} >
                Description
              </TableCell>
              <TableCell size='small' style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} >

                Date

              </TableCell>
              {/* <TableCell size='small' style={{ minWidth: '80px', position: 'relative', color: palette.black, fontWeight: '700' }}>Type</TableCell> */}
              <TableCell style={{ width: '100px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                Actions
              </TableCell>



            </TableRow>
          </CustomHead>
          {datas?.map((item) => (
            <TableBody key={item._id} style={{ background: '#fff', cursor: 'pointer' }}>
              <TableRow>
                <TableCell size="small" onClick={() => {
                  navigate(`/dashboard/contacts/${item?.contactId}`)
                }}>
                  {item?.name && item?.name}
                </TableCell>
                <TableCell size="small">
                  <TaskDetails>
                    <TaskLabel>{item?.task}</TaskLabel>
                  </TaskDetails>
                </TableCell>
                <TableCell size="small">
                  {(item?.type == 'Task' || !item?.type) ? `${moment(item?.date)?.format('DD MMM YYYY')}, ${moment(item.time).format("hh:mm A") === 'Invalid date' ? item?.time : moment(item.time).format("hh:mm A")}` : `${moment(item?.createdAt)?.format('DD MMM YYYY')}, ${moment(item?.createdAt)?.format('hh:mm A')}`}
                </TableCell>


                <TableCell size='small'  >
                  <ActionIcons>
                    <FiCheckCircle onClick={async () => {
                      await dispatch(deleteAddTask(item?._id))
                      init()

                    }} />
                    <FiTrash2 onClick={async () => {
                   await dispatch(DoneAddTask(item?._id));
                   init()
                    }} />

                  </ActionIcons >
                </TableCell>
                {/* <TableCell size='small'>{capitalizeFirstLetter(item?.type || "Task")}</TableCell> */}
                {/* <TableCell size="small" onClick={async () => {
                  await dispatch(deleteAddTask(item?._id))
                  init()
                }} >
                  <DeleteOutlined style={{ fontSize: '20px' }} />
                </TableCell>
                <TableCell size="small" onClick={async () => {
                  console.log(item, "dsds")
                  await dispatch(DoneAddTask(item?._id));
                  init()
                }}>
                  <Check style={{ fontSize: '20px' }} />
                </TableCell> */}
              </TableRow>
            </TableBody>
          ))}

        </Table>
        {datas?.length > 0 ? null : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="60vh"
            sx={{ color: 'gray' }}
          >
            {<Notifications sx={{ fontSize: '50px', marginBottom: 2 }} />}
            <Typography variant="h5" align="center">
            No New Notifications
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
            All your notifications will appear here
            </Typography>
          </Box>
        )}
      </div>


    )
  }

  return (
    <Body>
      <FlexboxContainer>
        <h1 >Notifications</h1>

        {loader ? <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
          src={require('../../assets/loaders.json')}
          className="player"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        />
        </div>

          : <CustomTable>     <CustomTabs

            boxStyle={{
              p: 0, marginTop: '20px', marginBottom: '25px'
            }}
            tabsData={[
              {
                label: 'Contacts',
                icon: <TypeSpecimenOutlined style={{ fontSize: '16px' }} />,
                content: renderData(data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Contact')),
                unreadCount: data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Contact')?.length
            

              },

              {
                label: 'Jobs',
                icon: <Handyman style={{ fontSize: '16px' }} />,
                content: renderData(data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Job')),
                unreadCount: data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Job')?.length


              },
              {
                label: 'Quotes',
                icon: <Receipt style={{ fontSize: '16px' }} />,
                content: renderData(data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Quote')),
                unreadCount: data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Quote')?.length

              },
              {
                label: 'Invoices',
                icon: <Paid style={{ fontSize: '16px' }} />,
                content: renderData(data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Invoice')),
                unreadCount: data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Invoice')?.length

              },
              {
                label: 'Tasks',
                icon: <TaskSharp style={{ fontSize: '16px' }} />,
                content: renderData(data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Task')),
                unreadCount: data?.filter((item) => capitalizeFirstLetter(item?.type || "Task") === 'Task')?.length

              }


            ]}
          />
          </CustomTable>

        }


      </FlexboxContainer>
    </Body>
  )
}

export default MissionControl;

const FlexboxContainer = styled.div`
    width: 100%;
    background: #fff;
    display: flex;
 
    flex-direction: column;
    
    align-items: flex-start;

    h1{
      margin-left:30px;
      margin-right:30px;
    }
`;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;
const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const ValueContainer = styled.div`
    padding: 15px;
    background: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ProfileIcon = styled.div`
    background: #2b373f;

    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 12px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: bold;
`;

const NameLabel = styled.label`
    font-weight: bold;
    color: #344054;

`;

const TaskLabel = styled.label`
    color: #344054;
    text-align:start;
    
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
`;

const DateLabel = styled.label`
    margin-top: 5px;
    margin-right: 35px;
    color: #344054;
`;

const ActionIcon = styled.img`
    width: 22px;
    cursor: pointer;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content:start;
    align-self:start;
    max-width:500px;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-wrap;
`;

