const mapStyles =[
  {
      "featureType": "poi.business",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
{
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#c9edc5"
        },
        {
            "visibility": "on"
        }
    ]
},
{
    "featureType": "landscape.man_made",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "color": "#f4f3f0"
        }
    ]
},
{
    "featureType": "landscape.natural",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "visibility": "on"
        }
    ]
},
{
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#bcdfb8"
        }
    ]
},
{
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
        {
            "color": "#c8eec4"
        },
        {
          "visibility": "off"
        }
    ]
},
{
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
       
        {
          "visibility": "off"
        }
    ]
},
{
    "featureType": "poi.sports_complex",
    "elementType": "geometry",
    "stylers": [
       
        {
          "visibility": "off"
        }
    ]
},
{
  featureType: "poi",
  elementType: "labels",
  stylers: [
        { visibility: "off" }
  ]
},
{
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
        {
            "lightness": 100
        },
        {
            "visibility": "simplified"
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.text",
    "stylers": [
        {
            "weight": "1"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
        {
            "lightness": "0"
        },
        {
            "color": "#ffe492"
        },
        {
            "visibility": "on"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
        {
            "color": "#ffffff"
        },
        {
            "weight": "2.53"
        },
        {
            "visibility": "on"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
        {
            "visibility": "on"
        }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "labels.text.stroke",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "color": "#ffffff"
        },
        {
            "weight": "0.50"
        }
    ]
},
{
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "lightness": 700
        }
    ]
},
{
    "featureType": "water",
    "elementType": "all",
    "stylers": [
        {
            "color": "#7dcdcd"
        }
    ]
},
{
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
        {
            "color": "#abd9ee"
        }
    ]
}
]


export default mapStyles;
