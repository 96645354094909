import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";

import SettingList from "../../Components/Settings/SettingList";
import AccountDetails from "../../Components/Settings/AccountDetails";
import LoginSecurity from "../../Components/Settings/LoginAndSecurity";
import TaxDetails from "../../Components/Settings/Taxdetails";
import ProductCatalogue from "../../Components/Dashboard/Payments/ProductCatalogue";
import TermsAndConditions from "../../Components/Settings/TermAndConitions";
import FacebookLeads from "../Dashboard/Contacts/FacebookLeads";


import { getAccessToken, getUserDetails, getuserInfo } from "../../store/Actions/dashboard.action";
import CustomFieldsPage from "./Customization/Customfields";
import CustomTags from "./Customization/Tags";
import JobTypeAndJobPriority from "./Customization/JobTypeAndJobPriority";
import CustomizeLeadStatus from "./Customization/CustomizeLeadStatus";

function Settings() {
    const [data, setData] = useState([]);
    const [connection, setConnection] = useState();
    const callShow = useSelector(state => state?.auth?.showCall);
    const incomingcallShow = useSelector(state => state?.auth?.showIncomingCall);
    const detail = useSelector(state => state?.auth?.incominDetail);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const init = async () => {
        await dispatch(getUserDetails());
        await dispatch(getuserInfo());
        await dispatch(getAccessToken());
    };

    connection?.then((call) => {
        call.on("disconnect", () => {
            dispatch({ type: "CALLSHOW", payload: false });
        });
    });

    useEffect(() => {
        init();
    }, []);

    const handleSettingChange = (value) => {
        // Replace history.push with navigate
        navigate(`/dashboard/settings/${value.replace(/\s+/g, '-').toLowerCase()}`);
    };

    const renderData = useCallback(() => {
        console.log(location.pathname.split("/"), "dcsdsdcd")
        switch (location.pathname.split("/")[3]) {
            case "account-details":
                return <AccountDetails />;
            case "login-and-security":
                return <LoginSecurity />;
            case "tax-details":
                return <TaxDetails />;
            case "custom-status":
                return <CustomizeLeadStatus />;

            case "terms-and-conditions":
                return <TermsAndConditions />;
            case "pricebook":
                return <ProductCatalogue />;
            case "facebook-leads":
                return <FacebookLeads />;
            case "custom-fields":
                return <CustomFieldsPage />;
            case "custom-tags":
                return <CustomTags />;
            case "type-priority":
                return <JobTypeAndJobPriority />;
            default:
                return <AccountDetails />; // Default view
        }
    }, [location.pathname]);

    return (
        <>
            <Body>
                <div style={{ display: 'flex', width: '100%' }}>
                    <SettingList setIndex={handleSettingChange} />
                    {renderData()}
                </div>
            </Body>
        </>
    );
}

export default Settings;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;


`
const NumberDiv = styled.div`
width:350px;
padding-top:15px;
flex-shrink: 0;
background-color: #DDDDD;
overflow: auto; 
@media (min-width: 260px) and (max-width: 1311px){
    display:none;
}
`
// Update Routes in your main `App.js` or entry point

// <Router>
//     <Routes>
//         <Route path="/settings/:section?" element={<Settings />} />
//     </Routes>
// </Router>
