// SelectTimebox.js
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { palette } from '../utils/Palette';

const StyledSelectTimebox = styled(Select)`
    width: 100%;
    border-radius: 4px;
    text-align: start;
    border: none;
    height: 34px;
    width: 150px;
    margin-left: 10px;
    &:focus {
        outline: 1.5px solid black;
        border: 0.5px solid ${palette.charcoleBlack};
      
      }
    .select-timebox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 13px;
        position: relative;
    }

    .schedule-icon {
        font-size: 16px;
        position: absolute;
        right: 10px;
    }

    .MuiSelect-select {
        padding-right: 0px;
        width: 100px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ddd;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1.5px solid #000;
      }

    .MuiMenuItem-root {
        border: 1px solid #ddd;
        font-size:13px;
    }

    .MuiMenuItem-root:hover {
        background: #dddddd;
        border-radius: 5px;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    align-self: start;
    font-size:14px;

    :hover {
        background: #dddddd;
        border-radius: 5px;
    }
`;

const InputPicker = ({value, onChange, data, label ,...props}) => (
    <StyledSelectTimebox
    {...props}
    
        renderValue={() =>
            <div className='select-timebox'>
                {value?value:label}

            </div>
        }
        defaultValue={"REAL_ESTATE"}
        onChange={(event) =>onChange(event?.target.value)}
    >
        {data?.map((item) =>
            <StyledMenuItem key={item.value} value={item.value}>
                {item.label}
            </StyledMenuItem>
        )}
    </StyledSelectTimebox>
);

InputPicker.propTypes = {
    value:  PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    data: PropTypes.array,
};

export default InputPicker;
