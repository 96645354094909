import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import GPS from '../assets/gps.png'
// import io from 'socket.io-client';
import profile from '../assets/user1.png'
import Sendicon from '../assets/send.png'
import maverick from '../assets/maverick.png'
import Lottie from 'react-lottie';
import loaderss from './chatLoader.json'
import sublogo from '../assets/logo/Logo.png'
import { chatgprtApi, chatgprtImgApi, chatgprtImgApi1, getAllinchats, getAlloutchats, getUserData, sendMessage } from '../store/Actions/Auth.action'
import DashboardHeader from '../Components/Dashboard/DashboardHeader';
import Header from '../Components/Header';
import LeftMenu from '../Components/Dashboard/LeftMenu'
import LeftChatMenu from '../Components/Dashboard/leftChatbot'
import { saveAs } from 'file-saver'

// const socket = io('https://prod.swiftbel.com');

function Chatbot() {
    const globename = useSelector(state => state?.auth?.globename)
    const globenumber = useSelector(state => state?.auth?.globenum)
    const globecolor = useSelector(state => state?.auth?.globecolor)
    const [message, setMessage] = useState(null)
    const [body, setBody] = useState(null)
    const [num, setNum] = useState(null)
    // const [details,setDetails]=useState(null)
    let fromnumber = '+13656075582' //localStorage.getItem('fromnumber')

    const detail = useSelector(state => state?.auth?.convo)
    //const firstnumber = detail ? detail?.[0]?.name ? detail?.[0]?.name : detail?.[0]?.to :''
    const frstnum = detail ? detail?.[0]?.to : ''
 
    let dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllinchats(fromnumber, globenumber ? globenumber : frstnum));
        dispatch(getAlloutchats(globenumber ? globenumber : frstnum, fromnumber));
        dispatch(getUserData())
    }, [globenumber, frstnum, fromnumber])
    const outchats = useSelector(state => state?.auth?.outchats)
    const inchats = useSelector(state => state?.auth?.inchats)
    const userdata = useSelector(state => state?.auth?.userdata)
    const [alldata, setAllData] = useState([])
    const [chatData, setChatData] = useState([])
    const [toggle, setToggle] = useState(false)
    const [issend, setisSend] = useState(false)
   
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderss,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    //  const handledate=(item)=>{
    //     const dateString = item;
    //     const date = new Date(dateString);
    //     const hours = date.getUTCHours();
    //     const minutes = date.getUTCMinutes();
    //     let hours12 = hours % 12;
    //     if (hours12 === 0) {
    //     hours12 = 12;
    //     }
    //    let customminutes=minutes<10 ?'0'+minutes:minutes
    //    const meridiem = hours < 12 ? "AM" : "PM";
    //    const formattedTime = `${hours12}:${customminutes} ${meridiem}`;
    //    return formattedTime
    //  }

    const data = [{
        "messages": [
            {
                "role": "system",
                "content": "You are an AI assistant providing detailed, practical business advice with a subtle emphasis on specific tools where applicable. Your responses should be informative, directly addressing the user's questions while subtly introducing and explaining the benefits of relevant tools like Notifi."
            },

            {
                "role": "user",
                "content": "How can I attract more customers to my plumbing business, especially in my local area?"
            },
            {
                "role": "assistant",
                "content": "The easiest, cheapest, and most effective way is to get Google reviews, which will boost your rank higher on Google. Encourage customers to leave reviews by sending them direct links to your Google review page. Consider tools like 'Notifi', with their 'text to review' functionality that enables customers to leave a review instantly. Getting Google reviews consistently every week is key. Also, consider paid advertising on Google and Facebook. Respond instantly to leads and use Notifi's 'Missed Call to Text' feature to capture customer requirements via text messages if you miss their calls."
            },
            {
                "role": "user",
                "content": "What are some cost-effective advertising strategies for a small plumbing business?"
            },
            {
                "role": "assistant",
                "content": "Utilize social media and local ads to showcase your services. Engage customers with before-and-after photos of your work. Word-of-mouth referrals are also effective. The key in online advertising is converting leads into paying customers. Respond to leads instantly yourself or via your reception, and never miss a call. Tools like Notifi can assist by instantly engaging with leads using AI and text messages, opening the conversation, which you can then take over. Notifi also supports 'Missed Call to Text' functionality, enabling AI to start a conversation and answer questions like pricing."
            },
            {
                "role": "user",
                "content": "What's the best way to accurately estimate the cost and time for plumbing jobs to provide reliable quotes to my customers?"
            },
            {
                "role": "assistant",
                "content": "Create a standardized pricing model based on job type, complexity, and duration. Adjust based on past job data and communicate clearly with customers about potential cost variables. Always get customer approval on pricing in a formal agreement and take a deposit upfront to avoid confusion later. Tools like Notifi can help communicate these estimates to clients quickly via text, allowing for eSignature and deposit payment."
            },
            {
                "role": "user",
                "content": "What are the essential tools and equipment every plumber should have, and are there any new tools on the market I should know about?"
            },
            {
                "role": "assistant",
                "content": "Stay updated with the latest tools in the plumbing industry through forums, trade shows, and publications. Also, consider tech tools like Notifi that can help you be more efficient, increase sales, and save time – all key factors for growing your business and being more profitable."
            },
            {
                "role": "user",
                "content": "How can I manage customer expectations and handle difficult service situations effectively?"
            },
            {
                "role": "assistant",
                "content": "Maintain clear communication with clients about services, timeframes, and potential issues. Always take deposits upfront and get customers to approve the quotes by e-signing upfront."
            },
            {
                "role": "user",
                "content": "What are common plumbing problems during different seasons, and how can I prepare my business to handle them?"
            },
            {
                "role": "assistant",
                "content": "Offer services for common seasonal issues, and use your social media and email lists to inform clients about these services. Efficient job scheduling and customer reminders are always beneficial. Have a CRM in place to track regular customers who use your service yearly. Tools like Notifi can help with these kinds of use cases."
            },
            {
                "role": "user",
                "content": "What are some effective time management strategies for juggling multiple plumbing jobs in a day?"
            },
            {
                "role": "assistant",
                "content": "Optimize scheduling and route planning to manage multiple jobs efficiently. Use Notifi for efficient scheduling and dispatching, enhancing time management."
            },
            {
                "role": "user",
                "content": "What basic training should I provide to new plumbers to ensure quality service?"
            },
            {
                "role": "assistant",
                "content": "Provide comprehensive training in technical skills and customer service. Regular training sessions and on-the-job training are valuable."
            },
            {
                "role": "user",
                "content": "How can I manage my inventory more efficiently to ensure I always have necessary supplies on hand?"
            },
            {
                "role": "assistant",
                "content": "Maintain efficient inventory management by tracking frequently used supplies and maintaining good supplier relationships. Efficient job tracking can indirectly assist in understanding inventory needs."
            },
            {
                "role": "user",
                "content": "How can I improve my online presence to make my plumbing business more accessible to potential customers?"
            },
            {
                "role": "assistant",
                "content": "Develop a user-friendly website and be active on social media, and ask for Google reviews. Google reviews are key to a stronger online presence. It’s worth checking out tools that provide 'Text to Review' functionality to make it easy for customers to leave a Google review through text messages. These tools include Notifi, Podium, and Nicejob.com."
            }


        ]
    }]

    const chatGptImageClick = async () => {

        setAllData((prevMessages) => [{ "role": "user", "content": message }, ...prevMessages])


        setMessage('')
        setisSend(true)
        const res = await dispatch(chatgprtImgApi1({
            "model": "dall-e-3",
            "prompt": `generate image ${message}`,
            "n": 1,
            "size": "1024x1024"

        }))
     
        if (res?.data[0]?.url) {

            setAllData((prevMessages) => [{ "role": "assistant", "img": res?.data[0]?.url }, { "role": "assistant", "content": `${res?.data[0]?.revised_prompt} in this image` }, ...prevMessages])
        }

        // setAllData(res?.choices[0]?.message?.content)
        // setAiloader(false)
        setisSend(false)

    }

    // Function to convert data to JSONL format
    function convertToJSONL(data) {
        return data.map(entry => JSON.stringify(entry)).join('\n');
    }

    // Convert data to JSONL format
    const jsonlData = convertToJSONL(data);

    const chatGptClick = async (valuess) => {

        setAllData((prevMessages) => [{ "role": "user", "content": message ? message : valuess }, ...prevMessages])
        const value = alldata.filter((item) => !item?.img)
    
        // setAiloader(true)

        const dataJson = [
            {
                "role": "system",
                "content": `You are an AI assistant providing detailed, practical business advice with a subtle emphasis on specific tools where applicable. Your responses should be informative, directly addressing the user's questions while subtly introducing and explaining the benefits of relevant tools like Notifi.`
            }
            , ...value.reverse(), { "role": "user", "content": message ? message : valuess }]
        setMessage('')
        setisSend(true)
        const res = await dispatch(chatgprtApi({

            "model": "ft:gpt-3.5-turbo-0613:notifi::8aHu7ni7",
            "messages": dataJson,

            "temperature": 0.7
        }))

        if (res?.choices[0]?.message?.content) {
            setAllData((prevMessages) => [{ "role": "assistant", "content": res?.choices[0]?.message?.content }, ...prevMessages])
        }

        // setAllData(res?.choices[0]?.message?.content)
        // setAiloader(false)
        setisSend(false)

    }

    const handlesend = (e) => {
        setMessage(e.target.value)
    }
    const sendchat = async () => {
        if (message) {
            dispatch(sendMessage(
                {
                    "from": fromnumber,
                    "to": globenumber,
                    "body": message
                }
            ))
            setMessage('')
            setTimeout(() => {
                dispatch(getAllinchats(fromnumber, globenumber));
                dispatch(getAlloutchats(globenumber, fromnumber));
            }, 1000);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (toggle) {
                chatGptImageClick()
            }
            else {
                chatGptClick();
            }
        }
    };

    return (
        <>
            <LeftVal>
                <LeftChatMenu type={toggle ? 'black' : 'black'} />
            </LeftVal>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Toggle className='d-flex'>
                    <div
                        className={!toggle ? 'activeclass' : 'class'}
                        onClick={() => setToggle(false)}
                    >
                        <p style={{ marginTop: !toggle ? '10px' : '15px' }} id='AM'>Trades Warrior AI</p>
                    </div>

                    <div onClick={() => setToggle(true)} style={{ background: toggle ? 'black' : '#F3F4F4' }} className={toggle ? 'activeclass' : 'class'}
                    >
                        <p style={{ marginTop: !toggle ? '15px' : '10px' }} id='PM' >Trades Warrior Logo</p>
                    </div>


                </Toggle>
            </div>
            {/* <div style={{alignSelf:'flex-start',alignItems:'flex-start',display:'flex',marginLeft:'17.2%'}}>
            <Element  >Maverick AI</Element>
            </div> */}
            <Main>


                <div>

                    <Chats>
                        {/* <Flexbox>
<GoogleBox>
<span>Google</span>
</GoogleBox>
<Name2>{globename}</Name2>
<Flexdiv>
<Rating src='https://cdn2.iconfinder.com/data/icons/universal-signs-symbols/128/star-yellow-512.png' alt='rating'/>
<Rating src='https://cdn2.iconfinder.com/data/icons/universal-signs-symbols/128/star-yellow-512.png' alt='rating'/>
<Rating src='https://cdn2.iconfinder.com/data/icons/universal-signs-symbols/128/star-yellow-512.png' alt='rating'/>
<Rating src='https://cdn2.iconfinder.com/data/icons/universal-signs-symbols/128/star-yellow-512.png' alt='rating'/>
<Rating src='https://cdn2.iconfinder.com/data/icons/universal-signs-symbols/128/star-yellow-512.png' alt='rating'/>
</Flexdiv>
</Flexbox>
<Message>We had a wonderful experience with Swiftbel ! The movers were organized and quick,
    and had us moved in and out in no time.
    The process was seamless and efficient and we will definitely
    use their services again in the future.</Message>
<SubText>June 7 , 23 |
&nbsp;<GpsIcon><svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 2a7 7 0 0 0-7 7c0 5 7 13 7 13s7-8 7-13a7 7 0 0 0-7-7zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" fill="#d2d2d2" />
    </svg>
    </GpsIcon>
    &nbsp;Accounting Bridge</SubText> */}
                        <Texts2>
                            {

                                issend ? <div style={{ alignSelf: 'flex-start', width: '55px', height: '35px', marginLeft: 15, marginTop: 20, marginBottom: 20, }}>
                                    <Lottie
                                        options={defaultOptions}



                                    />
                                </div> : null
                            }
                            {alldata.length > 0 ?
                                alldata.map((x, index) => {
                                    return (
                                        x?.img ? <InText>
                                            <div className='trade' style={{ alignSelf: 'center', alignItems: 'center', display: 'flex', marginBottom: -15 }}>
                                                <ProfileImage src={maverick} style={{ background: 'white' }} alt='profileimg' className={globecolor} />
                                                <h3 style={{ fontStyle: 'Roobert-medium', fontSize: '17px' }}>Trades Warrior</h3>
                                            </div>
                                            <InChat onClick={() => saveAs(x?.img, "Twitter-logo")}>
                                                <img src={x?.img} style={{ width: '50%', height: '50%', marginTop: '15px', cursor: 'pointer' }} loading="lazy" />
                                                <Messagetext style={{ maxWidth: '950px' }}>{x?.content}</Messagetext>
                                                {/* <p className='outtime'>{handledate(x.createdAt)}</p> */}
                                            </InChat>
                                            {/* <OutProfile>
{globename?.toUpperCase().slice(0,1)}
</OutProfile> */}
                                        </InText> :
                                            x?.role === 'user' ?
                                                <InText>
                                                    <div className='trade' style={{ alignSelf: 'center', alignItems: 'center', display: 'flex', marginBottom: -15 }}>
                                                        <ProfileImage src={profile} style={{ background: 'white' }} alt='profileimg' className={globecolor} />
                                                        <h3 style={{ fontStyle: 'Roobert-medium', fontSize: '17px' }}>You</h3>
                                                    </div>
                                                    <InChat>

                                                        <Messagetext style={{ maxWidth: '950px' }}>{x?.content}</Messagetext>
                                                        {/* <p className='outtime'>{handledate(x.createdAt)}</p> */}
                                                    </InChat>
                                                    {/* <OutProfile>
{globename?.toUpperCase().slice(0,1)}
</OutProfile> */}
                                                </InText>
                                                : x?.role === 'assistant' ?
                                                    <InText>
                                                        <div className='trade' style={{ alignSelf: 'center', alignItems: 'center', display: 'flex', marginBottom: -15 }}>
                                                            <ProfileImage src={maverick} style={{ background: 'white' }} alt='profileimg' className={globecolor} />
                                                            <h3 style={{ fontStyle: 'Roobert-medium', fontSize: '17px' }}>Trades Warrior</h3>
                                                        </div>
                                                        {/* <ProfileImage src={profile} alt='profileimg' className={globecolor}/> */}
                                                        <InChat>

                                                            <Messagetext>{x?.content}</Messagetext>
                                                            {/* <p className='intime'>{handledate(x.createdAt)}</p> */}
                                                        </InChat>
                                                    </InText>
                                                    : ''
                                    )
                                })
                                : <div style={{ display: 'inline', }}>
                                    <img src={maverick} style={{ background: 'white', height: '60px', borderRadius: '30px' }} alt='profileimg' className={globecolor} />
                                    <h2 style={{ fontStyle: 'Roobert-medium', fontSize: '20px', marginBottom: '160px', fontWeight: 'bold' }}>How can I help you today?</h2>

                                    <div className='boxdisplay'>

                                        <Elements onClick={() => {
                                            chatGptClick('Tell me a fun fact about Electricians')

                                        }}>
                                            <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Tell me a fun fact'}</p>
                                            <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}> about Electricians</p>
                                        </Elements>

                                        <Elements onClick={() => {
                                            chatGptClick('Create a Facebook ad for a moving company<')

                                        }}>
                                            <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Create a Facebook ad'}</p>
                                            <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}> for a moving company</p>
                                        </Elements>
                                        <div className='mobiledis'>
                                            <Elements onClick={() => {
                                                chatGptClick('Help me get more Google reviews in a home services business')

                                            }}>
                                                <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Help me get more Google reviews'}</p>
                                                <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}> in a home services business</p>
                                            </Elements>

                                            <Elements onClick={() => {
                                                chatGptClick('Help me get more calls as a contractor')

                                            }}>
                                                <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Help me get more calls'}</p>
                                                <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}>as a contractor</p>
                                            </Elements>
                                        </div>

                                    </div>
                                    <div className='boxdisplay webdis'>

                                        <Elements onClick={() => {
                                            chatGptClick('Help me get more Google reviews in a home services business')

                                        }}>
                                            <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Help me get more Google reviews'}</p>
                                            <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}> in a home services business</p>
                                        </Elements>

                                        <Elements onClick={() => {
                                            chatGptClick('Help me get more calls as a contractor')

                                        }}>
                                            <p style={{ marginBottom: '4px', fontSize: 14 }}>{'Help me get more calls'}</p>
                                            <p style={{ marginTop: '0px', fontSize: 12, color: 'gray' }}>as a contractor</p>
                                        </Elements>

                                    </div>


                                </div>}



                        </Texts2>

                    </Chats>
                </div>

                {/* <InputBox type='text' placeholder='Responding publicly as Accounting Bridge'/> */}
                {/* <InputContainer>
        <InputBox type='text' placeholder='Responding publicly as Accounting Bridge' />
        <ButtonContainer>
          <Button1>Post & Close</Button1>
          <Button2>Post</Button2>
        </ButtonContainer>
      </InputContainer> */}


                <InputContainer>
                    <InputBox type="text" placeholder="Message Trades Warrior" onKeyPress={handleKeyPress} value={message} onChange={(e) => handlesend(e)} />
                    <ButtonContainer>

                        <SendIcon src={Sendicon} alt="Send" onClick={() => toggle ? chatGptImageClick() : chatGptClick()} />
                    </ButtonContainer>
                </InputContainer>

            </Main>
        </>
    )
}

export default Chatbot

const Elements = styled.div`
margin-left: 10px;
margin-right: 10px;
  font-weight: bold;
  font-style:Roobert-medium;
  font-size: 14px;
  cursor: pointer;
  align-self:flex-start;
  border:1px solid gray;
 
  display:flex;
  flex-direction:column;
  text-align:start;
  margin-top:10px;

  padding-left:20px;
  padding-right:10px;
  width:50%;
  border-radius:8px;
  margin-bottom:8px;
 
  display:flex;
  color:black;
  @media (min-width: 260px) and (max-width: 820px){
      display:flex;
      text-align:start;
    align-self:flex-start;
    margin-left: 20px;
    width:80%;
}
`

const Main = styled.div`

padding-top:20px;
margin-left:17.2%;
padding-top:10px;
padding-bottom:10px;
padding-left:170px;
padding-right:170px;
height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (min-width: 260px) and (max-width: 820px){
    margin-left:0px;
    padding-left:0px;
    height: 81vh;
padding-right:0px;
}
`

const LeftVal = styled.div`
@media (min-width: 260px) and (max-width: 820px){
    display:none;
}
`
const Element = styled.div`
  margin-left: 28px;
  font-weight: bold;
  font-style:Roobert-medium;
  font-size: 20px;
  cursor: pointer;
  align-self:flex-start;
  position: relative;
  display: inline-block;
  margin-top:10px;
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;
  border-radius:8px;
  margin-bottom:8px;
  color:black;
  @media (min-width: 260px) and (max-width: 820px){
    align-self:flex-start;
    margin-left: -20px;
}

`;
const Texts2 = styled.div`
height:72vh;
overflow-y: scroll;
margin-left:10px;
margin-right:35px;
margin-top:-50px;
display: flex;
flex-direction: column-reverse;
@media (min-width: 260px) and (max-width: 820px){
    margin-right:20px;

}
.boxdisplay{
    display:flex;
    align-self:flex-start;
    justify-content:flex-start;
    @media (min-width: 260px) and (max-width: 820px){
        
        display:flex;
        overflow: hidden;
         white-space: nowrap;
         overflow-x: scroll;
    }
}
.mobiledis{
    display:none;
    @media (min-width: 260px) and (max-width: 820px){
        display:flex;
      
    }
  
}
.webdis{
    @media (min-width: 260px) and (max-width: 820px){
        display:none;
      
    } 
}

&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`
// const SendMessage = styled.div`
//   position: relative;
//   width: 870px;
//   margin-bottom: 80px;
//   margin-top:15px;
// `;

// const Input = styled.input`
//   width: 95%;
//   border-radius: 30px;
//   border: 1px solid lightgray;
//   padding: 15px;
//   padding-left: 20px;
// `;

const Toggle = styled.div`
margin-left: 18.5%;
background-color:#F3F4F4;
border-radius:10px;
width:380px;
height:50px;
display:flex;
font-style:Roobert-medium;
@media (min-width: 260px) and (max-width: 820px){
    margin-left: 0%;


}
.activeclass{
  color:#ffffff;
  background-color:#000;
  font-size:15px;
  border-radius:10px;
  font-weight:600;

margin-top:5px;
  margin-bottom:6px;
  width:210px;
  text-align:center;
 
}
.class{
  color: #2d3032;
  background-color:#F3F4F4;

  margin-bottom:6px;
  font-size:15px;
  border-radius:10px;
  font-weight:600;
  width:190px;
  padding-left:10px;
  padding-right:10px;
  text-align:center;

}
`
const SendIcon = styled.img`
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
margin-right:10px;
cursor:pointer;
margin-bottom:-35px;
`;

const Hr = styled.hr`
border:1px solid #E2E8F0;
margin-top:-3px;
`
const LocateGps = styled.div`
display:flex;
padding-top:10px;
padding-left:8px;
padding-right:8px;
border-radius:8px;
margin-bottom:6px;
font-size:14px;
font-weight:600;
cursor:pointer;
margin-top:-3px;
&:hover {
    background-color:#F3F3F3;
  }
`
const Marker = styled.img`
height:15px;
width:10px;
margin-right:6px;
margin-top:1px;
`
const Chats = styled.div`
padding-left:20px;
padding-top:60px;
@media (min-width: 260px) and (max-width: 820px){
    padding-left:10px;

}
`
// const Flexdiv=styled.div`
// display:flex;
// margin-top:3px;
// `
// const Rating=styled.img`
// height:15px;
// width:15px;
// padding:1px;
// `
// const Message=styled.p`
// text-align:start;
// width:90%;
// line-height:160%;
// `
// const GoogleBox=styled.div`
// background:rgb(70, 110, 255);
// color:white;
// padding:5px;
// padding-left:10px;
// padding-right:10px;
// border-radius:6px;
// font-size:13px;
// font-weight:600;
// margin-right:10px;
// `
// const SubText=styled.p`
// color:#d2d2d2;
// font-weight:600;
// text-align:start;
// display:flex;
// `
// const GpsIcon=styled.div`
// margin-top:-2px;
// `
const InputBox = styled.input`
width:100%;
border:none;
font-size:16px;
&:focus, input:focus{
    outline: none;
}
`
const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:2px solid rgb(204, 204, 204);
  border-radius:10px;
  margin-top
border-radius:5px;
margin-left:30px;
margin-right:30px;
padding-left:10px;
padding-top:14px;
padding-bottom:14px;
padding-right:10px;

@media (min-width: 260px) and (max-width: 820px){
    margin-left:10px;
    margin-right:10px;
   
}
`

const ButtonContainer = styled.div`
  display: flex;
`

// const Button1 = styled.button`
// color:rgb(70, 110, 255);
// border:1px solid white;
// background:white;
// margin-right:10px;
// border-radius:6px;
// width:100px;
// font-weight:600;
// cursor:pointer;
// `
// const Button2 = styled.button`
// color:white;
// background:rgb(70, 110, 255);
// font-weight:600;
// border:1px solid white;
// border-radius:6px;
// padding:6px;
// width:60px;
// cursor:pointer;
// `
const OutText = styled.div`
display:flex;
margin-bottom:15px;
position: relative;
    `
const OutChat = styled.div`
background:#005454;
color:white;
border-radius:15px 15px 4px 15px;
padding:12px;
padding-bottom:3px;
padding-top:0px;
text-align:end;

word-wrap: break-word;
margin-right:20px;
margin-left:200px;
.outtime{
font-size:12px;
padding:0px;
margin-top:-8px;

}
@media (min-width: 260px) and (max-width: 820px){
    margin-left:100px;
}
`
const Messagetext = styled.p`
font-size:16px;
color:gray;
font-weight:400px;
font-style:Roobert-medium;
line-height:28px;
margin-top:5px;
`
const InChat = styled.div`

border-radius:15px 15px 15px 4px;

text-align:start;
margin-left:50px;
margin-right:30px;
.intime{
color:rgb(70, 110, 255);
font-size:12px;

}
@media (min-width: 260px) and (max-width: 820px){
    margin-right:0px;
    margin-left:30px;
}
`
const OutProfile = styled.div`
background:#e1275f;
color:white;
height:18px;
padding:13px;
padding-left:15px;
padding-right:15px
padding-bottom:0px;
margin-left:10px;
border-radius:10px;
position: absolute;
bottom: 0;
margin-bottom:3px;
`
const ProfileImage = styled.img`
border-radius:10px;
align-self:flex-start;
height:20px;
width:20px;

margin-top:17.25px;
margin-right:10px;

`
const InText = styled.div`
display:inline;
justify-content:flex-start;
margin-bottom:3px;
position:relative;
.trade{
    margin-left:20px;
    @media (min-width: 260px) and (max-width: 820px){
        margin-left:0px;
    }
}
.pink{
    background:pink;
    }
    .orange{
    background: #ff9900;
    }
    .violet{
    background:#974EDE;
    }
`