import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchTeamData, getBookings } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import Next from '../../../assets/next.png';
import Back from '../../../assets/back.png'
import cross from '../../../assets/cross.png'
import styled, { keyframes } from 'styled-components'
import CustomTooltip from '../../Tooltip/Tooltip';

import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react';
import { colorArray, formatMobileNumber } from '../../../utils/Commonfunction';
import Modal from '../../Modal';
import BookingDetails from './BookingDetails';
import { Box, Button, Divider, MenuItem, Popover, Select, Tooltip, Typography } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { useNavigate, useNavigation, useSearchParams } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const CustomTimeGrid = ({ events, localizer, date }) => {
    const times = [];
    let time = moment().startOf('day');
    while (time.isBefore(moment().endOf('day'))) {
        times.push(time.clone());
        time.add(1, 'hour');
    }

    return (
        <CustomTimeGridContainer>
            <div className="times">
                {times.map((timeSlot, idx) => (
                    <div key={idx} className="time-slot">
                        {localizer.format(timeSlot.toDate(), 'hh:mm A')}
                    </div>
                ))}
            </div>
            <div className="events">
                {events.map((event, idx) => {
                    const start = moment(event.start);
                    const end = moment(event.end);
                    const duration = moment.duration(end.diff(start)).asHours();
                    const startOffset = moment.duration(start.diff(moment().startOf('day'))).asHours();

                    return (
                        <div
                            key={idx}
                            className="event"
                            style={{
                                left: `${startOffset * 100}px`,
                                width: `${duration * 100}px`
                            }}
                        >
                            {event.title}
                        </div>
                    );
                })}
            </div>
        </CustomTimeGridContainer>
    );
};


function JobCalander(props) {
    const [isLoader, setIsLoader] = React.useState(false);
    const localizer = momentLocalizer(moment)
    const [event, setEvents] = React.useState([])
    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Week")
    const [tooltipContent, setTooltipContent] = useState('');
    const popoverRef = React.useRef(null);
    const [tooltipId, setTooltipId] = useState('');
    const [value, setValue] = useState({})
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState('week')
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);

    const handlePopoverOpen = (event, number) => {
        setAnchorEl(popoverRef.current);
        setCurrentEvent(event);
        setIsHovered(number)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCurrentEvent(null);
    };

    const open = Boolean(anchorEl);

    const handleEventClick = (event) => {

        setTooltipContent(`Event: ${event?.title}`);
        setTooltipId(`tooltip-${event?.title}`);



    };

    const fetchTeam = async (val) => {
        setSelected(val)
        if (val?.email === 'AllData') {
            setEvents(AllData)

            return
        }
        setEvents([])
        const res = await dispatch(fetchTeamData({
            "emails": [val?.email]
        }))
        let arr = []
        res.data?.[0]?.bookings.map((item) => {
            arr.push({
                data: item,
                title: item?.name || item?.to,
                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                color: '#daf0ff',
                description: item?.customerAddress,
            })
        })
        setEvents(arr)
    }

    const init = async () => {

        setIsLoader(true)
        const res1 = await dispatch(getBookings())
        let arr = []
        let teamArr = []
        const dataarr = [...res1.data?.present, ...res1.data?.past]
        dataarr?.map((item) => {
            let startdat = new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes())
            if (!isNaN(startdat)) { // Check if startdat is a valid date
                console.log(startdat, new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()));
                arr.push({
                    data: item,
                    title: item?.name || item?.to,
                    start: startdat,
                    end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                    color: '#daf0ff',
                    description: item?.customerAddress,
                    status: item?.bookingStatus
                });
            }
        })
        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index + 1 })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
        setIsLoader(false)
    }
    React.useEffect(() => {
        const invoiceParam = searchParams.get('type');
        console.log(invoiceParam, "dkparam")
        if (invoiceParam) {
            setType(invoiceParam)
            setFilter(invoiceParam?.charAt(0).toUpperCase() + invoiceParam?.slice(1))
            console.log(Views.MONTH)
        }

        init()
    }, [])
    const handleSelectEvent = (event) => {
        setValue({})
        // setIsModal(true)
        // setSelectedEvent(event?.data);
        console.log(event?.data?.referenceNo)
        // navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };



    const MyEvent = ({ event }) => {
        console.log(event, "fhjdfbdjb")


        return (
            <Tooltip

                disableTouchListener
                disableInteractive={false}
                sx={{ background: '#fff', color: '#000' }}
                placement={'left-start'}
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '350px',
                            width:'350px',
                            overflowY: 'scroll'
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff'
                        }
                    }
                }}


                title={ <EventBox onClick={(e)=>e.stopPropagation()}>
                    <Typography variant="body2" color="textSecondary" style={{ color: palette.newBlue, fontSize: '12px' }}>
                        {`${moment(event?.start).format('DD MMM, hh:mm A')} - ${moment(event?.end).format('DD MMM, hh:mm A')}`}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                        <div>
                            <EventTitle>{event?.title}</EventTitle>
                           {event?.data?.email&& <StyledTypography style={{marginBottom:'6px'}} variant="body2" color="textSecondary">
                                {formatMobileNumber(event?.data?.email)}
                            </StyledTypography>}
                            <StyledTypography variant="body2" color="textSecondary">
                                {formatMobileNumber(event?.data?.customerNumber)}
                            </StyledTypography>
                        </div>
                        <BookingStatus status={event?.data?.bookingStatus}>
                            <span style={{ marginRight: '5px' }}>●</span>
                            {event?.data?.bookingStatus === 'Waiting for confirmation' ? 'Open' : event?.data?.bookingStatus}
                        </BookingStatus>
                    </div>
                    <Divider />
                    <LocationTitle>Location</LocationTitle>
                    <StyledTypography >
                        {event?.description}
                    </StyledTypography>
                    {event?.data?.team?.length > 0 && <>
                        <Divider />
                        <LocationTitle>Crew</LocationTitle>
                        {event?.data?.team?.map(item =>
                            <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                {item?.firstName} {item?.lastName}
                            </StyledTypography>
                        )}
                    </>}
                    {event?.data?.items?.length > 0 && <>
                        <Divider />
                        <LocationTitle>Line items</LocationTitle>
                        {event?.data?.items?.map(item =>
                            <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                {item?.name} x {item?.quantity}
                            </StyledTypography>
                        )}
                    </>}
                    {/* <Button
          onClick={() => alert('Button clicked!')}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          Click Me
        </Button> */}
                </EventBox>}
            >
                <div
                    ref={popoverRef}
                    onClick={() => setIsHovered(true)}
                    style={{

                        height: '100vh',
                        width: '100%',

                        textDecoration: event?.status === "Completed" && 'line-through',
                        paddingTop: '5px',
                        paddingRight: '10px',
                        fontSize: '12px',
                        position: 'relative'
                    }}
                >
                    {event?.title}
                    <p style={{ marginBottom: '5px', marginTop: '5px', fontSize: '12px', lineHeight: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}> {event.description}</p>
                    {moment(event?.start).format('dd mm yyyy') === moment(event?.end).format('dd mm yyyy') && <p style={{ marginBottom: '5px', marginTop: '5px', fontSize: '10px', lineHeight: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}> {`${moment(event?.start).format('hh:mm A')} - ${moment(event?.end).format('hh:mm A')}`}</p>}

                </div>
            </Tooltip>
        );
    };


    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: selected?.index ? colorArray[selected?.index] : '#D6E3F6',
            borderRadius: '5px',
            padding: '6px',
            opacity: 0.8,
            color: 'black',
            fontSize: '12px',

            opacity: 1.0,
            display: 'block',
            border: '0px solid #fff',
            borderLeft: selected?.index ? `3.5px solid ${colorArray[selected?.index]}` : `3.5px solid ${palette.newBlue}`,



        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {

        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            setFilter('Week')
            setType(Views.WEEK)
            toolbar.onView('week');
        };

        const goToDay = () => {
            setFilter('Day')
            setType(Views.DAY)
            toolbar.onView('day');
        };
        const goToMonth = () => {
            setFilter('Month')
            setType(Views.MONTH)
            toolbar.onView('month');
        };
        // const goToAgendaView = () => {

        //     toolbar.onView('agenda');
        // };


        return (
            <div className="rbc-toolbar">


                <span style={{ display: 'flex', marginLeft: '20px' }} className="rbc-btn-group">
                    <button style={{ marginRight: '15px', borderRadius: '5px' }} type="button" onClick={goToToday}>
                        Today
                    </button>
                    <button style={{ width: '30px', display: 'flex', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('PREV')}>
                        <Icon src={Back} alt='addteam' />
                    </button>
                    <span style={{ alignSelf: 'center', fontSize: '14px' }} className="rbc-toolbar-label">{toolbar.label}</span>
                    <button style={{ width: '30px', display: 'flex', borderRadius: '4px', justifyContent: 'center', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                        <Icon src={Next} alt='addteam' />
                    </button>

                </span>

                <span className="rbc-toolbar-label"></span>
                <span style={{ marginRight: '20px' }} className="rbc-btn-group">
                    <Selectbox
                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                        placeholder='select' value={'Select an option'} displayEmpty
                        renderValue={() => <div>{filter}</div>} onChange={(e) => {
                            console.log(e.target)

                        }}
                        MenuProps={{ // Add MenuProps to customize menu styling
                            PaperProps: {
                                style: {
                                    marginTop: '8px', // Adjust this value for the desired gap
                                },
                            },
                        }}
                    >
                        {team?.length > 0 ? <StyledMenuItem type="button" onClick={() => {
                            setFilter('Day')
                            navigate('/dashboard/timeline')
                        }}>
                            Day
                        </StyledMenuItem> : <StyledMenuItem type="button" onClick={goToDay}>
                            Day
                        </StyledMenuItem>}

                        <StyledMenuItem type="button" onClick={goToWeek}>
                            Week
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToMonth}>
                            Month
                        </StyledMenuItem>


                        {/* <StyledMenuItem type="button" onClick={goToAgendaView}>
                        Agenda
                    </StyledMenuItem> */}
                    </Selectbox>




                </span>
            </div>
        );
    };
    const dayHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const MyTimeSlotWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '14px', marginTop: 5, borderTop: 'none', color: 'gray', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayColumnWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '14px', marginTop: 5, flexDirection: 'row', display: 'flex', borderTop: 'none', color: 'gray', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayFormat = 'dddd, MMMM D, YYYY';
    const monthFormat = 'MMMM YYYY';


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {

                backgroundColor: isToday ? 'transparent' : 'white', // Set the background color to transparent for today's date

            },
        };
    }
    const MyEventWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                background: 'none',
                borderRadius: 4,
            }}
        >
            {children}
        </div>
    );

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const handleSelectSlot = (e) => {
        console.log(e)

        props?.setValue({
            date: e?.start?.toISOString(),
            endDate: e?.end?.toISOString(),
            time: moment(e?.start?.toISOString())?.format('hh:mm A'),
            endTime: moment(e?.end?.toISOString())?.format('hh:mm A'),
            edit: true

        })
        setIsModal(true)

        // setIsModal(true)
        // `start` and `end` contain the selected range of dates

        // You can perform any actions with the selected dates here
    };
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);

    const timeGutterWraper = ({ event }) => {
        console.log(event, "dccjcdn")
        return (
            <div style={{ height: 'auto', width: '125%', fontSize: '14px', marginTop: '29px', paddingTop: '5px', borderTop: '1px solid #ddd', marginLeft: '-10px', marginRight: '-20px', color: 'gray', paddingLeft: '10px' }}>
                All day
            </div>
        )
    }


    return (
        <Body>
            {isLoader && <LoaderOverlay>
                <Loader />
            </LoaderOverlay>}

            <div style={{ display: 'flex', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={event}

                    startAccessor="start"
                    view={type}
                    onSelectEvent={handleSelectEvent}
                    selectable
                    tooltipAccessor={null}
                    eventPropGetter={eventStyleGetter}
                    dayLayoutAlgorithm={'no-overlap'}

                    endAccessor="end"
                    style={{ height: '100%', width: '100%' }}
                    timeslots={2}
                    step={15}
                    min={minTime}
                    max={maxTime}
                    popup={true}
                    onSelectSlot={handleSelectSlot}


                    formats={{
                        dayFormat: dayFormat,
                        monthHeaderFormat: monthFormat,
                        timeGutterFormat: 'h:mm A',
                        eventTimeRangeFormat: () => {
                            return ""
                        }





                    }}
                    allDayMaxRows={5}

                    allDayAccessor={(event) => console.log(event, "ckscjkcsjkncdsnjkcdsnjkdc")}
                    dayPropGetter={dayPropStyles}


                    components={{
                        toolbar: MyToolbar,
                        day: {
                            header: dayssHeaderContent
                        },


                        week: {
                            header: dayHeaderContent,






                        },

                        timeSlotWrapper: MyTimeSlotWrapper,
                        timeGutterHeader: timeGutterWraper,
                    


                        event: MyEvent,
                        eventContainerWrapper: MyEventWrapper,




                    }}
                />

            </div>
        </Body>
    );
}
export default JobCalander;
const Body = styled.div`
background:#fff;
`
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled overlay component
const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the loader is on top of other content */
`;

// Styled loader component
const Loader = styled.div`
  border: 8px solid #f3f3f3; /* Light grey background color */
  border-top: 8px solid ${palette.newBlue}; /* Blue color */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
`;

const Icon = styled.img`
height:13px;

margin-right:10px;
padding-left:8px;
color:black
`

const CalanderTime = styled(Calendar)`

.rbc-time-view .rbc-row{
    min-height:30px;
    
    max-height: calc(100vh - 200px); 
}
.rbc-day-slot {
    position:none;
}
.rbc-timeslot-group {
    max-height:200px;
  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    height: auto !important;
    display:flex;
   
    align-items:center;

}

 
  .rbc-events-container{
      overflow:hidden;
  }
  


`

const EventBox = styled(Box)`
  padding: 4px;

`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  
`;

const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Selectbox = styled(Select)`

padding-right:10px;
border-radius:8px;
text-align:start;
margin-left:10px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;
height:45px;
font-size:14px;
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const CustomTimeGridContainer = styled.div`
  .times {
    display: flex;
    justify-content: space-between;
  }
  .time-slot {
    padding: 5px;
    border: 1px solid #ddd;
    width: 100px;
    text-align: center;
  }
  .events {
    position: relative;
  }
  .event {
    position: absolute;
    top: 40px; /* Adjust this as needed */
    background-color: lightblue;
    border: 1px solid #ddd;
    padding: 5px;
  }
`;