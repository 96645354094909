import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formatMobileNumber, putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import { changePaymentMethod, fetchAllContacts, submitchangeStatus } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import FullScreenModal from "../../FullScreenModal";
import convo from '../../../assets/comment1.png'
import add from '../../../assets/add.png'
import calltel from '../../../assets/viber.png'
import RightModal from "../../RightSideModal";
import RightSideConversations from "../RightSideConversation";
import ContactDetails from "./ContactDetails";
import { DateRange } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import AddContact from "../../Settings/AddContact";
import cross from '../../../assets/cross.png'
import searchs from '../../../assets/searchs.png'
import calenda from '../../../assets/calenda.png'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Modal from "../../Modal";
import moment from "moment";
import { IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";

function FacebookLeads(props) {
    const popupRef = useRef(null);
    const detail = useSelector(state => state?.auth?.allContacts)
    const [allContact, setAllContact] = useState([])
    const [searchContact, setSearchContact] = useState([])
    const [filter, setFilter] = useState("All leads")
    const ProgressDataColor = [
        { textColor: '#045ad0', backgroundColor: '#cbf5fd' },
        { textColor: '#de8c4e', backgroundColor: '#FFF3E0' },
        { textColor: '#B02A37', backgroundColor: '#F5D7D9' },
        { textColor: '#DC3545', backgroundColor: '#F7D7DA' },
        { textColor: '#28A745', backgroundColor: '#E6F4EA' },
        { textColor: '#FF851B', backgroundColor: '#FFF0E4' }
    ];
    const [search, setSearch] = React.useState('');
    const ProgressData = [
        'Potential', 'In-Progress', 'Qualified', 'Bad fit', 'Customer', 'Not Interested'
    ]
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDetailOpen, setIsDetailOpen] = useState(false)
    const [contactDetail, setContactDetail] = useState({})
    const [customerValue, setCustomerValue] = useState({})
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [statusModalOpen, setStatusModalOpen] = useState(false)
    const [opendAddContact, setOpenAddContact] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [open, setOpen] = useState(false)
    const containerRef = useRef(null);
    const token = useSelector(state => state?.auth?.token)
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const refOne = useRef(null)
    const [selectedForm,setSelectedForm]=useState('')
    const [allDat,setAllDat]=useState([])
    const dispatch = useDispatch()
    const [alldata, setAllData] = useState([])
    const [jsonData,setJsonData]=useState([])
    const SelectForm=(item)=>{
        setLoader(true)
        fetch(`https://graph.facebook.com/v12.0/${item?.id}/leads?access_token=${jsonData.access_token}`)
        .then(response => response.json())
        .then(leadsDat => {
          // Process the leads data retrieved from the API
          console.log(leadsDat,leadsDat?.data[1]?.field_data, "datassssss");
          let val=[]
          leadsDat?.data?.map(async (item) => {
          console.log(item?.field_data)
          var vals={
            firstName:'',
            lastName:'',
            number:'',
            email:''
    
          }
          item?.field_data.map(item=>{
            if(item.name==='first_name'){
              vals={...vals,firstName:item?.values[0]}
             
            }
           else if(item.name==='last_name'){
            vals={...vals,lastName:item?.values[0]}
            }
          else  if(item.name==='phone_number'){
            vals={...vals,number:item?.values[0]}
            }
            
          else  if(item.name==='email'){
            vals={...vals,email:item?.values[0]}
            }
          })
          val?.push({name:`${vals?.firstName} ${vals?.lastName}`,email:`${vals?.email}`,number:vals?.number})
         
        })
        setAllDat(val)
         
        })
        setLoader(false)
      }
    const init = async () => {
        setLoader(true)
        const token = await localStorage.getItem("fbToken");
        const tokenVal=JSON.parse(token)
       
        console.log(JSON.parse(token), "shbdjbhccjdbcjdbjd")
        const response = await fetch(`https://graph.facebook.com/v12.0/${tokenVal.id}?fields=access_token&access_token=${tokenVal.token}&api_version=v12.0&access_token_source=TEST_USER`);
        const json = await response.json();
      setJsonData(json)
        fetch(`https://graph.facebook.com/v12.0/${tokenVal.id}/leadgen_forms?access_token=${json.access_token}`)
          .then(response => response.json())
          .then(leadsDat => {
            // Process the leads data retrieved from the API
            console.log(leadsDat, "datas");
          setAllData(leadsDat?.data)
          setSelectedForm(leadsDat?.data[0])
          setFilter(leadsDat?.data[0]?.name)
          setLoader(false)
          fetch(`https://graph.facebook.com/v12.0/${leadsDat?.data[0]?.id}/leads?access_token=${json.access_token}`)
          .then(response => response.json())
          .then(leadsDat => {
            // Process the leads data retrieved from the API
            console.log(leadsDat,leadsDat?.data[1]?.field_data, "datassssss");
            let val=[]
            leadsDat?.data?.map(async (item) => {
            console.log(item?.field_data)
            var vals={
              firstName:'',
              lastName:'',
              number:'',
              email:''
    
            }
            item?.field_data.map((item,index)=>{
              if(item.name==='first_name'){
                vals={...vals,firstName:item?.values[0]}
               
              }
             else if(item.name==='last_name'){
              vals={...vals,lastName:item?.values[0]}
              }
            else  if(item.name==='phone_number'){
              vals={...vals,number:item?.values[0]}
              }
              
            else  if(item.name==='email'){
              vals={...vals,email:item?.values[0]}
              }
              else{
                vals={...vals,[`${item?.name}`]:item?.values[0]}
              }
            })
            val?.push({name:`${vals?.firstName} ${vals?.lastName}`,...vals})
           
          })
          setAllDat(val)
          setLoader(false)
          })
        })
       
      }

    useEffect(() => {
        init()
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStatusModalOpen(false);


            }

        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setStatusModalOpen]);
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }


    const OnsubmitStauts = async (e, status) => {
        console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
        e.stopPropagation()

        const res = await dispatch(submitchangeStatus({
            "numberId": invoice?._id,
            "status": status
        }))
        init()
        setStatusModalOpen(false)
    }

    const searchFilterFunction = (text) => {
        console.log(text, "data");
        if (text) {
            const newData = allContact?.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const numberData = item.number ? item.number.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();
                return nameData.includes(searchText) || numberData.includes(searchText);
            });
            setSearchContact(newData);
            setSearch(text);
        } else {
            setSearchContact(allContact);
            setSearch(text);
        }
    };
    return (
        <>
        
            <Flexbox>



                <CustomTable>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '30px' }}>
                        <h1 style={{ textAlign: 'start' }}>Facebook leads</h1>

                    </div>
                    {loader && !detail?.invoice ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>

                        :
                        <div style={{ padding: 20 }}>
                            {/* <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
                                {["All leads", ...ProgressData]?.map((item) => <ContainerBox
                                    onClick={() => {
                                        setFilter(item)
                                        if (item == 'All leads') {
                                            setSearchContact(allContact)
                                        }
                                        else {
                                            const data = allContact?.filter(items => items?.status === item)
                                            setSearchContact(data)
                                        }
                                        // setSearchInvoice(detail?.quote)
                                    }}
                                    style={{ border: filter === item ? '1.5px solid #4169e1' : '1px solid #bbb', color: filter === item ? '#4169e1' : '#000' }}>{item}</ContainerBox>
                                )
                                }
                            </div> */}
                            <div style={{ display: 'flex', width: '100%',marginBottom:'20px',alignItems:'center', justifyContent: 'space-between' }}>

                                <CustomTextField
                                    placeholder="Search"
                                    size="small"
                                    value={search}
                                    onChange={(e)=>searchFilterFunction(e.target.value)}
                                    InputProps={{
                                        style: {
                                      
                                            paddingLeft: '10px',
                                            borderRadius:'8px',
                                            fontSize:'14px',
                                            width:'250px',
                                            marginTop:'-4px'
                                          },
                                        startAdornment: (
                                            <CustomInputAdornment position="start">
                                                <CustomIconButton>
                                                    <Icons style={{width:'15px',height:'15px'}} src={searchs} />
                                                </CustomIconButton>
                                            </CustomInputAdornment>
                                        ),
                                    
                                    }}
                                />
                                  <div style={{position:'relative',display:'flex'}}>
                                {/* <div style={{display:'flex',border:'1px solid #bbb',alignItems:'center', height:'35px',borderRadius:'8px',marginLeft:'25px',paddingLeft:'15px',paddingRight:'15px'}}>
                                    <Icons style={{width:'18px',height:'18px',marginRight:'10px'}} src={calenda}/>
                                <CustomUrl
                                    value={range1[0]?.startDate?`${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}`:'Select date'}
                                    readOnly
                                    className="inputBox"
                                   
                                    onClick={() => setOpen(open => !open)}
                                />
                                </div> */}


                                {open &&
                                    <>
                                        <StyleBox style={{ position: 'absolute',left:0, marginTop: '50px',marginLeft:'10px', background: 'white' }} ref={refOne}>
                                            <DateRange
                                                style={{ height: '220px' }}
                                                onChange={item => setRange([item.selection])}
                                                editableDateInputs={true}
                                                moveRangeOnFirstSelection={false}
                                                
                                                ranges={range}
                                                months={1}
                                                direction="horizontal"
                                                className="calendarElement"
                                            />
                                            <ButtonBox>
                                                <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                                     setOpen(false)
                                                     setRange1([{
                                                         startDate:'',
                                                         endDate:''
                                                     }])
                                                     setRange([
                                                        {
                                                            startDate: new Date(),
                                                            endDate: addDays(new Date(), 3),
                                                            key: 'selection'
                                                        }
                                                     ])
                                                     setSearchContact(allContact)
                                                     setFilter('All leads')
                                                }}>Clear</SendButton1>
                                                <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                    setOpen(false)
                                                    setRange1([{
                                                        startDate:range?.[0]?.startDate,
                                                        endDate:range?.[0]?.endDate
                                                    }])
                                                    const filteredData = allContact.filter(item => {
                                                        const itemDate = moment(item.createdAt).format('YYYY-DD-MM');
                                                        return itemDate >= moment(range?.[0]?.startDate)?.format('YYYY-DD-MM') && itemDate <= moment(range?.[0]?.endDate)?.format('YYYY-DD-MM');
                                                    });
                                                    setSearchContact(filteredData)
                                                    // const res = await dispatch(createNewCustomField({
                                                    //     "fields": [
                                                    //         newCutomField
                                                    //     ]
                                                    // }))
                                                    // setDataCustomField([...customField, newCutomField])
                                                    // setIsModalOpen1(false)
                                                }}>Apply</SendButton1>
                                            </ButtonBox>
                                        </StyleBox>
                                    </>
                                }
                              
                                  <Selectbox
                                    style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3',fontSize:'14px' }}
                                    placeholder='select' value={'Select an option'} displayEmpty
                                    renderValue={() => filter} onChange={(e) => {
                                        console.log(e.target)

                                    }}
                                    MenuProps={{ // Add MenuProps to customize menu styling
                                        PaperProps: {
                                            style: {
                                                marginTop: '8px', // Adjust this value for the desired gap
                                            },
                                        },
                                    }}
                                >
                                    {[...alldata]?.map((item, index) =>
                                        <StyledMenuItem key={index} value={index}>
                                            <FilterBox
                                               onClick={() => {
                                                setFilter(item?.name)
                                               SelectForm(item)
                                                // setSearchInvoice(detail?.quote)
                                            }}
                                                style={{ color: filter === item?.name ? '#4169e1' : '#000' }}>

                                                
                                                {item?.name}</FilterBox>
                                        </StyledMenuItem>
                                    )}
                                </Selectbox>
                                </div>
                              
                            </div>

                            <div ref={containerRef} style={{ overflowY: 'auto', height: '99vh', paddingBottom: '120px' }}>
                                <Table sx={{ width: '100%' }} aria-label="simple table">
                                    <CustomHead>
                                        <TableRow >

                                            <TableCell size='small'>
                                                <div >
                                                   First name
                                                </div>
                                            </TableCell>
                                           
                                            <TableCell style={{ width: '150px' }} size="small">
                                                    Last name
                                            </TableCell>

                                            <TableCell size="small">
                                                Phone number
                                            </TableCell>
                                            <TableCell size='small'>Email</TableCell>
                                            <TableCell size='small'>Date</TableCell>


                                        </TableRow>
                                    </CustomHead>


                                    {allDat?.map((item, index) => <>

                                        <TableBody key={index} onClick={(event) => {
                                            setContactDetail({ ...item })
                                            setIsDetailOpen(true)


                                        }} style={{ background: '#fff', cursor: 'pointer' }} >
                                            <TableRow style={{}}>
                                                <TableCell size='small'>
                                                    <div style={{ fontWeight: '500' }}>
                                                        {item?.firstName}
                                                    </div>
                                                </TableCell>
                                             
                                                <TableCell
                                                    style={{ display: 'flex',  }}
                                                    size='small'>
                                                   {item?.lastName}

                                                </TableCell>
                                                <TableCell size='small'>
                                                    {formatMobileNumber(item?.number)}

                                                </TableCell>

                                                <TableCell size='small'>
                                                    {item?.email}

                                                </TableCell>

                                                <TableCell size='small'> {item?.createdAt ? moment(item?.createdAt)?.format('DD MMM YYYY') : moment(new Date())?.format('DD MMM YYYY')}</TableCell>

                                            </TableRow>

                                        </TableBody>
                                        {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>

                                            <StyledPopupContainer ref={popupRef} style={{
                                                top: popupPosition.y - 120,
                                                left: popupPosition.x,

                                            }}>
                                                <StyledPopupContent >
                                                    <StyledPopupItems>

                                                        {
                                                            ProgressData?.map((items, index) =>
                                                                <PopupValues onClick={e => {
                                                                    OnsubmitStauts(e, items)
                                                                    // setDatadet(item)
                                                                    // OnsubmitStauts(e, items)
                                                                }}>
                                                                    <a style={{ fontSize: '16px', color: ProgressDataColor[index]?.textColor, marginRight: '8px' }}>{`●  `}</a>
                                                                    {items}</PopupValues>
                                                            )
                                                        }
                                                    </StyledPopupItems>

                                                </StyledPopupContent>
                                            </StyledPopupContainer>


                                        </div>}
                                    </>

                                    )}

                                </Table>
                                {allDat?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No leads</h3>}

                            </div>
                        </div>
                    }

                </CustomTable>

            </Flexbox>
            {isDetailOpen && <FullScreenModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <div style={{ display: 'flex' }}>
                    <ContactDetails
                        setModal={() => setIsDetailOpen(false)}
                        value={contactDetail}
                        setConnection={(call) => {
                            props?.setConnection(call)
                        }}
                    />
                </div>
            </FullScreenModal>}

            {modalOpen && <RightModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <div style={{ height: '100%', display: 'flex' }}>
                    <RightSideConversations setConnection={(call) => {
                        // setConnection(call)
                    }} value={customerValue} />
                </div>
            </RightModal>}
            {opendAddContact && <Modal
                onClose={() => setOpenAddContact(false)}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                    <h2>Add contact</h2>

                    <CloseButton onClick={() => setOpenAddContact(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                </div>
                <AddContact
                    onCancel={() => {
                        init()
                        setOpenAddContact(false)
                    }}
                />
            </Modal>}
        
        </>
    )


}


export default FacebookLeads;
const Body = styled.div`



`
const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 250px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
width:90%;
padding:12px;
cursor:pointer;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const PDFContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#fff;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const DotContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
align-items:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px; 
padding:5px;
background:#fff;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const CustomTable = styled(TableContainer)`
border-color: red;
.MuiTableBody-root {
  
    border-bottom: none !important; /* Add !important */
 }
 .MuiTableRow-root {
   
    height: 30px; 
    border-bottom: none !important;
    &:hover {
        background:#f3f3f3;
      }
  }
 .MuiTableCell-root{
    border-color: #f3f3f3;
    height: 32px;  

  
   
   
}
  
.purple{
background:#974EDE;
}
.green{
background:#32cd32;
}
.dimgreen{
background:#008000;
}

`

const CustomHead = styled(TableHead)`

border-radius:8px;


`
const Flexbox = styled.div`
width:100%;
background:#fff;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;

align-items:center;

font-weight:500;
cursor:pointer;

`

const Icon1 = styled.img`
height:15px;
width:15px;


`
const FlexDiv = styled.div`
display:flex;
align-items:center;
`
const IconBox = styled.div`
width:25px;
height:25px;
display:flex;
background: #f3f3f3;
justify-content:center;
align-items:center;
padding:3px;
border-radius:5px;
margin-left:8px;
margin-right:8px;
color:white;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const OptionBox = styled.div`
width:150px;
height:35px;
border-radius:8px;
font-weight:600;
font-size:14px;
border:1px solid #bbb;

display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const CustomTextField = styled(TextField)`
border-radius: 8px;
&& {
   
    height: 30px; /* Adjust the height as needed */
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;
const Selectbox = styled(Select)`
width:200px;
border-radius:8px;
text-align:start;
margin-left:15px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const CustomUrl = styled.input`
background: #FFF;
border-radius: 8px;
cursor:pointer;
border: 1px solid #fff;
height: 30px;

&:focus {
    outline: none;
    border: none; /* Remove border on focus */
  }
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #f3f3f3;
background:white;
width:350px;
margin-top:30px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
