const initialState = {
      loginToken: '',
      userId: '',
      dispnum: '',
      buynum: '',
      payment: '',
      locationdetails: '',
      convo: '',
      globenum: '',
      outchats: '',
      inchats: '',
      globecolor: 'pink',
      globename: '',
      sendMessage: '',
      activemenu: 'Get Started',
      quote: '',
      invoice: '',
      invoiceQuote: {},
      userNameData: {},
      allContacts: [],
      userData: {},
      userDetails: {},
      contactDetail: {},
      token: {},
      callDetail: {},
      showCall: false,
      showIncomingCall: false,
      incominDetail: null,
      chatSelect: {},
      connection: null,
      unreadCount: {}
}

const authReducers = (state = initialState, action) => {
      switch (action.type) {
            case 'LOGIN_TOKEN':
                  return ({ ...state, loginToken: action.payload })
            case 'USERID':
                  return ({ ...state, userId: action.payload })
            case 'DISPNUMBER':
                  return ({ ...state, dispnum: action.payload })
            case 'BUYNUMBER':
                  return ({ ...state, buynum: action.payload })
            case 'PAYMENT':
                  return ({ ...state, payment: action.payload })
            case 'LOCATION':
                  return ({ ...state, locationdetails: action.payload })

            case 'CONVO':
                  return ({ ...state, convo: action.payload })
            case 'OUTCHATS':
                  return ({ ...state, outchats: action.payload })
            case 'INCHATS':
                  return ({ ...state, inchats: action.payload })
            case 'SENDMESSAGE':
                  return ({ ...state, sendMessage: action.payload })
            case 'GLOBENUM':
                  return ({ ...state, globenum: action.payload })
            case 'ACTIVEMENU':
                  return ({ ...state, activemenu: action.payload })
            case 'QUOTE':
                  return ({ ...state, quote: action.payload })
            case 'INVOICE':
                  return ({ ...state, invoice: action.payload })
            case 'USERNAME':
                  return ({ ...state, userNameData: action.payload })
            case 'GLOBECOLOR':
                  return ({ ...state, globecolor: action.payload })
            case 'GLOBENAME':
                  return ({ ...state, globename: action.payload })
            case 'ALLCONTACTS':
                  return ({ ...state, allContacts: action.payload })
            case 'USERDATA':
                  return ({ ...state, userData: action.payload })
            case 'USERDETAILS':
                  return ({ ...state, userDetails: action.payload })

            case 'QUOTEINVOICE':
                  return ({ ...state, invoiceQuote: action.payload })

            case 'CONTACTDETAIL':
                  return ({ ...state, contactDetail: action.payload })
            case 'CALLTOKEN':
                  return ({ ...state, token: action.payload })
            case 'CALLDETAIL':
                  return ({ ...state, callDetail: action.payload })
            case 'CALLSHOW':
                  return ({ ...state, showCall: action.payload })
            case 'INCOMINGCALLSHOW':
                  return ({ ...state, showIncomingCall: action.payload })
            case 'INCOMINGCALLDETAIL':
                  return ({ ...state, incominDetail: action.payload })
            case 'CHATSELECT':
                  return ({ ...state, chatSelect: action.payload })
            case 'CONNECTION':
                  return ({ ...state, connection: action.payload })
            case 'UNREADCOUNT':
                  return ({ ...state, unreadCount: action.payload })

            default:
                  return state;
      }

}

export default authReducers;

