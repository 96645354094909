import { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReportTemplate from './ReportTemplate';
import styled from 'styled-components';
import InvoiceReport from './InvoiceReport';
import html2canvas from 'html2canvas';
import { Image, Text, View, Page, Document, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import NewInvoice from './NewInvoice';
import { useParams } from 'react-router-dom';
import { getInvoiceData } from '../store/Actions/Auth.action';
import { useDispatch, useSelector } from 'react-redux';
import NewPreviewInvoice from './NewPreviewInvoice';
import { totalprice } from '../utils/Commonfunction';
// import { Button } from '@mui/material';
function Quote(props) {
  const reportTemplateRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false)
  const [error, setError] = useState(null);
  const [taxval, setTaxVal] = useState([])
  const dispatch=useDispatch()
  let { id } = useParams()
  const printDocument = () => {
    const input = document.getElementById('divToPrint');

    html2canvas(input, { scale: 2 }) // Adjust scale as needed
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
        //pdf.addImage(userData?.logoImage,0,0,50,50)
        pdf.save("download.pdf");
      })
      .catch((error) => {
        setError('Error generating PDF: ' + error.message);
      });
  };
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: 'P',
      format: 'a3',
      unit: 'pt',
    });
    doc.setFont('Inter-Regular', 'normal');

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save('document');
        window.open(doc.output('bloburl'))
      },
      width: 800,
      windowWidth: 800
    });
  };
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=0.5';

    const head = document.head;
    head.appendChild(meta);

    return () => {
      head.removeChild(meta);
    };
  }, []);
  const quotedata = useSelector(state => state?.auth?.invoice)
  const userData = useSelector(state => state?.auth?.userNameData)
	const flattenItems = (items) => {
    return items?.reduce((acc, item) => {
        if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
            acc.push(...item.lineItems);
        } else {
            acc.push(item);
        }
        return acc;
    }, []);
};
const calc = () => {
    const totalTax = {};
    const totalPrices = {}; // To accumulate the total prices for each tax
    const discountAmount = parseFloat(applyDiscount()) || 0;
    const items = flattenItems([...quotedata?.items]);

    // Step 1: Calculate the total price of all items
    const totalOriginalPrice = items.reduce((acc, item) => {
        const price = parseFloat(item.price);
        const quantity = parseInt(item.quantity);
        if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
            return acc;
        }
        return acc + (price * quantity);
    }, 0);

// Step 2: Calculate the weightage and apply the discount accordingly
items.forEach(item => {
const taxName = item.taxName;
const taxAmount = parseFloat(item.tax);
const taxNumber = item?.taxNumber || '';
const price = parseFloat(item.price);
const quantity = parseInt(item.quantity);

if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
    return;
}

const totalPrice = price * quantity;
const weightage = totalPrice / totalOriginalPrice;
const discountForItem = discountAmount * weightage;
const discountedPrice = totalPrice - discountForItem;

if (!isNaN(taxAmount) && taxAmount > 0) {
    const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places

    if (totalTax.hasOwnProperty(taxName)) {
        totalTax[taxName].taxAmount += taxValue;
        totalTax[taxName].taxPercentage = taxAmount;
        totalTax[taxName].taxNumber = taxNumber;
        totalPrices[taxName] += discountedPrice;
    } else {
        totalTax[taxName] = {
            taxAmount: taxValue,
            taxName,
            taxPercentage: taxAmount,
            taxNumber
        };
        totalPrices[taxName] = discountedPrice;
    }
}
});

const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
taxName,
taxAmount: parseFloat(taxAmount.toFixed(2)), // Ensure rounding to 2 decimal places
taxNumber,
taxPercentage,
totalPrice: parseFloat(totalPrices[taxName].toFixed(2)) // Ensure rounding to 2 decimal places
}));

setTaxVal(totalTaxArray);
    console.log(totalTaxArray, "Tax Values");
};
  const init = async () => {
    await dispatch(getInvoiceData({ referenceNo: id }))
   
}
const applyDiscount = () => {
  var discountedTotalValue = 0
  let discountPercentage = 0;
  if (quotedata?.discountType === '$') {
      discountedTotalValue = parseFloat(quotedata?.discount);
      discountPercentage = (quotedata?.discount / (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }, 0)?.toFixed(2)) * 100)
  }
  else if (quotedata?.discountType === '%') {
      discountedTotalValue = (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
          return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
      }, 0)?.toFixed(2) * (quotedata?.discount / 100));
      discountPercentage = parseFloat(quotedata?.discount);
  }

  return discountedTotalValue.toFixed(2);
};
useEffect(() => {
  if ( quotedata) {
      calc();
  }
}, [quotedata]);

useEffect(() => {
    init()
}, [])

  return (
    <MainContainer>
     <div style={{ width:'100%',display:'flex',justifyContent:'center',background:'#fafafa' }}>
				{/* <CustomButton onClick={()=>handlePdf()}>
					⬇	Download
				</CustomButton> */}
				<div style={{ display: 'flex',width:'230mm',marginBottom:'20px',marginRight:'15px', alignSelf: 'flex-end', justifyContent: 'flex-end',  }}>
				{quotedata?.phone && <CustomButton   document={<NewInvoice
					data={quotedata}
					userData={userData}
				
				/>} fileName='invoice.pdf'>
					<div >

						<span>⬇	Download</span>
					</div>
				</CustomButton>}
				</div>
			</div>
     {/* {quotedata?.phone&& <PDFViewer height={800} width={800}  style={{border:'1px solid #fff',backgroundColor:'white'}} showToolbar={false}>
      <NewInvoice
              data={quotedata}
              userData={userData}
              />
      </PDFViewer >} */}
    	{quotedata?.phone&&<div  id='divToPrint' ref={reportTemplateRef}>
				<InvoiceReport
        taxval={taxval}
        />
			</div>}
      
    </MainContainer>

  );
}

export default Quote;
const MainContainer = styled.div`
background:#fafafa;
height:100vh;
overflow:scroll;
@media (min-width: 900px) {
    body {
      zoom: 100%;
    }
  }

`

const CustomButton = styled(PDFDownloadLink)`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
align-self:flex-end;
color:white;
font-size:16px;
background:#4169E1;
text-decoration: none; 
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
`