import { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReportTemplate from './ReportTemplate';
import styled from 'styled-components';
import customFont from '../../src/assets/fonts/MonsieurLaDoulaise-Regular.ttf'
import { useDispatch, useSelector } from 'react-redux';
import { getQuotedata, saveSign } from '../store/Actions/Auth.action';
import { useLocation, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import NewInvoice from './NewInvoice';
import { totalprice } from '../utils/Commonfunction';
import { Player } from '@lottiefiles/react-lottie-player';
// import { Button } from '@mui/material';

function Quote() {
	const dispatch = useDispatch()
	let location = useLocation()
	const searchParams = new URLSearchParams(location.search);

	const quotedata = useSelector(state => state?.auth?.quote)
	const userData = useSelector(state => state?.auth?.userNameData)
	const [taxval, setTaxVal] = useState([])
	const referenceNo = searchParams.get('referenceNo');
	const [error, setError] = useState(null);
	const email = searchParams.get('email');
	let { id } = useParams()
	const [loader, setloader] = useState(true)
	
	const getToken = () => {
		const token = localStorage.getItem('sign')
		console.log(token, "ddjhbj")
		return token ? ` ${token}` : ""
	}
	const flattenItems = (items) => {
        return items?.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };
	const calc = () => {
        const totalTax = {};
        const totalPrices = {}; // To accumulate the total prices for each tax
        const discountAmount = parseFloat(applyDiscount()) || 0;
        const items = flattenItems([...quotedata?.items]);

        // Step 1: Calculate the total price of all items
        const totalOriginalPrice = items.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity);
            if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
                return acc;
            }
            return acc + (price * quantity);
        }, 0);

  // Step 2: Calculate the weightage and apply the discount accordingly
  items.forEach(item => {
    const taxName = item.taxName;
    const taxAmount = parseFloat(item.tax);
    const taxNumber = item?.taxNumber || '';
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity);

    if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
        return;
    }

    const totalPrice = price * quantity;
    const weightage = totalPrice / totalOriginalPrice;
    const discountForItem = discountAmount * weightage;
    const discountedPrice = totalPrice - discountForItem;

    if (!isNaN(taxAmount) && taxAmount > 0) {
        const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places

        if (totalTax.hasOwnProperty(taxName)) {
            totalTax[taxName].taxAmount += taxValue;
            totalTax[taxName].taxPercentage = taxAmount;
            totalTax[taxName].taxNumber = taxNumber;
            totalPrices[taxName] += discountedPrice;
        } else {
            totalTax[taxName] = {
                taxAmount: taxValue,
                taxName,
                taxPercentage: taxAmount,
                taxNumber
            };
            totalPrices[taxName] = discountedPrice;
        }
    }
});

const totalTaxArray = Object.entries(totalTax)?.map(([taxName, { taxAmount, taxPercentage, taxNumber }]) => ({
    taxName,
    taxAmount: parseFloat(taxAmount.toFixed(2)), // Ensure rounding to 2 decimal places
    taxNumber,
    taxPercentage,
    totalPrice: parseFloat(totalPrices[taxName].toFixed(2)) // Ensure rounding to 2 decimal places
}));

setTaxVal(totalTaxArray);
        console.log(totalTaxArray, "Tax Values");
    };
	const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (quotedata?.discountType === '$') {
            discountedTotalValue = parseFloat(quotedata?.discount);
            discountPercentage = (quotedata?.discount / (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (quotedata?.discountType === '%') {
            discountedTotalValue = (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (quotedata?.discount / 100));
            discountPercentage = parseFloat(quotedata?.discount);
        }

        return discountedTotalValue.toFixed(2);
    };
	const init = async () => {
		await dispatch(getQuotedata({ referenceNo: id }))
		setloader(false);
		if (!loader && getToken()) {
			try {
				
				pdf(<NewInvoice
					data={quotedata}
					userData={userData}
					type={'Quote'}
				/>).toBlob().then(async blob=>{
					const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });
					const res = await dispatch(saveSign(pdfFile, id, email))
					console.log(blob,"blob")
				})

		

			} catch (error) {
				setError('Error generating PDF: ' + error.message);
			}

		}
	}
	useEffect(() => {
        if (!loader && quotedata) {
            calc();
        }
    }, [loader, quotedata]);

	useEffect(() => {
		init()
	}, [loader])

	const reportTemplateRef = useRef(null);
	const printDocument = () => {
		const input = document.getElementById('divToPrint');
		html2canvas(input, { scale: 2 }) // Adjust scale as needed
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format
				const pdf = new jsPDF('p', 'mm', 'a4');
				const imgWidth = pdf.internal.pageSize.getWidth();
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
				//pdf.addImage(userData?.logoImage,0,0,50,50)
				pdf.save("download.pdf");
			})
			.catch((error) => {
				setError('Error generating PDF: ' + error.message);
			});
		init()
	};
	const handleGeneratePdf = () => {
		let blob
		const doc = new jsPDF({
			orientation: 'P',
			format: 'a3',
			unit: 'pt',
		});
		doc.addFileToVFS(customFont);
		doc.addFont('Monsieur', 'custom', 'normal');
		doc.setFont('Monsieur');
		doc.text('Total', 10, 20);
		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				blob = new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				});

				const res = await dispatch(saveSign(blob, id, email))
				console.log(new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				}))
				await doc.save('document');
				window.open(doc.output('bloburl'))
				// const pdfDataUri = doc.output('datauristring');
				// window.open(pdfDataUri);
			},
			width: 800,
			windowWidth: 800
		});
		// const pdfs = new File([doc.output("blob")], "myDoc.pdf", {
		// 	type: "application/pdf",
		// });
		// console.log(pdfs, "djfhhjf")

	};
	const handlePdf=()=>{
		pdf(<NewInvoice
			data={quotedata}
			userData={userData}
			type={'Quote'}
		/>).toBlob().then(async blob=>{
			const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });
			const res = await dispatch(saveSign(pdfFile, id, email))
			console.log(blob,"blob")
		})
	}
	useEffect(() => {
		const meta = document.createElement('meta');
		meta.name = 'viewport';
		meta.content = 'width=device-width, initial-scale=0.5';

		const head = document.head;
		head.appendChild(meta);

		return () => {
			head.removeChild(meta);
		};
	}, []);
	return (
		<MainContainer>
		{	<>
			<div style={{ width:'100%',display:'flex',justifyContent:'center',background:'#fafafa' }}>
				{/* <CustomButton onClick={()=>handlePdf()}>
					⬇	Download
				</CustomButton> */}
				<div style={{ display: 'flex',width:'230mm',marginBottom:'20px',marginRight:'15px', alignSelf: 'flex-end', justifyContent: 'flex-end',  }}>
				{quotedata?.phone && <CustomButton  onClick={()=>handlePdf()} document={<NewInvoice
					data={quotedata}
					userData={userData}
					type={'Quote'}
				/>} fileName='Quote.pdf'>
					<div >

						<span>⬇	Download</span>
					</div>
				</CustomButton>}
				</div>
			</div>
		
   {/* {quotedata?.phone&& <PDFViewer height={800} width={800}  style={{border:'1px solid #fff',backgroundColor:'white'}} showToolbar={false}>
      <NewInvoice
              data={quotedata}
              userData={userData}
              />
      </PDFViewer >} */}
			<div id="divToPrint" ref={reportTemplateRef}>
				<ReportTemplate
					setloader={() => setloader(false)}
					taxval={taxval}
				/>
			</div>
			</>}

		</MainContainer>
	);
}

export default Quote;
const MainContainer = styled.div`
overflow:scroll;
background:#fafafa;
height:100vh;

@media (min-width: 768px) {
	
    body {
		zoom: 100%;
    }
  }


`
const CustomButton = styled(PDFDownloadLink)`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
align-self:flex-end;
color:white;
font-size:16px;
background:#4169E1;
text-decoration: none; 
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
`