import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import cross from '../../assets/cross.png'
import { Form } from 'react-bootstrap'
import { useState } from "react";
import { calculateTotalTaxAmount, formatMobileNumber, putCommaInvalue, taxprice, totalprice } from "../../utils/Commonfunction";
import { editInvoice, fetchAllContacts, getInvoiceQuote, sendBookingInvoice, sendInvoice, UploadNotesImage } from "../../store/Actions/dashboard.action";
import Autocomplete from 'react-google-autocomplete';
import PreviewInvoice from "../../Pages/PreviewInvoice";
import { Checkbox, Menu, InputAdornment, MenuItem, Radio, Select, TextField, ToggleButton, ToggleButtonGroup, ListItemIcon } from "@mui/material";
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { AddBoxOutlined, AddCircle, Cancel, Contacts, Delete, DeleteOutlined, DragIndicatorOutlined, Edit, Home, LocationOn, LoginOutlined, MoreHoriz, Person, Phone, Search, SwitchAccount } from "@mui/icons-material";
import { palette } from "../../utils/Palette";
import TopModal from "../TopModal";
import DatePickerComponent from "../../ReuseableComponets/DatePickerComponent";
import RippleButton from "../../ReuseableComponets/RippleButton";
import InputPicker from "../../ReuseableComponets/InputPicker";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditBundle from "./Payments/EditBundle";
const filter = createFilterOptions();
function CreateInvoice(props) {

    const userdata = useSelector(state => state?.auth?.userData)
    const lineItem = useSelector(state => state?.auth?.userData?.lineItem)
    const detailContact = useSelector(state => state?.auth?.allContacts)
    const [isFocused, setIsFocused] = useState(false)
    const mainRef = useRef(null);
    const addLineFieldRef = useRef(null);
    const autocompleteRef = useRef(null);
    const tax = useSelector(state => state?.auth?.userData?.tax)
    const bundleItem = useSelector(state => state?.auth?.userData?.bundleData)
    const [value2, setValue2] = useState(null)
    const invoicenum = useSelector(state => state?.auth?.userData?.numberOfInvoice)

    const dispatch = useDispatch()
    const [selectedContact, setSelectedContact] = useState(props?.value?.tempUserDetails || [])
    const [isswitch, setisswitch] = useState(false)
    const [editInvoiceData, setEditInvoiceData] = useState(props?.value?.edit)
    const [value, setValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",
        "phoneNumber": props?.value?.customerPhoneNumber || props?.value?.number || props?.value?.to,
        "notes": 'On Receipt',
        "date": props?.value?.date || new Date(),
        'invoiceNumber': props?.value?.numberOfInvoice || invoicenum + 1 || '',
        'currency': userdata?.value?.currency || 'CAD',
        "acceptPaymentOnline": props?.value?.acceptPaymentOnline || "",
        "notesInvoice": props?.value?.notesInvoice || "",
        deposit: props?.value?.numberOfInvoice && props?.value?.deposit || '',
        depositType: "",
        discount: props?.value?.discount || "",
        discountType: props?.value?.discountType || "",
        serviceAddress: props?.value?.serviceAddress || "",
    })
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",

    })
    const [addOwnerData, setAddOwnerData] = useState(props?.value?.items || [])
    const [editDetail, setEditDetail] = useState(false)
    const [contactData, setContactData] = useState(detailContact?.length > 0 ? detailContact : [])
    const [addMaterialData, setAddMaterialData] = useState([])
    const [boolValue, setBoolValue] = useState({})
    const [isAddMaterialLine, setIsAddMaterialline] = useState(false)
    const [modaBundleOpen, setmodaBundleOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(props?.value?.attachment || []);
    const [isEditMaterial, setIsEditMaterial] = useState(false)
    const [discount, setDiscount] = useState({
        value: '',
        type: '$'
    })
    const [deposit, setDeposit] = useState({
        value: props?.value?.deposit || userdata?.deposit || '',
        type: props?.value?.depositType || userdata?.depositType || "",
    })
    const [isEdit, setIsEdit] = useState(false)
    const [invoicedata, setInvoiceData] = useState({
        "name": "",
        "price": "",
        "description": "",
        "quantity": "1",
        "tax": "0",
        "taxName": "no Tax",
        "taxNumber": '',
        "optional": false
    })
    const [isAddLine, setIsAddline] = useState(false)
    const [switchAccount, setSwitchAccount] = useState(true)
    const [defaultTax, setDefaultTax] = useState({
        name: '',
        value: '',
        default: true,
        index: 0

    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const addCustomFiled = () => {
        setAddOwnerData([...addOwnerData, invoicedata])
        setInvoiceData({
            "name": "",
            "price": "",
            "description": "",
            "quantity": "1",
            "tax": "0",
            "taxName": "no Tax",
            "taxNumber": ''
        })
        setIsAddline(false)
        setIsEdit(false)
    }
    const addCustomMaterialFiled = () => {
        setAddMaterialData([...addMaterialData, invoicedata])
        setInvoiceData({
            "name": "",
            "price": "0",
            "description": "",
            "quantity": "1",
            "tax": "0",
            "taxName": "no Tax",
        })
        setIsAddMaterialline(false)
        setIsEditMaterial(false)
    }
    const init = async () => {
        const res1 = await dispatch(fetchAllContacts())
        setContactData(res1?.data)
    }
    useEffect(() => {
        init()
    }, [])
    const deleteHandler = (index) => {

        const data = [...addOwnerData]
        data.splice(index, 1)
        setAddOwnerData(data)


    }
    useEffect(() => {
        if (isFocused && addLineFieldRef.current && mainRef.current) {
            addLineFieldRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
        else {
            if (mainRef.current) {
                mainRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [isFocused]);
    const onchangeMaterials = (text, index, value) => {

        const data = [...addOwnerData]
        data[index][text] = value;
        setAddOwnerData(data);
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.toString()?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }
    const imageHandleChange = async (e) => {
        const FileList = e.target.files
        const imageList = []

        Object.keys(FileList).map((item, index) => {
            const fileURL = URL.createObjectURL(FileList[item])
            imageList.push(fileURL)

        })
        const res = await dispatch(UploadNotesImage([...FileList]));
        setSelectedImage([...selectedImage, ...res?.data])
    }
    const onPress = async (draft) => {

        const val = {
            ...value, ...editValue, "date": '', "time": '', ...editValue, "phone": value?.phoneNumber, "items": addOwnerData, "total": `${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}`, date: value?.date, "attachment": selectedImage
        }
        if (draft?.draft) {
            if (props?.value?.edit) {
                const res1 = await dispatch(editInvoice({ ...val, referenceNo: props?.value?.invoiceNo, invoiceDraft: true }))
                if (res1.status === true) {
                    await dispatch(getInvoiceQuote())
                    props?.setModalOpenInvoice(false)
                }
            }
            else if (props?.booking) {
                const res = await dispatch(sendBookingInvoice({ ...val, bookingRef: props?.value?.referenceNo, bookingInvoice: true, invoiceDraft: true }))
                if (res.status === true) {

                    props?.setModalOpenInvoice(false)

                }
            }

            else {
                const res = await dispatch(sendInvoice({ ...val, invoiceDraft: true }))
                if (res.status === true) {
                    await dispatch(getInvoiceQuote())
                    props?.setModalOpenInvoice(false)
                }
            }
        }
        else {
            if (props?.value?.edit) {
                const res1 = await dispatch(editInvoice({ ...val, referenceNo: props?.value?.invoiceNo }))
                if (res1.status === true) {
                    props?.onSubmitsw(`Please find the invoice attached from ${userdata?.businessName} for $${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}. https://app.getnotifi.com/invoice/${res1?.data?.newNumber}`, val)
                }
            }
            else if (props?.booking) {
                const res = await dispatch(sendBookingInvoice({ ...val, bookingRef: props?.value?.referenceNo, bookingInvoice: true }))
                if (res.status === true) {
                    props?.onSubmitsw(`Please find the invoice attached from ${userdata?.businessName} for $${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}. https://app.getnotifi.com/invoice/${res?.data?.referenceNo}`, val)

                }
            }
            else {
                const res = await dispatch(sendInvoice(val))
                if (res.status === true) {
                    props?.onSubmitsw(`Please find the invoice attached from ${userdata?.businessName} for $${([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    }, 0) - discountvalue() + calculateTotalTaxAmount([...addOwnerData], discountvalue()))?.toFixed(2)}. https://app.getnotifi.com/invoice/${res?.data?.referenceNo}`, val)
                }
            }
        }

    }
    const formatDate = (dates) => {
        if (!dates) return ""; // Handle case when date is null or undefined
        const date = new Date(dates)
        const year = date?.getFullYear();
        const month = String(date?.getMonth() + 1).padStart(2, "0");
        const day = String(date?.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    function validateInput(event) {
        if (event.key === 'e') {
            event.preventDefault(); // Prevent typing negative sign, exponent, or decimal point
        }
    }
    function validateInput1(event) {
        if (event.key === '-' || event.key === 'e' || event.key === '.') {
            event.preventDefault(); // Prevent typing negative sign, exponent, or decimal point
        }
    }
    function calculateMarkupPercentage(costPrice, unitPrice) {

        const markupPercentage = ((unitPrice - costPrice) / costPrice) * 100;
        return parseFloat(markupPercentage)?.toFixed(2);
    }
    const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (value?.discountType === '$') {
            discountedTotalValue = parseFloat(value?.discount);
            discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (value?.discountType === '%') {
            discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (value?.discount / 100));
            discountPercentage = parseFloat(value?.discount);
        }

        return `${discountedTotalValue.toFixed(2)} (${discountPercentage?.toFixed(2)} %)`;
    };
    const discountvalue = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (value?.discount) {
            if (value?.discountType === '$') {
                discountedTotalValue = parseFloat(value?.discount);
                discountPercentage = (value?.discount / ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2)) * 100)
            }

            else if (value?.discountType === '%') {
                discountedTotalValue = ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                }, 0)?.toFixed(2) * (value?.discount / 100));
                discountPercentage = parseFloat(value?.discount);
            }
        }

        return discountedTotalValue.toFixed(2);
    };
    const applyDeposit = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (deposit?.type === '$') {
            discountedTotalValue = parseFloat(deposit?.value);
            discountPercentage = (deposit?.value / ([...addOwnerData]?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
            }, 0)?.toFixed(2) - discountvalue()) * 100)
        }

        else if (deposit?.type === '%') {
            discountedTotalValue = ((([...addOwnerData]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
            }, 0)?.toFixed(2) - discountvalue()) * (deposit?.value / 100));
            discountPercentage = parseFloat(deposit?.value);
        }

        return discountedTotalValue.toFixed(2);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const draggedItem = addOwnerData[result.source.index];
        const remainingItems = [...addOwnerData];
        remainingItems.splice(result.source.index, 1);
        remainingItems.splice(result.destination.index, 0, draggedItem);

        setAddOwnerData(remainingItems);

        if (editInvoiceData) {
            setEditInvoiceData(false)
        }

    };
    const flattenItems = (items) => {
        return items.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const calculateTotalAmount = (items) => {
        const flattenedItems = flattenItems(items);
        return `${([...flattenedItems].reduce(function (accumulator, currentValue) {
            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
        }, 0) - discountvalue() + calculateTotalTaxAmount([...flattenedItems], discountvalue()))?.toFixed(2)}`
    };

    const handleSearchContact = useMemo(() =>(inputValue, options) => {
        const lowerInput = inputValue?.toLowerCase();
        return options?.filter(option => {
            const nameMatch = option?.name?.toLowerCase().includes(lowerInput) || false;
            const numberMatch = option?.number?.toLowerCase().includes(lowerInput) || false;
            const businessMatch = option?.businessName?.toLowerCase().includes(lowerInput) || false;
            return nameMatch || numberMatch || businessMatch;
        });
    }, []);
    return (
        <Main ref={mainRef} isFocused={isFocused} style={{ backgroundColor: isFocused && 'rgba(255, 255, 255, 0.5)' }}>
            <HeaderContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CloseButton onClick={() => props?.setModalOpenInvoice(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                    <h4 style={{ color: palette.charcoleBlack }}>{props?.value?.edit ? "Edit invoice" : "Invoice"}</h4>
                </div>
                <div style={{ display: 'flex', paddingRight: '40px' }}>
                    {!props?.value?.edit && <RippleButton style={{ background: '#fff', color: '#000', border: '1px solid #ddd', marginRight: '10px' }} onClick={() => onPress({ draft: true })}>Save</RippleButton>}
                    {props?.value?.edit ? <RippleButton onClick={() => !editInvoiceData ? onPress({ draft: false }) : setEditInvoiceData(false)}>{!editInvoiceData ? "Send" : 'Edit'} </RippleButton> : <RippleButton onClick={() => onPress({ draft: false })}>Send </RippleButton>}
                </div>
            </HeaderContainer>
            <Flexbox>
                <CustomTable>
                    <MainContainer>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }} >
                            <div style={{ justifyContent: 'space-between', width: '100%', alignItems: 'flex-start', display: 'flex', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Label>Due Date</Label>
                                    <DueDateForm Value={value?.notes} style={{ marginTop: '0px' }} onChange={(event) => setValue({ ...value, notes: event.target.value })}>

                                        {['On Receipt',
                                            'No Due Date'

                                            , '10 days'
                                            ,
                                            '15 days'
                                            ,
                                            'NET 30',
                                            'NET 60',
                                            'NET 90'

                                        ].map((item, index) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })}

                                    </DueDateForm>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                                    <DatePickerComponent
                                        label="Date"
                                        selectedDate={value?.date}
                                        onDateChange={(text) => setValue({
                                            ...value, date: text
                                        })}
                                    />
                                    <div style={{ display: 'flex', width: '150px', flexDirection: 'column', marginTop: '10px', alignItems: 'flex-start' }}>
                                        <Label>Invoice number</Label>
                                        <Inputbox
                                            type="text"
                                            value={value?.invoiceNumber}
                                            placeholder='Invoice number'
                                            readOnly={!editInvoiceData ? false : true}
                                            style={{ width: '100%', paddingLeft: '10px', boxSizing: 'border-box' }}
                                            onChange={(e) => {
                                                setValue({
                                                    ...value, invoiceNumber: e.target.value
                                                })
                                            }}
                                        // onBlur={() => onClick("email")}
                                        />
                                    </div>
                                </div>

                            </div>
                            <h4>Customer</h4>
                            {
                                props?.edit ? switchAccount && <div style={{ marginTop: '10px', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                                    <Autocomplete1
                                        value={value2}
                                        onChange={(event, newValue) => {

                                            const searchString = 'Add new contact';
                                            const replacement = '';
                                            const result = newValue?.name
                                            console.log(newValue, "valueee")

                                            if (newValue && newValue?.name?.includes(searchString)) {

                                            }

                                            else if (typeof newValue?.name === 'string') {
                                                // setValue2(result);
                                                setValue2(result)
                                                setSelectedContact({
                                                    ...newValue, additionalAddress: [{ address: newValue?.address }, ...newValue?.additionalAddress], additionalContact: [{
                                                        "name": newValue?.name,
                                                        "number": newValue?.number,
                                                        "email": newValue?.email,

                                                    }, ...newValue?.additionalContact]
                                                })
                                                setValue({
                                                    ...value,
                                                    name: newValue?.name,
                                                    address: newValue?.address,
                                                    email: newValue?.email,
                                                    businessName: newValue?.businessName,
                                                    phoneNumber: newValue?.number
                                                })
                                                setEditValue({
                                                    name: newValue?.name,
                                                    address: newValue?.address,
                                                    email: newValue?.email,
                                                    businessName: newValue?.businessName,
                                                    phoneNumber: newValue?.number
                                                })
                                                setSwitchAccount(false)
                                                setValue2('')

                                            }
                                            else {
                                                setValue2(result)
                                                setEditValue(newValue)
                                            }

                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        filterOptions={(options, { inputValue }) => {
                                            return handleSearchContact(inputValue, options);
                                        }}
                                   
                       
                                        id="free-solo-with-text-demo"
                                        options={contactData}
                                      
                                        renderOption={(props, option) => <div style={{ flexDirection: 'column' }} {...props}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                                <Person style={{ fontSize: '16px', marginRight: '5px' }} />
                                                <Value>{option?.name}</Value>
                                            </div>
                                            <Value style={{ marginBottom: '0px', fontSize: '12px', color: palette.grey }}>{formatMobileNumber(option?.number)}</Value>
                                        </div>}
                                        sx={{
                                            width: 320, '& input': {
                                                height: '5px', fontSize: '13px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#ccc',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#999',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#000',
                                                },
                                            }
                                        }}
                                        freeSolo
                                        style={{ height: 30, }}
                                        renderInput={(params) => (
                                            <div>
                                                <TextField style={{ fontSize: '13px' }} {...params}

                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Contacts style={{ fontSize: '20px' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder="Add contact" />

                                            </div>
                                        )}
                                    />
                                </div> : null
                            }
                            {value?.phoneNumber ? !editDetail ? <Flexbox style={{ alignItems: 'start', width: '100%', display: 'flex', marginTop: '0px' }}>
                                <div style={{ width: '40%', marginRight: '15px' }} >
                                    <Name className="nameButtton">{value?.name}</Name>
                                    {value?.address && <Value>{value?.address}</Value>}
                                    {value?.email && <Value>{value?.email}</Value>}
                                    <Value>{formatMobileNumber(value?.phoneNumber)}</Value>
                                </div>
                                <EditContainer onClick={handleClick}>
                                    <MoreHoriz style={{ fontSize: '20px' }} />
                                </EditContainer>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem style={{ color: palette.pup, fontSize: '14px' }} onClick={() => {
                                        setEditDetail(true)
                                        setAnchorEl(null);
                                    }}>
                                        <ListItemIcon>
                                            <Edit style={{ color: palette.pup, fontSize: '18px' }} />
                                        </ListItemIcon>
                                        Edit Information
                                    </MenuItem>
                                    {props?.edit && <MenuItem style={{ color: palette.pup, fontSize: '14px' }} onClick={() => {
                                        setSwitchAccount(true)
                                        setValue({
                                            ...value,
                                            name: '',
                                            address: '',
                                            email: '',
                                            businessName: '',
                                            phoneNumber: '',
                                        })
                                        setAnchorEl(null);
                                    }}>
                                        <ListItemIcon>
                                            <SwitchAccount style={{ color: palette.pup, fontSize: '18px' }} />
                                        </ListItemIcon>
                                        Change Customer
                                    </MenuItem>}
                                    {selectedContact?.additionalAddress?.length > 0 && <MenuItem style={{ color: palette.pup, fontSize: '14px' }} onClick={() => {
                                        setisswitch(true)
                                    }}>
                                        <ListItemIcon>
                                            <Home style={{ color: palette.pup, fontSize: '18px' }} />
                                        </ListItemIcon>
                                        Change Address
                                    </MenuItem>}
                                    {selectedContact?.additionalContact?.length > 0 && <MenuItem style={{ color: palette.pup, fontSize: '14px' }} onClick={() => {
                                        setBoolValue({ ...boolValue, isSwitchNumber: true })
                                    }}>
                                        <ListItemIcon>
                                            <Phone style={{ color: palette.pup, fontSize: '18px' }} />
                                        </ListItemIcon>
                                        Switch to Sub-Contact
                                    </MenuItem>}
                                    { <MenuItem style={{ color: palette.pup, fontSize: '14px' }} onClick={() => {
                                        setBoolValue({...boolValue,isServiceAddressswitch:true})
                                    }}>
                                        <ListItemIcon>
                                            <LocationOn style={{ color: palette.pup, fontSize: '18px' }} />
                                        </ListItemIcon>
                                        Add Service Address
                                    </MenuItem>}

                                </Menu>
                            </Flexbox> :
                                <div style={{ width: '320px' }}>
                                    <Label htmlFor={'Name'}>Name</Label>
                                    <Input
                                        placeholder="name"
                                        type="text"
                                        value={editValue?.name}
                                        style={{ height: 30 }}
                                        onChange={(e) => {
                                            setEditValue({
                                                ...editValue, name: e.target.value
                                            })
                                        }}

                                    />
                                    <Label style={{ textAlign: 'start' }} htmlFor={'Email'}>Email</Label>
                                    <Input
                                        placeholder="Email"
                                        type="text"
                                        value={editValue?.email}
                                        style={{ height: 30 }}
                                        onChange={(e) => {
                                            setEditValue({
                                                ...editValue, email: e.target.value
                                            })
                                        }}

                                    />
                                    <Label htmlFor={'Address'}>Address</Label>
                                    <Place
                                        apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                        types={['address']}
                                        options={{
                                            types: ["address"],


                                        }}
                                        placeholder='Address'
                                        //onChange={onPlaceSelected}
                                        //onClick={onPlaceSelected}
                                        onPlaceSelected={(val) => setEditValue({ ...editValue, address: val?.formatted_address })}
                                        defaultValue={editValue?.address}
                                    />
                                    <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end', }}>
                                        <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => setEditDetail(false)}>Cancel</SendButton1>
                                        <SendButton1 style={{ marginRight: -15 }} onClick={() => {
                                            setValue({ ...value, ...editValue })
                                            setEditDetail(false)
                                        }}>Save</SendButton1>
                                    </div>
                                </div> : null}

                                {value?.serviceAddress &&
                                    <>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <div style={{marginRight:'20px'}}>
                                     <h4 style={{marginBottom:'10px',textAlign:'start'}} >Service Address</h4>
                    
                                     { <Value>{value?.serviceAddress}</Value>}
                                     </div>
                                     <DeleteOutlined onClick={()=>{
                                         setValue({ ...value, serviceAddress:''})
                                         setEditValue({ ...editValue, serviceAddress:''})
                                     }}/>
                                     </div>
                                     </>
                                }


                            <h4 style={{ marginTop: '20px', }}>Line items</h4>
                            {/* {!editInvoiceData ? <Value style={{ width: '100%',marginBottom:'20px' }}>Add single, one-time items or products from your price book to this quote.</Value> : null} */}
                            {/* <h4 style={{ marginTop: '15px', }}>Services</h4> */}
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="selectedItems">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{ width: '100%' }}
                                        >
                                            {addOwnerData?.map((item, index) => (
                                                <Draggable key={item.name} draggableId={item.name} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                top: 'auto',
                                                                left: 'auto',
                                                                userSelect: 'none',
                                                                marginBottom: '20px',
                                                                background: snapshot.isDragging ? '#f3f3f3' : 'white',
                                                                ...provided.draggableProps.style,
                                                            }}
                                                        >
                                                            <LineItem style={{ width: '100%' }}>
                                                                <div style={{ display: 'flex', width: '75%' }}>
                                                                    <DragIndicatorOutlined style={{ fontSize: '18px', marginLeft: '-5px', marginRight: '5px' }} />
                                                                    <div style={{ width: '100%' }}>
                                                                        <p className="name">{item?.name} × {item?.quantity || '1'} {item?.optional ? '(Optional)' : null}</p>
                                                                        <p className="description">{item?.description}</p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))} </p>
                                                                    {!editInvoiceData && (
                                                                        <>
                                                                            <Edit style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                                                const data = [...addOwnerData];
                                                                                data.splice(index, 1);
                                                                                setAddOwnerData(data);
                                                                                setInvoiceData(item);
                                                                                if (item?.type === 'Bundle') {
                                                                                    setmodaBundleOpen(true)
                                                                                }
                                                                                else {
                                                                                    setIsAddline(true);
                                                                                    setIsFocused(true)
                                                                                }
                                                                                setIsEdit(true);
                                                                            }} />
                                                                            <DeleteOutlined style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                                                const data = [...addOwnerData];
                                                                                data.splice(index, 1);
                                                                                setAddOwnerData(data);
                                                                            }} />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </LineItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {isAddLine ? invoicedata?.type === 'Bundle' ?
                                <AddLineFiled>
                                    {<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 style={{ marginTop: '5px' }} >Bundle details</h4>
                                        <h4 style={{ marginTop: '5px' }}>${invoicedata?.price}</h4>
                                    </div>}
                                    <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                        <div className="name">
                                            <Label>Name</Label>
                                            <Inputbox
                                                type="text"
                                                placeholder='item name'
                                                style={{ width: '100%', marginLeft: '12px' }}
                                                value={invoicedata?.name}

                                                onChange={(e) => setInvoiceData({ ...invoicedata, name: e.target.value })}
                                            />
                                        </div>
                                        <DropdownContainer>
                                            <Label>Category Visibility</Label>
                                            <SelectDrpoDown id="category-visibility" value={invoicedata?.categoryVisiblity} onChange={(event) => {
                                                setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })

                                            }}>
                                                <Option value="Items and item prices are visible to your customer">
                                                    Items and item prices are visible to your customer
                                                </Option>
                                                <Option value="Items are NOT visible to your customer">
                                                    Items are NOT visible to your customer
                                                </Option>
                                                <Option value="Items are visible, but item prices are NOT visible to your customer">
                                                    Items are visible, but item prices are NOT visible to your customer
                                                </Option>
                                            </SelectDrpoDown>
                                        </DropdownContainer>


                                    </div>
                                    <div className="name" style={{ width: '100%', marginTop: '15px', marginBottom: '20px' }}>
                                        <Label>Description</Label>
                                        <InputText
                                            type="text"
                                            placeholder='Details'
                                            style={{ marginTop: '0px', minHeight: '25px' }}
                                            value={invoicedata?.description}
                                            onChange={(e) => setInvoiceData({ ...invoicedata, description: e.target.value })}
                                        />
                                    </div>
                                </AddLineFiled>
                                :
                                <AddLineFiled ref={addLineFieldRef} className="add-line-field" isFocused={isFocused}>
                                    {<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 style={{ marginTop: '5px' }} >Item details</h4>
                                        <h4 style={{ marginTop: '5px' }}>${putCommaInvalue((invoicedata?.price * invoicedata?.quantity)?.toFixed(2))}</h4>
                                    </div>}
                                    <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                        <div className="name">
                                            <Label>Name</Label>
                                            <Inputbox
                                                type="text"
                                                placeholder='item name'
                                                style={{ width: '100%', marginLeft: '12px' }}
                                                value={invoicedata?.name}

                                                onChange={(e) => setInvoiceData({ ...invoicedata, name: e.target.value })}
                                            />
                                        </div>


                                        <div className="subname" style={{ marginRight: '5px' }}>
                                            <Label>Cost</Label>
                                            <InputWrapper>
                                                <CustomUrl type='number' placeholder='Cost' min='0' style={{ paddingLeft: '15px' }} onKeyDown={validateInput} value={invoicedata?.unitPrice} onChange={(e) => {
                                                    const markupPercentage = ((invoicedata?.price - e.target.value) / e.target.value) * 100;
                                                    setInvoiceData({ ...invoicedata, unitPrice: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                                }} />
                                                <DollarSymbol>$</DollarSymbol>
                                            </InputWrapper>
                                        </div>
                                        <div className="subname">
                                            <Label>Tax</Label>
                                            <Selectbox style={{ width: '100%', height: '34px', fontSize: 13, }} onChange={(e) => {

                                                setInvoiceData({ ...invoicedata, tax: e.target?.value?.value, taxName: e.target?.value?.name, taxNumber: e.target?.value?.description })
                                            }}
                                                sx={{
                                                    "&:hover": {
                                                        "&& fieldset": {
                                                            border: "0.5px solid #414552"
                                                        }
                                                    },

                                                }}


                                                renderValue={() => invoicedata?.tax ? `${invoicedata?.tax} %` : "Select tax"}
                                                defaultValue={"0"} >
                                                {[{ name: 'No tax', value: '0' }, ...tax]?.map((item, index) =>
                                                    <StyledMenuItem key={item.value} value={item}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #f3f3f3', width: '100%', marginTop: '-10px', marginBottom: '-10px' }}>


                                                            <div>
                                                                <p style={{ fontSize: '14px', textAlign: 'start' }}>{item?.name}</p>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>


                                                            </div>
                                                        </div>
                                                    </StyledMenuItem>
                                                )}
                                            </Selectbox>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'start', width: '100%' }}>
                                        <div style={{ marginLeft: '0px', width: '25%' }} className="subname">
                                            <Label>Qty</Label>
                                            <CustomUrl placeholder='Quantity' type='number' value={invoicedata?.quantity} onKeyDown={validateInput1} onChange={(e) => setInvoiceData({ ...invoicedata, quantity: e.target.value })} />
                                        </div>
                                        <div style={{ width: '25%', marginRight: '5px' }} className="subname">
                                            <Label>Price</Label>
                                            <InputWrapper>
                                                <CustomUrl type='number' placeholder='Price' min='0' style={{ paddingLeft: '15px' }} onKeyDown={validateInput} value={invoicedata?.price} onChange={(e) => {
                                                    const markupPercentage = ((e.target.value - invoicedata?.unitPrice) / invoicedata?.unitPrice) * 100;
                                                    setInvoiceData({ ...invoicedata, price: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                                }} />
                                                <DollarSymbol>$</DollarSymbol>
                                            </InputWrapper>
                                        </div>
                                        <div style={{ width: '25%' }} className="subname">
                                            <Label>Markup</Label>
                                            <InputWrapper>
                                                <CustomUrl placeholder='Markup' type='number' value={invoicedata?.markup} onKeyDown={validateInput1} onChange={(e) => {
                                                    if (!invoicedata?.unitPrice) {
                                                        setInvoiceData({ ...invoicedata, markup: 0 })
                                                    }
                                                    else {
                                                        const unitPrice = invoicedata?.unitPrice * (1 + (e.target.value / 100));
                                                        setInvoiceData({ ...invoicedata, markup: e.target.value, price: unitPrice?.toFixed(2) })

                                                    }
                                                }} />
                                                <PercentageSymbol>%</PercentageSymbol>
                                            </InputWrapper>
                                        </div>
                                        <div className="subname">
                                            <Label>Type</Label>
                                            <InputPicker
                                                value={invoicedata?.type}
                                                style={{ width: '100%', marginLeft: '0px' }}
                                                data={[{
                                                    label: "Service", value: 'Service',
                                                }, {
                                                    label: "Material", value: 'Material',
                                                },]}
                                                label='Type'

                                                onChange={(val) => setInvoiceData({ ...invoicedata, type: val })}

                                            />
                                        </div>
                                    </div>

                                    <div className="name" style={{ width: '100%', marginTop: '15px' }}>
                                        <Label>Description</Label>
                                        <InputText
                                            type="text"
                                            placeholder='Details'
                                            style={{ marginTop: '0px', minHeight: '25px' }}
                                            value={invoicedata?.description}
                                            onChange={(e) => setInvoiceData({ ...invoicedata, description: e.target.value })}
                                        />
                                    </div>


                                    <div style={{ display: 'flex', marginTop: '5px', marginLeft: '5px', alignSelf: 'start' }}>
                                        <Checkbox
                                            style={{ width: '25px', marginRight: '10px', height: '42px' }}
                                            checked={invoicedata.optional}
                                            onChange={(e) => {
                                                setInvoiceData({ ...invoicedata, optional: e.target.checked })
                                            }}

                                        // key={index}
                                        // value={item?.email}
                                        // checked={day.includes(item?.email)}
                                        // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                        />
                                        <Label style={{ marginTop: '12px', fontSize: '14px', marginBottom: 0 }}>Optional Add-on</Label>
                                    </div>


                                </AddLineFiled> : !editInvoiceData && ((boolValue?.newItem || boolValue?.newBundle) ?

                                    boolValue?.newBundle ?
                                        <Autocomplete1
                                            value={value2}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Add new line item';
                                                const replacement = '';
                                                const result = newValue?.name

                                                if (newValue && newValue?.name?.includes(searchString)) {
                                                    setInvoiceData({
                                                        "name": "",
                                                        "price": "0",
                                                        "description": "",
                                                        "quantity": "1",
                                                        "tax": "0",
                                                        "taxName": "no Tax",
                                                        "taxNumber": '',
                                                        "optional": false
                                                    })
                                                    setIsAddline(true)
                                                    // Create a new value from the user input
                                                    // addNewHashTag(result)
                                                    //setValue(result);
                                                    // setValue2(result)
                                                    //   setOpenAddContact(true)
                                                }

                                                else if (typeof newValue?.name === 'string') {
                                                    if (newValue?.type === 'Bundle') {
                                                        setInvoiceData({ ...newValue, lineItems: newValue?.lineItems, categoryVisiblity: 'Items and item prices are visible to your customer' })
                                                        // setIsAddline(true)
                                                        setmodaBundleOpen(true)
                                                    }
                                                    else {
                                                        setIsAddline(true)
                                                        const taxItem = tax.find(t => t.name === newValue.taxName);

                                                        setInvoiceData({ ...newValue, taxNumber: taxItem?.description, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) })
                                                    }

                                                    setValue2('')
                                                    // setSelectedHash([...selectHash, result])
                                                }
                                                else {
                                                    setValue2(result)
                                                    setEditValue(newValue)

                                                    //  setHasTag([...selectHash,result])
                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option?.name);


                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={bundleItem}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option?.name === 'string') {
                                                    return option.name;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, item, index) => <div  {...props} style={{ position: 'relative' }}>
                                                {<LineItem style={{ width: '100%' }}>
                                                    <div style={{ width: '90%' }}>
                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                        {item?.description && <p className="description">{item?.description}</p>}
                                                    </div>
                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                </LineItem>}
                                            </div>}
                                            sx={{
                                                width: "100%", '& input': {
                                                    height: '5px', fontSize: '13px'
                                                },
                                            }}

                                            style={{ height: 30, fontSize: '13px', marginTop: '10px' }}
                                            renderInput={(params) => (
                                                <div style={{ position: 'relative' }}>
                                                    <TextField
                                                        {...params}
                                                        style={{ fontSize: '13px' }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Search style={{ fontSize: '20px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        placeholder="Search bundle item"
                                                        inputRef={autocompleteRef}
                                                    />


                                                </div>
                                            )}
                                        /> :
                                        <Autocomplete1
                                            value={value2}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Add new line item';
                                                const replacement = '';
                                                const result = newValue?.name

                                                if (newValue && newValue?.name?.includes(searchString)) {
                                                    setInvoiceData({
                                                        "name": "",
                                                        "price": "0",
                                                        "description": "",
                                                        "quantity": "1",
                                                        "tax": "0",
                                                        "taxName": "no Tax",
                                                        "taxNumber": '',
                                                        "optional": false
                                                    })
                                                    setIsAddline(true)
                                                    setIsFocused(true)
                                                    // Create a new value from the user input
                                                    // addNewHashTag(result)
                                                    //setValue(result);
                                                    // setValue2(result)
                                                    //   setOpenAddContact(true)
                                                }

                                                else if (typeof newValue?.name === 'string') {
                                                    if (newValue?.type === 'Bundle') {
                                                        setInvoiceData({ ...newValue, lineItems: newValue?.lineItems, categoryVisiblity: 'Items and item prices are visible to your customer' })
                                                        setIsAddline(true)
                                                    }
                                                    else {
                                                        setIsAddline(true)
                                                        const taxItem = tax.find(t => t.name === newValue.taxName);
                                                        setIsFocused(true)
                                                        setInvoiceData({ ...newValue, taxNumber: taxItem?.description, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) })
                                                    }

                                                    setValue2('')
                                                    // setSelectedHash([...selectHash, result])
                                                }
                                                else {
                                                    setValue2(result)
                                                    setEditValue(newValue)

                                                    //  setHasTag([...selectHash,result])
                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option?.name);
                                                // filtered.push({ name: `Add new line item` });


                                                return [{ name: `Add new line item` }, ...filtered];
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={lineItem}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option?.name === 'string') {
                                                    return option.name;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, item, index) => <div  {...props} style={{ position: 'relative' }}>
                                                {item?.name === 'Add new line item' ? <div style={{ color: palette.newBlue, fontSize: '13px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px', zIndex: '1000', }}>
                                                    <AddCircle style={{ marginRight: '8px' }} />

                                                    Add new line item</div> : <LineItem style={{ width: '100%' }}>
                                                    <div style={{ width: '90%' }}>
                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                        {item?.description && <p className="description">{item?.description}</p>}
                                                    </div>
                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                </LineItem>}
                                            </div>}
                                            sx={{
                                                width: "100%", '& input': {
                                                    height: '5px', fontSize: '13px'
                                                },
                                            }}

                                            style={{ height: 30, fontSize: '13px', marginTop: '10px' }}
                                            renderInput={(params) => (
                                                <div style={{ position: 'relative' }}>
                                                    <TextField
                                                        {...params}
                                                        style={{ fontSize: '13px' }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Search style={{ fontSize: '20px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        placeholder="Search line item"
                                                        inputRef={autocompleteRef}

                                                    />


                                                </div>
                                            )}
                                        /> : <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div onClick={() => {
                                            setBoolValue({ ...boolValue, newItem: true })
                                            if (autocompleteRef.current) {
                                                autocompleteRef.current.focus();
                                            }
                                        }} style={{ background: '#0F52BA', cursor: 'pointer', color: 'white', padding: '10px 13px 10px 13px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                            <AddBoxOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                                            <h4 style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px', color: palette.white, }}> Add item</h4></div>
                                        <div onClick={() => {
                                            setBoolValue({ ...boolValue, newBundle: true })
                                            if (autocompleteRef.current) {
                                                autocompleteRef.current.focus();
                                            }
                                        }} style={{ background: '#fff', cursor: 'pointer', color: 'black', border: '1px solid #ddd', padding: '10px 13px 10px 13px', marginLeft: '25px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                            <LoginOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                                            <h4 style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px', color: palette.black, }}> Insert bundle</h4></div>
                                    </div>
                                )
                            }
                            {isAddLine ? <div className="add-line-field" style={{ alignSelf: 'flex-end', zIndex: 15000000, background: 'white', pointerEvents: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: '15px' }}>
                                <RippleButton style={{ marginRight: '20px', background: '#fff', color: palette.newBlue, border: '0px solid #ddd' }} onClick={() => {
                                    if (isEdit) {
                                        addCustomFiled()
                                    }
                                    setIsAddline(false)
                                    setIsFocused(false)
                                    setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                                }}>Cancel</RippleButton>
                                <RippleButton onClick={() => {
                                    setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                                    setIsFocused(false)
                                    addCustomFiled()
                                }}>Save</RippleButton>
                            </div> : null}


                            {/* <h4 style={{ marginTop: '30px', }}>materials</h4>
                            {
                                addMaterialData?.map((item, index) =>
                                    <LineItem style={{ width: '100%', marginTop: '0px', marginBottom: '15px' }} >
                                        <div style={{ width: '75%' }}>
                                            <p className="name">{item?.name} × {item?.quantity || '1'} {item?.optional ? '(Optional)' : null}</p>
                                            <p className="description">{item?.description}</p>
                                        </div>
                                        {<div style={{ display: 'flex' }}>
                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))} </p>
                                            {!editInvoiceData && <>
                                                <Edit style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                    const data = [...addMaterialData]
                                                    data.splice(index, 1)
                                                    setAddMaterialData(data)
                                                    setInvoiceData(item)
                                                    setIsAddMaterialline(true)
                                                    setIsEditMaterial(true)
                                                }} />
                                                <DeleteOutlined style={{ fontSize: '18px', marginLeft: '15px', cursor: 'pointer' }} onClick={() => {
                                                    const data = [...addMaterialData]
                                                    data.splice(index, 1)
                                                    setAddMaterialData(data)

                                                }} />

                                            </>}
                                        </div>}
                                    </LineItem>
                                )
                            }
                            {isAddMaterialLine ?
                                <AddLineFiled>
                                    {<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 style={{ marginTop: '5px' }} >Item details</h4>
                                        <h4 style={{ marginTop: '5px' }}>${putCommaInvalue((invoicedata?.price * invoicedata?.quantity)?.toFixed(2))}</h4>
                                    </div>}
                                    <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                        <div className="name">
                                            <Label>Name</Label>
                                            <Inputbox
                                                type="text"
                                                placeholder='item name'
                                                style={{ width: '100%', marginLeft: '12px' }}
                                                value={invoicedata?.name}

                                                onChange={(e) => setInvoiceData({ ...invoicedata, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="subname">
                                            <Label>Qty</Label>
                                            <CustomUrl placeholder='Quantity' type='number' value={invoicedata?.quantity} onKeyDown={validateInput1} onChange={(e) => setInvoiceData({ ...invoicedata, quantity: e.target.value })} />
                                        </div>
                                        <div className="subname">
                                            <Label>Price</Label>
                                            <CustomUrl type='number' placeholder='Price' min='0' onKeyDown={validateInput} value={invoicedata?.price} onChange={(e) => {
                                                const markupPercentage = ((e.target.value - invoicedata?.unitPrice) / invoicedata?.unitPrice) * 100;
                                                setInvoiceData({ ...invoicedata, price: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                            }} />
                                        </div>
                                        <div className="subname">
                                            <Label>Markup</Label>
                                            <InputWrapper>
                                                <CustomUrl placeholder='Markup' type='number' value={invoicedata?.markup} onKeyDown={validateInput1} onChange={(e) => {
                                                    if (!invoicedata?.unitPrice) {
                                                        setInvoiceData({ ...invoicedata, markup: 0 })
                                                    }
                                                    else {
                                                        const unitPrice = invoicedata?.unitPrice * (1 + (e.target.value / 100));
                                                        setInvoiceData({ ...invoicedata, markup: e.target.value, price: unitPrice })

                                                    }
                                                }} />
                                                <PercentageSymbol>%</PercentageSymbol>
                                            </InputWrapper>
                                        </div>

                                        <div className="subname">
                                            <Label>Cost</Label>
                                            <CustomUrl type='number' placeholder='Cost' min='0' onKeyDown={validateInput} value={invoicedata?.unitPrice} onChange={(e) => {
                                                const markupPercentage = ((invoicedata?.price - e.target.value) / e.target.value) * 100;
                                                setInvoiceData({ ...invoicedata, unitPrice: e.target.value, markup: parseFloat(markupPercentage)?.toFixed(2) })
                                            }} />
                                        </div>
                                        <div className="subname">
                                            <Label>Tax</Label>
                                            <Selectbox style={{ width: '100%', height: '34px', fontSize: 13, }} onChange={(e) => {
                                                console.log(e.target)
                                                setInvoiceData({ ...invoicedata, tax: e.target?.value?.value, taxName: e.target?.value?.name, taxNumber: e.target?.value?.description })
                                            }}
                                                sx={{
                                                    "&:hover": {
                                                        "&& fieldset": {
                                                            border: "0.5px solid #414552"
                                                        }
                                                    },

                                                }}


                                                renderValue={() => invoicedata?.tax ? `${invoicedata?.tax} %` : "Select tax"}
                                                defaultValue={"0"} >
                                                {[{ name: 'No tax', value: '0' }, ...tax]?.map((item, index) =>
                                                    <StyledMenuItem key={item.value} value={item}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #f3f3f3', width: '100%', marginTop: '-10px', marginBottom: '-10px' }}>


                                                            <div>
                                                                <p style={{ fontSize: '14px', textAlign: 'start' }}>{item?.name}</p>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>


                                                            </div>
                                                        </div>
                                                    </StyledMenuItem>
                                                )}
                                            </Selectbox>
                                        </div>
                                    </div>

                                    <div className="name" style={{ width: '100%', marginTop: '15px' }}>
                                        <Label>Description</Label>
                                        <InputText
                                            type="text"
                                            placeholder='Details'
                                            style={{ marginTop: '0px', minHeight: '25px' }}
                                            value={invoicedata?.description}
                                            onChange={(e) => setInvoiceData({ ...invoicedata, description: e.target.value })}
                                        />
                                    </div>


                                    <div style={{ display: 'flex', marginTop: '5px', marginLeft: '5px', alignSelf: 'start' }}>
                                        <Checkbox
                                            style={{ width: '25px', marginRight: '10px', height: '42px' }}
                                            value={invoicedata.optional}
                                            onChange={() => invoicedata.optional ? setInvoiceData({ ...invoicedata, optional: false }) : setInvoiceData({ ...invoicedata, optional: true })}
                                        // key={index}
                                        // value={item?.email}
                                        // checked={day.includes(item?.email)}
                                        // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                        />
                                        <Label style={{ marginTop: '12px', fontSize: '14px', marginBottom: 0 }}>Optional Add-on</Label>
                                    </div>


                                </AddLineFiled> : !editInvoiceData &&
                                <Autocomplete1
                                    value={value2}
                                    onChange={(event, newValue) => {
                                        console.log(newValue, "dfkvndkkjdvkjdvfjkn")
                                        const searchString = 'Add new line item';
                                        const replacement = '';
                                        const result = newValue?.name
                                        //const regex = new RegExp(`\\b${searchString}\\b`, 'i'); 
                                        console.log(newValue, 'dsckjnl')
                                        if (newValue && newValue?.name?.includes(searchString)) {
                                            setInvoiceData({
                                                "name": "",
                                                "price": "0",
                                                "description": "",
                                                "quantity": "1",
                                                "tax": "0",
                                                "taxName": "no Tax",
                                                "taxNumber": '',
                                                "optional": false
                                            })
                                            setIsAddMaterialline(true)
                                            // Create a new value from the user input
                                            // addNewHashTag(result)
                                            //setValue(result);
                                            // setValue2(result)
                                            //   setOpenAddContact(true)
                                        }

                                        else if (typeof newValue?.name === 'string') {
                                            setIsAddMaterialline(true)

                                            setInvoiceData({ ...newValue, "markup": calculateMarkupPercentage(newValue?.unitPrice, newValue?.price) })

                                            setValue2('')
                                            // setSelectedHash([...selectHash, result])
                                        }
                                        else {
                                            setValue2(result)
                                            setEditValue(newValue)

                                            //  setHasTag([...selectHash,result])
                                        }

                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option?.name);
                                        filtered.push({ name: `Add new line item` });


                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={lineItem}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option?.name === 'string') {
                                            return option.name;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, item, index) => <div {...props}>
                                        {item?.name === 'Add new line item' ? <div style={{ position: 'fixed', bottom: 0, left: '0px', color: palette.newBlue, fontSize: '13px', fontWeight: '600', background: '#F0F8FF', width: '99%', right: 0, display: 'flex', justifyContent: 'center', padding: '4px' }}>+ Add new line item</div> : <LineItem style={{ width: '100%' }}>
                                            <div style={{ width: '90%' }}>
                                                <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                {item?.description && <p className="description">{item?.description}</p>}
                                            </div>
                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                        </LineItem>}
                                    </div>}
                                    sx={{
                                        width: "100%", '& input': {
                                            height: '5px', fontSize: '13px'
                                        },
                                    }}
                                    freeSolo
                                    style={{ height: 30, fontSize: '13px' }}
                                    renderInput={(params) => (
                                        <div style={{ position: 'relative' }}>
                                            <TextField
                                                {...params}
                                                style={{ fontSize: '13px' }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search style={{ fontSize: '20px' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Search material line item"
                                                inputRef={autocompleteRef}
                                            />


                                        </div>
                                    )}
                                />
                            }
                            {isAddMaterialLine ? <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: '15px' }}>
                                <RippleButton style={{ marginRight: '20px', background: '#fff', color: '#000', border: '1px solid #ddd' }} onClick={() => {
                                    if (isEdit) {
                                        addCustomMaterialFiled()
                                    }
                                    setIsAddMaterialline(false)
                                }}>Cancel</RippleButton>
                                <RippleButton onClick={() => addCustomMaterialFiled()}>Save</RippleButton>
                            </div> : null} */}
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', marginTop: '40px' }}>
                                <div>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <h5 style={{ marginBottom: '0px', marginTop: '0px', width: '20%', marginRight: '30px', }}>Subtotal:</h5>
                                        <Inputbox style={{ background: '#f3f3f3', width: '120px', cursor: 'no-drop' }} value={`$${putCommaInvalue([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                        }, 0)?.toFixed(2))}`} readOnly />
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px' }}>
                                        <h5 style={{ marginBottom: '0px', marginTop: '0px', width: '20%', marginRight: '30px' }}>Total:</h5>
                                        <Inputbox style={{ background: '#f3f3f3', width: '120px', cursor: 'no-drop' }} value={`$${putCommaInvalue(calculateTotalAmount(addOwnerData))}`} readOnly />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', }}>
                                        <h5 style={{ marginBottom: '0px', marginTop: '0px', marginRight: '20px' }}>Discount:</h5>
                                        <Inputbox style={{ background: '#f3f3f3', width: '120px', cursor: 'pointer' }} onClick={() => setBoolValue({ ...boolValue, discount: true })} value={applyDiscount()} readOnly />
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px' }}>
                                        <h5 style={{ marginBottom: '0px', marginTop: '0px', marginRight: '20px' }}>Deposit:</h5>
                                        <InputPicker
                                            label={"Apply deposit"}
                                            value={value?.deposit}
                                            style={{ background: '#f3f3f3', width: '135px', marginLeft: '0px' }}
                                            data={deposit?.value ? [{
                                                label: `$${props?.value?.depositValue||value?.deposit||props?.value?.deposit}`, value: `${props?.value?.depositValue||value?.deposit||props?.value?.deposit}`
                                            }] : []}
                                            onChange={(text) => {
                                                setValue({ ...value, deposit: text, depositType: deposit?.type })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {userdata?.stripeAccountId && <div style={{ display: 'flex', marginTop: '15px', alignSelf: 'start' }}>
                                <Checkbox
                                    style={{ width: '25px', marginRight: '10px', height: '42px' }}
                                    checked={value.acceptPaymentOnline}
                                    onChange={() => value.acceptPaymentOnline ? setValue({ ...value, acceptPaymentOnline: false }) : setValue({ ...value, acceptPaymentOnline: true })}
                                // key={index}
                                // value={item?.email}
                                // checked={day.includes(item?.email)}
                                // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                />
                                <Label style={{ marginTop: '12px', fontSize: '14px', marginBottom: 0 }}>Allow online payment</Label>
                            </div>}
                            <h4 style={{ marginBottom: '0px', marginTop: '20px' }}>Message</h4>
                            <InputText
                                placeholder="Type your message"
                                type="text"
                                value={value?.notesInvoice}
                                readOnly={!editInvoiceData ? false : true}
                                onChange={(e) => {
                                    setValue({ ...value, 'notesInvoice': e.target.value })
                                }}


                            />
                            <h4 style={{ marginBottom: '0px', marginTop: '20px' }}>Attachments / Photos</h4>
                            <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '10px' }}>
                                    {selectedImage?.map((item) =>
                                        <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                            <PhotosStyle src={item} />
                                            <div
                                                onClick={() => {
                                                    const arr = selectedImage?.filter(obj => obj !== item)
                                                    setSelectedImage(arr)
                                                }}
                                                style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                <Cancel style={{ fontSize: '16px' }} />
                                            </div>
                                        </div>
                                    )}

                                    <FileInputButton htmlFor="fileInput">
                                        <AddCircle style={{ fontSize: '30px', color: palette.newBlue }} />
                                    </FileInputButton>
                                    <FileInput
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={imageHandleChange}
                                    />
                                </div>

                            </div>
                            {/* <ContainerTotal>
                                <SubTable >
                                    <p className="key" >Sub total</p>
                                    <p className="text" style={{ textAlign: 'end', }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                    }, 0)?.toFixed(2))}`}</p>
                                </SubTable>
                                <SubTable style={{ marginTop: '-10px', paddingTop: 0 }}>
                                    <p className="key">Tax</p>
                                    <p className="text" style={{ textAlign: 'end', paddingBottom: '15px' }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                        return accumulator + taxprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                                    }, 0)?.toFixed(2))}`}</p>
                                </SubTable>
                                <SubTable style={{ marginTop: '-10px', paddingTop: 0, borderTop: '1px solid #DDD', }}>
                                    <p className="key" style={{ paddingTop: '10px', }}>Total</p>
                                    <p className="text" style={{ textAlign: 'end', paddingTop: '10px' }}>{`$${putCommaInvalue(addOwnerData.reduce(function (accumulator, currentValue) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                                    }, 0)?.toFixed(2))}`}</p>
                                </SubTable>
                            </ContainerTotal> */}
                        </div>
                    </MainContainer>
                </CustomTable>
                <PreviewContainer>
                    {/* Preview page styling */}
                    <div
                        style={{
                            zoom: 0.70,
                            width: '230mm',
                            height: "297mm",
                            display: 'flex',
                            justifyContent: 'center',
                            background: 'white',
                            boxShadow: "0px 4px 16px rgba(30, 30, 30, 0.05)", // Example box shadow

                            boxSizing: 'border-box',
                            overflow: 'hidden', // Enable scrolling if content exceeds the page
                        }}
                    >
                        <PreviewInvoice
                            userDetails={{ ...value, attachment: selectedImage }}
                            bundleData={addOwnerData?.filter((item) => item?.type === 'Bundle')}
                            quoteData={addOwnerData?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')}
                            materialData={addOwnerData?.filter((item) => item?.type === 'Material')}
                        />
                    </div>
                </PreviewContainer>

                {isswitch &&
                    <TopModal
                        onClose={() => setisswitch(false)}
                        overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4>
                                {"Select address"}
                            </h4>
                            <Cancel onClick={() => {
                                setisswitch(false)
                            }} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            {selectedContact?.additionalAddress?.map((item) => <div onClick={() => {
                                setValue({ ...value, address: item?.address, title: item?.title })
                                setEditValue({ ...editValue.address, address: item?.address, title: item?.title })
                                setisswitch(false)
                            }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Radio
                                    checked={item?.address === value?.address && item?.title === value?.title ? true : false}
                                />
                                <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>{item?.address}</h5>

                            </div>)}
                        </div>


                    </TopModal>
                }
                  {boolValue?.isServiceAddressswitch &&
                    <TopModal
                        onClose={() => setBoolValue({...boolValue,isServiceAddressswitch:false})}
                        overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4>
                                {"Select Service Address"}
                            </h4>
                            <Cancel onClick={() => {
                              setBoolValue({...boolValue,isServiceAddressswitch:false})
                            }} style={{ cursor: 'pointer' }} />
                        </div>
                      {boolValue?.newAddress ? <Place
                        apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                        types={['address']}
                        options={{
                            types: ["address"],
                        }}

                        style={{
                            width: '95%',
                            borderRadius: '5px',
                            fontSize: '14px',
                            height: '25px',
                            fontWeight: '400',
                            fontFamily: 'Inter, sans-serif',
                            color: '#344054',
                            alignSelf: 'start',
                            margin: '10px 0px',
                            background: '#ffffff',
                            border: '0.5px solid #d0d5dd',
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            padding: '10px 14px',
                        }}
                        placeholder='Search address'
                        onPlaceSelected={(details) => {
                            const street = `${details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('street_number')
                            )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('route'),
                            )?.short_name || ''}`;

                            const zipCode = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('postal_code'),
                            )?.short_name;
                            const region = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('administrative_area_level_1'),
                            )?.long_name;
                            const city = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('locality'),
                            )?.long_name;
                            const country = details?.address_components.find((addressComponent) =>
                                addressComponent.types.includes('country'),
                            )?.long_name;
                            let address = { postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address, title: details?.formatted_address, }
                            setBoolValue({ ...boolValue, newAddress: false })
                            setValue({ ...value, serviceAddress: address?.address })
                            setBoolValue({...boolValue,isServiceAddressswitch:false})
                            setEditValue({ ...editValue, serviceAddress: address?.address})
                            setSelectedContact({ ...selectedContact, additionalAddress: [...selectedContact?.additionalAddress, address] })
                           
              
                        }}

                    /> :  <div style={{ marginBottom: '15px' }}>
                            {selectedContact?.additionalAddress?.map((item) => <div onClick={() => {
                                setValue({ ...value, serviceAddress: item?.address })
                                setEditValue({ ...editValue, serviceAddress: item?.address })
                                setBoolValue({...boolValue,isServiceAddressswitch:false})
                            }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Radio
                                    checked={item?.address === value?.address && item?.title === value?.title ? true : false}
                                />
                                <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>{item?.address}</h5>

                            </div>)}
                        </div>}
                        {boolValue?.newAddress ? <AddressButton onClick={() => setBoolValue({ ...boolValue, newAddress: false })}>
                        <h4 style={{color:'white'}}> cancel</h4>
                    </AddressButton> : <AddressButton onClick={() => {
                        setBoolValue({ ...boolValue, newAddress: true })
                    }}>
                        <AddBoxOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                        <h4 style={{color:'white'}}> Add address</h4>
                    </AddressButton>}

                    </TopModal>
                }

                {boolValue?.isSwitchNumber &&
                    <TopModal
                        onClose={() => setBoolValue({ ...boolValue, isSwitchNumber: false })}
                        overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4>
                                {"Select number"}
                            </h4>
                            <Cancel onClick={() => {
                                setBoolValue({ ...boolValue, isSwitchNumber: false })
                            }} style={{ cursor: 'pointer' }} />
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            {selectedContact?.additionalContact?.map((item) => <div onClick={() => {
                                var cleaned2 = ("" + item?.number).replace(/\D/g, "");
                                setValue({ ...value, name: item?.name, email: item?.email, phoneNumber: cleaned2?.length > 10 ? `+${cleaned2}` : `+1${cleaned2}` })
                                setEditValue({ ...editValue, name: item?.name, email: item?.email, phoneNumber: cleaned2?.length > 10 ? `+${cleaned2}` : `+1${cleaned2}` })
                                setBoolValue({ ...boolValue, isSwitchNumber: false })
                            }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '10px' }}>
                                <Radio
                                    checked={item?.number === value?.phoneNumber ? true : false}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>{item?.name}</h5>
                                    <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>{formatMobileNumber(item?.number)}</h5>
                                </div>

                            </div>)}
                        </div>


                    </TopModal>
                }


                {boolValue?.discount && <TopModal
                    onClose={() => setBoolValue({ ...boolValue, discount: false })}
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    customStyle={{ width: '400px' }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>
                            {"Add discount"}
                        </h4>
                        <Cancel onClick={() => {
                            setBoolValue({ ...boolValue, discount: false })
                        }} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ display: 'flex' }}>

                        <ToggleButtonGroup
                            value={discount?.type}
                            exclusive
                            onChange={(event, newAlignment) => {
                                setDiscount({ ...discount, type: newAlignment });
                            }}
                            style={{ height: '34px', marginRight: '20px' }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="$" aria-label="left aligned">
                                $
                            </ToggleButton>
                            <ToggleButton value="%" aria-label="right aligned">
                                %
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <CustomUrl value={discount?.value} onChange={(e) => setDiscount({ ...discount, value: e.target.value })} style={{ width: '70%' }} placeholder="discount" />

                    </div>
                    {discount?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                    }, 0)) < discount?.value && <Label style={{ textAlign: 'start', color: 'red', marginTop: '5px' }}>{"Discount is more than the sub total"}</Label>}
                    <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                            setBoolValue({ ...boolValue, discount: false })
                        }}>Cancel</RippleButton>
                        <RippleButton onClick={async () => {
                            if (discount?.type === '$' && ([...addOwnerData, ...addMaterialData].reduce(function (accumulator, currentValue) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }, 0)) < discount?.value) {

                            }
                            else {
                                setValue({ ...value, discount: discount?.value, discountType: discount?.type })
                                setBoolValue({ ...boolValue, discount: false })
                            }
                        }}>Save</RippleButton>
                    </div>

                </TopModal>}

                {modaBundleOpen && (
                    <TopModal
                        isOpen={modaBundleOpen}
                        customStyle={{
                            width: '47%', maxHeight: '95%', height: '100%', flexWrap: 'wrap', position: 'relative', overflow: 'hidden', paddingLeft: '0px',
                            paddingRight: '0px',
                        }}
                        overlayStyle={{ paddingTop: '0px', alignItems: 'center' }}
                        onClose={() => {
                            setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                            setmodaBundleOpen(false)
                        }}>
                        <EditBundle
                            {...props}
                            value={{ ...invoicedata }}
                            lineItem={lineItem}
                            setModalOpenInvoice={() => {
                                setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                                setmodaBundleOpen(false)

                            }}
                            setValue={(val) => {
                                setBoolValue({ ...boolValue, newItem: false, newBundle: false })
                                setAddOwnerData([...addOwnerData, val])
                                setInvoiceData({
                                    "name": "",
                                    "price": "",
                                    "description": "",
                                    "quantity": "1",
                                    "tax": "0",
                                    "taxName": "no Tax",
                                    "taxNumber": ''
                                })
                                setIsAddline(false)
                                setIsEdit(false)
                                setmodaBundleOpen(false)


                            }
                            }
                        />
                    </TopModal>
                )}



            </Flexbox>
        </Main>
    )
}

export default CreateInvoice;

const PreviewContainer = styled.div`
width:50%;
height:100%;
margin-top:40px;
paddingBottom:80px;
display: flex;

justify-content:center; 
@media (min-width: 260px) and (max-width: 1300px){
    display:none;
}

`
const Main = styled.div`
width:100%;
height:99vh;
overflow-y: scroll;
z-index: 1;
${({ isFocused }) =>
        isFocused &&
        `  &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6); /* White faded overlay */
        z-index: 1;
      }
      overflow-y: scroll;
    & > .add-line-field {
      filter: blur(0px); /* Remove blur for focused child */
      background-color: white;
      z-index: 1000000000; /* Bring child above the Main container */
      pointer-events: auto;
    }
  `}

`
const MainContainer = styled.div`
width:92%;
padding-top:25px;
display:block;
height:auto;


`

const Place = styled(Autocomplete)`
height:30px;
width:315px;
border-radius:4px;
border:1px solid lightgray;
font-weight:400;
padding-left:12px;
@media (min-width: 260px) and (max-width: 721px){
    width:85%;
    }
&::placeholder {
  font-size:14px;
  font-weight:400;
}
&:focus {
outline:none;
border:1px solid lightgray;
}
`

const CustomUrl = styled(Form.Control)`
background: #FFF;
padding-left:10px;
background: #ffffff;
border: 1px solid #ddd;
border-radius:4px;
height: 30px;
font-size:13px;
width:100%;
-moz-appearance: textfield; /* Firefox */
  
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
&:focus {
    outline: none;
    border: 1.5px solid #000; /* Remove border on focus */
  }
`
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PercentageSymbol = styled.span`
  position: absolute;
  right:-5px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 13px;
  color: black;
  pointer-events: none; 
`;
const DollarSymbol = styled.span`
  position: absolute;
  left:5px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 13px;
  color: black;
  pointer-events: none; 
`;
const DraftButton = styled.button`
border:1px solid ${palette.lightGrey};
 background:#fff; 
 margin-right:10px;
 borderRadius:4px;

color:${palette.charcoleBlack}; 
font-size: 12px;
padding-left:15px;
padding-right:15px;
height:35px;
border-radius:8px;
border:1px solid #DDD;
font-weight:600;


cursor:pointer;

:hover{
    border:1px solid #9da6b4;
}

`
const AddLineFiled = styled.div`
display:flex; 
flex-direction:column; 
width:100%;
z-index:100000000000;
opacity: ${({ isFocused }) => (isFocused ? 1 : 0.3)};
pointer-events: ${({ isFocused }) => (isFocused ? 'auto' : 'none')};

.name{
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    width:60%;
}
.subname{
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    width:20%;
    margin-left:20px;
}
`
const DueDateForm = styled(Form.Select)`
border-radius:4px;
cursor:pointer;
width: 150px;
color:black;
border:1px solid #ddd;
font-size:14px;
padding-left:10px;
padding-right:30px;
height:35px;
appearance: none; /* Remove default arrow */
 
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust arrow position */
  background-size: 12px; 
&:focus {
outline: none;
box-shadow: 0px 0px 0px white;
background: #fff;
}
@media (min-width: 360px) and (max-width: 820px){
width:11rem;
}

`
const SelectForm = styled.p`
display:flex;

font-size:14px;
font-weight:600;
cursor:pointer;
`
const Arrow = styled.img`

width:20px;
height:22px;

`
const CustomButton = styled.button`
width:130px;
height:40px;
border-radius:8px;
border:1px solid white;
align-self:flex-end;
color:white;
font-size:14px;
background:#000;
margin-top:15px;
cursor:pointer;
`
const Selectbox = styled(Select)`
width:300px;
border-radius:4px;
text-align:start;
display:flex;
border:none;
height:38px;
font-size:14px;
.MuiSelect-select {
    padding-right: 0px;
   
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }



  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
align-self:start;
@media (min-width: 260px) and (max-width: 700px){
    zoom:0.6;
}
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1; 
  padding: 0px 20px; 

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton{
      
  }
`;
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const Inputbox = styled.input`
height: 30px;
font-size: 13px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
color:${palette.charcoleBlack}; 
border-radius:4px;
align-self:center;
::placeholder {
    font-size: 13px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1.5px solid ${palette.charcoleBlack};
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top:5px;
  margin-bottom:5px;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-top:0px;
      margin-bottom:0px;
  
    }
    &.description {
      color: gray;
      margin-top:0px;
      text-align:start;
      margin-bottom:0px;
      line-height:16px;
      flex-wrap:wrap;
      word-break:break-all;
      white-space:nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    &.price {
      font-weight: 550;
      margin-top:0px;
    }
  }
`;
const Label = styled.label`
font-size: 13px;
font-weight: 500;
margin-bottom: 5px;
margin-top:5px;
display:flex;
align-text:start;
margin-left:3px;
align-self:flex-start;
font-family:Inter;

text-align:start;


  
`

const AddressButton = styled.div`
  background: #0F52BA;
  cursor: pointer;
  color: white;
  padding: 10px 13px;
  border-radius: 8px;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  h4 {
    text-align: start;
    font-size: 13px;
    margin: 0;
    color: white;
  }
`;
const Input = styled.input`
height: 25px;
font-size: 14px;
padding-left:10px;
word-wrap: break-word;
width:100%;
display:block;
border-radius:5px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #ddd;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  
  }

@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const CustomTable = styled.div`
width:53%;
display:flex;
height:auto;
flex-direction:column;
align-items:center;
background:#fff;
@media (min-width: 701px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;

    overflow: auto; 
}
@media (min-width: 260px) and (max-width: 700px){
    width:100%;
    flex-shrink: 0;
    zoom:0.6;
    overflow: auto; 
}

`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 12px;
font-weight:500;
padding-left:15px;
padding-right:15px;
height:35px;
border-radius:8px;
border:1px solid white;
font-weight:600;
color:white;

cursor:pointer;

`
const Title = styled.p`
color:gray;
font-size: 14px;
text-align:start;
font-weight:500;
padding:6px;

margin-bottom:0px;

`
const Value = styled.p`
color:${palette.black};
font-size: 13px;
text-align:start;
line-height:16px;
width:100%;
margin-top:0px;
margin-bottom:4px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

.nameButtton{
    font-weight:600;
    color:${palette.charcoleBlack}
}

`
const Name = styled.p`
color:${palette.charcoleBlack};
font-size: 16px;
text-align:start;
width:auto;
font-weight:500;
margin-top:0px;
margin-bottom:8px;

`
const Flexbox = styled.div`
display:flex;
min-height:100%;
margin-top:60px;
h3{
    color:${palette.charcoleBlack}
}
h4{
    color:${palette.charcoleBlack}
}


@media (min-width: 260px) and (max-width: 1300px){
    height:100%;
    }



`
const SubTable = styled.div`

display:flex;
justify-content:space-between; 
width:550px;

padding-top:25px; 

.key{
     text-align:start; 
     font-weight:bold;
     font-size:14px;
}
.text{
    font-size:14px;
}
`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`
const MoreIcon = styled.img`


width:15px;
cursor:pointer;
color:black
`
const EditContainer = styled.div`
border-radius:5px;
display:flex;
justify-content:center;
align-items:center;

padding:4px 10px 4px 10px;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#fff;
margin-right:15px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 25px;
height: 25px;
`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 90px; /* Normal height of two lines */
  font-size: 13px;
  padding: 10px;
  line-height: 16px;
  word-wrap: break-word;
  width: 100%;
  margin-top: 10px;
  display: block;
  border-radius: 4px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical; /* Allow vertical resizing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  
  &:focus {
    outline: none;
    border: 1.5px solid #000;
  }
  
  @media (min-width: 260px) and (max-width: 721px) {
    width: 96.6%;
  }
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
margin-left:30px;
`;

const LabelDropdown = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const SelectDrpoDown = styled.select`
  padding: 8.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-bottom:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:60px;
  height:60px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:10px;
margin-bottom:10px;
width: 60px;
height: 60px;

`