import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Cancel } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { palette } from '../../../utils/Palette';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { changePaymentMethod, changeQuotePaymentMethod, deleteContact, deleteJob } from '../../../store/Actions/dashboard.action';



const DeleteModal = (props) => {
    const { value } = props;
    const token = useSelector(state => state?.auth?.token)
    const dispatch = useDispatch()
    const onpress = async () => {
        if (value?.type === 'Delete') {
            const res = await dispatch(deleteContact(value?.id))
            props?.onSubmit('delete')
        }
        else if (value?.type === 'Invoice') {
            const res = await dispatch(changePaymentMethod({ "val": "Void", "invoiceNo": value?.item?.invoiceNo }))
            props?.onSubmit()
        }
        else if (value?.type === 'Job') {
            const res = await dispatch(deleteJob(value?.item?.referenceNo))
            props?.onSubmit()
        }
        else {
            const res = await dispatch(changeQuotePaymentMethod({ "val": value?.val, "invoiceNo": value?.item?.referenceNo }))
            props?.onSubmit()
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>
                    { value?.type === 'Delete' ? "Delete contact":`Delete ${value?.type}`}
                </h4>
                <Cancel onClick={() => props?.onCancel()} style={{ cursor: 'pointer' }} />
            </div>
            {
                value?.type === 'Delete' ? <NameLabel>{`Are you sure you would like to delete the contact?`}</NameLabel> :value?.type === 'Job'?<NameLabel>{`Are you sure you would like to delete the job?`}</NameLabel>: <NameLabel>{`Are you sure you would like to delete the ${value?.type} #${value?.number}?`}</NameLabel>
            }

            <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '30px', marginBottom: '20px', paddingRight: '15px', justifyContent: 'flex-end', }}>
                <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                    props?.onCancel()
                }}>Cancel</RippleButton>
                <RippleButton onClick={() => {
                    onpress()
                }}>Confirm</RippleButton>
            </div>

        </>
    )
}

export default DeleteModal;

const ProfileIcon = styled.div`
    background: ${palette.newBlue};
    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 12px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: bold;
`;

const NameLabel = styled.label`
    font-weight: 500;
    color: #000;
    font-size:14px;
    cursor:pointer;
  display:flex;

`;