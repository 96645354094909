import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, } from "react-redux";
import { getFormDetails } from "../../store/Actions/dashboard.action";
import { Box, ClickAwayListener, Tab, Tabs, useTheme } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import SwipeableViews from 'react-swipeable-views';

import cross from '../../assets/cross.png'
import FullScreenModal from "../../Components/FullScreenModal";

import RightModal from "../../Components/RightSideModal";
import RequestForm from "../../Components/Dashboard/RequestForm";
import TabPanel from "../../Components/TabPanel";
import Modal from "../../Components/Modal";
import FormList from "./components/FormList";
import FormDataList from "./components/FormDataList";
import FormDetails from "../../Components/Dashboard/FormDetails";
import { plusIcon } from "../../assets";
import CustomTabs from "../../ReuseableComponets/CustomTab";
import { DatasetOutlined, CallToActionOutlined } from "@mui/icons-material";
import { palette } from "../../utils/Palette";
import { useNavigate } from "react-router-dom";
import CreateBooking from "../../Components/Dashboard/CreateBooking";

const RequestFormList = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [loader, setLoader] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [formDetailModal, setFormDetailModal] = useState(false)
    const [copy, setCopy] = useState('Copy')
    const [copy1, setCopy1] = useState('Copy')
    const [shareModal, setShareModal] = useState(false)
    const [formNumber, setFormNumber] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const theme = useTheme();

    const [value, setValue] = React.useState(null);
    const [value1, setValue1] = React.useState(0);
    const handleChangeIndex1 = (index) => {
        setValue1(index);
    };
    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
    };

    const init = async () => {
        setLoader(true)
        const res = await dispatch(getFormDetails())
        setData(res?.data)
        setCustomerData(res?.customerData)
        setLoader(false)
    }
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        init()
    }, [])

    const handleCopyClick = (item) => {
        navigator.clipboard.writeText(`https://app.getnotifi.com/form/${item}`);

    };

    function a11yProps1(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    return (
        <>
            <Body>
                <FlexboxContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginRight: '40px' }}>
                        <h1 style={{ textAlign: 'start' }}>Forms</h1>
                        <OptionBox onClick={() => {
                            setFormNumber('')
                            setFormModal(true)
                        }}>

                            Create form
                        </OptionBox>
                    </div>
                    {loader ? <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>

                        :
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <CustomTable>
                                <CustomTabs
                                    tabpanelWidth={"400px"}
                                    tabsData={[
                                        {
                                            label: 'Forms submitted',
                                            icon: <DatasetOutlined style={{ fontSize: '16px' }} />,
                                            content: <FormDataList
                                                data={customerData}
                                                setFormDetailModal={() => setFormDetailModal(true)}
                                                setSelectedData={(items) => setSelectedData(items)}
                                            />
                                        },
                                        {
                                            label: 'Form templates',
                                            icon: <CallToActionOutlined style={{ fontSize: '16px' }} />,
                                            content: <FormList
                                                setShareModal={() => {
                                                    setCopy('Copy')
                                                    setCopy1('Copy')
                                                    setShareModal(true)
                                                }}
                                                setFormNumber={(num) => setFormNumber(num)}
                                                setFormModal={() => setFormModal(true)}
                                                data={data}
                                                init={() => init()}
                                            />
                                        },
                                    ]}
                                    value={value}
                                />

                            </CustomTable>
                        </ClickAwayListener>
                    }
                    {formModal && <FullScreenModal
                        isOpen={formModal}
                        onClose={() => setFormModal(false)}
                    >

                        <HeaderContainer>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CloseButton onClick={() => setFormModal(false)}>
                                    <Icons src={cross} />
                                </CloseButton>
                                <h4 style={{ color: palette.charcoleBlack }}>{"Create form"}</h4>
                            </div>
                        </HeaderContainer>
                        <div style={{ height: '100%', marginTop: '60px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <RequestForm
                                onCancel={() => {
                                    init()
                                    setFormModal(false)
                                }}
                                formNumber={formNumber}
                                onSelect={(item) => setFormNumber(item)}
                            />

                        </div>
                    </FullScreenModal>}
                    {formDetailModal && <RightModal
                        isOpen={formDetailModal}
                        onClose={() => setFormDetailModal(false)}
                    >

                        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                            <h2>Form detail</h2>
                            <CloseButton onClick={() => setFormDetailModal(false)}>
                                <Icons src={cross} />
                            </CloseButton>
                        </div>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <FormDetails
                                formDetail={selectedData}
                                setJobmodal={() => {
                                    setFormDetailModal(false)
                                    setModalOpen(true)
                                }}
                                onCancel={() => {
                                    init()
                                    setFormDetailModal(false)
                                }}
                            />
                        </div>
                    </RightModal>}


                </FlexboxContainer>
                {shareModal && <Modal
                    customStyle={{ background: '#fff' }}
                    onClose={() => setShareModal(false)}
                >
                    <div >
                        <Box sx={{ bgcolor: 'background.paper', width: 500 }}>
                            <Tabs
                                value={value1}
                                onChange={handleChange1}
                                indicatorColor="secondary"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#0F52BA', // Change this to your desired color
                                    },
                                }}
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab sx={{ textTransform: "none" }} label="Share link" {...a11yProps1(0)} />
                                <Tab sx={{ textTransform: "none" }} label="Add to website" {...a11yProps1(1)} />

                            </Tabs>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value1}
                                onChangeIndex={handleChangeIndex1}
                            >
                                <TabPanel value={value1} index={0} dir={theme.direction}>
                                    <InputBoxContainer style={{ marginBottom: '20px', alignItems: 'center' }}>
                                        <div style={{ fontSize: '14px', marginBottom: '20px' }}>Copy and paste your form link in a message</div>
                                        <Inputbox

                                        >
                                            {`https://app.getnotifi.com/form/${formNumber}`}
                                            <CopyButton onClick={() => {
                                                handleCopyClick(formNumber)
                                                setCopy('Copied!')

                                            }}>{copy}</CopyButton>
                                        </Inputbox>
                                    </InputBoxContainer>
                                </TabPanel>
                                <TabPanel value={value1} index={1} dir={theme.direction}>
                                    <h4 style={{ marginTop: 0, textAlign: 'start' }}>Embed code</h4>
                                    <InputBoxContainer style={{ marginBottom: '20px', }}>

                                        <div style={{ fontSize: '14px', marginBottom: '20px' }}>Place your code in your web platform </div>
                                        <EmbbededInput
                                            style={{ whiteSpace: 'pre-wrap' }}
                                        >
                                            {`
<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
<iframe src="https://app.getnotifi.com/onlineForm/${formNumber}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                                    `}
                                        </EmbbededInput>
                                    </InputBoxContainer>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <CopyButton style={{ alignSelf: 'flex-end', marginRight: -20 }} onClick={() => {
                                            handleCopyClick(`
                                        <div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
                                        <iframe src="https://app.getnotifi.com/onlineForm/${formNumber}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                                        `)
                                            setCopy1('Copied!')
                                        }}>{copy1}</CopyButton>
                                    </div>
                                </TabPanel>

                            </SwipeableViews>
                        </Box>

                    </div>
                </Modal>}

                {modalOpen && (
                    <FullScreenModal onClose={() => {


                        setModalOpen(false)

                    }}>

                        <CreateBooking
                            {...props}
                            value={{
                                name: selectedData?.customerName,
                                to: selectedData?.customerNumber,
                                email: selectedData?.customerEmail,
                                address: selectedData?.Address,
                            }}
                            newService={true}
                            form={true}
                            formDetail={selectedData}
                            setCreateBookingModal={(val) => {

                                setModalOpen(false)
                                if (val?.number) {
                                    navigate(`/dashboard/servicerequests/${val?.number}`)
                                }
                            }}
                            onSubmitsw={async (val, editValue) => {
                                navigate(`/dashboard/servicerequests/${editValue?.number}`)

                            }
                            }
                        />


                    </FullScreenModal>
                )}
            </Body>
        </>
    )
}

export default RequestFormList;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const InputBoxContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
position:relative;
margin-bottom:20px;
@media (min-width: 260px) and (max-width: 721px){
    margin-left:0px;
}
`
const Inputbox = styled.div`
align-self:center;
width:100%;
border-radius:10px;
display:flex;
justify-content:space-between;
height:35px;
align-items:center;
font-size: 14px;

font-weight: 400;
color: #344054;
background:#f3f3f3;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding-left:10px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 
  padding: 0px 20px; 

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton{
      
  }
`;
const EmbbededInput = styled.div`
width:100%;

border-radius:10px;
display:flex;
justify-content:start;
height:auto;
display:flex;
font-size: 12px;
border-style: dotted;

boder:1px solid #4169e1;
font-weight: 400;
color: gray;
background:#f3f3f3;
padding:20px;

`
const CopyButton = styled.button`
    border: 1px solid #4169e1;
    background: #4169e1;
    color: #fff;
    width: 150px;
    height: 38px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
`;



const FlexboxContainer = styled.div`
    width: 100%;

    display: flex;
    margin-left:15px;
    margin-right:15px;
    flex-direction: column;

    overflow-x:hidden;
    align-items: flex-start;
`;


const CustomTable = styled.div`
display:flex; 
flex-wrap:wrap;

width:100%;
`
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
font-size:14px;
border:1px solid #bbb;
background:#0F52BA;
color:#fff;
display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#fff;
margin-right:15px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 22px;
height: 22px;
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#fff; 
 borderRadius:4px;
color:#4169e1; 
font-size: 14px;
height:30px;
padding-left:15px;
padding-right:15px;
border-radius:8px;
margin-bottom:10px;
cursor:pointer;

`

