import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Cancel, CancelOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { useDispatch } from 'react-redux';
import { createNewCustomField } from '../../../store/Actions/dashboard.action';
import { editCustomField } from '../../../store/Actions/SettingsAction';


// Styled Components
const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  background-color: #FAFAFA;
  box-shadow: 0 12px 48px rgba(32,40,77,0.4), 0 32px 80px rgba(32,40,77,0.1);
  padding: 20px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 8px;
  position: relative;
  height: auto;
  max-height: 80vh;
  width: 35%;
  z-index: 150;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    width:85%;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
  width: 100%;
  box-sizing: border-box;
`;
const Value = styled.label`
    font-weight: 500;
    font-size: 13px;
    width:85%;
    color: gray;
    display: flex;
    margin-bottom: 18px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
`;

const Inputbox = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 10.5px;
  margin-right: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Selectbox = styled.select`
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  padding: 8px;
  font-size: 14px;
  margin-top: 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;
align-items:center;
font-weight:500;
cursor:pointer;

`

const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const AddCustomField = ({ isVisible, onClose, onSubmit,type, selectedField }) => {
    const [customFieldValue, setCustomFieldValue] = useState({
        fieldName:  '',
        fieldValue: '',
        fieldType: 'text',
        options: [],
        description:''
    });
    const [val, setVal] = useState('');
    const [customData, setCustomData] = useState([])
    const dispatch = useDispatch()
    const dataList = [
        { label: 'Text (Single Line)', value: 'text' },
        { label: 'Dropdown (Single)', value: 'dropdownSingle' },
        { label: 'Dropdown (Multiple)', value: 'dropdownMultiple' }
    ]

    useEffect(() => {
        if (selectedField?._id) {
            setCustomFieldValue({
                fieldName: selectedField?.fieldName || '',
                fieldValue: selectedField?.fieldValue || '',
                fieldType: selectedField?.fieldType || 'text',
                options: selectedField?.options || [],
                description: selectedField?.description || ''
            });
        }
        else{
            setCustomFieldValue({
                fieldName:'',
                fieldValue: '',
                fieldType:'text',
                options:[],
                description: ''
            });
        }
    }, [selectedField]);
    const deleteHandler = (index) => {

        const data = [...customData]
        data.splice(index, 1)
        setCustomData(data)


    }

    if (!isVisible) return null;

    const handleAddOption = (event) => {

    };

    const handleAddField = async () => {

        if (selectedField?._id) {
            const res = await dispatch(editCustomField(selectedField?._id,{...customFieldValue}))
            onSubmit()
        }
        else {

            const res = await dispatch(createNewCustomField({
                "fields": [{...customFieldValue,"type":type}]
            }))
            onSubmit(customFieldValue)
        }
    }



    return (
        <Overlay>
            <ModalWrapper>
                <HeaderContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloseButton onClick={() => onClose(false)}>
                            <Cancel />
                        </CloseButton>
                        <h4>{selectedField?._id ? `Edit ${type} custom field` : `Add ${type} custom field`}</h4>
                    </div>
                </HeaderContainer>
                <div style={{ overflowY: 'scroll', scrollbarWidth: 'none' }} >

                    <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label htmlFor={'Email'}>Name</Label>
                        <Inputbox
                            placeholder="Name"
                            type="text"
                            value={customFieldValue?.fieldName}

                            onChange={(e) => {
                                setCustomFieldValue({
                                    ...customFieldValue, fieldName: e.target.value
                                })
                            }}

                        />
                    </InputBoxContainer>
                    <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label htmlFor={'Email'}>
                            Select type</Label>

                        <SelectDrpoDown
                            id="category-visibility"
                            value={customFieldValue?.fieldType}
                            onChange={(event) => {
                                setCustomFieldValue({ ...customFieldValue, fieldType: event.target.value })

                            }}
                        >
                            {dataList?.map((item, index) =>
                                <Option value={item?.value}>
                                    {item?.label}
                                </Option>
                            )}


                        </SelectDrpoDown>

                    </InputBoxContainer>

                    {customFieldValue?.fieldType === 'dropdownSingle' || customFieldValue?.fieldType === 'dropdownMultiple' ? <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label htmlFor={'Email'}>Options</Label>
                        <Inputbox
                            placeholder="Type an option and press enter to add"
                            type="text"
                            value={val}

                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    const data = customFieldValue?.options
                                    data.push(val)
                                    setCustomFieldValue({
                                        ...customFieldValue, options: data
                                    })
                                    setVal('')
                                }
                            }}
                            onChange={(e) => {
                                setVal(e?.target?.value)
                            }}

                        />
                    </InputBoxContainer> : null}
                    {(customFieldValue?.fieldType === 'dropdownSingle' || customFieldValue?.fieldType === 'dropdownMultiple') ? customFieldValue?.options?.length > 0 && <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px', marginBottom: '15px', width: '95%' }}>
                        {customFieldValue?.options?.map((item, index) =>
                            <div style={{ background: '#ddd', display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', fontSize: '13px', marginBottom: '5px' }}>{item} <CancelOutlined style={{ marginLeft: '10px', fontSize: '16px', cursor: 'pointer' }} onClick={() => {
                                const data = customFieldValue?.options
                                data.splice(index, 1)

                                setCustomFieldValue({
                                    ...customFieldValue, options: data
                                })
                            }} /></div>
                        )}
                    </div> : null}
                    {<InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label htmlFor={'Email'}>Description (optional)</Label>
                        <Inputbox
                            placeholder="Description (optional)"
                            type="text"
                            value={customFieldValue?.description}

                            onChange={(e) => {
                                setCustomFieldValue({
                                    ...customFieldValue, description: e.target.value
                                })
                            }}

                        />
                    </InputBoxContainer>}


                </div>
                <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginBottom: '20px', marginTop: '10px', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#ddd', color: '#000', border: 'none', marginRight: '15px' }} onClick={() => onClose(false)}>Cancel</RippleButton>
                    <RippleButton style={{ marginRight: -15 }} onClick={() => handleAddField()}>{selectedField?._id ? "Save" : "Add"}</RippleButton>
                </div>
            </ModalWrapper>
        </Overlay>
    );
};

// PropTypes
AddCustomField.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dataList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedField: PropTypes.object.isRequired,
    type:PropTypes.string
};


export default AddCustomField;
