export const formatMobileNumber = (text) => {
  var cleaned = ("" + text)?.replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var number = [match[1] ? `+${match[1]}` : match[1], " (", match[2], ") ", match[3], "-", match[4]].join(
      ""
    );
    return number;
  }
  return text;
}
export const putCommaInvalue = (price) => {

  const cleanText1 = price?.toString()?.replace(/,/g, '');
  const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formatted;
}
export const totalprice = (price, quantity, tax) => {

  const cleanText = price?.toString()?.replace(/,/g, '');
  const taxes = ((parseFloat(price ? price : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)
  const fromatedvalue = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) + taxes;


  return fromatedvalue
}
export const taxprice = (price, quantity, tax) => {
  const cleanText = price?.toString()?.replace(/,/g, '');
  const taxes = ((parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)

  return taxes;
}
export const calculateTotalTaxAmount = (items, discountAmount) => {
  // Calculate the total price of all items
  const totalOriginalPrice = items?.reduce((acc, item) => {
      const price = parseFloat(item?.price);
      const quantity = parseInt(item?.quantity);
      if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
          return acc;
      }
      return acc + (price * quantity);
  }, 0);

  let totalTaxAmount = 0;

  // Calculate the weightage and apply the discount accordingly
  items?.forEach(item => {
      const taxAmount = parseFloat(item?.tax);
      const price = parseFloat(item?.price);
      const quantity = parseInt(item?.quantity);

      if (isNaN(price) || isNaN(quantity) || price === 0 || quantity === 0) {
          return;
      }

      const totalPrice = price * quantity;
      const weightage = totalPrice / totalOriginalPrice;
      const discountForItem = discountAmount * weightage;
      const discountedPrice = totalPrice - discountForItem;

      if (!isNaN(taxAmount) && taxAmount > 0) {
          const taxValue = parseFloat(((discountedPrice * taxAmount) / 100).toFixed(2)); // Ensure rounding to 2 decimal places
          totalTaxAmount += taxValue;
      }
  });

  return parseFloat(totalTaxAmount.toFixed(2)); // Ensure rounding to 2 decimal places
};



export const TimeListAm = [
  '6:00 AM', '6:30 AM',
  '7:00 AM', '7:30 AM',
  '8:00 AM', '8:30 AM',
  '9:00 AM', '9:30 AM',
  '10:00 AM', '10:30 AM',
  '11:00 AM', '11:30 AM',
  '12:00 PM'
];

export const TimeListPM = [
  '12:30 PM',
  '1:00 PM', '1:30 PM',
  '2:00 PM', '2:30 PM',
  '3:00 PM', '3:30 PM',
  '4:00 PM', '4:30 PM',
  '5:00 PM', '5:30 PM',
  '6:00 PM', '6:30 PM',
  '7:00 PM', '7:30 PM',
  '8:00 PM', '8:30 PM',
  '9:00 PM'
];


export const colorArray = [




 
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9", "#FFA07A", "#FF7F50", "#FF6347",
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FFD700", "#FFA500", "#E68A00", "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9", "#FFA07A", "#FF7F50", "#FF6347",
];
export const dayViewColor=[
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#DC143C", "light": "#FFB4BA" },
  { "original": "#CD5C5C", "light": "#FFCECE" },
  { "original": "#8B4513", "light": "#D6A681" },
  { "original": "#A52A2A", "light": "#FFB1B1" },
  { "original": "#D2691E", "light": "#FFCBA2" },
  { "original": "#DEB887", "light": "#FFEDD7" },
  { "original": "#F4A460", "light": "#FFE5D2" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#E9967A", "light": "#FFE7DC" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF7F50", "light": "#FFDFC8" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#DC143C", "light": "#FFB4BA" },
  { "original": "#CD5C5C", "light": "#FFCECE" },
  { "original": "#8B4513", "light": "#D6A681" },
  { "original": "#A52A2A", "light": "#FFB1B1" },
  { "original": "#D2691E", "light": "#FFCBA2" },
  { "original": "#DEB887", "light": "#FFEDD7" },
  { "original": "#F4A460", "light": "#FFE5D2" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#E9967A", "light": "#FFE7DC" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FFD700", "light": "#FFF9D5" },
  { "original": "#FFA500", "light": "#FFE6B3" },
  { "original": "#E68A00", "light": "#FFDFAA" },
  { "original": "#FF6347", "light": "#FFDACF" },
  { "original": "#FF4500", "light": "#FFD3C4" },
  { "original": "#FF8C00", "light": "#FFE4B8" },
  { "original": "#FFDAB9", "light": "#FFF6EE" },
  { "original": "#FFA07A", "light": "#FFECE3" },
  { "original": "#FF7F50", "light": "#FFDFC8" },
  { "original": "#FF6347", "light": "#FFDACF" }
]

export const calendarTeamColor=[
  { "original": "#66a8a9", "light": "#c1fbfb" },
  { "original": "#FFA500", "light": "#FFE6B3" },
  { "original": "#8458B3", "light": "#d0bdf4" },
  { "original": "#c89666", "light": "#E4C5A0" },
  { "original": "#c74a7c", "light": "#fd83b7" },
  { "original": "#D1B58A", "light": "#EDE0C7" },
  { "original": "#0025B3", "light": "#96B3E5" },
  { "original": "#596A80", "light": "#B4C0D0" },
  { "original": "#3B5627", "light": "#A4C59B" },
  { "original": "#A0E066", "light": "#CFF0A7" },
  { "original": "#71C957", "light": "#B7E5A4" },
  { "original": "#660E99", "light": "#C591D8" },
  { "original": "#D168D5", "light": "#E6B8E9" },
  { "original": "#121212", "light": "#707070" },
  { "original": "#A5A5A5", "light": "#D6D6D6" }
]




export const linethemes = [
  "#0055bc",
  "#9966ff",
  "#00a1c1",
  "#ed6802",
  "#004488",
  "#997799",
  "#0099aa",
  "#ee7744"
]

export const colorThemes = [
  
  {
    name: "Light Steel Blue",
    outline: "#B0C4DE",
    background: "#F0F8FF",
    text: "#4682B4"
  },
  {
    name: "Light Pink",
    outline: "#FFB6C1",
    background: "#FFF0F5",
    text: "#C71585"
  },
  {
    name: "Lavender",
    outline: "#E6E6FA",
    background: "#F8F8FF",
    text: "#6A5ACD"
  },
  {
    name: "Peach Puff",
    outline: "#FFDAB9",
    background: "#FFF5EE",
    text: "#FF6347"
  },
  {
    name: "Pale Green",
    outline: "#98FB98",
    background: "#F0FFF0",
    text: "#2E8B57"
  },
  {
    name: "Pale Turquoise",
    outline: "#AFEEEE",
    background: "#E0FFFF",
    text: "#20B2AA"
  },
  {
    name: "Khaki",
    outline: "#F0E68C",
    background: "#FFFACD",
    text: "#DAA520"
  },
  {
    name: "Thistle",
    outline: "#D8BFD8",
    background: "#F5F5DC",
    text: "#9370DB"
  },
  {
    name: "Pink",
    outline: "#FFC0CB",
    background: "#FFE4E1",
    text: "#FF69B4"
  },
  {
    name: "Cornsilk",
    outline: "#FFF8DC",
    background: "#FAF0E6",
    text: "#F4A460"
  },
  {
    name: "Powder Blue",
    outline: "#B0E0E6",
    background: "#F0F8FF",
    text: "#4682B4"
  },
  {
    name: "Navajo White",
    outline: "#FFDEAD",
    background: "#FFF8DC",
    text: "#FFA07A"
  },
  {
    name: "Light Cyan",
    outline: "#E0FFFF",
    background: "#F0FFFF",
    text: "#00CED1"
  },
  {
    name: "Papaya Whip",
    outline: "#FFEFD5",
    background: "#FFF5EE",
    text: "#FF4500"
  },
  {
    name: "Plum",
    outline: "#DDA0DD",
    background: "#E6E6FA",
    text: "#BA55D3"
  }
];



export const colorStatusArray = [
  '#A5B5FF', '#FFD4A5', '#A5D4FF', '#FFAAC4', '#AAC4FF', '#D1A5FF', '#FFB5D1',
  '#B5E6FF', '#FFD1B5', '#A5FFA5', '#FFB3BA', '#A3FFC4', '#B3FFFF', '#FFDFBA',
  '#BAE1FF', '#D4A5A5', '#A5D4D4', '#33FF57', '#3357FF', '#FF33A6', '#33FFF0',
  '#FFC733', '#C4A3FF', '#C733FF', '#A3C4FF',  '#FFC4C4', '#C4FFA5',
  '#A5A3FF', '#A3FFA5', '#C4D4A5', '#E6FFB5', '#BFFCC6', '#FF6347', '#FFC4A3',
  '#FFA6C4',  '#A5C4AA', '#C4AFAA', '#AAF3C4', '#C4AFFA',
  '#FAA4D4', '#FAD4A4',
];


