import React, { useEffect, useState } from "react";
import { DirectionsRenderer, GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import styled from "styled-components";
import { editCreateBookings, getCalendarMapDataV2 } from "../../store/Actions/dashboard.action";
import { useDispatch } from "react-redux";
import mapStyles from "../../Components/Tracking/mapStyles";
import RightModal from "../../Components/RightSideModal";
import cross from '../../assets/cross.png'
import moment from "moment";
import { colorThemes, formatMobileNumber, putCommaInvalue, TimeListAm, TimeListPM } from "../../utils/Commonfunction";
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { Divider, MenuItem, Select, TextField } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import MapCard from "../../Components/Dashboard/Bookings/Component/MapCard";
import RippleButton from "../../ReuseableComponets/RippleButton";
import { CalendarMonth, ContactPage, ListAlt, NoteAlt, Person2, Schedule } from "@mui/icons-material";
import { palette } from "../../utils/Palette";
import DatePickerComponent from "../../ReuseableComponets/DatePickerComponent";

const filter = createFilterOptions();
function Map(props) {
    const [activeMarker, setActiveMarker] = useState(null);
    const [loader, setLoader] = useState(false)
    const [tabData, setTabData] = useState([])
    const [values, setValues] = useState({})
    const [notes, setNotes] = useState('')
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [team, setTeam] = React.useState(props?.team || [])
    const [isCustomRoute, setIsCustomRoute] = useState(false);
    const [selectedTeam, setSelectedTeam] = React.useState(props?.value?.team || [])
    const [selectedItem, setSelectedItem] = useState({})
    const [isOpenTask, setIsModalTask] = useState(false)
    const [value3] = React.useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [isRoute, setIsRoute] = useState(false);
    const [editValue, setEditValue] = useState({
        "name": props?.value?.name || "",
        "email": props?.value?.email || "",
        "address": props?.value?.address || "",
        "to": props?.value?.number || "",
        "businessName": props?.value?.businessName || ''

    })
    const dispatch = useDispatch()
    const haversine = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };


    const init = async () => {
        setLoader(true);
        let locat = {};
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                locat = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            },

                (error) => {
                    setCurrentLocation({
                        lat: 38.7946,
                        lng: -106.5348,
                    });

                }
            );
        }
        else {

            setCurrentLocation({
                lat: 38.7946,
                lng: -106.5348,
            });

        }
        let res = await dispatch(getCalendarMapDataV2());
        setTeam(res?.data?.team)
        if (res.status === true) {
            const data = [...res?.data?.detailData]?.filter(items => !items?.date && items?.customerAddress);
            const promises =
                await Promise.all(
                    data.map((item, index) => {
                        if (item?.latitude && item?.longitude) {
                            return {
                                name: item?.customerAddress,
                                position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                                item: item
                            };
                        }
                        return null;
                    })
                )
            const filteredData = promises.filter(item => item !== null);

            let startingLocation = filteredData?.[0];
            let remainingLocations = [...filteredData.slice(1)];
            let optimizedOrder = filteredData?.length > 0 ? [startingLocation] : [];
            if (filteredData?.length > 0) {

                const sortByProximity = (startLocation, locations) => {
                    return locations.sort((a, b) => {
                        const distanceA = haversine(startLocation.position.lat, startLocation.position.lng, a.position.lat, a.position.lng);
                        const distanceB = haversine(startLocation.position.lat, startLocation.position.lng, b.position.lat, b.position.lng);
                        return distanceA - distanceB;
                    });
                };


                while (remainingLocations.length > 0) {
                    let closestLocation = sortByProximity(optimizedOrder[optimizedOrder.length - 1], remainingLocations)[0]; // Get the closest location
                    optimizedOrder.push(closestLocation);
                    remainingLocations = remainingLocations.filter(location => location !== closestLocation); // Remove the selected location from remaining
                }

                optimizedOrder = optimizedOrder.map((location, index) => ({
                    ...location,
                    index: index + 1
                }));
                setTabData(optimizedOrder);
            }
            else {
                setTabData(filteredData)
            }
            setLoader(false);

        }
    };


    useEffect(() => {
        init()
    }, [])

    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const handleOnLoad = (map) => {
        if (isCustomRoute) {

        }
        else {


            const bounds = new window.google.maps.LatLngBounds();
            tabData?.forEach(({ position }) => bounds.extend(position));
            map.fitBounds(bounds);
        }
    };


    const onPress = async () => {
        var newDate = values?.time ? new Date() : '';
        var newDate1 = values?.endTime ? new Date() : '';
        const selectedData = []

        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (values?.time) {
            const val = values.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (values?.endTime) {
            const val3 = values.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }

        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        await dispatch(editCreateBookings({ ...selectedItem?.item, ...values, "description": notes, ...editValue, phone: editValue?.to, time: newDate, endTime: newDate1, "referenceNo": selectedItem?.item?.referenceNo, "workersAssigned": selectedData }))
        setIsModalTask(false)

        init()
        if (props?.noheader) {
            props?.onSubmit()
        }

    }
    const handleMarkerClick = (location, index, starting) => {
        setSelectedLocations((prevSelected) => {
            const alreadySelected = prevSelected.find(
                (loc) => loc.lat === location.lat && loc.lng === location.lng
            );
            let updatedLocations;
            if (alreadySelected) {
                updatedLocations = prevSelected.filter(
                    (loc) => loc.lat !== location.lat || loc.lng !== location.lng
                );
            } else {
                updatedLocations = [...prevSelected, location];
            }

            if (updatedLocations.length >= 2) {
                generateDirections(updatedLocations);
            } else {
                setDirectionsResponse(null);
            }

            const updateData = [...tabData].map((item, idx) => {
                if (idx === index - 1) {
                    return {
                        ...item,
                        "index": selectedLocations?.length + 1
                    };
                } else {
                    return {
                        ...item,
                        "index": starting ? '' : item?.index
                    };
                }
            });

            setTabData(updateData);
            return updatedLocations;
        });
    };

    const generateDirections = (locations) => {
        const waypoints = locations.slice(1, locations.length - 1).map((location) => ({
            location,
            stopover: true,
        }));

        const directionsService = new window.google.maps.DirectionsService();

        const request = {
            origin: locations[0],
            destination: locations[locations.length - 1],
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result);
            } else {
                console.error('Error fetching directions', status);
            }
        });
    };

    const roundToNearestHalfHour = (date) => {
        const momentDate = moment(date);
        const minutes = momentDate.minutes();
        const roundedMinutes = minutes < 15 ? 0 : (minutes < 45 ? 30 : 60);
        if (roundedMinutes === 60) {
            momentDate.add(1, 'hour');
        }

        momentDate.minutes(roundedMinutes).seconds(0);

        return new Date(momentDate);
    };


    return (
        loader ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
            src={require('../../assets/loaders.json')}
            className="player"
            style={{ width: '200px', height: '200px' }}
            loop
            autoplay
        />
        </div> :
            <Body style={{ ...props?.customStyle }}>
                {!props?.noheader && <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <CloseButton onClick={() => props?.setModal(false)}>
                        <Icons src={cross} />
                    </CloseButton>
                    <h3>
                        {"Unscheduled jobs"}
                    </h3>
                </div>}

                <div style={{ display: 'flex', height: '100vh', }}>
                    <MapCardContainer>
                        {tabData?.length > 0 && <RippleButton style={{ marginRight: '10px', width: '100%', marginBottom: '15px', marginTop: '5px' }} onClick={() => {
                            if (isRoute || isCustomRoute) {
                                if (isCustomRoute) {

                                    const optimizedOrder = tabData?.map((location, index) => ({
                                        ...location,
                                        index: index + 1
                                    }));

                                    setTabData(optimizedOrder);
                                }

                                setIsCustomRoute(false)
                                setDirectionsResponse(null)
                                setSelectedLocations([])
                                setIsRoute(false)

                            }
                            else {
                                setIsRoute(true)
                                let mapArr = []
                                tabData?.map((item) => {
                                    mapArr.push(item?.position)
                                })

                                if (mapArr?.length > 1) {
                                    const waypoints = mapArr.slice(1, mapArr.length - 1).map((location) => ({
                                        location,
                                        stopover: true,
                                    }));

                                    const directionsService = new window.google.maps.DirectionsService();

                                    const request = {
                                        origin: mapArr?.[0],
                                        destination: mapArr[mapArr.length - 1],
                                        waypoints: waypoints,
                                        optimizeWaypoints: true,
                                        travelMode: window.google.maps.TravelMode.DRIVING,
                                    };
                                    directionsService.route(request, (result, status) => {
                                        if (status === window.google.maps.DirectionsStatus.OK) {
                                            setDirectionsResponse(result);
                                        } else {
                                            setDirectionsResponse([]);

                                        }
                                    });
                                }
                                else if (tabData.length === 1) {
                                    setDirectionsResponse(null);

                                }
                                else {
                                    setDirectionsResponse([]);
                                }
                            }



                        }}>
                            {(isRoute || isCustomRoute) ? "Reset Route" : "Optimize Route"}
                        </RippleButton>}
                        <MapCard
                            data={tabData}
                            type={true}
                            onclick={(item) => {
                                setEditValue({
                                    name: item?.item?.name,
                                    to: item?.item?.customerNumber,
                                    email: item?.item?.email,
                                    address: item?.item?.customerAddress
                                })
                                setSelectedItem(item)
                                setIsModalTask(true)
                                setSelectedTeam([])
                                setValues({})
                                setNotes('')
                            }}
                        />
                    </MapCardContainer>
                    {tabData?.length > 0 ? <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{ width: "100vw", height: "100vh" }}
                        options={{
                            styles: mapStyles // Apply custom map styles
                        }}
                        center={selectedItem?.position && selectedItem?.position}
                        zoom={selectedItem?.position ? 16 : 4}
                    >
                        {(isRoute || isCustomRoute) && (directionsResponse && tabData?.length) && (
                            <DirectionsRenderer
                                directions={directionsResponse}

                                options={{
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        strokeColor: '#0F52BA',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,

                                        zIndex: 0,
                                        icons: [
                                            {
                                                icon: {
                                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                    scale: 1.5,
                                                    strokeColor: '#0F52BA',
                                                    fillColor: '#0F52BA',
                                                    fillOpacity: 0.5,
                                                },
                                                offset: '0%',
                                                repeat: '70px'
                                            }
                                        ],

                                    },
                                }}
                            />
                        )}


                        {tabData?.map((item, index) => (

                            <MarkerF
                                key={index}
                                position={item?.position}
                                onClick={() => {
                                    if (isCustomRoute) {
                                        handleMarkerClick(item?.position, index + 1)
                                    }
                                    else {
                                        setActiveMarker(index)
                                    }


                                }}
                                icon={isCustomRoute ? {
                                    url: selectedLocations.find(
                                        (loc) => loc.lat === item?.position?.lat && loc.lng === item?.position.lng
                                    )
                                        ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                                        : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                                } : {
                                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                                }}

                            >
                                {activeMarker === index ? (
                                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                        <div key={index} style={{ width: '300px', height: '100%', maxHeight: '400px', overflowY: 'scroll', }}>
                                            <EventBox>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                                    <div>
                                                        <EventTitle>{item?.item?.name}</EventTitle>
                                                        {item?.item?.email && <StyledTypography style={{ marginBottom: '6px' }} variant="body2" color="textSecondary">
                                                            {(item?.item?.email)}
                                                        </StyledTypography>}
                                                        <StyledTypography variant="body2" color="textSecondary">
                                                            {formatMobileNumber(item?.item?.customerNumber)}
                                                        </StyledTypography>

                                                    </div>
                                                    <BookingStatus status={item?.item?.bookingStatus}>
                                                        <span style={{ marginRight: '5px' }}>●</span>
                                                        {item?.item?.bookingStatus === 'Waiting for confirmation' ? 'Open' : item?.item?.bookingStatus}
                                                    </BookingStatus>
                                                </div>
                                                <Divider />
                                                <LocationTitle>Location</LocationTitle>
                                                <StyledTypography >
                                                    {item?.item?.customerAddress}
                                                </StyledTypography>

                                            </EventBox>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', background: '#fff' }}>

                                                <RippleButton
                                                    onClick={() => {
                                                        setEditValue({
                                                            name: item?.item?.name,
                                                            to: item?.item?.customerNumber,
                                                            email: item?.item?.email,
                                                            address: item?.item?.customerAddress
                                                        })
                                                        setValues({})
                                                        setSelectedItem(item)
                                                        setSelectedTeam([])
                                                        setNotes('')
                                                        setIsModalTask(true)
                                                        setActiveMarker(null)
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ width: '130px', fontWeight: '500', fontSize: '13px', }}
                                                >
                                                    Edit details
                                                </RippleButton>
                                                <RippleButton
                                                    onClick={() => {
                                                        setIsCustomRoute(true)
                                                        handleMarkerClick(item?.position, index + 1, true)
                                                        setActiveMarker(null)

                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ width: '130px', fontWeight: '500', fontSize: '13px', background: '#f3f3f3', color: 'black', border: '1px solid #ddd' }}
                                                >
                                                    Start Routing
                                                </RippleButton>
                                            </div>
                                        </div>
                                    </InfoWindow>
                                ) : null}
                            </MarkerF>
                        ))}
                    </GoogleMap> 
                    :
                     <GoogleMap
                        zoom={4}
                        mapContainerStyle={{ width: "100vw", height: "100vh" }}
                        options={{
                            styles: mapStyles
                        }}

                        center={{ lat: 1.10, lng: 1.10 }}

                    >
                    </GoogleMap>}
                </div>
                {isOpenTask && <RightModal onClose={() => setIsModalTask(false)} customStyle={{ width: '30%' }} isOpen={isOpenTask}>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => setIsModalTask(false)}>
                                <Icons src={cross} />
                            </CloseButton>
                            <h4>Schedule job</h4>

                        </div>
                        <RippleButton style={{ marginRight: '0px' }} onClick={() => onPress()}  >Save</RippleButton>
                    </HeaderContainer>
                    <div>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', flexDirection: 'column' }}>
                            <StyleBox>

                                <div className='keyContainer' style={{ padding: '5px 10px 5px 10px' }}>
                                    <LeftHeader style={{ marginLeft: '0px' }}>{"Contact details"}</LeftHeader>
                                    <ContactPage style={{ width: '18px' }} />
                                </div>

                                <FlexDetail>
                                    <ValueName> {editValue?.name}</ValueName>
                                    <ValueName>{formatMobileNumber(editValue?.to)}</ValueName>
                                    {editValue?.address && <ValueName>{editValue?.address}</ValueName>}
                                </FlexDetail>
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer' style={{ padding: '5px 10px 5px 10px' }}>
                                    <LeftHeader style={{ marginLeft: '0px' }}>{"Schedule"}</LeftHeader>
                                    <CalendarMonth style={{ width: '18px' }} />
                                </div>

                                <div className='valueContainer' style={{ flexDirection: 'column' }}>

                                    <div className='datewrapper'>
                                        <DatePickerComponent
                                            label="Start date"
                                            selectedDate={values?.date}
                                            onDateChange={(text) => setValues({ ...values, date: text, endDate: text, time: moment(roundToNearestHalfHour(new Date())).format('hh:mm A'), endTime: moment(roundToNearestHalfHour(moment(new Date()).add(1, 'hour')))?.format('hh:mm A') })}

                                        />
                                        <div style={{ position: 'relative' }}>
                                            <Label style={{ marginLeft: '15px', fontWeight: '600' }}>Start time</Label>
                                            <SelectTimebox

                                                renderValue={() =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                        {values?.time || 'Time'}
                                                        <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                    </div>
                                                } defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, time: event.target.value })}>
                                                {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                    <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                )}
                                            </SelectTimebox>
                                        </div>
                                    </div>
                                    <div className='datewrapper' style={{ marginBottom: '10px' }}>
                                        <DatePickerComponent
                                            label="End date"
                                            selectedDate={values?.endDate}
                                            onDateChange={(text) => setValues({ ...values, endDate: text })}

                                        />
                                        <div style={{ position: 'relative' }}>
                                            <Label style={{ marginLeft: '15px', fontWeight: '600' }}>End time</Label>
                                            <SelectTimebox

                                                renderValue={() =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                        {values?.endTime || 'Time'}
                                                        <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                    </div>
                                                } defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, endTime: event.target.value })}>
                                                {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                    <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                )}
                                            </SelectTimebox>
                                        </div>
                                    </div>

                                </div>
                            </StyleBox>
                            {selectedItem?.item?.items?.length > 0 && <StyleBox >
                                <div className='keyContainer' style={{ padding: '5px 10px 5px 10px' }}>
                                    <LeftHeader style={{ marginLeft: '0px' }}>{"Line items"}</LeftHeader>
                                    <ListAlt style={{ width: '18px' }} />
                                </div>
                                <div style={{ padding: '5px 10px 5px 10px' }}>

                                    {
                                        selectedItem?.item?.items?.map(item =>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                                <div style={{ display: 'flex', alignItems: 'start' }}>

                                                    <div>
                                                        <p style={{ fontSize: '14px', textAlign: 'start', }}>{item?.name} × {item?.quantity || '1'}</p>
                                                        <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-5px' }}>{item?.description}</p>
                                                    </div>
                                                </div>
                                                <p style={{ fontSize: '14px' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                            </div>

                                        )
                                    }
                                </div>
                            </StyleBox>}
                            <StyleBox>
                                <div className='keyContainer' style={{ padding: '5px 10px 5px 10px' }}>
                                    <LeftHeader style={{ marginLeft: '0px' }}>{"Crew"}</LeftHeader>
                                    <Person2 style={{ width: '18px' }} />
                                </div>
                                <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                    <Autocomplete1
                                        value={value3}
                                        onChange={(event, newValue) => {
                                            console.log(newValue, "dfkvndkkjdvkjdvfjkn")
                                            const searchString = 'Add new contact';
                                            console.log(newValue, 'dsckjnl')
                                            if (newValue && newValue?.firstName?.includes(searchString)) {

                                            }

                                            else if (typeof newValue?.firstName === 'string') {
                                                setSelectedTeam([...selectedTeam, newValue])
                                            }
                                            else {
                                                setSelectedTeam([...selectedTeam, newValue])
                                            }

                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option?.firstName);
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="free-solo-with-text-demo"
                                        options={team}
                                        getOptionLabel={(option) => {
                                            if (typeof option?.firstName === 'string') {
                                                return option.firstName;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option?.firstName}</li>}
                                        sx={{
                                            width: '98%', '& input': {
                                                height: '5px', 
                                            },
                                        }}
                                        freeSolo
                                        style={{ height: 30, }}
                                        renderInput={(params) => (
                                            <div>
                                                <TextField style={{ fontSize: '14px' }} {...params}
                                                    placeholder="Add crew member" />

                                            </div>
                                        )}
                                    />
                                    {selectedTeam?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '30px', marginLeft: '12px', width: '295px' }}>
                                        {selectedTeam?.map((item, index) =>
                                            <div style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }}>{item?.firstName} <Icons style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                                setSelectedTeam(arr)
                                            }} src={cross} /></div>
                                        )}
                                    </div> : null}

                                </div>
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer' style={{ padding: '5px 10px 5px 10px' }}>
                                    <LeftHeader style={{ marginLeft: '0px' }}>{"Field notes"}</LeftHeader>
                                    <NoteAlt style={{ width: '18px' }} />
                                </div>
                                <div className='valueContainer' style={{ flexDirection: 'column' }}>
                                    <InputText
                                        placeholder="Field notes"
                                        type="text"
                                        value={notes}
                                        onChange={(e) => {
                                            setNotes(
                                                e.target.value
                                            )
                                        }}

                                    />
                                </div>
                            </StyleBox>

                        </div>
                    </div>
                </RightModal>}
            </Body>
    );
}

export default Map;
const Body = styled.div`
display:flex;
flex-direction:column;
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;

`
const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  margin-left:-20px;
  width: 100%;
  display: flex;
align-self:center;
  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 
  padding: 0px 20px; 

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton{
      
  }
`;

const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid ${palette.smokeWhite};
background:white;
width:98%;
margin-top:20px;
border-radius:4px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
h5{
    margin-top:0px;
    margin-bottom:0px;
}
p{
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left:10px;
    margin-right:10px;
    line-height:16px;
}
.keyContainer{
    padding: 0px 0px 0px 10px;
    font-size:16px;
    font-weight:500;
    color:${palette.black};
    background:#f9f9f9;
display:flex;
justify-content:space-between;
border-bottom:1px solid ${palette.smokeWhite};
align-items:center;
}
.valueContainer{
    padding:7px;
    
    background:#fff;
    font-size:14px;
  
display:flex;
}
.valuewrapper{
    margin-top:20px;
    margin-bottom:30px; 
    display:flex; 
    justify-content:center; 
}
.datewrapper{
    margin-top:10px;
    display:flex; 
    justify-content:space-around;
    width:100%;  
}
`;
const LeftHeader = styled.p`
color:#000;
font-size: 15px;
text-align:start;
margin-top:0px;
margin-bottom:0px;
width:70%;
font-weight:600;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const Icons = styled.img`
width: 15px;
height: 15px;
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`

const FlexDetail = styled.div`
width:100%;
display:flex;
flex-direction:column;
padding:2px 10px 10px 10px;
align-items:center;

`
const ValueName = styled.p`
color:#000;
font-size: 13px;
text-align:start;
margin-top:8px;
margin-bottom:0px;
width:100%;
font-weight:400;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const InputText = styled.textarea`
  font-family: 'Inter', sans-serif;
  height: 60px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 91%;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical; 
  margin:5px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.renderdiv{
    display:flex;
    justify-content:space-between; 
    align-items:center; 
    font-size:13px; 
}
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const EventBox = styled.div`
  padding: 4px;
overflow-y:scroll;
`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
  text-align:start;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
  text-align:start;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  text-align:start;
  
`;
const MapCardContainer = styled.div`
  width: 370px;
  padding: 10px;
  overflow-y: scroll;
  padding-bottom: 100px;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none; 
  }
`;
