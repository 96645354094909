import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { useDispatch } from 'react-redux';
import { addHasgtag } from '../../../store/Actions/dashboard.action';

const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  background-color: #FAFAFA;
  box-shadow: 0 12px 48px rgba(32,40,77,0.4), 0 32px 80px rgba(32,40,77,0.1);
  padding: 20px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 8px;
  position: relative;
  height: auto;
  max-height: 50vh;
  width: 20%;
  z-index: 150;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    width:85%;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
  width: 100%;
  box-sizing: border-box;
`;


const Inputbox = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 10.5px;
  margin-right: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;






const AddTags = ({ isVisible, onClose, onSubmit }) => {
    const [tagName, setTagName] = useState('');

    const dispatch = useDispatch()

    const handleAddField = async () => {
        if (!tagName) {
            return;
        }

        const res = await dispatch(addHasgtag({
            "hashTags": tagName
        }))
        onSubmit()

    }
    if (!isVisible) return null;



    return (
        <Overlay>
            <ModalWrapper>
                <HeaderContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloseButton onClick={() => onClose(false)}>
                            <Cancel />
                        </CloseButton>
                        <h4>{"Add tag"}</h4>
                    </div>
                </HeaderContainer>
                <div style={{ overflowY: 'scroll', scrollbarWidth: 'none' }} >

                    <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label htmlFor={'Email'}>Name</Label>
                        <Inputbox
                            placeholder="Name"
                            type="text"
                            value={tagName}
                            onChange={(e) => {
                                setTagName(e.target.value)
                            }}

                        />
                    </InputBoxContainer>


                </div>
                <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginBottom: '20px', marginTop: '10px', justifyContent: 'flex-end', }}>
                    <RippleButton style={{ background: '#ddd', color: '#000', border: 'none', marginRight: '15px' }} onClick={() => onClose(false)}>Cancel</RippleButton>
                    <RippleButton style={{ marginRight: -15 }} onClick={() => handleAddField()}>{"Add"}</RippleButton>
                </div>
            </ModalWrapper>
        </Overlay>
    );
};

// PropTypes
AddTags.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dataList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};


export default AddTags;
