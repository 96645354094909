import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'react-redux';
import Activeplus from '../../assets/Activeplus.png'
import { getAllconversations, getname, getnumber } from '../../store/Actions/Auth.action';
import { getContactDetail, getUserDetails, submitEditContacts } from '../../store/Actions/dashboard.action';
import user from '../../assets/user1.png'
import convo from '../../assets/message.png'
import calltel from '../../assets/telephone.png'
import { formatMobileNumber } from '../../utils/Commonfunction';
import Calendar from "react-calendar";
// import arrow from '../.././assets/arrow.png'
function UserDetails(props) {
    const [value, setValue] = React.useState('OPEN');
    const [date, setDate] = React.useState(new Date());
    const inputRef = useRef(null);
    const [isEditing, setEditing] = useState({
        name: false,
        email: false,
        address: false
    });
    const [userDet, setUserDet] = useState(props?.value)
    const detail = useSelector(state => state?.auth?.convo)
    const globenumber = useSelector(state => state?.auth?.globenum)
  
    let fromnumber = localStorage.getItem('fromnumber')

    const init = async () => {
        if(props?.value?.customerPhoneNumber){
        const res1 = await dispatch(getContactDetail(props?.value?.to ||props?.value?.customerPhoneNumber|| globenumber))

        if (res1?.data?.[0]?._id) {
            setUserDet(res1?.data[0])
            props?.setUserDet(res1?.data[0])

        }
    }

    }
    useEffect(() => {
        setUserDet(props?.value)
        init()
    }, [props?.value?.number, globenumber]);

    let dispatch = useDispatch()

    const onClick = async (val) => {
        setEditing({ ...isEditing, [val]: false })

        await dispatch(submitEditContacts({
            "email": userDet?.email || "",
            "name": userDet?.name || "",
            "phoneNumber": userDet?.to || userDet?.number || "",
            "profilePhoto": "",
            "_id": userDet?._id,
            "address": userDet?.address || ''
        }))

    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    return (
        <Main style={props?.mainStyle}>
            <br />

            <Subdiv>
                <Name>{userDet?.name} &nbsp; </Name>
                <span style={{ fontSize: '16px',textAlign:'start',marginTop:'10px', fontWeight: '300' }}>{formatMobileNumber(userDet?.to || userDet?.number||props?.value?.customerPhoneNumber)}</span>

                <FlexBox >
                    <Title>Address</Title>

                    <Inputbox
                        type="text"
                        placeholder='Address'
                        value={userDet?.address}
                        style={{ height: 30, marginTop: '-12px', width: '100%' }}
                        onChange={(e) => {
                            setUserDet({
                                ...userDet, address: e.target.value
                            })
                            props?.setUserDet({
                                ...userDet, address: e.target.value
                            })
                        }}
                        onBlur={() => onClick("address")}
                    />

                </FlexBox>
                <FlexBox>
                    <Title>Email</Title>
                    <Inputbox
                        type="text"
                        value={userDet?.email}
                        placeholder='Email'
                        style={{ height: 30, marginTop: '-12px', width: '100%' }}
                        onChange={(e) => {
                            setUserDet({
                                ...userDet, email: e.target.value
                            })
                            props?.setUserDet({
                                ...userDet, email: e.target.value
                            })
                        }}
                        onBlur={() => onClick("email")}
                    />

                </FlexBox>
                {/* <FlexBox>
                    <Title>Invoice number</Title>
                    <Inputbox
                        type="text"
                        value={userDet?.email}
                        placeholder='#'
                        style={{ height: 30, marginTop: '-12px', width: '100%' }}
                        onChange={(e) => {
                            setUserDet({
                                ...userDet, email: e.target.value
                            })
                        }}
                        onBlur={() => onClick("email")}
                    />

                </FlexBox> */}
                <FlexBox style={{ zIndex: 10000, width: '295px',height:'200px', marginRight:'-60px',marginLeft:'-10px',marginTop:'30px', paddingBottom:'30px', borderRadius:'5px',  paddingRight:'-25px' }}>
               
                {/* <Title style={{marginLeft:'0px'}}>Select date</Title> */}
                {props?.date&&<Calendar className='react-calendar'
                    value={date}
                    onChange={(val)=>{
                        setDate(val)
                        props?.setDate(val)
                    }}
                      minDate={new Date()}
                    />}

                 
                </FlexBox>

            </Subdiv>
        </Main>
    )
}
export default UserDetails;
const Inputbox = styled.input`
height:10px;
border-radius:5px;
font-size: 14px;
padding-left:10px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Dates = styled.div`

width:49.5%;
margin-right:15px;
border-radius:5px;
border:1px solid lightgray;
padding-left:10px;
padding-top:9px;
display:flex;
align-items:center;
justify-content:start;
font-size:12px;
height:35px;
color:black;
font-weight:500;
font-family:Inter;

`

const Main = styled.div`

width:300px;
padding-top:5px;
padding-bottom:10px;
display:inline;
height: auto;
background:#FCFBFC;
margin-left:-15px;

`
const Profile = styled.div`
width:80px;
height:80px;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
border-radius:10px;
background:#4169e1;
color:white;
font-weight:600;
font-size:24px;

`
const IconBox = styled.div`
width:35px;
height:35px;
display:flex;
border:1px solid #DDDDDD;
justify-content:center;
align-items:center;
padding:3px;
border-radius:5px;
margin-left:8px;
margin-right:8px;
color:white;

font-weight:600;
font-size:14px;

`
const FlexDiv = styled.div`
display:flex;
margin-top:-20px;


`
const FlexBox = styled.div`
display:flex;
flex-direction:Column;


`
const Icon = styled.img`
height:22px;
width:22px;

`
const Names = styled.span`
margin-top:4px;
`
const Heading = styled.p`
font-weight:600;
font-size: 15px;
padding-left:10px;
margin-bottom:-12px;
text-align:start;
`
const SearchContainer = styled.div`
display: flex;
align-items: center;
width: 95%;
border: 1px solid #E2E8F0;
border-radius: 8px;
padding: 3px;
color:#E2E8F0;
`
const Searchinput = styled.input`
flex: 1;
border: none;
padding: 5px;
font-size: 14px;
outline: none;

`
const Searchbutton = styled.button`
background: none;
  border: none;
  padding: 0;
`
const Searchimg = styled.img`
width: 15px;
height: 15px;
margin-left:10px;
margin-top:2px;
margin-right:5px;
`
const Subdiv = styled.div`
display:flex;
flex-direction:column;

border-radius:10px;
padding-left:20px;
padding-right:20px;
margin-top:-20px;


`
const AddDiv = styled.div`
height:35px;
width:35px;
background:rgb(70, 110, 255);
border-radius:8px;
margin-left:8px;
`
const AddIcon = styled.img`
height:60%;
width:60%;
margin-top:8px;
`
const CustomTab = styled(Tab)`
width:50%;
`
const OutProfile = styled.div`
background:#e1275f;
color:white;
height:18px;
padding:13px;
padding-left:15px;
padding-right:15px
padding-bottom:0px;
margin-left:10px;
border-radius:10px;

margin-bottom:3px;
`
const Convos = styled.div`
height:65vh;
overflow-y: scroll;
&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.blueseg{
background:rgb(70, 110, 255);
}
.whitetext{
color:white;
}
.smokytext{
color:#FAFAFA;
}
.limit2{
color:white;
background:#1e90ff;
margin-bottom:15px;
}
`
// const Rating=styled.img`
// height:15px;
// width:15px;
// padding:1px;
// `
// const Icons=styled.img`
// height:20px;
// width:18px;
// padding-right:6px;
// margin-bottom:-5px;
// `
const Name = styled.p`
font-size: 20px;
text-align:start;
color: rgb(45, 47, 49);
margin-bottom:0px;
font-weight: 600;
width:100%;
`
const Title = styled.p`
color:#787373;
font-size: 14px;
text-align:start;
margin-left:-10px;
padding:6px;
width:100%;
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;
padding:5px;
cursor:pointer;
margin-top:-15px;
width:100%;
:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`

const ConvoHead = styled.div`
padding-left:20px;
padding-right:20px;
margin:10px;
border-radius:8px;
border-bottom:1px solid #d4d4d4;
cursor:pointer;
padding-bottom:20px;
.limit{
background:rgba(231, 62, 81, 0.16);
color: rgb(231, 62, 81);
margin-bottom:15px;
}
.review{
background:rgb(231, 237, 254);
color:rgb(99, 114, 125);
}
.general{
background:#d8e5f2;
color:rgb(99, 114, 125);
}
.general2{
background:#3973ac;
color:#FAFAFA;
}
`
const Namediv = styled.div`
display:flex;
justify-content:space-between;
`
const Flexdiv = styled.div`
display:flex;
margin-top:-5px;
`
const ChatText = styled.div`
font-size:13px;
margin-left:0px;
margin-top:-4px;
color:#a1a1a1;
text-align:start;
`
// const Googleimg=styled.img`
// height:20px;
// width:20px;
// margin-right:5px;
// border-radius:4px;
// margin-top:-6px;
// `
// const Boxes=styled.div`
// border-radius:5px;
// width:60px;
// font-size:10px;
// .boxtext{
// padding-top:3px;
// padding-bottom:3px;
// }
// `

const Sublogo = styled.img`


width: 37px;
margin-left:15px;
margin-top:17px;
height: 37px;


`