import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { palette } from '../utils/Palette';

const filter = createFilterOptions();

const AutocompleteComponent = ({
  value,
  onChange,
  options,
  placeholder,
  width,
  marginLeft,
  inputHeight,
  inputFontSize,
  onNewChange
}) => {
    const [inputValue, setInputValue] = useState('');
    const [optionVal, setOptionVal] = useState(options||[]);

    useEffect(()=>{
        console.log(options,"ccjcbdjcbj")
        setOptionVal(options)
       
    },[options])
    return (
        <StyledAutocomplete
            value={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
                const searchString = 'Create ';
                const replacement = '';
                const result = newValue?.replace(searchString, replacement)?.replace(/"/g, '');

                if (newValue && newValue.includes(searchString)) {
                    // Create a new value from the user input
                    onNewChange(result);
                    setInputValue('');
                } else if (typeof newValue === 'string') {
                    onChange(result);
                    setInputValue('');
                }
                setInputValue('');
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(`Create "${inputValue}"`);
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            
            id="free-solo-with-text-demo"
            options={optionVal}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{
                width: width || 300,
                marginLeft: marginLeft || '10px',
                
             
                '& input': {
                    height: '5px', 
                    fontSize:'14px'
                },
            }}
            style={{ height: 30, }}
            freeSolo
            renderInput={(params) => (
                <StyledTextField
                    style={{ fontSize: inputFontSize || '14px' }}
                    {...params}
                    value={inputValue}
                    placeholder={placeholder || "Search tag"}
                />
            )}
        />
    );
}

AutocompleteComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewChange:PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AutocompleteComponent;

const StyledAutocomplete = styled(Autocomplete)`
    display: flex;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
  
    font-family: Inter, sans-serif;
    color: #344054;
    background: #ffffff;
`;

const StyledTextField = styled(TextField)`
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    color: #344054;
    
    background: #ffffff;
    border:0.5px solid #d0d5dd;
    padding: 0;

    .MuiInputBase-input {
        height: 25px;
        
        padding: 0 14px; // Adjust padding to maintain the desired height
        box-sizing: border-box;
    }

    .MuiOutlinedInput-root {
        
        fieldset {
            border-color: #d0d5dd;
        }
        &:hover fieldset {
            border-color: #d0d5dd;
          
        }
        &.Mui-focused fieldset {
            border-color: #d0d5dd;
           
            border:1.5px solid ${palette.charcoleBlack};
        }
    }

    ::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: gray;
    }
`;
