import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { palette } from '../utils/Palette';

const TopBarLoader = ({ color, height, speed, barWidth }) => {
  const [position, setPosition] = useState(-barWidth); // Start from outside the screen

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) =>
        prevPosition >= window.innerWidth ? -barWidth : prevPosition + 5
      );
    }, speed);

    return () => clearInterval(interval);
  }, [speed, barWidth]);

  return (
    <LoaderContainer height={height}>
      <LoaderBar color={color} barWidth={barWidth} position={position} />
    </LoaderContainer>
  );
};

TopBarLoader.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  speed: PropTypes.number,
  barWidth: PropTypes.number,
};

TopBarLoader.defaultProps = {
  height: '4px',
  speed: 20, 
  barWidth: 150, 
};

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height};
  background-color: #A1C8E7;
  z-index: 1000;
`;

const LoaderBar = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => `${props.position}px`}; 
  width: ${(props) => `${props.barWidth}px`}; 
  height: 100%;
  border-radius:10px;
  background-color:${palette.newBlue};
  transition: left ${(props) => props.speed}ms linear;
`;

export default TopBarLoader;
