import React, { useState } from "react";
import styled from "styled-components";
import { FiEdit, FiTrash2, FiMoreHorizontal } from "react-icons/fi";
import { palette } from "../../../utils/Palette";
import { TableContainer, TableHead, TableCell, Table, TableRow, TableBody } from "@mui/material";
import CustomTabs from "../../../ReuseableComponets/CustomTab";
import { ContactMail, HandymanOutlined, LeaderboardSharp } from "@mui/icons-material";
import AddCustomField from "../component/AddCustomField";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteCustomField, getAllCustomField } from "../../../store/Actions/SettingsAction";
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomFieldsPage = () => {
  const [fields, setFields] = useState([

  ]);
  const [jobfields, setJobFields] = useState([

  ]);
  const [isnewCustomfield, setIsNewCustomField] = useState(false)
  const [isEditCustomfield, setIsEditCustomField] = useState(false)
  const [type, setType] = useState("contact")
  const [loader, setLoader] = useState(false)
  const [selectCustomField, setSelectCustomField] = useState({})
  const dispatch = useDispatch()


  const init = async () => {
    setLoader(true)
    const res = await dispatch(getAllCustomField())
    if (res?.status == true) {
      setFields(res?.data?.contactFields)
      setJobFields(res?.data?.jobFields)
    }
    setLoader(false)
  }
  useEffect(() => {
    init()
  }, [])

  const deleteHandler = async (id) => {

    const res = await dispatch(deleteCustomField(id))
    init()


  }
  const renderItem = (fields, type) => {

    return (
      <>
        <Header>
          <ButtonGroup>
          
            <AddButton onClick={() => {
              setSelectCustomField({})
              setIsNewCustomField(true)
              setType(type)
            }}>
             <ButtonText> + New {type?.charAt(0)?.toUpperCase() + type?.slice(1)} Custom Field </ButtonText></AddButton>
          </ButtonGroup>
        </Header>
        {loader ? <StyledPlayer> <Player
          src={require('../../../assets/loaders.json')}
          className="player"
          style={{ width: '200px', height: '200px' }}
          loop
          autoplay
        />
        </StyledPlayer>
          : <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
            <CustomHead>
              <TableRow >
                <TableCell className="sticky" size="small" style={{ cursor: 'pointer', width: '100px', }}>
                  #</TableCell>
                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                  Name
                </TableCell>
                <TableCell style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                  Description
                </TableCell>
                <TableCell style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                  Type
                </TableCell>

                <TableCell style={{ width: '100px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                  Actions
                </TableCell>

              </TableRow>
            </CustomHead>

            {fields?.map((field, index) => (
              <TableBody key={index} style={{ background: '#fff', cursor: 'pointer' }} >
                <TableRow >
                  <TableCell style={{ fontWeight: '500' }} className="sticky" size='small'>{index + 1}</TableCell>

                  <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  >{field?.fieldName}</TableCell>
                  <TableCell size='small'>
                    {field?.description}
                  </TableCell>
                  <TableCell size='small'>
                    {field?.fieldType === 'text' ? "Text" : field?.fieldType === 'dropdownSingle' ? "Dropdown (Single)" : field?.fieldType === 'dropdownMultiple' ? "Dropdown (Multiple)" : "Text"}
                  </TableCell>

                  <TableCell size='small'  >
                    <ActionIcons>
                      <FiEdit onClick={async () => {
                        setSelectCustomField(field)
                        setIsNewCustomField(true)
                        setIsEditCustomField(true)

                      }} />
                      <FiTrash2 onClick={async () => {
                        deleteHandler(field?._id)
                      }} />

                    </ActionIcons >
                  </TableCell>

                </TableRow>
              </TableBody>
            ))}


          </Table>}
        {fields?.length > 0 ? null : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="60vh"
            sx={{ color: 'gray' }}
          >
            {type == 'contact' ? <ContactMail sx={{ fontSize: '50px', marginBottom: 2 }} /> : <HandymanOutlined sx={{ fontSize: '50px', marginBottom: 2 }} />}
            <Typography variant="h5" align="center">
              No {type} custom fields available
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              You can add new {type} fields to manage your data more effectively.
            </Typography>
          </Box>
        )}

      </>
    )
  }

  return (
    <Container>
      

      <CustomTable>
      <h2 style={{ textAlign: 'start',marginLeft:'15px',marginTop:'0px',marginBottom:'10px' }}>Custom Fields</h2>
      
        <CustomTabs
          tabpanelWidth={'400px'}
          boxStyle={{
            p: 0, marginTop: '10px', marginBottom: '25px'
          }}
          tabsData={[
            {
              label: 'Contacts',
              icon: <ContactMail style={{ fontSize: '16px' }} />,
              content: renderItem(fields, "contact")
            },

            {
              label: 'Jobs',
              icon: <HandymanOutlined style={{ fontSize: '16px' }} />,
              content: renderItem(jobfields, "job")
            }

          ]}
        />


      </CustomTable>
      <AddCustomField
        isVisible={isnewCustomfield}
        type={type}
        onClose={() => {
          setIsEditCustomField(false)
          setIsNewCustomField(false)
        }}
        selectedField={isEditCustomfield ? selectCustomField : {}}
        onSubmit={() => {
          setIsNewCustomField(false)
          setIsEditCustomField(false)
          init()
        }}
      />
    </Container>
  );
};

export default CustomFieldsPage;
const Container = styled.div`

width:100%;
background:#fff;
padding-top:30px;

display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`;



const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

const Tab = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #f4f4f4;
  color: #333;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0F52BA;
    color: white;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
`;

const AddButton = styled.button`
border: 1px solid #0F52BA;
margin-top: 5px;

justify-content: center;
display: flex;
padding: 7px 12px;
border-radius: 20px;
cursor: pointer;
background:#fff;
transition: background-color 0.3s ease, box-shadow 0.3s ease;

&:hover {
  background-color: rgba(15, 82, 186, 0.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
`;
const ButtonText = styled.h4`
  text-align: start;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0F52BA;
  cursor: pointer;
`;




const StyledPlayer = styled.div`
display:flex;
height:60vh; 
align-items:center; 
justify-content:center; 
`

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }


  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:30px;
  max-width:30px;

}
`