import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { formatMobileNumber, TimeListAm, TimeListPM } from '../utils/Commonfunction';
import cross from '../assets/cross.png'
import { Checkbox, MenuItem, Select } from '@mui/material';
import plus from '../assets/plus.png'
import logoName from '../assets/LogoName.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editForm, getForm } from '../store/Actions/dashboard.action';
import '../App.css'

const PreviewForm = (props) => {
    const navigate = useNavigate()

    let { id } = useParams()

    const [showModal1, setShowModal1] = useState(false);

    const userdata = useSelector(state => state?.auth?.userData)
    const detail = useSelector(state => state?.auth?.userDetails)

    const [copy, setCopy] = useState('Copy Link')
    const [linl, setLink] = useState('')
    const [datadetail, setDatadetail] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [customFieldValue, setCustomFieldValue] = useState({
        "customerEmail": "",
        "customerName": "",
        "customerNumber": ""
    })

    const [customData, setCustomData] = useState(props?.customField || [])
    const [header, setHeader] = useState({})

    const deleteHandler = (index) => {

        const data = [...customData]
        data.splice(index, 1)
        setCustomData(data)


    }
    const dispatch = useDispatch()
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    function onChangePhone(value) {
        setPhoneNumber(value);
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText("inputValue");
        setCopy('Copied !')
    };

    useEffect(() => {
        setCustomData(props?.customField)


    }, [props?.customField, props?.subHeader])



    const submitValue = async (val) => {
        console.log({ ...customFieldValue, customerNumber: phoneNumber })
        const res1 = await dispatch(editForm(id, { ...customFieldValue, customerNumber: phoneNumber }))
        if (res1?.status === true) {
            navigate(`/response/${id}`)
        }

    }


    return (
        <Container>
            <MainContainer>

                <Form>
                    <h2 style={{ marginBottom: 0, textAlign: 'start', marginTop: '10px' }}>{props?.header || 'Title'}</h2>

                    {props?.isProfile && <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginTop: '25px', marginBottom: '20px', marginLeft: ' 15px' }}>
                        <div style={{ display: 'flex', width: '90%', }}>
                            {userdata?.logoImage ? <ProfilePic src={userdata?.logoImage} /> : <OutProfile>
                                {getName(userdata?.businessName)}
                            </OutProfile>}
                            <IconWrapper style={{ marginTop: '-10px', textAlign: 'start' }}>
                                <Heading>{userdata?.businessName?.slice(0, 57)}{userdata?.businessName?.length > 57 ? '...' : ''}</Heading>
                                <Header style={{ marginBottom: '-10px' }}>{formatMobileNumber(localStorage.getItem('number'))}</Header>
                                <Header >{detail?.email}</Header>
                            </IconWrapper>
                        </div>



                    </div>}
                    <SubHeader>
                        {props?.subHeader || 'Please fill the information below'}
                    </SubHeader>

                    <InputBoxContainer>
                        <Label htmlFor={'Create a password'}>Name</Label>
                        <Inputbox id="outlined-basic" size="small" placeholder="Name" variant="outlined" onChange={(e) => {
                            setCustomFieldValue({ ...customFieldValue, customerName: e.target.value })
                        }} />
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <Label htmlFor={'Create a password'}>Email</Label>
                        <Inputbox id="outlined-basic" size="small" placeholder="Email" variant="outlined"

                            onChange={(e) => {
                                setCustomFieldValue({ ...customFieldValue, customerEmail: e.target.value })
                            }}
                        />
                    </InputBoxContainer>
                    <InputBoxContainer>
                        <Label htmlFor={'First address'}>Phone number</Label>
                        <div className='phoneInput'>
                            <PhoneFiled
                                style={{ width: '99%' }}
                                placeholder="Enter phone number"
                                value={customFieldValue?.customerNumber}
                                onChange={onChangePhone}

                                defaultCountry="US"

                                international
                                focusInputOnCountrySelection='true'
                            />
                        </div>
                    </InputBoxContainer>

                    {
                        customData?.map((item, index) =>
                            item?.fieldType === 'Short text' ? <InputBoxContainer>
                                <Label htmlFor={item?.fieldName}>{item?.fieldName}  {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                <Inputbox id="outlined-basic" size="small"
                                    placeholder={item?.placeHolder}
                                    onChange={(e) => {
                                        setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                    }}
                                    variant="outlined" />

                            </InputBoxContainer>
                                : item?.fieldType === 'Date' ? <InputBoxContainer>
                                    <Label htmlFor={item?.fieldName}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                    <Inputbox id="outlined-basic" size="small"
                                        type={"date"}

                                        placeholder={item?.placeHolder}
                                        onChange={(e) => {
                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                        }}
                                        variant="outlined" />


                                </InputBoxContainer>
                                    : item?.fieldType === 'Long text' ? <InputBoxContainer>
                                        <Label htmlFor={item?.fieldName}>{item?.fieldName} {item?.optional && <StyledSpan>Optional</StyledSpan>}</Label>
                                        <InputText id="outlined-basic" size="small"
                                            type={"date"}
                                            placeholder={item?.placeHolder}
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                            variant="outlined" />


                                    </InputBoxContainer> : item?.fieldType === 'Time' ? <InputBoxContainer style={{ width: '97.5%', }}>
                                        <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>"Optional"</StyledSpan>}</Label>
                                        {/* <TimeSelect
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                        >
                                            <option value="">{item?.placeHolder}</option>
                                            {[...item?.data].map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </TimeSelect> */}
                                        <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '6px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => customFieldValue[item?.valueKey] ? customFieldValue[item?.valueKey] : 'Select time'} onChange={(e) => {
                                                console.log(e.target)
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })

                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {[...item?.data]?.map((item, index) =>
                                                <StyledMenuItem key={index} value={item}>
                                                    <FilterBox
                                                        onChange={(e) => {
                                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                                        }}
                                                        style={{ color: customFieldValue?.filed === item ? '#4169e1' : '#000' }}>


                                                        {item}</FilterBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox>

                                    </InputBoxContainer> : item?.fieldType === 'Multi options' ? <InputBoxContainer style={{ width: '97.5%', }}>
                                        <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>"Optional"</StyledSpan>}</Label>
                                        {/* <TimeSelect
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                        >
                                            <option value="">{item?.placeHolder}</option>
                                            {[...item?.data].map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </TimeSelect> */}
                                        {[...item?.data]?.map((items, index) => {

                                            return (
                                                <div

                                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        checked={customFieldValue[item?.valueKey]?.includes(items)}
                                                        onChange={() => {
                                                            const currentValues = customFieldValue[item?.valueKey] || [];
                                                            const isSelected = customFieldValue[item?.valueKey]?.includes(items);
                                                            let newValues;

                                                            if (isSelected) {
                                                                newValues = currentValues.filter(value => value !== items);
                                                            } else {
                                                                newValues = [...currentValues, items];
                                                            }

                                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: newValues });
                                                        }}
                                                    />
                                                    <FilterBox
                                                        style={{ color: customFieldValue?.filed === items ? 'rgba(32, 32,32, 0.75)' : 'rgba(32, 32,32, 0.75)', cursor: 'initial' }}
                                                    >
                                                        {items}
                                                    </FilterBox>
                                                </div>
                                            )
                                        })}

                                    </InputBoxContainer> : item?.fieldType === 'Select' ? <InputBoxContainer style={{ width: '97.5%', }}>
                                        <Label htmlFor={'Create a password'}>{item?.fieldName} {item?.optional && <StyledSpan>"Optional"</StyledSpan>}</Label>
                                        {/* <TimeSelect
                                            onChange={(e) => {
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                            }}
                                        >
                                            <option value="">{item?.placeHolder}</option>
                                            {[...item?.data].map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </TimeSelect> */}
                                        <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '6px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => customFieldValue[item?.valueKey] ? customFieldValue[item?.valueKey] : 'Select an option'} onChange={(e) => {
                                                console.log(e.target)
                                                setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })

                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {[...item?.data]?.map((item, index) =>
                                                <StyledMenuItem key={index} value={item}>
                                                    <FilterBox
                                                        onChange={(e) => {
                                                            setCustomFieldValue({ ...customFieldValue, [item?.valueKey]: e.target.value })
                                                        }}
                                                        style={{ color: customFieldValue?.filed === item ? '#4169e1' : '#000' }}>


                                                        {item}</FilterBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox>

                                    </InputBoxContainer> : null
                        )
                    }





                    <ButtonContainer>
                        <SubmitButton onClick={submitValue} type="submit">Submit</SubmitButton>
                    </ButtonContainer>
                    <div style={{ margin: '15px', paddingBottom: '30px', paddingTop: '30px', fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', color: 'gray', borderTop: '1px solid #ddd', justifyContent: 'center' }}>
                        <Desc2>By continuing, you agree that you have read and accept the Notifi <a style={{ color: '#4169e1' }} href='/'>Terms of Service</a> and <a style={{ color: '#4169e1' }} href='/'>Privacy Policy</a>.</Desc2>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            Powered by
                            <Icons style={{ width: '100px', height: '30px', marginLeft: '15px' }} src={logoName} />
                        </div>
                    </div>

                </Form>

            </MainContainer>
        </Container>
    );
};

const ButtonContainer = styled.div`
display:flex;
justify-content:end;
margin-right:13px;

@media (min-width: 260px) and (max-width: 621px){
    margin-right:15px;
    }
`

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  width: 93%;
  @media (min-width: 260px) and (max-width: 621px){
    width: 89%;
    }


`;

const Desc2 = styled.p`
text-align:center;
font-weight:300;
font-size:13px;
color:gray;
width:97%;
color:gray;
margin-top:0px;
margin-bottom:25px;
`
const StyledSpan = styled.span`
  background: #ddd;
  margin-left: 4px;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  font-size:10px;
`;


const CopyButton = styled.button`
    border: 1px solid #4169e1;
    background: #4169e1;
    color: #fff;
    width: 100px;
    height: 38px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
`;
const Container = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  background: #fafafa;
  width: 100%;
  min-height:100vh;
   overflow-x:hidden;

`;

const ProfilePic = styled.img`
background:#2b373f;
color:white;
height:65px;
width:65px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:35px;
font-weight:bold;
margin-left:-5px;

`

const DeleteContainer = styled.div`
position:absolute;
right:0;
top:-5px;
margin-top:-5px;
margin-right:10px;
background:#fff;

background:#f3f3f3;
display:flex;
align-items:center;
justify-content:center;
border: none;
margin-right:30px;

height:25px;
align-self:center;
border-radius:8px;
padding:5px;
font-size: 14px;
cursor: pointer;

`

const PhoneFiled = styled(PhoneInput)`
--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;
@media (min-width: 260px) and (max-width: 820px){
    --PhoneInputCountrySelectArrow-marginLeft:5px;
}
`

const Form = styled.div`
width:93%;
height:auto;
  padding: 20px;
  background-color: #fff;
  border: 1px #f3f3f3;
  border-radius: 8px;
  
 

`;
const MainContainer = styled.div`
  height: auto;
  width: 100%;
  border-radius: 8px;
  background:white;


  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @media (min-width: 720px) {
    max-width: 600px;
    margin: auto;

   
   
  }
`;


const IconContainer = styled.button`
display:flex;
justify-content:center;
align-items:center;
padding:2px;
width: 30px;
height: 30px;
 border:2px solid #4169e1;
 border-radius:30px;
 margin-right:15px;
 cursor:pointer;
`;
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const AddCustom = styled.div`
display:flex;
cursor:pointer;
justify-content:center;
align-items:center;
font-weight:600;
margin-top:25px;
margin-bottom:25px;
cursor:pointer;

`

const ModalContent = styled.div`
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #0F52BA;
  margin-bottom: 10px;
  boder-radius:8px;
  color: #fff;
  font-weight:500;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 48%;

`;

const Inputbox = styled.input`
  width: 99%;
  border-radius: 5px;
  font-size: 14px;
  height: 25px;
  font-weight: 400;
  font-family:Inter;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;

  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;

const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    color: black;
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-break: break-word; 
    overflow-wrap: anywhere; 
    display: inline-block;
`;
const HeaderContainer = styled.div`
display:flex;
height:auto;
justify-content:space-between;
align-items:center; 
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;

width:23px;
height:23px;
align-self:center;
border-radius:25px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const DateInputContainer = styled.div`
  display: flex;
  gap: 5px;
  position:relative;
  width:45%;
  align-items:center;
  @media (min-width: 260px) and (max-width: 721px){
    width:90%;
    }
`;

const DateInputSection = styled.input`
  width: calc(33.33% - 2px);
  border-radius: 5px;
  font-size: 16px;
  height: 25px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;
const TimeSelect = styled.select`
  width: 105.5%; /* Set the width to 100% */
  border-radius: 5px;
  font-size: 16px;
  height: 45px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px; /* Adjust padding as needed */
 
  
  @media (min-width: 260px) and (max-width: 721px) {
    width: 108%;
  }
`;

const Selectbox = styled(Select)`
  width: 100%;
  border-radius: 4px;
  text-align: start;
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  border: 1px solid #f3f3f3;
  background: white;
  height: 48px;
  font-size: 14px;
  margin-top: 10px;

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 98%;
  }
`;
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:13px;
align-items:center;
color:rgba(32, 32,32, 0.75);
font-weight:500;
cursor:pointer;
white-space: normal; /* Allow text to wrap */
word-break: break-word; /* Break long words */
padding: 0 8px; /* Add padding */
  box-sizing: border-box; /* Include padding in width */

`
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  align-self: start;
  white-space: normal; /* Allow text to wrap */
  word-break: break-word; /* Break long words */
  padding-bottom:5px;
  padding-top:5px;
  padding: 8px; /* Add padding */
  box-sizing: border-box; /* Include padding in width */
  :hover {
    background: #dddddd;
    border-radius: 5px;
 
  }
`;
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:65px;
width:65px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:35px;
font-weight:bold;
margin-left:-5px;
`
const Header = styled.p`
font-size:14px;
font-weight:500;
color:gray;

`
const Heading = styled.p`
font-weight:600;
font-size: 14px;
font-weight:900;
margin-bottom:-10px;
text-align:start;

`
const IconWrapper = styled.div`
  display:flex;
  flex-direction:column;
justify-content:start;
`;
const SubHeader = styled.div`
  text-align: start;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 10px;
  word-wrap: break-word; 
`;
const InputText = styled.textarea`
font-family:Inter;
width: 99%;
border-radius: 5px;
font-size: 16px;
height: 45px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
  resize: vertical; /* Allow vertical resizing */
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 99%;
  }
`;

export default PreviewForm;
