import React, { useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectPayouts,
  ConnectBalances,
} from "@stripe/react-connect-js";
import { useDispatch } from "react-redux";
import { stripeSession } from "../../store/Actions/SettingsAction";
import styled from "styled-components";
const fontLink = document.createElement('link');
fontLink.href = "https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap";
fontLink.rel = "stylesheet";
document.head.appendChild(fontLink);

export default function AccountManagement() {
    const dispatch=useDispatch()
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
        const res=await dispatch(stripeSession())

        return res?.client_secret;
      
    };
    return loadConnectAndInitialize({
        // This is a placeholder - it should be replaced with your publishable API key.
        // Sign in to see your own test API key embedded in code samples.
        // Don’t submit any personally identifiable information in requests made with this key.
        publishableKey: "pk_live_51P9N8zACcdvY3p8RYrzyirkdBENuYjon6a8IpYCsbdpGxIhHGsg6e8zwkpN1MHliCjhhXlm5eGzC4P1qo8IMifHJ00RCjlogPp",
        fetchClientSecret: fetchClientSecret,
        
        appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#0F52BA",
              spacingUnit: "8px",
              fontFamily: "'Inter', sans-serif",
              spacingUnit: "10px",
              headingXlFontWeight: "500",
              headingMdFontWeight: "500",
              headingSmFontWeight: "500",
              headingXsFontWeight: "500",
            },
          },
      })
    });

  return (
    <div style={{ width: '100%',minHeight:'100vh', fontFamily: "'Inter', sans-serif",padding:'0px 20px',marginBottom:'60px', overflowY:'scroll' }}>
          <Header>Account Management</Header>
        <ConnectComponentsProvider  connectInstance={stripeConnectInstance}>
         <ConnectAccountManagement/>
        </ConnectComponentsProvider>
    </div>
  )
}

const Header = styled.p
    `
font-weight: 600;
font-size: 28px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-top:25px;
margin-right:10px;
`