import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Modal from '../Modal';
import { formatMobileNumber, TimeListPM } from '../../utils/Commonfunction';
import cross from '../../assets/cross.png'
import { Checkbox, MenuItem, Select, Switch } from '@mui/material';
import plus from '../../assets/plus.png'
import trash from '../../assets/trash.png'
import attach from '../../assets/attach-file.png'
import { useDispatch, useSelector } from 'react-redux';
import { editForm, getForm, getrequestForm, submitEditForm, submitForm } from '../../store/Actions/dashboard.action';
import '../../App.css'
import { Player } from '@lottiefiles/react-lottie-player';
import PreviewForm from '../PreviewFrom';
import IOSSwitchComponent from '../../ReuseableComponets/IOSSwitch';
import { palette } from '../../utils/Palette';
import { AddCircle, DeleteOutlined } from '@mui/icons-material';
const RequestForm = (props) => {

    const [showModal1, setShowModal1] = useState(false);
    const userdata = useSelector(state => state?.auth?.userData)
    const detail = useSelector(state => state?.auth?.userDetails)
    const [val, setVal] = useState('')
    const dataList = ['Short text', "Date", "Time", "Multi options", 'Long text', 'Select']
    const [copy, setCopy] = useState('Share form')
    const [copy1, setCopy1] = useState('Embed in website')
    const [linl, setLink] = useState('')
    const [linl1, setLink1] = useState('')
    const [loader1, setLoader1] = useState(false)
    const [isProfile, setIsProfile] = useState(true)
    const [formNumber, setFormNumber] = useState('')
    const [header, setHeader] = useState('')
    const [subHeader, setSubHeader] = useState('')

    const [customFieldValue, setCustomFieldValue] = useState({
        "fieldName": "",
        "fieldType": "Short text",
        "placeHolder": "",
        "optional": '',
        "valueKey": '',
        "data": []
    })

    const [customData, setCustomData] = useState([])
    const deleteHandler = (index) => {

        const data = [...customData]
        data.splice(index, 1)
        setCustomData(data)


    }
    const dispatch = useDispatch()
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText(linl);
        setCopy('Copied!')
        setTimeout(() => {
            setCopy('Share form')
        }, 2000);
    };
    const handleCopyClick1 = () => {
        navigator.clipboard.writeText(linl1);
        setCopy1('Copied!')

        setTimeout(() => {
            setCopy1('Embed in website')
        }, 2000);
    };

    const handleSubmit = async () => {
        setLoader1(true)
        if (props?.formNumber) {
            const res = await dispatch(submitEditForm({
                "customerEmail": "",
                "customerName": "",
                "customField": customData,
                "customerNumber": "",
                "formName": formNumber,
                "profileRequest": isProfile,
                "header": header,
                "subHeader": subHeader,
                "referenceNo": props?.formNumber
            }))
            if (res?.status === true) {

                setLink(`https://app.getnotifi.com/form/${res?.data?.formNo}`)
                setLink1(`<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
                <iframe src="https://app.getnotifi.com/onlineForm/${res?.data?.formNo}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                `)



            }
        }
        else {
            const res = await dispatch(submitForm({
                "customerEmail": "",
                "customerName": "",
                "customField": customData,
                "customerNumber": "",
                "formName": formNumber,
                "profileRequest": isProfile,
                "header": header,
                "subHeader": subHeader
            }))
            if (res?.status === true) {
                setLink(`https://app.getnotifi.com/form/${res?.data?.formNo}`)
                setLink1(`<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
                <iframe src="https://app.getnotifi.com/onlineForm/${res?.data?.formNo}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                `)
                props?.onSelect(res?.data?.formNo)

            }
        }
        setLoader1(false)
    }

    const init = async () => {
        if (props?.formNumber) {
            const res1 = await dispatch(getForm(props?.formNumber))
            if (res1.status === true) {

                setCustomData(res1?.data?.customField)
                setLink(`https://app.getnotifi.com/form/${props?.formNumber}`)
                setLink1(`<div style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
                <iframe src="https://app.getnotifi.com/onlineForm/${props?.formNumber}" style="width: 100%; height: 100%; border: none;" title="Iframe"></iframe></div>
                `)
                setFormNumber(res1?.data?.formName)
                setHeader(res1?.data?.header)
                setSubHeader(res1?.data?.subHeader)

            }
        }
        // else {
        //     const res1 = await dispatch(getrequestForm())
        //     if (res1.status === true) {

        //         setCustomData(res1?.data?.customField)
        //         setLink(`https://app.getnotifi.com/form/${res1?.data?.formNo}`)
        //     }
        // }

    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div style={{ display: 'flex', width: '100%', minHeight: '100%' }}>
            <Container>
                <MainContainer>
                    <Form>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px',marginRight:'10px' }}>
                            <Inputbox
                                placeholder='Title'
                                style={{ width: '40%', height: '20px' }}
                                value={header}
                                onChange={(e) => setHeader(e.target.value)}
                            />
                            <Inputbox
                                placeholder='Form name (Internal)'
                                style={{ height: '23px' }}
                                value={formNumber}
                                onChange={(e) => setFormNumber(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginTop: '30px', marginBottom: '30px', marginLeft: '10px', marginRight: '10px' }}>
                            <div style={{ display: 'flex', width: '90%', }}>
                                {userdata?.logoImage ? <ProfilePic src={userdata?.logoImage} /> : <OutProfile>
                                    {getName(userdata?.businessName)}
                                </OutProfile>}
                                <IconWrapper style={{ marginTop: '-10px' }}>
                                    <Heading>{userdata?.businessName?.slice(0, 17)}{userdata?.businessName?.length > 17 ? '...' : ''}</Heading>
                                    <Header style={{ marginBottom: '-10px',textAlign:'start' }}>{formatMobileNumber(localStorage.getItem('number'))}</Header>
                                    <Header >{detail?.email}</Header>
                                </IconWrapper>
                            </div>

                            <IOSSwitchComponent
                                checked={isProfile}
                                onChange={(e) => {
                                    if (e?.target?.checked) {
                                        setIsProfile(true)
                                    }
                                    else {
                                        setIsProfile(false)
                                    }
                                }}
                            />

                        </div>
                        <InputText

                            placeholder='Please fill the information below'
                            value={subHeader}
                            onChange={(e) => setSubHeader(e.target.value)} // Changed onClick to onChange for input value update
                        />
                        <InputBoxContainer>
                            <Label htmlFor={'Create a password'}>Name<StyledSpan>Required</StyledSpan></Label>
                            <Value>{"Name"}</Value>
                        </InputBoxContainer>
                        <InputBoxContainer>
                            <Label htmlFor={'Create a password'}>Email<StyledSpan>Required</StyledSpan></Label>
                            <Value>{"Email"}</Value>
                        </InputBoxContainer>
                        <InputBoxContainer>
                            <Label htmlFor={'First address'}>Phone number <StyledSpan>Required</StyledSpan> </Label>
                            <div className='phoneInput'>
                                <Value>{"Phone number"}</Value>
                            </div>
                        </InputBoxContainer>

                        {
                            customData?.map((item, index) =>
                                item?.fieldType === 'Short text' ? <InputBoxContainer>
                                    <Label htmlFor={item?.fieldName}>{item?.fieldName} <StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan></Label>
                                    <Value>{item?.placeHolder}</Value>
                                    <DeleteContainer onClick={() => deleteHandler(index)}>
                                        <DeleteOutlined style={{fontSize:'20px'}}  />

                                    </DeleteContainer>
                                </InputBoxContainer>
                                    : item?.fieldType === 'Date' ? <InputBoxContainer>
                                        <Label htmlFor={item?.fieldName}>{item?.fieldName}<StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan> </Label>
                                        <Value>{item?.placeHolder}</Value>
                                        <DeleteContainer onClick={() => deleteHandler(index)}>
                                        <DeleteOutlined style={{fontSize:'20px'}}  />

                                        </DeleteContainer>

                                    </InputBoxContainer>
                                        : item?.fieldType === 'Time' ? <InputBoxContainer>
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} <StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan></Label>
                                            <Value>{item?.placeHolder}</Value>
                                            <DeleteContainer onClick={() => deleteHandler(index)}>
                                            <DeleteOutlined style={{fontSize:'20px'}}  />

                                            </DeleteContainer>
                                        </InputBoxContainer> : item?.fieldType === 'Multi options' ? <InputBoxContainer>
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} <StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan></Label>
                                            {<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', marginBottom: '10px', width: '80%' }}>
                                                {item?.data?.map((item, index) =>
                                                    <div style={{ background: '#f3f3f3', display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', fontSize: '14px', marginBottom: '5px' }}>{item} </div>
                                                )}
                                            
                                            </div> }
                                            <DeleteContainer onClick={() => deleteHandler(index)}>
                                            <DeleteOutlined style={{fontSize:'20px'}}  />

                                            </DeleteContainer>

                                        </InputBoxContainer> : item?.fieldType === 'Long text' ? <InputBoxContainer>
                                            <Label htmlFor={item?.fieldName}>{item?.fieldName} <StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan></Label>
                                            <Value>{item?.placeHolder}</Value>
                                            <DeleteContainer onClick={() => deleteHandler(index)}>
                                            <DeleteOutlined style={{fontSize:'20px'}}  />

                                            </DeleteContainer>
                                        </InputBoxContainer> : item?.fieldType === 'Select' ? <InputBoxContainer>
                                            <Label htmlFor={'Create a password'}>{item?.fieldName} <StyledSpan>{item?.optional ? "Optional" : "Required"}</StyledSpan></Label>
                                            {<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', marginBottom: '10px', width: '80%' }}>
                                                {item?.data?.map((item, index) =>
                                                    <div style={{ background: '#f3f3f3', display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', fontSize: '14px', marginBottom: '5px' }}>{item} </div>
                                                )}
                                            
                                            </div> }
                                            <DeleteContainer onClick={() => deleteHandler(index)}>
                                               <DeleteOutlined style={{fontSize:'20px'}}  />

                                            </DeleteContainer>
                                        </InputBoxContainer> : null
                            )
                        }


                        <AddCustom onClick={() => setShowModal1(true)}>
                           
                                <AddCircle style={{fontSize:'25px',color:palette.newBlue,marginRight:'10px'}} />
                            
                            Add new field
                            </AddCustom>

                        {showModal1 && <Modal
                            onClose={() => setShowModal1(false)}
                            customStyle={{ background: '#fff' }}
                        >
                            <div >
                                <HeaderContainer>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CloseButton onClick={() => setShowModal1(false)}>
                                            <Icons src={cross} />
                                        </CloseButton>
                                        <h4>Add custom field</h4>
                                    </div>
                                </HeaderContainer>
                                <InputBoxContainer style={{ marginLeft: '0px' }}>
                                    <Label htmlFor={'Email'}>
                                        Select type</Label>
                                    <Selectbox
                                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                        placeholder='select' value={'Select an option'} displayEmpty
                                        renderValue={() => customFieldValue?.fieldType} onChange={(e) => {
                                            console.log(e.target)

                                        }}
                                        MenuProps={{ // Add MenuProps to customize menu styling
                                            PaperProps: {
                                                style: {
                                                    marginTop: '8px', // Adjust this value for the desired gap
                                                },
                                            },
                                        }}
                                    >
                                        {dataList?.map((item, index) =>
                                            <StyledMenuItem key={index} value={index}>
                                                <FilterBox
                                                    onClick={() => {
                                                        setCustomFieldValue({ ...customFieldValue, fieldType: item })
                                                        // setFilter(item)
                                                        // if (item == 'All leads') {
                                                        //     setSearchContact(allContact)
                                                        // }
                                                        // else {
                                                        //     const data = allContact?.filter(items => items?.status === item)
                                                        //     setSearchContact(data)
                                                        // }
                                                        // // setSearchInvoice(detail?.quote)
                                                    }}
                                                    style={{ color: customFieldValue?.fieldType === item ? '#4169e1' : '#000' }}>


                                                    {item}</FilterBox>
                                            </StyledMenuItem>
                                        )}
                                    </Selectbox>
                                </InputBoxContainer>
                                <InputBoxContainer style={{ marginLeft: '0px' }}>
                                    <Label htmlFor={'Email'}>Label</Label>
                                    <Inputbox
                                        placeholder="Label"
                                        type="text"
                                        value={customFieldValue?.fieldName}
                                        style={{ width: '300px' }}
                                        onChange={(e) => {
                                            setCustomFieldValue({
                                                ...customFieldValue, fieldName: e.target.value
                                            })
                                        }}

                                    />
                                </InputBoxContainer>
                                {customFieldValue?.fieldType === 'Multi options' || customFieldValue?.fieldType === 'Select' ? <InputBoxContainer style={{ marginLeft: '0px' }}>
                                    <Label htmlFor={'Email'}>Options</Label>
                                    <Inputbox
                                        placeholder="Type an option and press enter to add"
                                        type="text"
                                        value={val}
                                        style={{ width: '300px' }}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                const data = customFieldValue?.data
                                                data.push(val)
                                                setCustomFieldValue({
                                                    ...customFieldValue, data: data
                                                })
                                                setVal('')
                                            }
                                        }}
                                        onChange={(e) => {
                                            setVal(e?.target?.value)
                                        }}

                                    />
                                </InputBoxContainer> : null}
                                {customFieldValue?.fieldType === 'Multi options' || customFieldValue?.fieldType === 'Select' ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px', marginBottom: '20px', marginLeft: '12px', width: '295px' }}>
                                    {customFieldValue?.data?.map((item, index) =>
                                        <div style={{ background: '#f3f3f3', display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginTop: '5px', fontSize: '14px', marginBottom: '5px' }}>{item} <Icons style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                            const data = customFieldValue?.data
                                            data.splice(index, 1)

                                            setCustomFieldValue({
                                                ...customFieldValue, data: data
                                            })
                                        }} src={cross} /></div>
                                    )}
                                </div> : null}
                                {customFieldValue?.fieldType !== 'Multi options' || customFieldValue?.fieldType === 'Select' ? <InputBoxContainer style={{ marginLeft: '0px' }}>
                                    <Label htmlFor={'Email'}>Placeholder</Label>
                                    <Inputbox
                                        placeholder="Placeholder"
                                        type="text"
                                        value={customFieldValue?.placeHolder}
                                        style={{ width: '300px' }}
                                        onChange={(e) => {
                                            setCustomFieldValue({
                                                ...customFieldValue, placeHolder: e.target.value
                                            })
                                        }}

                                    />
                                </InputBoxContainer> : null}
                                <div style={{ display: 'flex', marginTop: '10px', alignSelf: 'start' }}>
                                    {/* <Checkbox
                                    style={{ width: '25px', marginRight: '10px' }}
                                    value={customFieldValue.optional}
                                    onChange={() => customFieldValue.optional ? setCustomFieldValue({ ...customFieldValue, optional: false }) : setCustomFieldValue({ ...customFieldValue, optional: true })}
                                // key={index}
                                // value={item?.email}
                                // checked={day.includes(item?.email)}
                                // onChange={() => { day.includes(item?.email) ? handleRemoveSelected(item?.email) : handleCheckboxChange(item?.email) }}

                                /> */}
                                    <Label style={{ marginTop: '12px', marginBottom: 0 }}>Optional</Label>
                                    <Switch
                                        onChange={() => customFieldValue.optional ? setCustomFieldValue({ ...customFieldValue, optional: false }) : setCustomFieldValue({ ...customFieldValue, optional: true })}
                                    />
                                </div>

                            </div>
                            <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', marginBottom: '15px', justifyContent: 'flex-end', }}>
                                <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => setShowModal1(false)}>Cancel</SendButton1>
                                <SendButton1 style={{ marginRight: -15 }} onClick={async () => {

                                    const formatedValue = customFieldValue?.fieldName?.replace(/\s+/g, '_');
                                    setCustomData([...customData, { ...customFieldValue, valueKey: formatedValue }]);
                                    setCustomFieldValue({
                                        "fieldName": "",
                                        "fieldType": "Short text",
                                        "placeHolder": "",
                                        "optional": '',
                                        "valueKey": '',
                                        "data": []
                                    })
                                    setShowModal1(false)
                                }}>Add</SendButton1>
                            </div>
                        </Modal>}

                        <SubmitButton style={{ background: loader1 && '#bbb' }} onClick={handleSubmit} type="submit">

                            {loader1 ? <div style={{ display: 'flex', marginTop: '-20px', alignItems: 'center', justifyContent: 'center' }}> <Player
                                src={require('../../assets/loaders.json')}
                                className="player"
                                style={{ width: '60px', height: '60px' }}
                                loop
                                autoplay
                            />
                            </div> : props?.formNumber ? "Save form" : "Create form"}

                        </SubmitButton>

                        {linl && <InputBoxContainer style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
                            <Inputbox
                                type="text"
                                value={linl}
                                // value={inputValue}
                                // onChange={(e) => setInputValue(e.target.value)}
                                placeholder="Paste your link here"
                                disabled
                                style={{ width: '69%', marginRight: '20px',background:'#f3f3f3' }}
                            />
                            <CopyButton style={{ background: copy === 'Copied!' && 'green' }} onClick={handleCopyClick}>{copy}</CopyButton>
                        </InputBoxContainer>}
                        {linl1 && <InputBoxContainer style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '80px' }}>
                            <Inputbox
                                type="text"
                                value={linl1}
                                // value={inputValue}
                                // onChange={(e) => setInputValue(e.target.value)}
                                placeholder="Paste your link here"
                                disabled
                                style={{  width: '69%', marginRight: '20px',background:'#f3f3f3' }}
                            />
                            <CopyButton style={{ background: copy1 === 'Copied!' && 'green' }} onClick={handleCopyClick1}>{copy1}</CopyButton>
                        </InputBoxContainer>}

                    </Form>
                </MainContainer>
            </Container>
            <div style={{ width: '50%',paddingTop:'20px' }}>
                <PreviewForm
                    customField={customData}
                    isProfile={isProfile}
                    header={header}
                    subHeader={subHeader}
                />
            </div>
        </div>
    );
};

const InputBoxContainer = styled.div`
display:flex;
flex-direction:column;
position:relative;
margin-bottom:20px;
margin-left:10px;
@media (min-width: 260px) and (max-width: 721px){
    margin-left:0px;
}
`
const Value = styled.label`
    font-weight: 500;
    font-size: 13px;
    width:85%;
    color: gray;
    display: flex;
    margin-bottom: 18px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
`;


const CopyButton = styled.button`
    border: 1px solid ${palette.newBlue};
    background: ${palette.newBlue};
    color: #fff;
    width: 150px;
    height: 38px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
`;
const Container = styled.div`
width:50%;
display:flex;
height:auto;
flex-direction:column;
align-items:center;
background:#fff;
`
const ProfilePic = styled.img`
background:#2b373f;
color:white;
height:65px;
width:65px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:35px;
font-weight:bold;
margin-left:-5px;

`
const StyledSpan = styled.span`
  background: #ddd;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left:10px;
  font-size:11px;
`;


const DeleteContainer = styled.div`
position:absolute;
right:0;
top:-5px;
margin-top:-5px;
background:#fff;
display:flex;
align-items:center;
justify-content:center;
border: none;
margin-right:10px;
height:25px;
align-self:center;
border-radius:8px;
padding:5px;
font-size: 14px;
cursor: pointer;

`

const PhoneFiled = styled(PhoneInput)`
--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;
`

const Form = styled.div`

 margin-bottom:40px;
  min-height:100vh;
  padding: 20px;
  
background:#fff;
  border-radius: 8px;
  

`;
const MainContainer = styled.div`
width:100%;
padding-top:25px;
display:block;
justify-content:center;
align-items:center;
height:auto;


@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`


const IconContainer = styled.button`
display:flex;
justify-content:center;
align-items:center;
padding:2px;
width: 30px;
height: 30px;
 border:2px solid #4169e1;
 border-radius:30px;
 margin-right:15px;
 cursor:pointer;
`;
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const AddCustom = styled.div`
display:flex;
cursor:pointer;
justify-content:center;
align-items:center;
font-weight:600;
margin-top:25px;
font-size:16px;
margin-bottom:25px;
cursor:pointer;

`

const ModalContent = styled.div`
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${palette.newBlue};
  color: #fff;
  font-weight:500;
  border: none;
  cursor: pointer;
  height:45px;

  margin-bottom:40px;
  width:98%;
`;
const Inputbox = styled.input`
align-self:end;
width:30%;
border-radius:5px;
font-size: 14px;
height:22px;
font-weight: 400;
color: #344054;
background: #fff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding: 10px 14px;
&:focus {
    outline: none;
    border: 1px solid #000;
  }
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    width:85%;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word; /* Ensure that long words break and wrap onto the next line */
    word-break: break-all; /* Allow long words to break even within the word itself */
`;
const HeaderContainer = styled.div`
display:flex;
height:auto;
justify-content:space-between;
align-items:center; 
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;

width:23px;
height:23px;
align-self:center;
border-radius:25px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const DateInputContainer = styled.div`
  display: flex;
  gap: 5px;
  position:relative;
  width:45%;
  align-items:center;
  @media (min-width: 260px) and (max-width: 721px){
    width:90%;
    }
`;

const DateInputSection = styled.input`
  width: calc(33.33% - 2px);
  border-radius: 5px;
  font-size: 16px;
  height: 25px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;
const TimeSelect = styled.select`
  width: 45%;
  border-radius: 5px;
  font-size: 16px;
  height: 45px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding:10px;
  @media (min-width: 260px) and (max-width: 721px){
    width:90%;
    }
`;
const Selectbox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:48px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;
align-items:center;
font-weight:500;
cursor:pointer;

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:65px;
width:65px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:35px;
font-weight:bold;
margin-left:-5px;
`
const Header = styled.p`
font-size:14px;
font-weight:500;
color:gray;
text-align:start;

`

const Heading = styled.p`
font-weight:600;
font-size: 14px;
font-weight:900;
margin-bottom:-10px;
text-align:start;

`
const IconWrapper = styled.div`
  display:flex;
  flex-direction:column;
justify-content:start;
`;
const InputText = styled.textarea`
font-family:Inter;
width: 92%;
border-radius: 5px;
font-size: 16px;
height: 45px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
margin-bottom:30px;
  resize: vertical; /* Allow vertical resizing */
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 99%;
  }
`;
export default RequestForm;
