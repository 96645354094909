import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { addTermAndCondition, getUserDetails, updateDetail } from '../../store/Actions/dashboard.action'
import { formatMobileNumber } from '../../utils/Commonfunction'
import trash from '../../assets/trash.png'

function TermsAndConditions(props) {
    const detail = useSelector(state => state?.auth?.userData)
    console.log(detail)
    const [values, setValues] = useState({
        "invoice":detail?.termCondition?.[0]?.invoice||'',
        "quotes":detail?.termCondition?.[0]?.quotes||'',
        "bookings":detail?.termCondition?.[0]?.bookings||''
    })
    const [onFocus, setOnfocus] = useState(false)
    const [listData, setListData] = useState([])
    const [edit,setEdit]=useState(false)
    const [val, setVal] = useState('')
    const dispatch = useDispatch()

    const onPress = async () => {
        await dispatch(addTermAndCondition({"term":[values]}))
        await dispatch(getUserDetails())
  
        setEdit(false)
    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const deleteHandler = (index) => {
      
        const data = [...listData]
        data.splice(index, 1)
        setListData(data)


    }

    return (
        <Main>
            
             <div style={{ display: 'flex',justifyContent:'space-between', alignItems: 'center' }}>
                    
                    <h3>{"Terms and conditions"}</h3>
               {onFocus &&     <div style={{display:'flex'}}>
                    <SendButton style={{background:'#ddd',color:'black'}}
                onClick={()=>setOnfocus(false)}
                >Cancel</SendButton>
                 <SendButton 
                onClick={()=>{
                    onPress()
                    setOnfocus(false)}}
                >Save</SendButton>
                </div>}

                </div>
            <Wrapper>
            <StyleBox>
            <h4 style={{marginTop:0,textAlign:'start'}}>Invoice</h4>
            <Name>Manage your terms and conditions for invoices</Name>
                <InputText
                value={values?.invoice} 
                onFocus={() => setOnfocus(true)}
                onChange={(e) => setValues({ ...values, invoice: e.target.value })}
                />
            </StyleBox>

            <StyleBox>
            <h4 style={{marginTop:0,textAlign:'start'}}>Quote</h4>
            <Name>Manage your terms and conditions for quotes</Name>
                <InputText
                 value={values?.quotes} 
                 onFocus={() => setOnfocus(true)}
                 onChange={(e) => setValues({ ...values, quotes: e.target.value })}
                />
            </StyleBox>

            <StyleBox>
            <h4 style={{marginTop:0,textAlign:'start'}}>Service request</h4>
            <Name>Manage your terms and conditions for service requests</Name>
                <InputText
                 value={values?.servicerequest} 
                 onFocus={() => setOnfocus(true)}
                 onChange={(e) => setValues({ ...values, bookings: e.target.value })}
                />
            </StyleBox>
            </Wrapper>
        </Main>
    )


}

export default TermsAndConditions;



const Main = styled.div`
min-width:57vw;
height:100%;
margin-top:-10px;
padding-top:25px;
flex-shrink: 0;
overflow: auto; 
margin-left:30px;
padding-bottom:50px;
  display: flex;
  flex-direction: column;
 overflow-y:hidden;

  @media (min-width: 260px) and (max-width: 999px){
    width:600px;
}
@media (min-width: 1000px) and (max-width:1311px){
  width:730px;
}

`
const Name = styled.p`
text-align:start;
font-size: 14px;
color: rgb(45, 47, 49);
font-weight: 500;
margin-top:-15px;
margin-bottom:20px;
`

const Header = styled.p
    `
font-weight: 400;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-right:10px;
`
const Profile = styled.div`
width:80px;
height:80px;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
border-radius:10px;
background:#4169e1;

color:white;
font-weight:600;
font-size:24px;

`
const Wrapper = styled.div`
overflow-y:scroll;
&::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }

`
const Inputbox = styled.input`
width:90%;
border-radius:5px;
font-size: 16px;
height:25px;
font-weight: 400;
margin-right:15px;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding: 10px 14px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:4px;
    align-self: flex-start;
    font-family:Inter;

  
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

cursor:pointer;
width:60%;
:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 14px;
margin-left:20px;
width:110px;
height:40px;
border-radius:8px;
border:1px solid white;

color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`
const InputText = styled.textarea`
font-family:Inter;
width: 95%;
border-radius: 5px;
font-size: 16px;
height: 135px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
  resize: vertical; /* Allow vertical resizing */
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 99%;
  }
`;
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #white;
background:white;
width:90%;
margin-left:15px;
margin-top:30px;
padding:25px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.08);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`