import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import {  sendInviteToworker } from '../../store/Actions/dashboard.action'
import { formatMobileNumber } from '../../utils/Commonfunction'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import whiteUser from '../../assets/avatar.png'

function AddTeamMember(props) {
    const detail = useSelector(state => state?.auth?.userDetails)
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error,setError]=useState('')
    const [values, setValues] = useState({
        "medium": "email",
        "email": "",
        "phone": "",
        "firstName": "",
        "lastName": ""
    })
    const [onFocus, setOnfocus] = useState(false)
    const dispatch = useDispatch()

    const onPress = async () => {
        const res = await dispatch(sendInviteToworker({...values,phone:phoneNumber}))
        if(res.status){
            props?.onCancel()
        }
        else{
            setError(res?.message)
        }

    }
    const getName = (channel) => {
        if (!channel) {
          return 'A'
        }
        const val = channel?.split(' ')
    
        if (val.length == 2) {
          return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
          return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    return (
        <Main>

            <Profile>{values?.firstName||values?.lastName? getName(`${values?.firstName} ${values?.lastName}`)
        :  <UserIcon src={whiteUser}/>      
        }</Profile>

           
              
                    <Label >First name</Label>
                    <Inputbox value={values?.firstName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'center', width: '440px' }} id="outlined-basic" size="small" placeholder="First name" variant="outlined" onChange={(e) => setValues({ ...values, firstName: e.target.value })} />
                    <Label >Last name</Label>
                    <Inputbox value={values?.lastName} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'center', width: '440px' }} id="outlined-basic" size="small" placeholder="Last name" variant="outlined" onChange={(e) => setValues({ ...values, lastName: e.target.value })} />
               
               
                    <Label >Email</Label>
                    <Inputbox value={values?.email} onFocus={() => setOnfocus(true)} style={{ display: 'flex', alignSelf: 'center', width: '440px' }} id="outlined-basic" size="small" placeholder="Email" variant="outlined" onChange={(e) => setValues({ ...values, email: e.target.value })} />
                    <Label >Phone number</Label>
                    <div className='phoneInput'>
                   
                  
                    <PhoneFiled
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(val)=>setPhoneNumber(val)}
                       
                        defaultCountry="CA"
                        style={{ width: '440px', marginLeft: '-0px', borderRadius: '5px' }}
                        international
                        focusInputOnCountrySelection='true'
                    />
                    </div>
                    {error?<p style={{color:'red',textAlign:'start',marginLeft:'35px',marginRight:'30px'}}>{error}</p>:null}
              
                    {<div style={{ alignSelf: 'end', marginTop: '20px', marginBottom: '20px',position:'fixed',bottom:10 }}>
                <SendButton onClick={() => props?.onCancel()} style={{ background: '#DDD', color: '#000', marginRight: '20px' }}>Cancel</SendButton>
                <SendButton onClick={() => onPress()}>Save</SendButton>
            </div>}
          
        </Main>
    )


}

export default AddTeamMember;

const PhoneFiled=styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;
`


const Main = styled.div`
width:auto;

padding-top:25px;
scrollbar-width: thin;
align-items:center;
scrollbar-color: #888 transparent;

height: auto;
  display: flex;
  flex-direction: column;
  
`
const Name = styled.p`
text-align:start;
font-size: 16px;
line-height: 21px;
color: rgb(45, 47, 49);
font-weight: 600;
margin-top:-15px;
margin-bottom:40px;
`

const Header = styled.p
    `
font-weight: 400;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #000;
margin-top:40px;
margin-right:10px;
`
const Profile = styled.div`
width:80px;
height:80px;
padding:3px;
display:flex;
justify-content:center;
align-items:center;
align-self:center;
text-align:center;
border-radius:10px;
background:#4169e1;

color:white;
font-weight:600;
font-size:24px;

`
const UserIcon = styled.img`
width: 35px;
height: 35px;
`
const Inputbox = styled.input`
width:500px;
border-radius:5px;
font-size: 16px;
height:25px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #d0d5dd;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

padding: 10px 14px;
::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }


@media (min-width: 260px) and (max-width: 721px){
width:90%;
}
`
const Label = styled.label`

    font-weight: 500;
    font-size: 14px;
    margin-left:4px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    margin-top:24px;
    align-self: flex-start;

    font-family:Inter;

  
`
const Value = styled.p`
color:#000;
font-size: 14px;
text-align:start;

cursor:pointer;
width:60%;
:hover{
    background:#DDDDDD;
    border-radius:5px;
}
`
const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 16px;
margin-top:20px;
width:120px;
height:44px;
border-radius:8px;
border:1px solid white;

color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`