import React, { useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Menu, MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addNumber, } from '../../store/Actions/dashboard.action';
import { formatMobileNumber } from '../../utils/Commonfunction';
import moment from 'moment';
import { AutoAwesome, CheckBox, HandymanOutlined } from '@mui/icons-material';
import { palette } from '../../utils/Palette';

const FormDetails = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [error,setError]=useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createleadData = async () => {
    const res = await dispatch(addNumber({
      "email": props?.formDetail?.customerEmail,
      "name": props?.formDetail?.customerName,
      "phoneNumber": props?.formDetail?.customerNumber,
      "type": "website",
      "address": props?.formDetail?.Address,
      "businessName": "",
      'type': 'Customer form'
    }))

 
    if (res.status === true) {
      navigate(`/dashboard/leads/${res?.data?.contact?.[0]?._id}`)
      props?.onCancel()
    }
    else{
      setError(res?.meesage)
    }
  }
  return (
    <Container>

      <MainContainer>
       {props?.type!=='Job'&& <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ConvertButton onClick={handleClick}>Convert</ConvertButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open1}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
                width: '150px'
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {<MenuItem style={{ color: palette.newBlue, fontSize: '14px', fontWeight: '500', marginBottom: 5, }} onClick={createleadData}>
              <AutoAwesome style={{ fontSize: '16px', marginRight: '10px' }} />
              Lead
            </MenuItem>}
            <MenuItem style={{ color: palette.newBlue, fontSize: '14px', fontWeight: '500' }} onClick={async()=>{
                 const res = dispatch(addNumber({
                  "email": props?.formDetail?.customerEmail,
                  "name": props?.formDetail?.customerName,
                  "phoneNumber": props?.formDetail?.customerNumber,
                  "type": "website",
                  "address": props?.formDetail?.Address,
                  "businessName": "",
                  'type': 'Customer form'
                }))
              props?.setJobmodal()}}>
              <HandymanOutlined style={{ fontSize: '16px', marginRight: '10px' }} />
              Job
            </MenuItem>

          </Menu>
        </div>}
        <Form>
          {error&&<Label style={{color:'red',marginLeft:'20px'}}>{`⚠ ${error}`}</Label>}
          {Object.keys(props?.formDetail).map(key => {
            return (
              key === 'formNo' || key === 'formNumber' || key === 'formName' || key === 'subHeader' ?
                null :

                key === "customerName" ?
                  <InputBoxContainer key={key}>
                    <Label>Customer name</Label>
                    <Value>{props?.formDetail[key]}</Value>
                  </InputBoxContainer> :
                  key === "customerNumber" ?
                    <InputBoxContainer key={key}>
                      <Label>Customer number</Label>
                      <Value>{formatMobileNumber(props?.formDetail[key])}</Value>
                    </InputBoxContainer> :
                    key === "customerEmail" ?
                      <InputBoxContainer key={key}>
                        <Label>Customer email</Label>
                        <Value>{props?.formDetail[key]}</Value>
                      </InputBoxContainer> :
                      <InputBoxContainer key={key}>
                        <Label>{key === 'header' ? 'Form name' : key === 'createdAt' ? 'Created at' : key?.replace(/_/g, " ")}</Label>
                        {
                          key === 'createdAt' ? <Value>{moment(props?.formDetail[key])?.format('DD MMM YYYY')}</Value> :key==='header'?<Value>{props?.formDetail[key]||"New request"}</Value>:
                            typeof props?.formDetail[key] === 'string' ?
                              <Value> {props?.formDetail[key]} </Value> :
                              <CheckValue style={{ lineHeight: '18px', flexDirection: 'column' }}>
                                {props?.formDetail[key]?.map((item) =>
                                  <CheckValue><CheckBox style={{fontSize:'18px',marginRight:'10px',color:palette.newBlue}}/> {`${item}\n`}</CheckValue>
                                )}
                                </CheckValue>
                        }
                      </InputBoxContainer>
            );
          })}






        </Form>
      </MainContainer>
      
    </Container>
  )
}

export default FormDetails;
const InputBoxContainer = styled.div`
display:flex;
flex-direction:column;
position:relative;
margin-bottom:10px;
margin-left:20px;
margin-right:20px;
@media (min-width: 260px) and (max-width: 721px){
    margin-left:0px;
}
`
const Container = styled.div`
overflow-y:scroll;
display: flex;
  justify-content: center;
  width:100%;
  background:#FAFAFA;
`

const Form = styled.div`
width:100%;
height:auto;
  padding-top: 20px;
  border-radius: 8px;


`;
const MainContainer = styled.div`
height:100vh;
width:100%;
@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100wv;
    max-width: 520px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const ConvertButton = styled.button`
border:1px solid ${palette.newBlue};
 background: ${palette.newBlue};
color:#fff; 
margin-top:15px;
width:120px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    width:100%;
    margin-bottom:8px;
    color: #344054;
    display: flex;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word; /* Ensure that long words break and wrap onto the next line */
    word-break: break-all; /* Allow long words to break even within the word itself */
`;
const Value = styled.div`
    font-weight: 500;
    font-size: 13px;
    color: rgba(32, 32,32, 0.75);
    background:#f3f3f3;
    width:93%;
    border-radius:8px;
    border:1px solid #bbb;
    padding:10px;
    display: flex;
    margin-bottom: 18px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word; /* Ensure that long words break and wrap onto the next line */
    word-break: break-all; /* Allow long words to break even within the word itself */
`;
const CheckValue = styled.div`
    font-weight: 500;
    font-size: 13px;
    width:90%;
    border-radius:8px;
    display: flex;
    padding-bottom: 10px;
    align-self: flex-start;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word; /* Ensure that long words break and wrap onto the next line */
    word-break: break-all; /* Allow long words to break even within the word itself */
`;
