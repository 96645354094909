import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getCalendarDataV2 } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components'
import { DirectionsRenderer, GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import { formatMobileNumber } from '../../../utils/Commonfunction';
import { Divider } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { useNavigate } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import mapStyles from '../../Tracking/mapStyles';
import { useRef } from 'react';
import { useEffect } from 'react';
import DatePickerComponent from '../../../ReuseableComponets/DatePickerComponent';
import UnScheduledJobs from "../../../Pages/unScheduledJobs";
import { Player } from '@lottiefiles/react-lottie-player';
import { useState } from 'react';
import MapCard from './Component/MapCard';


function MapView() {
    const localizer = momentLocalizer(moment)
    const [loader, setLoader] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [currentDate, setCurrentDate] = useState(new Date());
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Map")
    const [type, setType] = useState("Scheduled")
    const [isRoute, setIsRoute] = useState(false);
    const [isCustomRoute, setIsCustomRoute] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [tabData, setTabData] = useState([])
    const [value, setValue] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedLocations, setSelectedLocations] = useState([]);
    const mapRef = useRef(null);
    const navigate = useNavigate();
    const startDate = (date) => {
        const start = moment(date).subtract(2, 'days').format('YYYY-MM-DD');
        const end = moment(date).add(2, 'day').format('YYYY-MM-DD');

        return { start, end };
    };
    const fetchLocationData = async (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const { start, end } = startDate(date);
        const res1 = await dispatch(getCalendarDataV2(start, end));
        const dataForDate = res1?.data?.detailData?.filter((item) => {
            const newDate = moment(item?.date).format('YYYY-MM-DD');
            if (newDate === formattedDate) {
                return item
            }
        });
        const promises =
            await Promise.all(
                dataForDate?.map((item, index) => {
                    if (item?.latitude && item?.longitude) {
                        return {
                            name: item?.customerAddress,
                            position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                            start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                            end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                            data: item,
                            
                        };
                    }
                    return null;
                })
            )
        const filteredData = promises?.filter(item => item !== null);
        filteredData.sort((a, b) => {
            const timeA = new Date(a?.start).getTime(); 
            const timeB = new Date(b?.start).getTime();
            
            return timeA - timeB; 
          });
          const optimizedOrder = filteredData?.map((location, index) => ({
            ...location,
            index: index + 1
        }));
    


        // const promises = dataForDate.map((item, index) => {
        //     return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${item?.customerAddress}&key=AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA`)
        //         .then(response => response.json())
        //         .then(data => {
        //             if (data?.results && data?.results?.length > 0) {
        //                 console.log('location', data.results[0].geometry.location);
        //                 const location = data.results[0].geometry.location;
        //                 const jitteredLatitude = location.lat + (index * 0.0001);
        //                 const jitteredLongitude = location.lng + (index * 0.0001);
        //                 return {
        //                     name: item?.customerAddress,
        //                     position: { lat: jitteredLatitude, lng: jitteredLongitude },
        //                     data: item
        //                 };
        //             } else {
        //                 console.warn(`No results found for address: ${item?.customerAddress}`);
        //                 return {
        //                     name: item?.customerAddress,
        //                     position: { lat: 0, lng: 0 },
        //                     data: item
        //                 };
        //             }
        //         });
        // });

        return optimizedOrder;
    };

    const handlePrevDate = () => {
        setLoader(true)
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() - 1);
            fetchLocationData(newDate)
                .then((arrData) => {
                    setTabData(arrData)
                    let mapArr = []
                    if (isRoute) {
                        arrData?.map((item) => {
                            mapArr.push(item?.position)
                        })

                        if (mapArr?.length > 1) {
                            const waypoints = mapArr.slice(1, mapArr.length - 1).map((location) => ({
                                location,
                                stopover: true,
                            }));

                            const directionsService = new window.google.maps.DirectionsService();

                            const request = {
                                origin: mapArr?.[0],
                                destination: mapArr[mapArr.length - 1],
                                waypoints: waypoints,
                                travelMode: window.google.maps.TravelMode.DRIVING,
                            };
                            directionsService.route(request, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    setDirectionsResponse(result);

                                } else {
                                    setDirectionsResponse([]);

                                }
                            });
                        }
                        else if (arrData.length === 1) {
                            setDirectionsResponse(null);

                        }
                        else {
                            setDirectionsResponse([]);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching geocode data for previous date:", error);
                });
            return newDate;
        });
        setLoader(false)
    };

    const handleNextDate = () => {
        setLoader(true)
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + 1);
            fetchLocationData(newDate)
                .then((arrData) => {
                    setTabData(arrData)
                    let mapArr = []
                    if (isRoute) {
                        arrData?.map((item) => {
                            mapArr.push(item?.position)
                        })
                        if (mapArr?.length > 1) {
                            const waypoints = mapArr.slice(1, mapArr.length - 1).map((location) => ({
                                location,
                                stopover: true,
                            }));

                            const directionsService = new window.google.maps.DirectionsService();

                            const request = {
                                origin: mapArr?.[0],
                                destination: mapArr[mapArr.length - 1],
                                waypoints: waypoints,
                               
                                travelMode: window.google.maps.TravelMode.DRIVING,
                            };
                            directionsService.route(request, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    setDirectionsResponse(result);
                                } else {
                                    setDirectionsResponse([]);

                                }
                            });
                        }
                        else if (arrData.length === 1) {
                            setDirectionsResponse(null);

                        }
                        else {
                            setDirectionsResponse([]);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching geocode data for previous date:", error);
                });

            return newDate;
        });
        setLoader(false)

    };


    const handleMarkerClick = (location, index, starting) => {
        setSelectedLocations((prevSelected) => {
            const alreadySelected = prevSelected.find(
                (loc) => loc.lat === location.lat && loc.lng === location.lng
            );
            let updatedLocations;
            if (alreadySelected) {
                updatedLocations = prevSelected.filter(
                    (loc) => loc.lat !== location.lat || loc.lng !== location.lng
                );
            } else {
                updatedLocations = [...prevSelected, location];
            }

            if (updatedLocations.length >= 2) {
                generateDirections(updatedLocations);
            } else {
                setDirectionsResponse(null);
            }

            const updateData = [...tabData].map((item, idx) => {
                if (idx === index - 1) {
                    return {
                        ...item,
                        "index": selectedLocations?.length + 1
                    };
                } else {
                    return {
                        ...item,
                        "index": starting ? '' : item?.index
                    };
                }
            });

            setTabData(updateData);
            return updatedLocations;
        });
    };

    const generateDirections = (locations) => {
        const waypoints = locations.slice(1, locations.length - 1).map((location) => ({
            location,
            stopover: true,
        }));

        const directionsService = new window.google.maps.DirectionsService();

        const request = {
            origin: locations[0],
            destination: locations[locations.length - 1],
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result);
            } else {
                console.error('Error fetching directions', status);
            }
        });
    };



    const init = async () => {
        setLoader(true)
        let locat = {};
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                locat = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            },

                (error) => {
                    setCurrentLocation({
                        lat: 38.7946,
                        lng: -106.5348,
                    });

                }
            );
        }
        else {

            setCurrentLocation({
                lat: 38.7946,
                lng: -106.5348,
            });

        }
        setIsRoute(false)
        const { start, end } = startDate(new Date());
        const res1 = await dispatch(getCalendarDataV2(start, end));
        if (res1.status === true) {
            const data = [...res1?.data?.detailData]?.filter(items => items?.customerAddress);
            const data1 = data?.filter((item) => moment(item?.date)?.format('DD MM YYY') === moment(new Date())?.format('DD MM YYY'))

            const promises =
                await Promise.all(
                    data1?.map((item, index) => {
                        if (item?.latitude && item?.longitude) {
                            return {
                                name: item?.customerAddress,
                                position: { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                                data: item,
                                index:index+1
                            };
                        }
                        return null;
                    })
                )
            const filteredData = promises?.filter(item => item !== null);
            filteredData.sort((a, b) => {
                const timeA = new Date(a?.start).getTime(); 
                const timeB = new Date(b?.start).getTime();
                
                return timeA - timeB; 
              });
              const optimizedOrder = filteredData?.map((location, index) => ({
                ...location,
                index: index + 1
            }));
              setTabData(optimizedOrder)

        }

        setLoader(false)

    };
    React.useEffect(() => {
        init()

    }, [])
    const handleSelectEvent = (event) => {
        setValue({})
        navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };


    const eventStyleGetter = (event, start, color, end, isSelected) => {
        const style = {
            backgroundColor: event?.color ? event?.color : palette.newBlue,
            borderRadius: '2px',
            padding: '4px',
            opacity: 0.8,
            color: 'white',
            fontSize: '12px',
            border: '0px solid #fff',
            opacity: 1.0,
            display: 'block',
            marginLeft: '5px',
            overflowY: 'hidden',
            marginLeft: '3px',

        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {

        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            navigate('/dashboard/calander?type=week')
        };

        const goToDay = () => {
            navigate('/dashboard/calander?type=day')
        };
        const goToMonth = () => {
            navigate('/dashboard/calander?type=month')
        };



        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginTop: '-10px', marginLeft: '10px' }}>
                        <ToggleTabs
                            labels={['Scheduled', 'Unscheduled']}
                            selected={type}
                            onToggle={(val) => {

                                if (val === 'Scheduled') {
                                    setType('Scheduled')
                                }

                                else {
                                    setType('Unscheduled')
                                }
                            }}

                        />
                    </div>
                    <div className="rbc-toolbar" >

                        <div style={{ display: 'flex', marginLeft: '20px' }} >

                            <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => handlePrevDate()}>
                                <ArrowBackIosNew style={{ fontSize: '18px' }} />
                            </button>
                            {/* <span style={{ alignSelf: 'center', fontSize: '14px',fontWeight:'500' }} className="rbc-toolbar-label">{toolbar.label}</span> */}
                            <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => handleNextDate()}>
                                <ArrowForwardIos style={{ fontSize: '18px' }} />
                            </button>
                    
                            <DatePickerComponent
                                selectedDate={currentDate}
                                onDateChange={(text) => {
                                    setCurrentDate(text)
                                    fetchLocationData(text)
                                        .then((arrData) => {
                                            let mapArr = []
                                            if (isRoute) {

                                                arrData?.map((item) => {
                                                    mapArr.push(item?.position)
                                                })

                                                if (arrData?.length > 1) {
                                                    const waypoints = mapArr.slice(1, mapArr.length - 1).map((location) => ({
                                                        location,
                                                        stopover: true,
                                                    }));

                                                    const directionsService = new window.google.maps.DirectionsService();

                                                    const request = {
                                                        origin: mapArr?.[0],
                                                        destination: mapArr[mapArr.length - 1],
                                                        waypoints: waypoints,
                                                       
                                                        travelMode: window.google.maps.TravelMode.DRIVING,
                                                    };
                                                    directionsService.route(request, (result, status) => {
                                                        if (status === window.google.maps.DirectionsStatus.OK) {
                                                            setDirectionsResponse(result);
                                                        } else {
                                                            setDirectionsResponse([]);
                                                            console.error('Error fetching directions', status);
                                                        }
                                                    });
                                                }
                                                else if (arrData.length === 1) {
                                                    setDirectionsResponse(null);

                                                }
                                                else {
                                                    setDirectionsResponse([]);
                                                }
                                            }
                                            setTabData(arrData)
                                            // if (arrData?.length > 0) {
                                            //     let startingLocation = arrData[0];
                                            //     let remainingLocations = [...arrData.slice(1)];


                                            //     const sortByProximity = (startLocation, locations) => {
                                            //         return locations.sort((a, b) => {
                                            //             const distanceA = haversine(startLocation.position.lat, startLocation.position.lng, a.position.lat, a.position.lng);
                                            //             const distanceB = haversine(startLocation.position.lat, startLocation.position.lng, b.position.lat, b.position.lng);
                                            //             return distanceA - distanceB; // Sort by ascending distance
                                            //         });
                                            //     };
                                            //     let optimizedOrder = [startingLocation];

                                            //     while (remainingLocations.length > 0) {
                                            //         let closestLocation = sortByProximity(optimizedOrder[optimizedOrder.length - 1], remainingLocations)[0]; // Get the closest location
                                            //         optimizedOrder.push(closestLocation);
                                            //         remainingLocations = remainingLocations.filter(location => location !== closestLocation); // Remove the selected location from remaining
                                            //     }
                                            //     optimizedOrder = optimizedOrder.map((location, index) => ({
                                            //         ...location,
                                            //         index: index + 1
                                            //     }));

                                            //     setTabData(optimizedOrder);
                                            // }
                                            // else {
                                            //     setTabData(arrData)
                                            // }
                                        })
                                        .catch((error) => {
                                            console.error("Error fetching geocode data for previous date:", error);
                                        });
                                }}
                                iconStyle={{ fontSize: '20px', marginTop: '-2px' }}
                                customStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40px', fontWeight: '550', fontSize: '14px', border: 'none' }}
                            />



                        </div>

                        <span className="rbc-toolbar-label"></span>
                        {/* <span style={{ marginRight: '20px' }} className="rbc-btn-group">
                    <Selectbox
                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                        placeholder='select' value={'Select an option'} displayEmpty
                        renderValue={() => filter} onChange={(e) => {
                            console.log(e.target)

                        }}
                        MenuProps={{ // Add MenuProps to customize menu styling
                            PaperProps: {
                                style: {
                                    marginTop: '8px', // Adjust this value for the desired gap
                                },
                            },
                        }}
                    >
                        <StyledMenuItem type="button" onClick={() => {
                            setFilter('Day')

                        }}>
                            Day
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToWeek}>
                            Week
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToMonth}>
                            Month
                        </StyledMenuItem>

                        {/* <StyledMenuItem type="button" onClick={goToAgendaView}>
                        Agenda
                    </StyledMenuItem> */}

                    </div>
                </div>

                <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center', marginRight: '15px' }}>

                    <ToggleTabs
                        labels={['Week', 'Month', "Day", "Map"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                                navigate('/dashboard/timeline')
                            }
                            else {
                                setFilter('Map')
                            }
                        }}

                    />
                </div>
            </div>
        );
    };

    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD');
        const dayName = moment(label).format('ddd');


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };



    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {
                backgroundColor: isToday && 'transparent'

            },
        };
    }


    const handleSelectSlot = (e) => {
        setValue({
            date: e?.start?.toISOString(),
            endDate: e?.end?.toISOString(),
            time: e?.start?.toISOString(),
            endTime: e?.end?.toISOString(),
            edit: true

        })
        setIsModal(true)

        // setIsModal(true)
        // `start` and `end` contain the selected range of dates

        // You can perform any actions with the selected dates here
    };
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);



    const handleDateChange = (newDate, view) => {

    };
    const handleOnLoad = (map) => {
        mapRef.current = map;
        fitBoundsToMarkers(map);


    };

    const fitBoundsToMarkers = (map) => {
        if (!isCustomRoute) {

            const bounds = new window.google.maps.LatLngBounds();
            if (tabData?.length > 0) {

                tabData.forEach(({ position }) => {
                    bounds.extend(new window.google.maps.LatLng(position.lat, position.lng));
                });

                map.fitBounds(bounds);
                if (tabData.length > 1) {
                    map.fitBounds(bounds);
                    const listener = map.addListener('bounds_changed', () => {
                        const currentZoom = map.getZoom();
                        if (currentZoom > 8) {
                            map.setZoom(8);
                        }
                        window.google.maps.event.removeListener(listener);
                    });
                } else {
                    map.setCenter(bounds.getCenter());
                    map.setZoom(8);
                }
            }
            else {

                bounds.extend(new window.google.maps.LatLng(currentLocation?.lat, currentLocation?.lng));
                map.fitBounds(bounds);
                const listener = map.addListener('bounds_changed', () => {
                    const currentZoom = map.getZoom();
                    if (currentZoom > 8) {
                        map.setZoom(8);
                    }
                    window.google.maps.event.removeListener(listener);
                });

            }
        }
    };
    useEffect(() => {
        if (mapRef.current && tabData && tabData.length > 0) {
            fitBoundsToMarkers(mapRef.current);
        }
    }, [tabData, isCustomRoute]);

    return (
        <Body>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={[]}
                    resources={[]}


                    defaultDate={new Date()}
                    style={{ width: '100%' }}

                    step={60}
                    timeslots={1}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    dayPropGetter={dayPropStyles}
                    onNavigate={handleDateChange}
                    views={false}
                    selectable={false}

                    defaultView={'day'}
                    eventPropGetter={eventStyleGetter}
                    formats={{

                        eventTimeRangeFormat: () => {
                            return ""
                        }

                    }}
                    components={{
                        toolbar: MyToolbar,
                        event: () => null,


                        day: {
                            header: dayssHeaderContent,

                        },

                        timeSlotWrapper: () => null,
                        dayColumnWrapper: () => null,
                        eventContainerWrapper: () => null,
                        timeGutterHeader: () => null,
                    }}
                />
                {(!loader && currentLocation?.lat) ? type === 'Unscheduled' ?

                    <UnScheduledJobs customStyle={{ left: 240, top: 72 }} noheader={true} onSubmit={() => init()} /> :
                    <div style={{ display: 'flex', height: '100vh', }}>
                        <MapCardContainer>
                            {tabData?.length > 0 && <RippleButton style={{ marginRight: '10px', width: '100%', marginBottom: '15px', marginTop: '5px' }} onClick={() => {
                                if (isRoute || isCustomRoute) {
                                    if (isCustomRoute) {

                                        const optimizedOrder = tabData?.map((location, index) => ({
                                            ...location,
                                            index: index + 1
                                        }));

                                    setTabData(optimizedOrder);
                                    }

                                    setIsCustomRoute(false)
                                    setDirectionsResponse(null)
                                    setSelectedLocations([])
                                    setIsRoute(false)

                                }
                                else {
                                    setIsRoute(true)
                                    let mapArr = []
                     
                                    tabData?.map((item) => {
                                        mapArr.push(item?.position)
                                    })

                                    if (mapArr?.length > 1) {
                                        const waypoints = mapArr.slice(1, mapArr.length - 1).map((location) => ({
                                            location,
                                            stopover: true,
                                        }));

                                        const directionsService = new window.google.maps.DirectionsService();

                                        const request = {
                                            origin: mapArr?.[0],
                                            destination: mapArr[mapArr.length - 1],
                                            waypoints: waypoints,
                                           
                                            travelMode: window.google.maps.TravelMode.DRIVING,
                                        };
                                        directionsService.route(request, (result, status) => {
                                            if (status === window.google.maps.DirectionsStatus.OK) {
                                                setDirectionsResponse(result);
                                            } else {
                                                setDirectionsResponse([]);

                                            }
                                        });
                                    }
                                    else if (tabData.length === 1) {
                                        setDirectionsResponse(null);
                                    }
                                    else {
                                        setDirectionsResponse([]);
                                    }
                                }
                            }}>
                                {(isRoute || isCustomRoute) ? "Reset Route" : "View Route"}
                            </RippleButton>}
                            <MapCard
                                data={tabData}
                                isRoute={isRoute}

                            />
                        </MapCardContainer>
                        {currentLocation?.lat && <GoogleMap
                            onLoad={handleOnLoad}
                            onClick={() => setActiveMarker(null)}
                            mapContainerStyle={{ width: "100vw", height: "100vh" }}
                            options={{
                                styles: mapStyles
                            }}
                            zoom={4}

                        >
                            {(isRoute || isCustomRoute) && (directionsResponse && tabData?.length) && (
                                <DirectionsRenderer

                                    directions={directionsResponse}
                                    options={{
                                        suppressMarkers: true,

                                        polylineOptions: {
                                            strokeColor: '#0F52BA',
                                            strokeOpacity: 0.8,
                                            strokeWeight: 2,

                                            zIndex: 0,
                                            icons: [
                                                {
                                                    icon: {
                                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                        scale: 1.5,
                                                        strokeColor: '#0F52BA',
                                                        fillColor: '#0F52BA',
                                                        fillOpacity: 0.5,
                                                    },
                                                    offset: '0%',
                                                    repeat: '70px'
                                                }
                                            ],

                                        },
                                    }}
                                />
                            )}
                            {tabData?.map((item, index) => (
                                <MarkerF
                                    key={index}
                                    position={item?.position}

                                    onClick={() => {
                                        if (isCustomRoute) {
                                            handleMarkerClick(item?.position, index + 1)
                                        }
                                        else {
                                            setActiveMarker(index)
                                        }
                                    }}
                                    icon={isCustomRoute ? {
                                        url: selectedLocations.find(
                                            (loc) => loc.lat === item?.position?.lat && loc.lng === item?.position.lng
                                        )
                                            ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                                            : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                                    } : {
                                        url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                                    }}
                                    zIndex={1000}
                                >
                                    {activeMarker === index ? (
                                        <InfoWindow key={index}  onCloseClick={() => setActiveMarker(null)}>
                                            <div key={index} style={{ width: '300px', height: '100%', maxHeight: '400px', overflowY: 'scroll', }}>
                                                <EventBox>
                                                    <Typography variant="body2" color="textSecondary" style={{ color: palette.newBlue, textAlign: 'start', fontSize: '12px' }}>
                                                        {`${moment(item?.data?.date).format('DD MMM YYYY')}, ${item?.data?.timezone ? moment(item?.data?.time).tz(item?.data?.timezone).format('hh:mm A') : moment(item?.data?.time).format('hh:mm A')}`}
                                                    </Typography>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                                        <div>
                                                            <EventTitle>{item?.data?.name}</EventTitle>
                                                            {item?.data?.email && <StyledTypography style={{ marginBottom: '6px' }} variant="body2" color="textSecondary">
                                                                {(item?.data?.email)}
                                                            </StyledTypography>}
                                                            <StyledTypography variant="body2" color="textSecondary">
                                                                {formatMobileNumber(item?.data?.customerNumber)}
                                                            </StyledTypography>

                                                        </div>
                                                        <BookingStatus status={item?.data?.bookingStatus}>
                                                            <span style={{ marginRight: '5px' }}>●</span>
                                                            {item?.data?.bookingStatus === 'Waiting for confirmation' ? 'Open' : item?.data?.bookingStatus}
                                                        </BookingStatus>
                                                    </div>
                                                    <Divider />
                                                    <LocationTitle>Location</LocationTitle>
                                                    <StyledTypography >
                                                        {item?.data?.customerAddress}
                                                    </StyledTypography>
                                                    {item?.data?.team?.length > 0 && <>
                                                        <Divider />
                                                        <LocationTitle>Crew</LocationTitle>
                                                        {item?.data?.team?.map(item =>
                                                            <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                                                {item?.firstName} {item?.lastName}
                                                            </StyledTypography>
                                                        )}
                                                    </>}
                                                    {item?.data?.items?.length > 0 && <>
                                                        <Divider />
                                                        <LocationTitle>Line items</LocationTitle>
                                                        {item?.data?.items?.map(item =>
                                                            <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                                                {item?.name} x {item?.quantity}
                                                            </StyledTypography>
                                                        )}
                                                    </>}
                                    
                                                </EventBox>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', background: '#fff' }}>

                                                    <RippleButton
                                                        onClick={() => navigate(`/dashboard/calander/${item?.data?.referenceNo}`)}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ width: '130px', fontWeight: '500', fontSize: '13px', }}
                                                    >
                                                        View details
                                                    </RippleButton>
                                                    <RippleButton
                                                        onClick={() => {
                                                            setIsCustomRoute(true)
                                                            handleMarkerClick(item?.position, index + 1, true)
                                                            setActiveMarker(null)

                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ width: '130px', fontWeight: '500', fontSize: '13px',background:'#f3f3f3',color:'black',border:'1px solid #ddd' }}
                                                    >
                                                        Start Routing
                                                    </RippleButton>
                                                </div>
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </MarkerF>


                            ))}
                        </GoogleMap>}
                    </div>
                    : <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>}
                {
                    ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                        <CreateBooking
                            value={value?.edit ? value : null}
                            team={team}
                            refresh={() => init()}
                            serviceRequest={value?.edit ? false : true}
                            newService={true}
                            setCreateBookingModal={() => {

                                setIsModal(false)
                            }}
                            onSubmitsw={(val, editValue) => {

                                // sendchat(val, editValue)
                                init()
                                setIsModal(false)



                            }
                            }

                        />
                    </FullScreenModal>
                }
            </div>
        </Body>
    );
}
export default MapView;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const EventBox = styled.div`
  padding: 4px;
overflow-y:scroll;
`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
  text-align:start;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
  text-align:start;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  text-align:start;
  
`;





const CalanderTime = styled(Calendar)`
height:60px;
.rbc-time-content {
    display: none;
  }

.rbc-time-view .rbc-allday-cell{
    display: none;
}
.rbc-time-view .rbc-row{
    display: none; 
}

.rbc-timeslot-group {
    display: none;

  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    display: none;

}

 
  .rbc-events-container{
    display: none;
  }
  .rbc-calendar {
    height: 500px; 
  }
  


`
const MapCardContainer = styled.div`
  width: 370px;
  padding: 10px;
  overflow-y: scroll;
  padding-bottom: 100px;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none; 
  }
`;
