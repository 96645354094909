// AccessDeniedPage.js
import React from 'react';
import styled from 'styled-components';

const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
`;

const Logo = styled.div`
  width: 150px;
  height: 150px;
  margin-bottom: 30px;

  svg {
    width: 100%;
    height: 100%;
    fill: #0f52ba;
  }
`;

const Heading = styled.h1`
  color: #0f52ba;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Message = styled.p`
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 40px;
  max-width: 600px;
`;

const ContactLink = styled.a`
  color: #0f52ba;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const AccessDeniedPage = () => {
  return (
    <AccessDeniedContainer>
      <Logo>
        <svg viewBox="0 0 24 24">
          <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25 21.75 6.615 21.75 12 17.385 21.75 12 21.75zm4.47-15.78l-9.54 9.54c-.293.293-.293.767 0 1.06.294.294.768.294 1.061 0l9.54-9.54c.293-.293.293-.767 0-1.06a.752.752 0 0 0-1.061 0z"/>
        </svg>
      </Logo>
      <Heading>Important Notice Regarding Login Access</Heading>
      <Message>
        Please note that you do not have permissions to log in via the web. To access your account, please log in using the Notifi App. If you encounter any issues or have questions, feel free to reach out for assistance.
      </Message>
      <ContactLink href="mailto:contact@getnotifi.com">Contact Support</ContactLink>
    </AccessDeniedContainer>
  );
};

export default AccessDeniedPage;
