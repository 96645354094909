import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from '../utils/Palette';



const InputBox = ({ type, placeholder, value,header, onChange,headerStyle, ...props }) => {
    return (
        <div style={{width:'100%'}}>
            {header && <Label style={headerStyle}>{header}</Label>}
            <StyledInputBox
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                {...props}
            />
        </div>
    );
};

InputBox.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    header:PropTypes.string,
    headerStyle:PropTypes.object
};

InputBox.defaultProps = {
    type: 'text',
    placeholder: '',
    header:''
};

export default InputBox;
const StyledInputBox = styled.input`
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  height: 20px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  color: #344054;
  background: #ffffff;
  color:${palette.charcoleBlack}; 
  border: 0.5px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;

  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;

const Label = styled.label`

    font-weight: 600;
    font-size: 13px;
    margin-left:4px;
    color: ${palette.charcoleBlack};
    display: flex;
    margin-bottom: 8px;
    margin-top:20px;
    align-self: flex-start;
    font-family:Inter;

  
`
