import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'; // Updated with Menu, MenuItem
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { useDispatch } from 'react-redux';
import { SubmitContactStatus, SubmitTypePriority } from '../../../store/Actions/SettingsAction';
import { imageLinks } from '../../../utils/CommonFile'; // Assuming imageLinks is your icon array
import { palette } from '../../../utils/Palette';
import { Suspense } from 'react';
const LazyIcon = React.lazy(() => import('./LazyComponent'));

const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  background-color: #FAFAFA;
  box-shadow: 0 12px 48px rgba(32,40,77,0.4), 0 32px 80px rgba(32,40,77,0.1);
  padding: 20px;
  padding-top:10px;
  padding-bottom:10px;
  border-radius: 8px;
  position: relative;
  height: auto;
  max-height: 60vh;
  width: 20%;
  z-index: 150;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Label = styled.label`
    font-weight: 600;
    font-size: 13px;
    color: #344054;
    display: flex;
    margin-bottom: 8px;
    align-self: flex-start;
    width:85%;
    font-family: Inter;
    text-align:start;
    margin-left: 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-all;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Inputbox = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 10.5px;
  margin-right: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelectedIconDisplay = styled.div`
  display: flex;

  flex-direction:column;
  margin-top: 10px;
`;


const icons = imageLinks;

const Addstatus = ({ isVisible, onClose, onSubmit, type }) => {
    const [tagName, setTagName] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [error, setError] = useState('')
    const dispatch = useDispatch();

    const handleAddField = async () => {
        if (!tagName) {
            return setError('Name field cannot be empty!');
        }
        if (!selectedIcon) {
            return setError('Please select an icon!');
        }

        const res = await dispatch(SubmitContactStatus({

            "value": tagName,
            "icon": selectedIcon,
            "color":selectedColor
        }));
        setTagName('');
        setSelectedIcon(null);
        setSelectedColor(null);
        onSubmit();
    };
    

    const handleIconClick = (event) => {
        setAnchorEl(event.currentTarget); 
    };

    const handleIconSelect = (icon,color) => {
        setSelectedIcon(icon);
        setSelectedColor(color)
        if(error){
            setError('')
        }
        setAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (!isVisible) return null;

    return (
        <Overlay>
            <ModalWrapper>
                <HeaderContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloseButton onClick={() => {
                            onClose(false)
                            setError('')
                            setTagName('')
                            setSelectedIcon(null)
                            }}>
                            <Cancel />
                        </CloseButton>
                        <h4>{`Add status`}</h4>
                    </div>
                </HeaderContainer>
                <div style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}>
                    <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label>Name</Label>
                        <Inputbox
                            placeholder="Name"
                            type="text"
                            value={tagName}
                            onChange={(e) => {
                                setTagName(e.target.value)
                                if(error){
                                    setError('')
                                }
                            }}
                        />
                    </InputBoxContainer>

                    {/* Icon Selector */}
                    <InputBoxContainer style={{ marginLeft: '0px' }}>
                        <Label>Select Icon</Label>
                        <Button
                            onClick={handleIconClick}
                            style={{
                                background: '#fff',
                                display: 'inline-block',
                                padding: '5px',
                                borderRadius: '8px',
                                border: `1.5px solid ${palette.newBlue}`,
                                cursor: 'pointer',
                                color: `${palette.newBlue}`,
                                textTransform: 'capitalize'

                            }}
                        >
                            {"Select an Icon"}
                        </Button>


                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}

                            onClose={handleMenuClose}
                            onClose={handleMenuClose}
                            transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                            PaperProps={{
                                style: {
                                    maxHeight: '300px',
                                    width: '300px',
                                    overflowY: 'auto',
                                },
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    maxHeight: '300px',
                                    width: '300px',
                                    mt: -1,

                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                        >
                            <Suspense fallback={<CircularProgress />}>
                                <Grid container spacing={2} style={{ padding: '10px', overflow: 'scroll', flexWrap: 'wrap' }}>
                                    {icons.map((iconItem, index) => (

                                        <Grid item xs={2.4} key={index}>

                                            <IconButton
                                                onClick={() => handleIconSelect(iconItem?.imageLink,iconItem?.color)}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '5px 0px',
                                                    paddingTop: '8px'
                                                }}
                                            >
                                                <LazyIcon iconItem={iconItem?.imageLink} />
                                               
                                                {/* <img style={{width:'45px',height:'45px'}} src={iconItem}/> */}
                                            </IconButton>

                                        </Grid>

                                    ))}
                                </Grid>
                            </Suspense>
                        </Menu>
                    </InputBoxContainer>
                    {error && (
                        <Typography style={{ color: 'red', textAlign: 'start', marginLeft: '5px', marginBottom: '10px' }} variant="body2">
                            {error}
                        </Typography>
                    )}


                    {selectedIcon && (
                        <SelectedIconDisplay>
                            <Typography style={{ textAlign: 'start' }} variant="body2">Selected Icon: </Typography>
                            <img style={{ width: '45px', height: '45px', marginTop: '5px' }} src={selectedIcon} />
                        </SelectedIconDisplay>
                    )}
                </div>

                <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginBottom: '20px', marginTop: '10px', justifyContent: 'flex-end' }}>
                    <RippleButton style={{ background: '#ddd', color: '#000', border: 'none', marginRight: '15px' }} onClick={() => {
                        onClose(false)
                             setError('')
                            setTagName('')
                            setSelectedIcon(null)
                        }}>Cancel</RippleButton>
                    <RippleButton style={{ marginRight: -15 }} onClick={handleAddField}>{"Add"}</RippleButton>
                </div>
            </ModalWrapper>
        </Overlay>
    );
};

Addstatus.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dataList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Addstatus;
