import React, { useState } from "react";
import styled from "styled-components";
import { FiTrash2 } from "react-icons/fi";
import { palette } from "../../../utils/Palette";
import { TableContainer, TableHead, TableCell, Table, TableRow, TableBody } from "@mui/material";
import { ContactMail, LowPriorityTwoTone } from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deletecontactStatus, getAllContactStatus } from "../../../store/Actions/SettingsAction";
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography } from '@mui/material';
import Addstatus from "../component/AddStatus";

const CustomizeLeadStatus = () => {
    const [fields, setFields] = useState([

    ]);

    const [isnewCustomfield, setIsNewCustomField] = useState(false)
    const [type, setType] = useState("job type")
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()


    const init = async () => {
        setLoader(true)
        const res = await dispatch(getAllContactStatus())
        if (res?.status == true) {
            console.log(res, "jhjhvjh")
            setFields(res?.data?.statusOptions)
        }
        setLoader(false)
    }
    useEffect(() => {
        init()
    }, [])

    const deleteHandler = async (type) => {
        const res = await dispatch(deletecontactStatus(type))
        init()

    }
    const renderItem = (fields, type) => {

        return (
            <>
                
                {loader ? <StyledPlayer> <Player
                    src={require('../../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </StyledPlayer>
                    : <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                        <CustomHead>
                            <TableRow >
                                <TableCell className="sticky" size="small" style={{ cursor: 'pointer', width: '100px', }}>
                                    #</TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                    Name
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700', width: '70px', }} size='small'>
                                    Marker
                                </TableCell>

                                <TableCell style={{ width: '100px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                                    Actions
                                </TableCell>

                            </TableRow>
                        </CustomHead>

                        {fields?.map((field, index) => (
                            <TableBody key={index} style={{ background: '#fff', cursor: 'pointer' }} >
                                <TableRow >
                                    <TableCell style={{ fontWeight: '500' }} className="sticky" size='small'>{index + 1}</TableCell>

                                    <TableCell size='small' style={{ fontWeight: '500', color: palette.newBlue }}  ><a style={{ fontSize: '12px', color:field?.color,marginRight:'5px'}}>{'●  '}</a>{` ${field?.value}`}</TableCell>

                                    <TableCell size='small'  >
                                        <img
                                            src={field?.icon}
                                            alt={field?.icon}
                                            style={{ width: '25px', height: '25px' }}
                                        />

                                    </TableCell>
                                    <TableCell size='small'  >
                                        <ActionIcons>

                                            <FiTrash2 onClick={async () => {
                                                deleteHandler(field?.value)
                                            }} />

                                        </ActionIcons >
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        ))}
   {fields?.length > 0 ? null : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="60vh"
                        sx={{ color: 'gray' }}
                    >
                        {type == 'contact' ? <ContactMail sx={{ fontSize: '50px', marginBottom: 2 }} /> : <LowPriorityTwoTone sx={{ fontSize: '50px', marginBottom: 2 }} />}
                        <Typography variant="h5" align="center">
                            No custom status available
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            You can add new custom status to manage your data more effectively.
                        </Typography>
                    </Box>
                )}

                    </Table>}
             

            </>
        )
    }

    return (
        <Container>


            <CustomTable>
                <Header>
                    <h2>Lead status</h2>
                    <AddButton  onClick={() => {

                            setIsNewCustomField(true)
                            setType(type)
                        }}>+ New Status</AddButton>
                </Header>

                {renderItem(fields, "job type")}


            </CustomTable>
            <Addstatus
                isVisible={isnewCustomfield}
                type={type}
                onClose={() => setIsNewCustomField(false)}
                onSubmit={() => {

                    setIsNewCustomField(false)
                    init()

                }}
            />
        </Container>
    );
};

export default CustomizeLeadStatus;
const Container = styled.div`

width:100%;
background:#fff;
padding-top:30px;

display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`;



const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

const Tab = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #f4f4f4;
  color: #333;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0F52BA;
    color: white;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right:20px;
  margin-left:20px;

  h2{
    margin-top:0px;
    margin-bottom:0px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
`;

const AddButton = styled.button`
  background-color: #0F52BA;
  color: white;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #08417c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`;
const ButtonText = styled.h4`
  text-align: start;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #0F52BA;
  cursor: pointer;
`;




const StyledPlayer = styled.div`
display:flex;
height:60vh; 
align-items:center; 
justify-content:center; 
`

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }


  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;

flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:30px;
  max-width:30px;

}
`