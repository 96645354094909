import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TextField, Typography, MenuItem, Select } from '@mui/material';
import DatePickerComponent from '../../../ReuseableComponets/DatePickerComponent';
import { colorThemes, TimeListAm, TimeListPM } from '../../../utils/Commonfunction';
import { Cancel, ScheduleOutlined } from '@mui/icons-material';
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import { palette } from '../../../utils/Palette';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import { cross } from '../../../assets';
import InputBox from '../../../ReuseableComponets/InputBox';
import { submitAdedVisit, submitEditVisit } from '../../../store/Actions/JobAction';
import moment from 'moment';
const filter = createFilterOptions();
const AddTask = (props) => {
    const [addTaskLoader, setAddTaskLoader] = useState(false);
    const [value3, setValue3] = useState(null)
    const [error, setError] = useState(false);
    const [selectedTeam, setSelectedTeam] = React.useState(props?.value?.worker||[])
    const dispatch = useDispatch();
    const [taskValues, setTaskValues] = useState({
        date:  props?.value?.date || '',
        endDate:  props?.value?.endDate || '',
        time:props?.value?.time?moment(props?.value?.time).format('hh:mm A'): "",
        endTime:props?.value?.endTime?moment(props?.value?.endTime).format('hh:mm A'): "",
        description:props?.value?.description|| '',
        "title":props?.value?.title|| '',
    });

    const handlePress = async () => {

        var newDate = taskValues?.time ? new Date() : '';
        var newDate1 = taskValues?.endTime ? new Date() : '';
        const selectedData = []
        selectedTeam?.map((item) => {
            selectedData.push(item?.email)
        })
        if (taskValues?.time) {
            const val = taskValues.time.split(':');
            const val2 = val[1].split(' ');
            var hours = parseInt(val[0], 10);
            if (val2[1] === 'PM' && hours < 12) {
                hours += 12;
            }
            if (val2[1] === 'AM' && hours === 12) {
                hours = 0;
            }
            newDate.setHours(hours);
            newDate.setMinutes(parseInt(val2[0], 10));
        }

        if (taskValues?.endTime) {
            const val3 = taskValues.endTime.split(':');
            const val4 = val3[1].split(' ');
            let hours1 = parseInt(val3[0], 10);
            if (val4[1] === 'PM' && hours1 < 12) {
                hours1 += 12;
            }
            if (val4[1] === 'AM' && hours1 === 12) {
                hours1 = 0;
            }
            newDate1.setHours(hours1);
            newDate1.setMinutes(parseInt(val4[0], 10));
        }
        if(props?.value?._id){
            await dispatch(submitEditVisit({ ...taskValues, "worker": selectedData, time: newDate, endTime: newDate1, }, props?.referenceNo, props?.value?._id))
        }
        else{

        const res = await dispatch(submitAdedVisit( { "multiVist": [
            { ...taskValues, "worker": selectedData, time: newDate, endTime: newDate1, }]},props?.referenceNo))
        }
        props?.onSubmit()


    };
    const roundToNearestHalfHour = (date) => {
        const momentDate = moment(date);
        const minutes = momentDate.minutes();
        const roundedMinutes = minutes < 15 ? 0 : (minutes < 45 ? 30 : 60);
        if (roundedMinutes === 60) {
            momentDate.add(1, 'hour');
        }

        momentDate.minutes(roundedMinutes).seconds(0);

        return `${momentDate.format('hh:mm A')}`;
    };



    return (
        <Container>
            <Header>

                <Cancel style={{ marginRight: '15px' }} onClick={() => props?.onClose()} />
                <Typography style={{ fontSize: '18px', fontWeight: '550' }} variant="h5">{props?.value?._id?"Edit visit": props?.header}</Typography>
            </Header>

            <FormContainer>
                <InputBox
                    style={{ width: '91%', height: '18px' }}
                    header={"Visit title"}
                    value={taskValues?.title}
                    placeholder={'Visit title'}
                    onChange={(e) => setTaskValues({ ...taskValues, title: e.target.value })}
                />

                <InputContainer>
                    <DatePickerComponent
                        label="Start date"
                        selectedDate={taskValues.date}
                        onDateChange={(date) => {
                            console.log(date)
                            setTaskValues({ ...taskValues, date:date, endDate: date, time: roundToNearestHalfHour(new Date()), endTime: roundToNearestHalfHour(moment(new Date()).add(1, 'hour')) })
                        }}
                    />
                    < div style={{ position: 'relative' }}>
                        <Label style={{ fontWeight: '600' }}>Start time</Label>
                        <SelectTimebox
                            style={{ marginLeft: '0px' }}

                            renderValue={() =>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                    {taskValues.time || 'Time'}
                                    <ScheduleOutlined style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                </div>
                            } defaultValue={"REAL_ESTATE"} onChange={(event) => setTaskValues({ ...taskValues, time: event.target.value })}>
                            {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                            )}
                        </SelectTimebox>
                    </div>
                </InputContainer>
                <InputContainer>
                    <DatePickerComponent
                        label="End date"
                        selectedDate={taskValues.endDate}
                        onDateChange={(date) => setTaskValues({ ...taskValues, endDate:date })}
                    />
                    < div style={{ position: 'relative' }}>
                        <Label style={{ fontWeight: '600' }}>End time</Label>
                        <SelectTimebox
                            style={{ marginLeft: '0px' }}

                            renderValue={() =>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                    {taskValues?.endTime || 'Time'}
                                    <ScheduleOutlined style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                </div>
                            } defaultValue={"REAL_ESTATE"} onChange={(event) => setTaskValues({ ...taskValues, endTime: event.target.value })}>
                            {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                            )}
                        </SelectTimebox>
                    </div>
                </InputContainer>

                <InputText
                    placeholder="Description"
                    type="text"
                    value={taskValues.description}
                    onChange={(e) =>
                        setTaskValues({ ...taskValues, description: e.target.value })
                    }
                />


                <div style={{ marginBottom: '5px', marginTop: '5px' }} className='authoCompletewrapper'>
                    <Autocomplete1
                        value={value3}
                        onChange={(event, newValue) => {

                            const searchString = 'Add new contact';

                            if (newValue && newValue?.firstName?.includes(searchString)) {

                                // setOpenAddContact(true)
                            }

                            else if (typeof newValue?.firstName === 'string') {
                                //setValue2(result);
                                setSelectedTeam([...selectedTeam, newValue])

                                // setSelectedHash([...selectHash, result])
                            }
                            else {
                                //setValue2(result)
                                setSelectedTeam([...selectedTeam, newValue])
                                //  setHasTag([...selectHash,result])
                            }

                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option?.firstName);

                            // filtered.push({ name: `Add new contact` });


                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={props?.team || []}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option?.firstName === 'string') {
                                return option.firstName;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option;
                        }}
                        renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.firstName}</li>}
                        sx={{
                            width: '99%', '& input': {
                                height: '5px', // Define the height of the input box here
                            },
                        }}

                        style={{ height: 35, marginBottom: '20px', fontSize: '12px' }}
                        renderInput={(params) => (
                            <div>
                                <TextField style={{ fontSize: '12px' }} {...params}


                                    placeholder="Add crew member" />

                            </div>
                        )}
                    />
                    {selectedTeam?.length > 0 ? <WrapperField style={{ marginTop: '0px' }}>
                        {selectedTeam?.map((item, index) =>
                            <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, }}>{item?.firstName} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                const arr = selectedTeam?.filter(obj => obj?.email !== item?.email)
                                setSelectedTeam(arr)
                            }} src={cross} /></WrapperContainer>
                        )}
                    </WrapperField> : null}
                    {/* <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                }}>Cancel</SendButton1>
                                <SendButton1 style={{ marginRight: -15 }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                    // setValue({ ...value, ...editValue })
                                    // setEditDetail(false)
                                }}>Save</SendButton1>
                            </div> */}
                </div>

                {error && <ErrorMessage>Add contact ⚠</ErrorMessage>}

                <ButtonContainer>
                    <RippleButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ width: '100%', height: '40px' }}
                        onClick={async () => handlePress()}

                    >
                        {addTaskLoader ? 'Adding...' :props?.value?._id?"Save": 'Add Visit'}
                    </RippleButton>
                </ButtonContainer>
            </FormContainer>
        </Container>
    );
};

export default AddTask;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top:10px;
  margin-left:-15px;
  margin-bottom: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputContainer = styled.div`
  display: flex;
justify-content:space-between;
  gap: 20px;
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 0px;
  margin-bottom:10px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
`;

const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.renderdiv{
    display:flex;
    justify-content:space-between; 
    align-items:center; 
    font-size:13px; 
}
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const ContactLabel = styled.p`
color:${palette.black};
font-size: 13px;
text-align:start;
line-height:16px;
width:100%;
margin-top:0px;
margin-bottom:4px;
flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

.nameButtton{
    font-weight:600;
    color:${palette.charcoleBlack}
}

`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-break:break-all;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`

const WrapperField = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;

`;
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 93%;


  display: block;
  border-radius: 4px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;