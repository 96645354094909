import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import GPS from '../../assets/gps.png'
import io from 'socket.io-client';
import payments from '../../assets/paymentsIconss.png'
import payment from '../../assets/payment.png'
import reviews from '../../assets/reviews.png'
import Sendicon from '../../assets/message1.png'
import calendasr from '../../assets/contactBookInactive.png'
import cross from '../../assets/cross.png'
import quote from '../../assets/quote.png'
import invoice from '../../assets/invoice.png'
import google from '../../assets/Google.png'
import iconlocation from '../../assets/iconlocation.png'
import edit from '../../assets/edit.png'
import calltel from '../../assets/viber.png'
import Openarrow from '../../assets/down.png'
import Closearrow from '../../assets/up.png'

import { getAllinchats, getAlloutchats, getUserData, sendMessage } from '../../store/Actions/Auth.action'
import { formatMobileNumber, TimeListAm, TimeListPM } from '../../utils/Commonfunction';
import Modals from '../Modal';
import Calendar from "react-calendar";
import UserDetails from './UserDetails';
import CreateInvoice from './CreateInvoice'
import CreateBooking from './CreateBooking'
import CreateQuote from './CreateQuote'
import FullScreenModal from '../FullScreenModal'
import { Player } from '@lottiefiles/react-lottie-player'
import { palette } from '../../utils/Palette'
import VideoJS from '../../ReuseableComponets/VideoJS'
import moment from 'moment'
const socket = io('https://apis.getnotifi.com',{autoConnect:false});

function RightSideConversations(props) {
  const globename = useSelector(state => state?.auth?.globename)
  const globenumber = useSelector(state => state?.auth?.globenum)
  const token = useSelector(state => state?.auth?.token)
  const globecolor = useSelector(state => state?.auth?.globecolor)
  const [message, setMessage] = useState(null)
  const [body, setBody] = useState(null)
  const [num, setNum] = useState(null)
  const [isModalopen, setModalOpen] = useState(false);
  const [isSlectModal, setIsSelectModal] = useState(false);
  const [isModalopenInvoice, setModalOpenInvoice] = useState(false);
  const [isModalopenQuote, setModalOpenQuote] = useState(false);
  const [loader, setLoader] = useState(false)
  const [show, setisShow] = useState(false)

  const [alldata, setAlldata] = useState([])
  // const [details,setDetails]=useState(null)
  let fromnumber = localStorage.getItem('fromnumber')

  const detail = useSelector(state => state?.auth?.convo)
  //const firstnumber = detail ? detail?.[0]?.name ? detail?.[0]?.name : detail?.[0]?.to :''
  const frstnum = detail ? detail?.[0]?.to : ''
  let dispatch = useDispatch()

  const init = async () => {
      setLoader(true)
    var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
    const res = await dispatch(getAllinchats(`+${cleaned2}`));
    const Dates = [...res?.data]
    Dates.sort((a, b) => new Date(b?.date) - new Date(a?.date))
    setAlldata(Dates)
    setLoader(false)
    // dispatch(getAlloutchats(globenumber?globenumber:frstnum,fromnumber));
    dispatch(getUserData())
  }
  useEffect(() => {

   // setAlldata(props?.value?.conversationIds?.sort((a, b) => new Date(b?.date) - new Date(a?.date)))
    init()
  }, [globenumber, frstnum, fromnumber])
  const outchats = useSelector(state => state?.auth?.outchats)
  const inchats = useSelector(state => state?.auth?.inchats)
  const userdata = useSelector(state => state?.auth?.userData)



  useEffect(() => {
    var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
    // socket.emit('join_room','hello worldddd')
    socket.connect()
    socket.on('conversation', (msg) => {
      console.log(msg, cleaned2, localStorage.getItem('number'))
      if (msg[0].from === `+${cleaned2}` && msg[0].to === `+${localStorage.getItem('number')}`) {
        init()
      }
      // setBody(msg?.[1])
      // setNum(msg?.[0].from)
      socket.close()
      socket.connect()
    })
    return () => {
      socket.off('conversation');
    };
  }, []);
  //  const handledate=(item)=>{
  //     const dateString = item;
  //     const date = new Date(dateString);
  //     const hours = date.getUTCHours();
  //     const minutes = date.getUTCMinutes();
  //     let hours12 = hours % 12;
  //     if (hours12 === 0) {
  //     hours12 = 12;
  //     }
  //    let customminutes=minutes<10 ?'0'+minutes:minutes
  //    const meridiem = hours < 12 ? "AM" : "PM";
  //    const formattedTime = `${hours12}:${customminutes} ${meridiem}`;
  //    return formattedTime
  //  }



  const handlesend = (e) => {
    setMessage(e.target.value)
  }
  const sendchat = async (val) => {
    setMessage('')
    if (message || val) {
      await dispatch(sendMessage(
        {
          "from": fromnumber,
          "to": props?.value?.number,
          "body": val ? val : message
        }
      ))

      var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
      const res = await dispatch(getAllinchats(`+${cleaned2}`));
      const Dates = [...res?.data]
      Dates.sort((a, b) => new Date(b?.date) - new Date(a?.date))
      setAlldata(Dates)

    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendchat();
    }
  };
  function formatChatTimestamp(dateString, userTimeZone) {
    const currentDate = new Date();
    const chatDate = new Date(dateString + 'T00:00:00'); // Assume midnight for simplicity

    // Adjust the chatDate to the user's time zone
    chatDate.toLocaleString('en-US', { timeZone: userTimeZone });

    const timeDifference = Math.abs(currentDate - chatDate);
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (hoursDifference < 24) {
      // Recent chat within 24 hours
      return `Today`;
    } else if (hoursDifference < 48) {
      // Recent chat within 24 hours
      return `Yesterday`;
    } else {
      // Older chat, display the date
      const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
      return `${chatDate.toLocaleDateString('en-US', dateOptions)}`
    }
  }

  const combinedArray = outchats && inchats ? outchats?.concat(inchats) : ''
  // let alldata= combinedArray?combinedArray?.sort((a, b) => {
  //   return  new Date(b.createdAt) - new Date(a.createdAt);
  // })
  // :''
  useEffect(() => {
    if (body) {
      if (globenumber === num) {
        combinedArray.push(body)
      }
    }
  }, [num, body, globenumber])

  const extractMoneyfromUrl = (url) => {
    const urlString = url;
    const priceRegex = /\$\d+\.\d{2}/;
    const match = urlString?.match(priceRegex);

    if (match && match.length > 0) {
      const price = match[0];

      return price; // Returning the actual price without converting it to a string
    }
    else {
      return message?.price
    }
  }

  function checkInvoiceQuote(bookingMessage) {
    const normalizedMessage = bookingMessage?.replace(/\n/g, '').toLowerCase();

    const baseResponse = {
        body: bookingMessage,
        type: "outbound-reply",
        createdAt: new Date()
    };

    if (normalizedMessage) {
        if (normalizedMessage.includes('invoice attached') || normalizedMessage.includes('attached invoice')) {
            return { ...baseResponse, invoice: true, price: extractMoneyfromUrl() };
        } else if (normalizedMessage.includes('quote attached') || normalizedMessage.includes('attached quote')) {
            return { ...baseResponse, quote: true, price: extractMoneyfromUrl() };
        } else if (normalizedMessage.includes('quick review')) {
            return { ...baseResponse, google: true };
        } else if (normalizedMessage.includes('track us using')) {
            return { ...baseResponse, track: true };
        }
    }

    return baseResponse;
}
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsSelectModal(false); // Close the modal when clicking outside
    }
  };


  return (
    <Main >
      <div>
        <FlexHeader>
          <div style={{ display: 'flex' }}>
            <Profile>
              <span>{props?.value?.name?.toUpperCase().slice(0, 1)}</span>
            </Profile>
            <Name>{props?.value?.name}</Name>
          </div>
          {/* <Line1>|</Line1>
<div>
<ReviewStar src='https://app.podium.com/static/media/review.9e8d7fce974894c5320fbeb93e8d4acb.svg' alt='review'/>
</div> */}
          <div style={{ display: 'flex' }}>
            <IconBox onClick={() => {
              const call = token.connect({
                params: {
                  to: props?.value?.number,
                  from: `+${localStorage.getItem('number')}`
                }
              })

              props?.setConnection(call)
              dispatch({
                type: "CALLDETAIL", payload: {
                  name: props?.value?.name,
                  to: props?.value?.number
                }
              })
              dispatch({ type: "CALLSHOW", payload: true })
            }}>
              <Icon src={calltel} alt={calltel} />
            </IconBox>
            <IconBox>
              <Icon src={edit} alt={edit} />
            </IconBox>
          </div>

        </FlexHeader>

        <Chats >

         {loader  ? <div style={{ display: 'flex', height: '73vh', alignItems: 'center', justifyContent: 'center' }}> <Player
                        src={require('../../assets/loaders.json')}
                        className="player"
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    />
                    </div>: <Texts2  >
            {props?.value ?
              alldata
                ?.map((x, index) => {
                  return (
                    <div  key={index}>
                      <Name style={{ fontSize: 12, fontWeight: '400', color: 'gray', marginTop: '15px', marginBottom: '25px', marginLeft: -10 }}>{formatChatTimestamp(x?.date, 'America/Toronto')}</Name>
                      {x?.messages?.map((x, index) => {
                        return (
                          x?.type === 'outbound' || !x.type ?
                            <OutText  >
                               <span className="tooltip">{moment(x?.createdAt).format('hh:mm A')}</span>
                              <OutChat>
                                {x?.invoice || checkInvoiceQuote(x?.message)?.invoice || checkInvoiceQuote(x?.message)?.quote || x?.type !== "inbound" && x?.quote || checkInvoiceQuote(x?.message)?.google || x?.google || x?.type !== "inbound" && checkInvoiceQuote(x?.message)?.track || x?.track ?
                                  checkInvoiceQuote(x?.message)?.google || x?.google ? <GoogleContainer><ChatIcons style={{ width: '15px', height: '15px', padding: 5 }} src={google} /></GoogleContainer> :
                                    <ChatIcons src={checkInvoiceQuote(x?.message)?.invoice || x?.invoice ? invoice : checkInvoiceQuote(x?.message)?.track || x?.track ? iconlocation : quote} /> : null}
                                <Messagetext>{checkInvoiceQuote(x?.message)?.invoice || x?.invoice ? `Payment request of ${extractMoneyfromUrl(x?.message) || x?.price}` : checkInvoiceQuote(x?.message)?.google || x?.google ? 'Leave us a review!' : checkInvoiceQuote(x?.message)?.track || x?.track ? "We're on our way." : checkInvoiceQuote(x?.message)?.quote || x?.quote ? `Quote sent for ${extractMoneyfromUrl(x?.message) || x?.price}` : x?.message}</Messagetext>
                                {/* <p className='outtime'>{handledate(x.createdAt)}</p> */}
                              </OutChat>

   
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor:palette.newBlue,
                                  //backgroundColor:"red",
                                  width: 20,
                                  height: 15,
                                  bottom: -2,
                                  borderBottomLeftRadius: 25,
                                  right: -10
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "#ffffff",

                                  //backgroundColor:"green",
                                  width: 20,
                                  height: 35,
                                  bottom: -6,
                                  borderBottomLeftRadius: 18,
                                  right: -20

                                }}
                              />
                            </OutText>
                            : x?.type === 'inbound' ?
                              <>
                             
                                <InText>
                                  <InChat>
                                  {x?.responseType === 'MMS' ? x?.contentType === 'video/mp4' ? <VideoJS options={{
                                      autoplay: false,
                                      controls: true,
                                      responsive: false,
                                      fluid: true,
                                      sources: [
                                        {
                                          src: x.message,
                                          type: 'video/mp4'
                                        },

                                      ]
                                    }} onReady={(player) => {
                                      // Handle player ready event here
                                      console.log('Player ready:', player);
                                    }} /> : <img style={{ width: '250px', marginTop: '15px', marginBottom: '15px' }} src={x?.message} /> :

                                      <Messagetext>{x?.message}</Messagetext>

                                    }
                            </InChat>
                            <span className="tooltip">{moment(x?.createdAt).format('hh:mm A')}</span>
                                  <div
                                    style={{
                                      position: "absolute",
                                      backgroundColor: '#F3F3F3',
                                      //backgroundColor:"red",
                                      width: 20,
                                      height: 15,
                                      bottom: -2,
                                      borderBottomRightRadius: 20,
                                      left: -10
                                    }}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      backgroundColor: "#ffffff",
                                      //backgroundColor:"green",
                                      width: 20,
                                      height: 35,
                                      bottom: -6,
                                      borderBottomRightRadius: 18,
                                      left: -20

                                    }}
                                  />
                                </InText>
                              </>
                              : ''
                        )
                      })}
                    </div>
                  )
                })
              : ''}
          </Texts2>}
        </Chats>
      </div>
      <InputContainer>
        {/* <Flexbox style={{ width: '150%' }}>
            <Flexbox onClick={() => setModalOpen(true)}>
              <ItemIcon src={calendasr} />
              <Element>&nbsp;Create booking&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setModalOpenQuote(true)}>
              <ItemIcon src={payment} />
              <Element>&nbsp;Quote&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setModalOpenInvoice(true)} >
              <ItemIcon src={payments} />
              <Element>&nbsp;Invoice&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setMessage(`Please leave us a quick review by clicking the link below:https://app.getnotifi.com/review/${new Date().getDate()}/${userdata?.placeId}`)}>
              <ItemIcon src={reviews} />
              <Element>&nbsp;Ask for review&nbsp;</Element>
            </Flexbox>
          </Flexbox> */}
          

        <div style={{ position: 'relative' }}>
          {isSlectModal ?
          <div onClick={handleOverlayClick} style={{
            zIndex: 1500,
            position: 'fixed',
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignContent: "stretch",
            alignItems: "center",
          }}>
          <div style={{
            backgroundColor: '#FAFAFA',
            boxShadow: "0 12px 48px rgba(32,40,77,.08),0 32px 80px rgba(32,40,77,.1)",
            opacity: 1.0,
            borderRadius: '8px',
            width: "250px",
            background: 'white',
            position: 'absolute', /* Change to absolute positioning */
            top: 'calc(100% - 290px)', /* Adjust top position to position above the button */
            left: "50px",

            zIndex: 1000,
          }
          } >
            <Flexbox style={{ display: 'inline', }}>
              <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                 setIsSelectModal(false)
                setModalOpen(true)}}>
                <ItemIcon src={calendasr} />
                <Element>&nbsp;Create booking&nbsp;</Element>
              </Flexbox>
              <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                setIsSelectModal(false)
                setModalOpenQuote(true)}}>
                <ItemIcon src={payment} />
                <Element>&nbsp;Quote&nbsp;</Element>
              </Flexbox>
              <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                 setIsSelectModal(false)
                setModalOpenInvoice(true)}} >
                <ItemIcon src={payments} />
                <Element>&nbsp;Invoice&nbsp;</Element>
              </Flexbox>
              <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => setMessage(`Please leave ${userdata?.businessName} a quick review by clicking the link below:https://app.getnotifi.com/review/${new Date().getDate()}/${userdata?.placeId}`)}>
                <ItemIcon src={reviews} />
                <Element>&nbsp;Ask for review&nbsp;</Element>
              </Flexbox>
            </Flexbox>

          </div>
          </div>
           : null}
          {/* <FlexboxForm onClick={() => isSlectModal ? setIsSelectModal(false) : setIsSelectModal(true)} style={{ alignItems: 'center', justifyContent: 'start',  }} >

            <SelectForm>&nbsp;Select&nbsp;</SelectForm>{
              isSlectModal ? <Arrow src={Closearrow} alt='open' /> : <Arrow src={Openarrow} alt='open' />

            }

          </FlexboxForm> */}
        </div>

        <Elements>
          <InputBox aria-rowcount={3} type="text" placeholder="Send a message" onKeyPress={handleKeyPress} value={message} onChange={(e) => handlesend(e)} />
          <SendIcon src={Sendicon} onClick={() => sendchat()} />
          {/* <CustomButton style={{ border: '1px solid #4169e1', background: '#4169e1', borderRadius: '4px', color: '#fff', marginBottom: '15px', height: '35px', width: '120px', fontSize: 14 }} onClick={() => sendchat()}>
            Send
          </CustomButton> */}
        </Elements>


        {isModalopen && (
          <Modals onClose={() => setModalOpen(false)}>
            <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
              <h2>Create booking </h2>
              <CloseButton onClick={() => setModalOpen(false)}>
                <Icons src={cross} />
              </CloseButton>
            </div>
            <CreateBooking
              {...props}
              value={props?.value}
              onSubmitsw={(val) => {

                sendchat(val)
                setModalOpen(false)


              }
              }
            />


          </Modals>
        )}

        {isModalopenInvoice && (
          <FullScreenModal onClose={() => setModalOpenInvoice(false)}>
          
            <CreateInvoice
              {...props}
              value={props?.value}
              setModalOpenInvoice={()=>setModalOpenInvoice(false)}
              onSubmitsw={(val) => {

                sendchat(val)
                setModalOpenInvoice(false)


              }
              }
            />


          </FullScreenModal>
        )}
        {isModalopenQuote && (
          <FullScreenModal onClose={() => setModalOpenQuote(false)}>
           
            <CreateQuote
            {...props}
              value={props?.value}
              setModalOpenInvoice={()=>setModalOpenQuote(false)}
              onSubmitsw={(val) => {

                sendchat(val)
                setModalOpenQuote(false)


              }
              }
            />


          </FullScreenModal>
        )}

      </InputContainer>
    </Main>
  )
}

export default RightSideConversations

const Main = styled.div`
flex-grow: 1;
overflow-x:hidden;
margin-top:-10px;
padding-top:25px;
scrollbar-width: thin;
scrollbar-color: #888 transparent;
padding-bottom:20px;



  display: flex;
  flex-direction: column;
  justify-content: space-between;


`
const Name = styled.p`
text-align:center;
padding-left:10px;
font-size: 16px;
line-height: 21px;
color: rgb(45, 47, 49);
font-weight: 600;
margin-top:7px;
`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const ChatIcons = styled.img`
width: 35px;
height: 35px;
`
const Element = styled.div`

font-size:14px;
font-weight:500;
margin-right:12px;
cursor:pointer;
`
const Elements = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:flex-start;
align-self:start;

margin-top:-10px;

`
const Arrow = styled.img`

width:20px;
height:22px;

`
const IconBox = styled.div`
width:25px;
cursor:pointer;
height:25px;
display:flex;
background: #DDDDDD;
justify-content:center;
margin-left:15px;
align-items:center;
padding:3px;
border-radius:5px;
margin-left:20px;
color:white;

font-weight:600;
font-size:14px;


`
const Icon = styled.img`
height:15px;
width:15px;

`
const Texts2 = styled.div`
  flex-grow: 1;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x:hidden;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
// const SendMessage = styled.div`
//   position: relative;
//   width: 870px;
//   margin-bottom: 80px;
//   margin-top:15px;
// `;

// const Input = styled.input`
//   width: 95%;
//   border-radius: 30px;
//   border: 1px solid lightgray;
//   padding: 15px;
//   padding-left: 20px;
// `;

const SendIcon = styled.img`
  transform: translateY(-50%);
  width: 25px;


margin-top:20px;

`;
const ItemIcon = styled.img`

  width: 20px;
  height: 20px;
  margin-right:10px;

`;
const Flexbox = styled.div`
display:flex;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05); /* Box shadow on hover */
}

`
const FlexboxForm = styled.div`
display:flex;

height:30px;
align-items:center;
width:100px;
margin-top:-5px;
border-radius:5px;
margin-bottom:10px;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05); /* Box shadow on hover */
}

`
const FlexHeader = styled.div`
display:flex;
border-bottom:1px solid #F3F3F3;
justify-content:space-between;
padding-left:20px;
padding-right:20px;
`
const Profile = styled.div`
width:20px;
padding:3px;
background:#4169e1;
text-align:center;
border-radius:5px;
color:white;
height:20px;
margin-top:4px;
font-weight:600;
font-size:14px;
padding-top:4px;
`
// const Line1=styled.p`
// color:#d4d4d4;
// margin-left:20px;
// margin-right:10px;
// margin-top:8px;
// `
const Line2 = styled.p`
color:#d4d4d4;
margin-left:10px;
margin-right:13px;
margin-top:8px;
`
// const ReviewStar=styled.img`
// margin-top:7px;
// `
const Hr = styled.hr`
border:1px solid #E2E8F0;
margin-top:-3px;
`
const LocateGps = styled.div`
display:flex;
padding-top:10px;
padding-left:8px;
padding-right:8px;
border-radius:8px;
margin-bottom:6px;
font-size:14px;
font-weight:600;
cursor:pointer;
margin-top:-3px;
&:hover {
    background-color:#F3F3F3;
  }
`
const Marker = styled.img`
height:15px;
width:10px;
margin-right:6px;
margin-top:1px;
`
const Chats = styled.div`
padding-left:20px;
padding-right:10px;
padding-top:10px;
`
const GoogleContainer = styled.div`
border-radius:20px;
background:#fff;
display:flex;
justify-content:center;
align-items:center;
margin-right:5px;

.img{

}

`
const InputBox = styled.textarea`
  width: 100%;
  border:none;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 8px; 
  box-sizing: border-box; 
  font-family: Inter;
  min-height: 40px;
  max-height:120px;
  padding-bottom:0px;
  resize: none; 
  border-radius: 4px;
  &:focus {
    outline: none;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background:white;
  white-space: pre-wrap;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12); 
  border-radius: 10px;

  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-top: 14px;
  margin-top: 15px;
  padding-right: 10px;

  @media (min-width: 260px) and (max-width: 820px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`

// const Button1 = styled.button`
// color:rgb(70, 110, 255);
// border:1px solid white;
// background:white;
// margin-right:10px;
// border-radius:6px;
// width:100px;
// font-weight:600;
// cursor:pointer;
// `
// const Button2 = styled.button`
// color:white;
// background:rgb(70, 110, 255);
// font-weight:600;
// border:1px solid white;
// border-radius:6px;
// padding:6px;
// width:60px;
// cursor:pointer;
// `
const OutText = styled.div`
display:flex;
justify-content:flex-end;
margin-right:15px;
margin-bottom:15px;
position: relative;
.tooltip {
  visibility: hidden;
  width: auto;
  color: rgba(32, 32, 32, 0.6);
  text-align: center;
  display:flex;
  align-items:center;
  border-radius: 5px;
  padding: 5px;
  font-size:12px;
  margin-right: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

&:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

    `
    const OutChat = styled.div`
    background:${palette.newBlue};
    color:white;
    border-radius:10px 10px 10px 10px;
    padding:15px;
    padding-bottom:3px;
    word-wrap: break-word; 
      overflow-wrap: break-word; 
     
    padding-top:0px;
    text-align:start;
    max-width:280px;
    word-wrap: break-word;
    word-break: break-word; 
    overflow-wrap: anywhere; 
    display: inline-block;
    display:flex;
    align-items:center;
    margin-right:0px;
    .outtime{
    font-size:12px;
    padding:0px;
    margin-top:-8px;
    }
    `
const Messagetext = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;
`
const InChat = styled.div`
background:#F3F3F3;
border-radius:10px 10px 10px 10px;
padding:12px;
padding-top:0px;
padding-bottom:3px;
text-align:start;
max-width:250px;
word-wrap: break-word; 
  overflow-wrap: break-word; 
  word-break: break-all;
  word-break: break-word; 
  overflow-wrap: anywhere; 
  display: inline-block;
align-items:center;
margin-left:0px;
.intime{
color:rgb(70, 110, 255);
font-size:12px;
padding:0px;
margin-top:-8px;
}
`
const Header = styled(Modal.Header)
  `
font-weight: 400;
font-size: 16px;
display: flex;
letter-spacing: 0.01em;
font-family:Inter;
color: #787373;
justify-content:center;
text-align:center;
margin-left:10px;
margin-right:10px;
`
const SelectForm = styled.p`
display:flex;

font-size:14px;
font-weight:600;
cursor:pointer;
`
const InText = styled.div`
display:flex;
justify-content:flex-start;
margin-bottom:15px;
margin-left:5px;
position:relative;
.pink{
    background:pink;
    }
    .orange{
    background: #ff9900;
    }
    .violet{
    background:#974EDE;
    }
    .tooltip {
      visibility: hidden;
      width: auto;
      color: rgba(32, 32, 32, 0.6);
      text-align: center;
      display:flex;
      align-items:center;
      border-radius: 5px;
      padding: 5px;
      font-size:12px;
      margin-left: 10px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  
    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
`