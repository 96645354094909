import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Stripelist from "./StripeList";
import { getuserInfo, getAccessToken, getUserDetails  } from "../../store/Actions/dashboard.action";
import PaymentStripe from "./Payments";
import AccountManagement from "./Account Management";
import Payouts from "./Payouts";
import PaymentMethodSetting from "./PaymentMethodSettings";


function StripeSetting() {
    const [data, setdata] = useState([])
    const [connection, setConnection] = useState()
    const [index, setIndex] = useState('Inbox')
    const [settingVal, setSettingVal] = useState('Account Management')
    const callShow = useSelector(state => state?.auth?.showCall)
    const incomingcallShow = useSelector(state => state?.auth?.showIncomingCall)
    const detail = useSelector(state => state?.auth?.incominDetail)
    const dispatch = useDispatch()
    const init = async () => {
        await dispatch(getUserDetails())
        await dispatch(getuserInfo())
        await dispatch(getAccessToken())

    }
    connection?.then((call) => {
        call.on("disconnect", () => {
            dispatch({ type: "CALLSHOW", payload: false })
        })
    })


    useEffect(() => {
        init()
    }, [])

    const renderData = (value) => {
        switch (settingVal) {
            case "Account Management":
                return (<AccountManagement />)
                break;
            case "Payments":
                return (<PaymentStripe />)
                break;
            case "Payouts":
                return (<Payouts />)
                case "Documents":
                    return (<PaymentMethodSetting />)
             
            default:
                break;
        }
    }
    return (
        <>

            <Body >
             <div style={{ display: 'flex',width:'100%',height:'100vh' }}>
                    
                    <Stripelist
                        setIndex={(val) => setSettingVal(val)}
                    />
                    {renderData(settingVal)}
                   
                </div>
            </Body>
          
        </>
    )
}

export default StripeSetting;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;


`
const NumberDiv = styled.div`
width:350px;
padding-top:15px;
flex-shrink: 0;
background-color: #DDDDD;
overflow: auto; 
@media (min-width: 260px) and (max-width: 1311px){
    display:none;
}
`